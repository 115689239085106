import React from "react";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import { fmtMoneyUSD } from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faCreditCard,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";

const buildTextLink = (
  category,
  fxIncomeDist,
  cleanCashback,
  viewFinanceId
) => {
  // Link cashbacks
  let linkCashback =
    viewFinanceId &&
    parseInt(viewFinanceId, 10) !== category.id &&
    category.type === "CASHBACK";
  // URL
  let url = null;
  if (category.type === "BUDGET") {
    url = Links.budgetFor(category.id);
  } else if (category.type === "SAVINGS") {
    url = Links.savingsGoalFor(category.id);
  } else if (
    category.type === "INCOME" ||
    category.type === "DEPOSIT" ||
    category.type === "BANKING" ||
    category.type === "STOCK"
  ) {
    url = Links.categoryFor(category.id);
  } else if (linkCashback) {
    url = Links.financeFor(category.id);
  }
  // Name
  let categoryName = category.name;
  if (linkCashback) {
    categoryName = categoryName.replace(/^.*:\s*/, "");
  } else if (cleanCashback) {
    categoryName = categoryName.replace(/:.+/, "");
  }
  // Content
  if (url) {
    const linkCmp = <Link to={url}>{categoryName}</Link>;
    if (category.type === "INCOME") {
      return (
        <div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={fxIncomeDist}
          >
            <FontAwesomeIcon icon={faList} />
          </button>
          <span style={{ marginLeft: "5px" }}>{linkCmp}</span>
        </div>
      );
    } else if (linkCashback) {
      return <span>CASHBACK: {linkCmp}</span>;
    } else {
      return linkCmp;
    }
  } else {
    return <span>{categoryName}</span>;
  }
};

export default function Categorization(props) {
  if (props.categorization) {
    return buildTextLink(props.categorization);
  }
  const categorizations = props.transaction.categories || [];
  const cleanCashback = props.transaction.isSingleCashback || false;
  const viewFinanceId = props.viewFinanceId;
  const isTransfer = props.transaction.transfer;
  const isCreditPayment = props.transaction.creditPayment;
  // Transfer
  if (isTransfer) {
    const _text = isCreditPayment ? "CREDIT PAYMENT" : "TRANSFER";
    const _icon = isCreditPayment ? (
      <FontAwesomeIcon icon={faCreditCard} />
    ) : (
      <FontAwesomeIcon icon={faExchangeAlt} />
    );
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={props.fxShowTransfers}
        >
          {_icon}
        </button>
        <span style={{ marginLeft: "5px" }}>{_text}</span>
      </div>
    );
  }
  // When 1
  if (categorizations.length === 1) {
    return buildTextLink(
      categorizations[0],
      props.fxShowDistributions,
      cleanCashback,
      viewFinanceId
    );
  }
  // Othwerise
  return (
    <table className="table table-sm" style={{ marginBottom: "0" }}>
      <tbody>
        {categorizations.map((category) => (
          <tr key={"c-" + category.id}>
            <td className="text-right">{fmtMoneyUSD(category.amount)}</td>
            <td>
              {buildTextLink(
                category,
                props.fxShowDistributions,
                cleanCashback,
                viewFinanceId
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
