import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Tabs, Tab } from "react-bootstrap";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { stockTransactionUrl } from "../../library/Urls";
import DateSelector from "../common/DateSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSave, faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  toUsMoney,
  UUID,
  removeIndexWithAttr,
  fmtMoneyUSD,
  isDefined,
  isUUID,
  clone,
  pluralize,
} from "../../library/Utilities";
import NumberField from "../common/NumberField";
import { editInlineAlignStyle } from "../../library/Styles";

const TAB_STOCKS = "stocks";
const TAB_EDITOR = "editor";
const DEFAULT_STOCK_EDIT = {
  id: null,
  symbol: "",
  action: "BUY",
  quantity: "",
  amount: "",
};
const DEFAULT_STOCK_SUMMARY = {
  buy: { count: 0, total: 0.0 },
  sell: { count: 0, total: 0.0 },
};

export default class EditStockTransaction extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      batchNumber: props.batchNumber,
      budgetYearMonth: props.budgetYearMonth,
      financeId: props.financeId,
      errorMessage: null,
      actionsEnabled: false,
      // Edit info
      editInfo: null,
      // Stocks
      stocks: [],
      stockSummary: DEFAULT_STOCK_SUMMARY,
      stockEdit: DEFAULT_STOCK_EDIT,
      // Tabs
      selectedTab: TAB_EDITOR,
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: stockTransactionUrl,
      params: {
        bn: this.state.batchNumber,
        ym: this.state.budgetYearMonth,
      },
      onSuccess: (data) => {
        // Collections
        const stocks = data.stocks;
        delete data.stocks;
        // State
        this.setState({
          editInfo: {
            ...data,
            date: data.date ? new Date(data.date) : null,
            fees: data.fees.toFixed(2),
            financeId:
              data.financeId ||
              this.state.financeId ||
              (data.finances.length ? data.finances[0].id : null),
            categorization:
              data.categorization ||
              (data.categorizations.length ? data.categorizations[0].id : null),
            reference: data.reference || "",
          },
          stocks: stocks,
          stockSummary: this.computeStockSummary(stocks),
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  validateTransaction = (values) => {
    const errors = {};
    if (!values.date) {
      errors.date = "Transaction date is required";
    }
    if (toUsMoney(values.amount) < 0.0) {
      errors.amount = "Fees cannot be less than 0.00";
    }
    if (!values.financeId) {
      errors.financeId = "Select financial account";
    }
    if (!values.categorization) {
      errors.categorization = "Select stock categorization";
    }
    const hasErrors = Object.keys(errors).length > 0;
    if (!hasErrors) {
      this.setState({
        transactionInfo: values,
        hasValidationErrors: false,
        actionsEnabled: true,
      });
    } else {
      this.setState({
        hasValidationErrors: true,
        actionsEnabled: false,
      });
    }
    return errors;
  };

  handleSave = (evt) => {
    // Disable actions
    this.setState({
      actionsEnabled: false,
      stockSaveEnabled: false,
    });
    // Transaction
    let transaction = this.state.transactionInfo;
    if (!transaction) {
      const editInfo = this.state.editInfo;
      transaction = { ...editInfo };
      delete transaction.finances;
      delete transaction.categorizations;
      delete transaction.actions;
    }
    // Stocks
    const stocks = [];
    this.state.stocks.forEach((stock) => {
      if (isUUID(stock.id)) {
        stock.id = null;
      }
      stocks.push(stock);
    });
    // Request
    const requestInput = {
      batchNumber: this.state.batchNumber,
      ...transaction,
      fees: Number(transaction.fees),
      financeId: Number(transaction.financeId),
      stocks: stocks,
    };
    // Post
    doHttpPost({
      url: stockTransactionUrl,
      body: requestInput,
      onSuccess: (response) => {
        this.state.onSave(response.batchNumber);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          let htmlError = "";
          error.response.data.errors.forEach((e) => {
            htmlError +=
              (htmlError ? "; " : "") +
              "[" +
              e.field +
              "]: " +
              e.defaultMessage;
          });
          this.setState({
            errorMessage: htmlError,
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  financeItem = (finance) => {
    if (finance) {
      return finance.name;
    }
    return "(UNKNOWN FINANCE)";
  };

  categorizationItem = (categorization) => {
    if (categorization) {
      let name = categorization.name;
      if (categorization.available) {
        name += " [" + fmtMoneyUSD(categorization.available) + "]";
      }
      return name;
    }
    return "(UNKNOWN CATEGORIZATION)";
  };

  validateStock = (values) => {
    const errors = {};
    if (!values.action) {
      errors.action = "Must select a stock action";
    }
    if (!values.symbol) {
      errors.symbol = "Stock symbol is required";
    }
    if (!values.quantity || parseFloat(values.quantity) === 0) {
      errors.quantity = "Quantity must be more than 0.00";
    }
    if (!values.amount || parseFloat(values.amount) === 0) {
      errors.amount = "Total amount must be more than 0.00";
    }
    this.setState({
      stockSaveEnabled: Object.keys(errors).length === 0,
    });
    return errors;
  };

  addStock = (values) => {
    // Stocks
    const stocks = [...this.state.stocks];
    // Stock
    const stock = {
      ...values,
      symbol: values.symbol.toUpperCase(),
      quantity: Number(values.quantity),
      amount: Number(values.amount),
    };
    // ID
    if (stock.id) {
      removeIndexWithAttr(stocks, "id", stock.id);
    } else {
      stock.id = UUID();
    }
    // Add
    stocks.push(stock);
    // State
    this.setState({
      stocks: stocks,
      stockSummary: this.computeStockSummary(stocks),
      stockEdit: clone(DEFAULT_STOCK_EDIT),
      stockSaveEnabled: false,
      actionsEnabled: true,
    });
  };

  editStock = (stockId) => {
    // Find
    const stocks = this.state.stocks.filter((s) => s.id === stockId);
    if (stocks.length > 0) {
      // First
      const stock = stocks[0];
      // State
      this.setState({
        stockEdit: stock,
        stockSaveEnabled: true,
      });
    }
  };

  removeStock = (stockId) => {
    // Stocks
    const stocks = [...this.state.stocks];
    const _stocks = stocks.filter((s) => s.id === stockId);
    if (_stocks.length > 0) {
      // First
      const stock = _stocks[0];
      // Remove from listing
      removeIndexWithAttr(stocks, "id", stock.id);
      // Summary
      const summary = this.computeStockSummary(stocks);
      // Selected
      this.setState({
        stocks: stocks,
        stockSummary: summary,
        stockEdit: clone(DEFAULT_STOCK_EDIT),
        actionsEnabled: true,
      });
    }
  };

  computeStockSummary = (stocks) => {
    const summary = clone(DEFAULT_STOCK_SUMMARY);
    stocks.forEach((s) => {
      if (s.action === "BUY") {
        summary.buy.count += s.quantity;
        summary.buy.total += s.amount;
      } else if (s.action === "SELL") {
        summary.sell.count += s.quantity;
        summary.sell.total += s.amount;
      }
    });
    return summary;
  };

  stockSummary = () => {
    const summary = this.state.stockSummary;
    const numStocks = this.state.stocks.length;
    const fx = (label, item) => {
      if (item.count > 0) {
        return (
          label +
          " " +
          pluralize(item.count, "share") +
          (numStocks === 1 ? " of " + this.state.stocks[0].symbol : "") +
          " for " +
          fmtMoneyUSD(item.total)
        );
      }
      return null;
    };
    const _buy = fx("Buy", summary.buy);
    const _sell = fx("Sell", summary.sell);
    let _summary = "";
    if (_buy) _summary += _buy;
    if (_buy && _sell) _summary += "; ";
    if (_sell) _summary += _sell;
    if (!_summary)
      _summary = "Use the 'Stocks' tab to add stock buy/sell transactions";
    return _summary;
  };

  render() {
    // Info
    const editInfo = this.state.editInfo;
    if (!editInfo) return null;
    // Whether editing
    const isEditing = this.state.batchNumber != null;
    // Form field values
    const {
      date,
      fees,
      financeId,
      categorization,
      reference,
      finances,
      categorizations,
      actions,
    } = editInfo;
    // Stocks
    const { stocks, stockEdit } = this.state;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Update" : "Create"} Stock Transaction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-danger"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Tabs
            defaultActiveKey={TAB_EDITOR}
            activeKey={this.state.selectedTab}
            onSelect={(tab) => this.setState({ selectedTab: tab })}
            id="edit-transactions-tab"
          >
            <Tab
              eventKey={TAB_EDITOR}
              title="Editor"
              style={{ padding: "5px" }}
            >
              <Formik
                initialValues={{
                  date,
                  fees,
                  financeId,
                  categorization,
                  reference,
                }}
                validate={this.validateTransaction}
                validateOnBlur={true}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {(props) => {
                  // Render
                  return (
                    <Form>
                      <fieldset className="form-group required">
                        <label className="control-label">Date</label>
                        <DateSelector
                          className="form-control"
                          name="date"
                          style={{ width: "100%" }}
                        />
                        <ErrorMessage
                          name="date"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Fees</label>
                        <Field name="fees" component={NumberField}></Field>
                        <ErrorMessage
                          name="fees"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Financing</label>
                        <Field
                          className="form-control"
                          as="select"
                          name="financeId"
                        >
                          {finances.map((finance) => {
                            const id = finance.id;
                            return (
                              <option key={id} value={id}>
                                {this.financeItem(finance)}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage
                          name="financeId"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Categorization</label>
                        <Field
                          className="form-control"
                          as="select"
                          name="categorization"
                        >
                          {categorizations.map((c) => {
                            return (
                              <option key={c.id} value={c.id}>
                                {this.categorizationItem(c)}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage
                          name="categorization"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group">
                        <label className="control-label">Reference</label>
                        <Field
                          type="text"
                          name="reference"
                          className="form-control"
                        ></Field>
                        <ErrorMessage
                          name="reference"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                    </Form>
                  );
                }}
              </Formik>
              <div className="alert alert-info">{this.stockSummary()}</div>
            </Tab>
            <Tab
              eventKey={TAB_STOCKS}
              title={"Stocks (" + stocks.length + ")"}
              style={{ padding: "5px" }}
            >
              <Formik
                initialValues={stockEdit}
                onSubmit={this.addStock}
                validate={this.validateStock}
                validateOnBlur={true}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {(props) => {
                  // Style
                  const styles = editInlineAlignStyle;
                  // Whether updating
                  const isUpdating = isDefined(props.values.id);
                  return (
                    <Form>
                      <fieldset
                        className="form-group required"
                        style={styles.fieldset}
                      >
                        <label className="control-label" style={styles.label}>
                          Action
                        </label>
                        <Field
                          className="form-control"
                          as="select"
                          name="action"
                          style={styles.input}
                        >
                          {actions.map((action) => {
                            const id = action.id;
                            return (
                              <option key={id} value={id}>
                                {action.name}
                              </option>
                            );
                          })}
                        </Field>
                      </fieldset>
                      <ErrorMessage
                        name="action"
                        className="alert alert-warning align-right"
                        component="div"
                        style={styles.error}
                      />
                      <fieldset
                        className="form-group required"
                        style={styles.fieldset}
                      >
                        <label className="control-label" style={styles.label}>
                          Symbol
                        </label>
                        <Field
                          type="text"
                          name="symbol"
                          className="form-control"
                          style={styles.input}
                        ></Field>
                      </fieldset>
                      <ErrorMessage
                        name="symbol"
                        className="alert alert-warning"
                        component="div"
                        style={styles.error}
                      />
                      <fieldset
                        className="form-group required"
                        style={styles.fieldset}
                      >
                        <label className="control-label" style={styles.label}>
                          Quantity
                        </label>
                        <Field
                          name="quantity"
                          component={NumberField}
                          style={styles.input}
                        ></Field>
                      </fieldset>
                      <ErrorMessage
                        name="quantity"
                        className="alert alert-warning"
                        component="div"
                        style={styles.error}
                      />
                      <fieldset
                        className="form-group required"
                        style={styles.fieldset}
                      >
                        <label className="control-label" style={styles.label}>
                          Amount
                        </label>
                        <Field
                          name="amount"
                          style={styles.input}
                          component={NumberField}
                        ></Field>
                      </fieldset>
                      <ErrorMessage
                        name="amount"
                        className="alert alert-warning"
                        component="div"
                        style={styles.error}
                      />
                      <div
                        className="text-right"
                        style={{ marginBottom: "5px", marginTop: "5px" }}
                      >
                        <button
                          type="submit"
                          className="btn btn-sm btn-success"
                          disabled={
                            !props.isValid || !this.state.stockSaveEnabled
                          }
                        >
                          <FontAwesomeIcon
                            icon={faSave}
                            style={{ marginRight: "5px" }}
                          />
                          {(isUpdating ? "Update" : "Add") + " Transaction"}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <table
                className="table table-bordered table-sm table-striped"
                style={{ marginBottom: 0, marginTop: 10 }}
              >
                <thead className="thead-dark">
                  <tr>
                    <th>Transactions ({stocks.length})</th>
                    <th className="text-center" style={{ width: "60px" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {stocks.map((stock) => {
                    // Render
                    return (
                      <tr key={"stock-" + stock.id}>
                        <td>
                          {stock.action +
                            " " +
                            stock.quantity +
                            " " +
                            stock.symbol +
                            " for " +
                            fmtMoneyUSD(stock.amount)}
                        </td>
                        <td style={{ textAlign: "center", minWidth: "80px" }}>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => this.editStock(stock.id)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => this.removeStock(stock.id)}
                            style={{ marginLeft: "5px" }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              className="btn btn-md btn-primary"
              disabled={
                !this.state.actionsEnabled ||
                this.state.hasValidationErrors ||
                this.state.stocks.length === 0
              }
              onClick={this.handleSave}
            >
              Save Stock Transaction
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
