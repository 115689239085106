// Session
export const login = "/login";
export const logout = "/logout";
export const register = "/register";
export const activate = "/activate";
// Dashboard
export const dashboard = "/dashboard";
// Admin
export const admin = "/admin";
// User
export const settings = "/settings";
export const settingsFeatures = `${settings}?t=features`;
export const loginHistory = "/loginhistory";
export const passwordReset = "/passwordreset";
// Finances
export const finances = "/finances";
export const financeAccount = "/finance/account";
export const financeFor = (id) => `${financeAccount}/${id}`;
// Reconciliation
export const reconciliation = `${finances}/reconciliation`;
export const reconcile = (id) => `${reconciliation}/${id}`;
// Payments
export const paymentHistory = `${finances}/payments/history`;
export const paymentHistoryFor = (id) => `${paymentHistory}/${id}`;
// Rewards
export const rewardHistory = `${finances}/rewards`;
export const rewardHistoryFor = (id) => `${rewardHistory}/${id}`;
// Stocks
export const stocks = "/stocks";
export const stockAnalysis = `${stocks}/analysis`;
export const stockAnalysisFor = (id) => `${stockAnalysis}/${id}`;
export const stockTransactions = `${stocks}/transactions`;
export const stockTransactionsFor = (id) => `${stockTransactions}/${id}`;
export const accountStock = "/stock";
export const accountStockFor = (id) => `${accountStock}/${id}`;
// Emergencies
export const emergencies = "/emergencies";
// Categories
export const categories = "/categories";
export const categoryFor = (id) => `${categories}/${id}`;
// Budgets
export const monthlyBudget = "/monthly-budget";
export const budgets = "/budgets";
export const budgetFor = (id) => `${budgets}/${id}`;
// Savings goals
export const savingsGoals = "/savingsgoals";
export const savingsGoalFor = (id) => `${savingsGoals}/${id}`;
// Reports
export const reports = "/reports";
export const reportBudgetAdjustments = `${reports}/budget-adjustment`;
export const reportBudgetAdjustmentsFor = (yearMonth) =>
  `${reportBudgetAdjustments}/${yearMonth}`;
// Transactions
export const transactions = "/transactions";
// Features
export const healthcare = "/healthcare";
export const healthcareCardById = (cardId) => `${healthcare}/card/${cardId}`;
export const healthcareCard = `${healthcare}/card`;
// Designations
export const designations = "/designations";
// Income analysis
export const incomeAnalysis = "/income-analysis";
// Forecasts
export const cashflowForecast = "/cashflow/forecast";
// Search
export const search = "/search";
// Gift cards
export const giftcards = "/giftcards";
export const giftcard = "/giftcard";
export const giftCardForId = (id) => `${giftcard}/${id}`;
// Retirement
export const retirement = "/retirement";
// College
export const college = "/college";

export const openLink = (link) => (window.location.href = link);
