import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { accountEditUrl, accountSaveUrl } from "../../library/Urls";

class EditAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      handleClose: props.handleClose,
      handleSave: props.handleSave,
      account: {
        zip_code: ""
      },
      errorMessage: null
    };
  }

  componentDidMount = evt => {
    doHttpGet({
      url: accountEditUrl,
      onSuccess: data => {
        this.setState({
          account: {
            zip_code: data.zip_code
          },
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  save = (values, actions) => {
    // Post
    doHttpPost({
      url: accountSaveUrl,
      body: values,
      rawError: true,
      onSuccess: () => {
        // Handle save
        this.state.handleSave();
      },
      onError: error => {
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach(error => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage
              });
            } else if (error.field === "zipCode") {
              actions.setFieldError("zip_code", errorMessage);
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage
          });
        }
      }
    });
  };

  validate = values => {
    const errors = {};
    // Zip code is required
    if (!values.zip_code) {
      errors.zip_code = "Zip code is required";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const { zip_code, account_status, deletion_date } = this.state.account;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px"
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{ zip_code, deletion_date, account_status }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {props => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Zip Code</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="zip_code"
                  ></Field>
                  <ErrorMessage
                    name="zip_code"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <Modal.Footer>
                  <button className="btn btn-primary" type="submit">
                    Save
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditAccount;
