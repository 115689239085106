import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function SelectAdjustment(props) {
  return (
    <FormControl style={{ width: "200px" }}>
      <InputLabel htmlFor="adjustment-month">
        Other adjustment months
      </InputLabel>
      <Select
        value={props.month}
        onChange={props.onSelectMonth}
        inputProps={{
          name: "month",
          id: "adjustment-month",
        }}
      >
        {props.adjustments.map((m) => {
          return (
            <MenuItem key={m.id} value={m.id}>
              {m.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
