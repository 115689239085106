import React, { useState } from "react";
import { fmtNumber } from "../../library/Utilities";
import Money from "../common/Money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import EditFinanceStockShares from "./EditFinanceStockShares";

const AccountStockDetailFinances = ({ stockId, finances, fxRefresh }) => {
  // State
  const [showSharesEditor, setShowSharesEditor] = useState(null);
  const [editFinanceId, setEditFinanceId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Update shares
  const sharesUpdated = () => {
    // Hide shares editor
    setShowSharesEditor(false);
    // Clear error message
    setErrorMessage(null);
    // Refresh data
    fxRefresh();
  };

  // Edit shares
  const editShares = (financeId) => {
    // Finance to edit
    setEditFinanceId(financeId);
    // Hide shares editor
    setShowSharesEditor(true);
  };

  // Totals
  let totalBalance = 0.0;
  let totalInvestmentCash = 0.0;
  let totalStockValue = 0.0;
  let totalStockShares = 0.0;
  finances.forEach((fa) => {
    totalBalance += fa.balanceAmount;
    totalInvestmentCash += fa.investmentCash;
    totalStockValue += fa.stockValue;
    totalStockShares += fa.shares;
  });

  // Return
  return (
    <div className="row">
      {errorMessage && (
        <div
          className="alert alert-danger"
          style={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "1.2em",
            padding: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}

      <div className="col-12">
        <table className="table table-bordered table-lg table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Financial Account</th>
              <th scope="col" style={{ textAlign: "right" }}>
                Balance
              </th>
              <th scope="col" style={{ textAlign: "right" }}>
                Cash
              </th>
              <th scope="col" style={{ textAlign: "right" }}>
                Stock Value
              </th>
              <th scope="col" style={{ textAlign: "right" }}>
                Shares
              </th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {finances.map((fa) => (
              <tr key={"fa-" + fa.id}>
                <td style={{ verticalAlign: "middle" }}>
                  <Link to={Links.financeFor(fa.id)}>
                    {fa.name} ({fa.description})
                  </Link>
                </td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                  <Money amount={fa.balanceAmount} />
                </td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                  <Money amount={fa.investmentCash} />
                </td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                  <Money amount={fa.stockValue} />
                </td>
                <td
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                  }}
                >
                  {fmtNumber(fa.shares, 4)}
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => editShares(fa.id)}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="table-footer">
            <tr>
              <th></th>
              <th style={{ textAlign: "right" }}>
                <Money amount={totalBalance} />
              </th>
              <th style={{ textAlign: "right" }}>
                <Money amount={totalInvestmentCash} />
              </th>
              <th style={{ textAlign: "right" }}>
                <Money amount={totalStockValue} />
              </th>
              <th style={{ textAlign: "right" }}>
                {fmtNumber(totalStockShares, 4)}
              </th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>

      {showSharesEditor && (
        <EditFinanceStockShares
          show={showSharesEditor}
          onSave={sharesUpdated}
          onClose={() => setShowSharesEditor(false)}
          stockId={stockId}
          financeId={editFinanceId}
        />
      )}
    </div>
  );
};

export default AccountStockDetailFinances;
