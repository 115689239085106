import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import {
  financeCreatingUrl,
  financeEditUrl,
  financeSaveCollegeUrl,
} from "../../library/Urls";
import {
  isValidUsMoney,
  toUsMoney,
  findItemByAttribute,
  currentYear,
} from "../../library/Utilities";
import Checkbox from "../common/Checkbox";
import NumberField from "../common/NumberField";

const errorFieldMappings = {
  name: "account_name",
  accountNumber: "account_number",
  institution: "institution",
  initialAmount: "initial_amount",
  enabled: "is_enabled",
  savingsType: "savings_type",
  beneficiaryName: "beneficiary_name",
  beneficiaryYOB: "beneficiary_yob",
  reconciliationFrequency: "reconciliation_freq",
};

class EditCollegeAccount extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleClose: props.handleClose,
      handleSave: props.handleSave,
      finance: {
        id: props.financeId,
        account_name: "",
        account_number: "",
        institution: "",
        is_enabled: false,
        initial_amount: "0.0",
        savings_type: null,
        savings_types: [],
        beneficiary_name: "",
        beneficiary_yob: "",
        reconciliation_freq: 1,
      },
      financeId: props.financeId,
      errorMessage: null,
      actionsEnabled: false,
      actionSubmitText: "Save",
    };
  }

  componentDidMount = (evt) => {
    // Whether creating or editing
    const fx = this.state.financeId ? financeEditUrl : financeCreatingUrl;
    const arg = this.state.financeId || "College";
    const submit = this.state.financeId
      ? this.state.actionSubmitText
      : "Create Account";
    // Initial data
    doHttpGet({
      url: fx(arg),
      onSuccess: (data) => {
        this.setState({
          finance: {
            ...data,
            initial_amount: data.initial_amount.toFixed(2),
          },
          actionsEnabled: true,
          errorMessage: null,
          actionSubmitText: submit,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false,
    });

    // Post
    doHttpPost({
      url: financeSaveCollegeUrl,
      body: {
        ...values,
        id: this.state.financeId,
        initial_amount: toUsMoney(values.initial_amount),
        beneficiary_yob: parseInt(values.beneficiary_yob, 10),
        reconciliation_freq: parseInt(values.reconciliation_freq, 10),
        savings_type: findItemByAttribute(
          this.state.finance.savings_types,
          "id",
          values.savings_type
        ).name,
      },
      onSuccess: (financeId) => {
        // Handle save
        this.state.handleSave(financeId);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach((error) => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage,
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  validate = (values) => {
    const errors = {};
    // Required inputs
    if (!values.account_name) {
      errors.account_name = "Account name is required";
    }
    if (!values.account_number) {
      errors.account_number = "Account number is required";
    }
    if (!values.institution) {
      errors.institution = "Institution is required";
    }
    if (!values.beneficiary_yob) {
      errors.beneficiary_yob = "Beneficiary year of birth is required";
    } else {
      const yob = parseInt(values.beneficiary_yob, 10);
      const _currentYear = currentYear();
      if (yob < 1970 || yob > _currentYear) {
        errors.beneficiary_yob =
          "Beneficiary year of birth must be between 1970 and " + _currentYear;
      }
    }
    if (values.initial_amount == null || values.initial_amount === "") {
      errors.initial_amount = "Initial amount is required";
    } else if (!isValidUsMoney(values.initial_amount)) {
      errors.initial_amount = "Invalid monetary amount";
    }
    if (!values.savings_type) {
      errors.savings_type = "College savings type is required";
    }
    if (!values.beneficiary_name) {
      errors.beneficiary_name = "Beneficiary name is required";
    }
    if (!values.reconciliation_freq) {
      errors.reconciliation_freq = "Reconciliation frequency is required";
    } else {
      const freq = parseInt(values.reconciliation_freq, 10);
      if (freq < 1 || freq > 12) {
        errors.reconciliation_freq =
          "Reconciliation frequency must be between 1 and 12";
      }
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const {
      id,
      account_name,
      initial_amount,
      is_enabled,
      account_number,
      institution,
      savings_type,
      beneficiary_yob,
      beneficiary_name,
      reconciliation_freq,
      for_transactions,
    } = this.state.finance;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{id ? "Update" : "Create"} College Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              account_name,
              initial_amount,
              is_enabled,
              account_number,
              institution,
              savings_type,
              beneficiary_yob,
              beneficiary_name,
              reconciliation_freq,
              for_transactions,
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Account Name</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="account_name"
                  ></Field>
                  <ErrorMessage
                    name="account_name"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Banking Institution</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="institution"
                  ></Field>
                  <ErrorMessage
                    name="institution"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Account Number</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="account_number"
                  ></Field>
                  <ErrorMessage
                    name="account_number"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">College Savings Type</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="savings_type"
                  >
                    {this.state.finance.savings_types.map((t) => (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    ))}
                  </Field>
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">
                    Statements Frequency (Months)
                  </label>
                  <Field
                    component={NumberField}
                    integerOnly={true}
                    name="reconciliation_freq"
                  ></Field>
                  <ErrorMessage
                    name="reconciliation_freq"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Beneficiary Name</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="beneficiary_name"
                  ></Field>
                  <ErrorMessage
                    name="beneficiary_name"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">
                    Beneficiary Year of Birth
                  </label>
                  <Field
                    component={NumberField}
                    integerOnly={true}
                    name="beneficiary_yob"
                  ></Field>
                  <ErrorMessage
                    name="beneficiary_yob"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Initial Amount</label>
                  <Field
                    component={NumberField}
                    allowNegative={true}
                    name="initial_amount"
                  ></Field>
                  <ErrorMessage
                    name="initial_amount"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <Field
                    component={Checkbox}
                    name="is_enabled"
                    id="is_enabled"
                    label="Account is enabled"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <Field
                    component={Checkbox}
                    name="for_transactions"
                    id="for_transactions"
                    label="Use account for transactions"
                  />
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.actionSubmitText}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditCollegeAccount;
