import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faFireExtinguisher,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { fmtMoneyUSD } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

const UserDashboardEmergency = (props) => {
  // Emergency
  const {
    designatedAmt,
    emergencyCount,
    emergencyGoal,
    goalMet,
    emergencyMinimum,
    minimumMet,
    planNotSetup,
    neededAmount,
  } = props.emergency || {};

  // Render
  return (
    <div
      className="card"
      style={{
        width: "20rem",
        marginTop: "3px",
        marginBottom: "3px",
        marginRight: "3px",
      }}
    >
      <div className="card-body">
        <h5 className="card-title">
          <span style={{ marginRight: "8px" }}>
            <FontAwesomeIcon icon={faFireExtinguisher} />
          </span>
          Emergency Plan
        </h5>
        <div
          className={classnames("card-block dashboard-big-amt", {
            "negative-amount": designatedAmt < 0.0,
            "positive-amount": designatedAmt >= 0.0,
          })}
        >
          {fmtMoneyUSD(designatedAmt)}
        </div>
        <table className="table table-sm">
          <tbody>
            <tr>
              <td className="label">Emergencies</td>
              <td>{emergencyCount}</td>
              <td></td>
            </tr>
            <tr>
              <td className="label">Funding Goal</td>
              <td>{fmtMoneyUSD(emergencyGoal)}</td>
              <td>
                <span
                  style={{ marginLeft: "10px" }}
                  className={classnames("", {
                    "positive-amount": goalMet,
                    "negative-amount": !goalMet,
                  })}
                >
                  {goalMet ? (
                    <FontAwesomeIcon icon={faCheckCircle} />
                  ) : (
                    <FontAwesomeIcon icon={faTimesCircle} />
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td className="label">Minimum Needed</td>
              <td>{fmtMoneyUSD(emergencyMinimum)}</td>
              <td>
                <span
                  style={{ marginLeft: "10px" }}
                  className={classnames("", {
                    "positive-amount": minimumMet,
                    "negative-amount": !minimumMet,
                  })}
                >
                  {minimumMet ? (
                    <FontAwesomeIcon icon={faCheckCircle} />
                  ) : (
                    <FontAwesomeIcon icon={faTimesCircle} />
                  )}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        {planNotSetup && (
          <div className="card-text alert alert-danger">
            Emergency Plan is not yet set up! Click the link below to manage
            your plan.
          </div>
        )}
        {neededAmount > 0.0 ? (
          <div className="card-text negative-amount">
            You need <b>{fmtMoneyUSD(neededAmount)}</b> to complete funding your
            emergency plan.
          </div>
        ) : (
          <div className="card-text">
            <b>Congratulations!</b> Your emergency plan is fully funded.
          </div>
        )}
      </div>
      <div className="card-footer">
        <Link to={Links.emergencies}>Manage emergency plan</Link>
      </div>
    </div>
  );
};

export default UserDashboardEmergency;
