import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpPost } from "../../services/WebService";
import { registrationUrl } from "../../library/Urls";
import * as Links from "../../library/Links";

const errorFieldMappings = {
  firstName: "first_name",
  lastName: "last_name",
  username: "username",
  emailAddress: "email_address",
  zipCode: "zip_code"
};

export default class Register extends Component {
  state = {
    fields: {
      username: "",
      first_name: "",
      last_name: "",
      email_address: "",
      zip_code: ""
    },
    errorMessage: null
  };

  register = (values, actions) => {
    // Post
    doHttpPost({
      url: registrationUrl,
      body: values,
      rawError: true,
      onSuccess: () => {
        // Activation page
        this.props.history.push(Links.activate);
      },
      onError: error => {
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach(error => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage
          });
        }
      }
    });
  };

  validate = values => {
    const errors = {};
    // All inputs are required
    if (!values.first_name) {
      errors.first_name = "First name is required";
    }
    if (!values.last_name) {
      errors.last_name = "Last name is required";
    }
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (!values.email_address) {
      errors.email_address = "Email address is required";
    }
    if (!values.zip_code) {
      errors.zip_code = "Zip Code is required";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const {
      username,
      first_name,
      last_name,
      email_address,
      zip_code
    } = this.state.fields;

    return (
      <div className="page-content">
        <div className="row" style={{ width: 400, margin: "0 auto" }}>
          <div className="container form-container col">
            <h2>Register for an Account</h2>

            {this.state.errorMessage && (
              <div
                className="alert alert-danger"
                style={{ marginBottom: "10px" }}
              >
                {this.state.errorMessage}
              </div>
            )}

            <Formik
              initialValues={{
                username,
                email_address,
                first_name,
                last_name,
                zip_code
              }}
              onSubmit={this.register}
              validate={this.validate}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
            >
              {props => (
                <Form>
                  <fieldset className="form-group required">
                    <label className="control-label">Username</label>
                    <Field
                      className="form-control"
                      type="text"
                      name="username"
                    ></Field>
                    <ErrorMessage
                      name="username"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">Email Address</label>
                    <Field
                      className="form-control"
                      type="email"
                      name="email_address"
                    ></Field>
                    <ErrorMessage
                      name="email_address"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">First Name</label>
                    <Field
                      className="form-control"
                      type="text"
                      name="first_name"
                    ></Field>
                    <ErrorMessage
                      name="first_name"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">Last Name</label>
                    <Field
                      className="form-control"
                      type="text"
                      name="last_name"
                    ></Field>
                    <ErrorMessage
                      name="last_name"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">Zip Code</label>
                    <Field
                      className="form-control"
                      type="text"
                      name="zip_code"
                    ></Field>
                    <ErrorMessage
                      name="zip_code"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <div className="float-right">
                    <button className="btn btn-primary" type="submit">
                      Register
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
