import React, { useState } from "react";
import { doHttpPost } from "../../services/WebService";
import { accountUpdateStockSettingsUrl } from "../../library/Urls";
import { useForm } from "react-hook-form";
import classnames from "classnames";

const AccountSettingsStocks = ({ stockSettings, setErrorMessage }) => {
  const [updateResponse, setUpdateResponse] = useState(null);
  const [isUpdateButtonDisabled, setUpdateButtonDisabled] = useState(true);
  const [allowFetchingUpdatedStockPrices, setAllowFetchingUpdatedStockPrices] =
    useState(stockSettings.allowFetchingUpdatedStockPrices);
  const canUpdateStockSettings = stockSettings.canUpdateStockSettings;
  const settingsUpdateDisabledReason =
    stockSettings.settingsUpdateDisabledReason;

  // Form
  const { register, handleSubmit } = useForm();

  // Update settings
  const updateSettings = (settings) => {
    setUpdateButtonDisabled(true);
    doHttpPost({
      url: accountUpdateStockSettingsUrl,
      body: settings,
      onSuccess: (response) => {
        setUpdateResponse(response);
        setErrorMessage(null);
        setUpdateButtonDisabled(false);
      },
      onError: (error) => {
        setErrorMessage(error);
        setUpdateButtonDisabled(false);
      },
    });
  };

  // Change handlers
  const allowFetchingUpdatedStockPricesHandler = (evt) => {
    const _allowFetchingUpdatedStockPrices = evt.target.checked;
    setAllowFetchingUpdatedStockPrices(_allowFetchingUpdatedStockPrices);
    setUpdateButtonDisabled(false);
  };

  // Render
  return (
    <div
      className={classnames("card border-dark mb-3", {
        disabled: !canUpdateStockSettings,
      })}
    >
      <div className="card-header">Stock Settings</div>
      <div className="card-body text-dark">
        <form onSubmit={handleSubmit(updateSettings)}>
          <fieldset
            className="form-group required"
            style={{ paddingLeft: "20px" }}
          >
            <input
              type="checkbox"
              className="form-check-input"
              defaultChecked={allowFetchingUpdatedStockPrices}
              {...register("allowFetchingUpdatedStockPrices", {
                onChange: allowFetchingUpdatedStockPricesHandler,
              })}
              disabled={!canUpdateStockSettings}
            />
            <label
              className="control-label form-check-label"
              htmlFor="allowFetchingUpdatedStockPrices"
            >
              Allow retrieval of updated stock prices from financial provider
              services
            </label>
          </fieldset>

          {!canUpdateStockSettings && settingsUpdateDisabledReason && (
            <div style={{ marginBottom: "15px", paddingLeft: "15px" }}>
              <span>Reason why disabled: &nbsp;</span>
              <span style={{ color: "red" }}>
                {settingsUpdateDisabledReason}
              </span>
            </div>
          )}

          <div>
            <button
              className={"btn btn-primary"}
              type="submit"
              disabled={isUpdateButtonDisabled && !canUpdateStockSettings}
            >
              Update Settings
            </button>
          </div>
        </form>

        {updateResponse && (
          <div
            className={classnames("alert", {
              "alert-danger": !updateResponse.success,
              "alert-success": updateResponse.success,
            })}
            style={{
              marginTop: "10px",
            }}
          >
            {updateResponse.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountSettingsStocks;
