import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faChevronCircleLeft,
  faChevronCircleRight,
  faArrowCircleRight,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { pluralize } from "../../library/Utilities";

const ToolbarButton = (props) => {
  return (
    <button
      className="btn btn-sm btn-light btn-footer-toolbar"
      disabled={!props.enabled}
      onClick={props.action}
    >
      <FontAwesomeIcon icon={props.icon} />
    </button>
  );
};

class PageToolbar extends Component {
  state = {
    page: 0,
  };

  constructor(props) {
    super(props);
    this.state.fxLoadPage = props.fxLoadPage;
    this.state.page = 1;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.page !== this.props.page) {
      this.setState({
        page: this.props.page,
      });
    }
  };

  handlePageNumberChange = (event) => {
    let pageNumber = event.target.value;
    if (!this.isValidPageNumberInput(pageNumber)) {
      pageNumber = this.state.page;
    }
    this.setState({
      page: pageNumber,
    });
  };

  handlePageNumberEntry = (event) => {
    if (event.key === "Enter") {
      // Validate entry
      let pageNumber = this.state.page;
      if (pageNumber !== "") {
        pageNumber = parseInt(pageNumber + "", 10);
        // Must be in range
        if (pageNumber >= 1 && pageNumber <= this.props.pages) {
          this.state.fxLoadPage(pageNumber);
        }
      }
    }
  };

  navigate = (pageNumber) => {
    this.setState({
      page: pageNumber,
    });
    this.state.fxLoadPage(pageNumber);
  };

  isValidPageNumberInput = (entry) => {
    return (
      entry != null &&
      entry !== undefined &&
      ((entry + "").match(/^\d+$/) || entry === "")
    );
  };

  render() {
    // Enabled buttons
    let pgFirst = true,
      pgPrevious = true,
      pgNumber = true,
      pgNext = true,
      pgLast = true,
      pgRefresh = true;
    if (this.props.page) {
      if (this.props.page === 1) {
        pgFirst = false;
        pgPrevious = false;
      }
      if (this.props.page === this.props.pages) {
        pgNext = false;
        pgLast = false;
      }
      if (this.props.pages === 1) {
        pgNumber = false;
      }
    }

    return (
      <div className="row">
        <div className="col-7">
          <ToolbarButton
            icon={faArrowCircleLeft}
            enabled={pgFirst}
            action={() => this.navigate(1)}
          />
          <ToolbarButton
            icon={faChevronCircleLeft}
            enabled={pgPrevious}
            action={() => this.navigate(this.props.page - 1)}
          />
          <span style={{ marginRight: "7px" }}>Page</span>
          <input
            style={{ width: "50px" }}
            type="text"
            value={this.state.page}
            onChange={this.handlePageNumberChange}
            disabled={!pgNumber}
            onKeyDown={this.handlePageNumberEntry}
          />
          <span style={{ marginRight: "10px", marginLeft: "5px" }}>
            of {this.props.pages} pages
          </span>
          <ToolbarButton
            icon={faChevronCircleRight}
            enabled={pgNext}
            action={() => this.navigate(this.props.page + 1)}
          />
          <ToolbarButton
            icon={faArrowCircleRight}
            enabled={pgLast}
            action={() => this.navigate(this.props.pages)}
          />
          <ToolbarButton
            icon={faSyncAlt}
            enabled={pgRefresh}
            action={() => this.navigate(this.props.page)}
          />
        </div>
        <div className="col-5 text-right">
          Displaying {pluralize(this.props.count, "Transaction")} (
          {this.props.start} - {this.props.end}) of {this.props.total} Total
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    count: state.transactionInfo.count,
    start: state.transactionInfo.startIndex,
    end: state.transactionInfo.endIndex,
    total: state.transactionInfo.total,
    page: state.transactionInfo.pageNumber,
    pages: state.transactionInfo.pages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PageToolbar);
