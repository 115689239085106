import * as Links from "../library/Links";
import jwt_decode from "jwt-decode";

const LOCAL_AUTH_TOKEN = "auth_token";
const SESS_ACCOUNT_DELETION_DATE = "account_deletion_date";
const LOCAL_ACCOUNT_OPTIONS = "account_options";

export const clearLocalSession = (navigateToLogin) => {
  // Clear token
  setAccountDeletionDate(null);
  removeAuthenticationToken();
  // Go to login
  if (navigateToLogin) {
    window.location.href = Links.login;
  }
};

export const setAccountDeletionDate = (date) => {
  if (date) {
    sessionStorage.setItem(SESS_ACCOUNT_DELETION_DATE, date);
  } else {
    sessionStorage.removeItem(SESS_ACCOUNT_DELETION_DATE);
  }
};

export const getAccountDeletionDate = () => {
  return sessionStorage.getItem(SESS_ACCOUNT_DELETION_DATE);
};

export const setAuthenticationToken = (token) => {
  localStorage.setItem(LOCAL_AUTH_TOKEN, token);
};

export const getAuthenticationToken = () => {
  return localStorage.getItem(LOCAL_AUTH_TOKEN);
};

export const removeAuthenticationToken = () => {
  localStorage.removeItem(LOCAL_AUTH_TOKEN);
};

export const isAuthTokenExpired = () => {
  const token = getAuthenticationToken();
  if (token) {
    const info = decodeJwtToken(token);
    const currTime = Date.now() / 1000;
    return info.exp < currTime;
  }
  return true;
};

export const decodeJwtToken = (token) => {
  if (token) {
    // const jwtDecode = require("jwt-decode");
    return jwt_decode(token);
  }
  return null;
};

export const setAccountOptions = (options) => {
  const _options = JSON.stringify(options);
  localStorage.setItem(LOCAL_ACCOUNT_OPTIONS, _options);
};

export const getAccountOptions = () => {
  const _options = localStorage.getItem(LOCAL_ACCOUNT_OPTIONS);
  if (_options) {
    const options = JSON.parse(_options);
    return options;
  }
  return null;
};

export const removeAccountOptions = () => {
  localStorage.removeItem(LOCAL_ACCOUNT_OPTIONS);
};
