import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Links from "../../library/Links";
import {
  fmtDate,
  fmtMoneyUSD,
  fmtInterestRate,
  fmtNumeric,
} from "../../library/Utilities";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import AuthenticationService from "../../services/AuthenticationService";

class FinanceDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: props.info,
      fxEditFinance: props.editFinance,
      fxConfirmDelete: props.confirmDelete,
    };
  }

  render() {
    const info = this.state.info;
    const { isCurrUserPrimary } = this.props;

    // Reconciliation
    const showReconciliation = info.is_reconcilable && info.type !== "CASH";
    let reconciliationMsg = "Never";
    if (showReconciliation && info.reconciliation_date) {
      reconciliationMsg =
        fmtMoneyUSD(info.reconciled_amount) +
        " as of " +
        fmtDate(info.reconciliation_date);
    }

    // Interest rate
    const interestRt = fmtInterestRate(info.interest_rate);

    // Credit
    const isCredit = info.type === "CREDIT";
    let nextPayment = null,
      rewardBalance = null,
      expiration = null;
    if (isCredit) {
      if (info.payment_date) {
        nextPayment =
          fmtMoneyUSD(info.payment_amount) +
          " on " +
          fmtDate(info.payment_date);
      } else {
        nextPayment = "None Scheduled";
      }
      rewardBalance =
        (info.reward_type === "Cash"
          ? fmtMoneyUSD(info.reward_balance)
          : fmtNumeric(info.reward_balance)) +
        " " +
        info.reward_type;
      expiration =
        (info.expiration_month < 10 ? "0" : "") +
        info.expiration_month +
        "/" +
        info.expiration_year;
    }

    // Account type
    const isInvestment = info.type === "INVESTMENT";

    // Retirement account
    const isRetirement = info.type === "RETIREMENT";
    const isPrimaryUser = isCurrUserPrimary;
    const isRetirementFeatureEnabled =
      AuthenticationService.isRetirementFeatureEnabled();
    const showRetirementConversion =
      isPrimaryUser && isRetirementFeatureEnabled && !isRetirement;

    // College account
    const isCollege = info.type === "COLLEGE";
    const isConvertToCollege = info.allowConversionToCollegeAccount;

    // Description
    return (
      <div className="row">
        <div className="col-7 fin-description">
          {info.description}
          <div>
            <b>Currency:</b> {info.currency}
          </div>
          {interestRt && (
            <div>
              <b>Interest Rate:</b> {interestRt} APR
            </div>
          )}
          {showReconciliation && (
            <div>
              <b>Reconciled:</b> {reconciliationMsg}
            </div>
          )}
          {isCredit && (
            <Fragment>
              <div>
                <b>Next Payment:</b> {nextPayment}
              </div>
              <div>
                <b>Reward Balance:</b> {rewardBalance}
              </div>
              <div>
                <b>Expiration:</b> {expiration}
              </div>
            </Fragment>
          )}
          {(isInvestment || isRetirement) && (
            <Fragment>
              <div>
                <b>Cash Reserve:</b> {fmtMoneyUSD(info.cash_amount)}
              </div>
              <div>
                <b>Stock Value:</b> {fmtMoneyUSD(info.total_stock_equity)}
              </div>
            </Fragment>
          )}
          {isCollege && (
            <Fragment>
              <div>
                <b>Beneficiary:</b> {info.beneficiary_name} (b.{" "}
                {info.beneficiary_yob})
              </div>
            </Fragment>
          )}
        </div>
        <div className="col-5">
          {info.is_editable && (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={this.state.fxEditFinance}
              style={{ marginRight: "10px" }}
            >
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
          )}
          {info.is_deletable && (
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() => this.state.fxConfirmDelete(true)}
            >
              <FontAwesomeIcon icon={faTrash} /> Delete
            </button>
          )}
          {showReconciliation && (
            <div style={{ marginTop: "5px" }}>
              <Link to={Links.reconcile(info.id)} className="nav-link">
                Reconciliation
              </Link>
            </div>
          )}
          {isCredit && (
            <Fragment>
              <div>
                <Link
                  to={Links.paymentHistoryFor(info.id)}
                  className="nav-link"
                >
                  Payment History
                </Link>
              </div>
              <div>
                <Link to={Links.rewardHistoryFor(info.id)} className="nav-link">
                  Rewards History
                </Link>
              </div>
            </Fragment>
          )}
          {(isInvestment || isRetirement) && (
            <Fragment>
              <div>
                <Link
                  to={Links.stockTransactionsFor(info.id)}
                  className="nav-link"
                >
                  Stock Transactions
                </Link>
              </div>
              <div>
                <Link to={Links.stockAnalysisFor(info.id)} className="nav-link">
                  Stock Analysis
                </Link>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCurrUserPrimary: state.session.isPrimary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _setErrorMessage: (message) => {
      dispatch(Actions.setErrorMessage(message));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinanceDescription);
