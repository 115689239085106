import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Tabs, Tab } from "react-bootstrap";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import {
  transactionEditUrl,
  transactionSaveUrl,
  transactionCategorizationsUrl,
} from "../../library/Urls";
import DateSelector from "../common/DateSelector";
import Money from "../common/Money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faClone,
} from "@fortawesome/free-solid-svg-icons";
import {
  isDefined,
  isValidUsMoney,
  toUsMoney,
  UUID,
  removeIndexWithAttr,
  fmtMoneyUSD,
  findItemByAttribute,
} from "../../library/Utilities";
import NumberField from "../common/NumberField";
import FormikCreateFilterSelect from "./FormikCreateFilterSelect";

const TAB_TRANSACTIONS = "transactions";
const TAB_EDITOR = "editor";

const DEFAULT_SPLIT = {
  id: null,
  amount: "",
  finance: 0,
  categorization: 0,
  budgetMonth: new Date().getMonth() + 1,
  budgetYear: new Date().getFullYear(),
  description: "",
  party: "",
  reference: "",
};

export default class EditTransaction extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      errorMessage: null,
      actionsEnabled: false,
      batchNumber: props.batchNumber,
      isExpense: !props.isDeposit,
      financeId: props.financeId,
      // Initial edit info from server
      editInfo: null,
      // Split info for editor form
      selectedSplit: DEFAULT_SPLIT,
      // Splits
      splits: [],
      defaultTab: props.batchNumber ? TAB_TRANSACTIONS : TAB_EDITOR,
      selectedTab: props.batchNumber ? TAB_TRANSACTIONS : TAB_EDITOR,
    };
  }

  componentDidMount = (evt) => {
    // Default financial account
    let _financeId = null;
    if (this.state.financeId) {
      _financeId = "FINANCE:" + this.state.financeId;
    }
    // Initial data
    doHttpGet({
      url: transactionEditUrl,
      params: {
        bn: this.state.batchNumber,
        t: this.state.isExpense ? "EXPENSE" : "DEPOSIT",
      },
      onSuccess: (data) => {
        this.setState({
          editInfo: {
            ...data,
            date: data.date ? new Date(data.date) : null,
          },
          splits: data.splits,
          selectedSplit: {
            ...DEFAULT_SPLIT,
            finance: _financeId ? _financeId : data.finances[0].id,
            categorization: data.categorizations[0].id,
          },
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  validateTransaction = (values) => {
    // Save form values in state
    this.setState({
      transactionInfo: values,
      actionsEnabled: true,
    });
  };

  processSplit = (values) => {
    // Split
    let split = { ...values };
    // Transformations
    split.amount = toUsMoney(split.amount);
    // Split ID
    let splitId = this.state.selectedSplit.id;
    const isExisting = splitId != null;
    if (!isExisting) {
      splitId = UUID();
    }
    split.id = splitId;
    // Remove existing split from list
    let splits = [...this.state.splits];
    if (isExisting) {
      removeIndexWithAttr(splits, "id", splitId);
    }
    // Add the updated split
    splits.push(split);
    // Save form values in state
    this.setState({
      splits: splits,
      selectedSplit: split,
      actionsEnabled: true,
      selectedTab: TAB_TRANSACTIONS,
    });
  };

  removeSplit = (splitId) => {
    // Remove existing split from list
    const splits = [...this.state.splits];
    removeIndexWithAttr(splits, "id", splitId);
    // Save form values in state
    this.setState({
      splits: splits,
      actionsEnabled: true,
    });
  };

  handleSave = (evt) => {
    // Disable actions
    this.setState({
      actionsEnabled: false,
    });
    // Transaction
    const transactionDate = this.state.transactionInfo
      ? this.state.transactionInfo.date
      : this.state.editInfo.date;
    // Split
    const splits = [...this.state.splits];
    splits.forEach((split) => {
      if (isNaN(split.id)) {
        split.id = null;
      }
    });
    // Build request
    const requestInfo = {
      batchNumber: this.state.batchNumber,
      type: this.state.isExpense ? "EXPENSE" : "DEPOSIT",
      date: transactionDate,
      splits: splits,
    };
    // Post
    doHttpPost({
      url: transactionSaveUrl,
      body: requestInfo,
      onSuccess: (response) => {
        this.state.onSave(response.batchNumber);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          let htmlError = "<ol>";
          error.response.data.errors.forEach((e) => {
            htmlError += "<li>" + e.field + ": " + e.defaultMessage + "</li>";
          });
          htmlError += "</ol>";
          this.setState({
            errorMessage: htmlError,
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  findFinance = (id) => {
    const finances = this.state.editInfo.finances.filter((f) => f.id === id);
    return finances.length > 0 ? finances[0] : null;
  };

  findCategorization = (id) => {
    const categorizations = this.state.editInfo.categorizations.filter(
      (c) => c.id === id
    );
    return categorizations.length > 0 ? categorizations[0] : null;
  };

  editSplit = (id, clone) => {
    let split = null;
    if (id) {
      const splits = this.state.splits.filter((s) => s.id === id);
      if (splits && splits.length > 0) {
        split = { ...splits[0] };
      }
    }
    if (!split) {
      split = { ...DEFAULT_SPLIT };
    }
    // Cloning
    if (clone) {
      split.id = null;
    }
    // Defaults
    if (!isDefined(split.reference)) {
      split.reference = "";
    }
    if (!isNaN(split.amount)) {
      if (typeof split.amount === "string") {
        split.amount = Number(split.amount);
      }
      split.amount = split.amount.toFixed(2);
    }
    // Update state
    this.setState({
      selectedSplit: split,
      selectedTab: TAB_EDITOR,
    });
  };

  validate = (values) => {
    // Errors
    const errors = {};
    // Party
    if (!values.party) {
      errors.party = "Party is required";
    }
    // Amount
    if (!values.amount) {
      errors.amount = "Amount is required";
    } else {
      if (!isValidUsMoney(values.amount)) {
        errors.amount = "Invalid monetary amount";
      } else {
        const _amount = toUsMoney(values.amount);
        if (_amount <= 0.0) {
          errors.amount = "Amount must be more than 0.0";
        }
      }
    }
    // Finance
    if (!values.finance) {
      errors.finance = "Select a financing option";
    }
    // Categorization
    if (!values.categorization) {
      errors.categorization = "Select a categorization option";
    }
    // Budget month
    if (!values.budgetMonth) {
      errors.budgetMonth = "Select a budget month";
    }
    // Budget year
    if (!values.budgetYear) {
      errors.budgetYear = "Select a budget year";
    }
    // Description
    if (!values.description) {
      errors.description = "Description is required";
    }
    // State
    this.setState({ formEntries: { ...values } });
    // Errors
    return errors;
  };

  changedBudgetMonth = (evt) => {
    const budgetYear = this.state.selectedSplit.budgetYear;
    const budgetMonth = evt.target.value;
    this.updateCategorizations(budgetMonth, budgetYear);
  };

  changedBudgetYear = (evt) => {
    const budgetMonth = this.state.selectedSplit.budgetMonth;
    const budgetYear = evt.target.value;
    this.updateCategorizations(budgetMonth, budgetYear);
  };

  updateCategorizations = (budgetMonth, budgetYear) => {
    // Form entries
    const formEntries = this.state.formEntries || {};
    let categorizationId = formEntries.categorization;
    if (!categorizationId) {
      categorizationId = this.state.selectedSplit.categorization;
    }
    // Initial data
    doHttpGet({
      url: transactionCategorizationsUrl,
      params: {
        mo: budgetMonth,
        yr: budgetYear,
        tt: this.state.isExpense ? "EXPENSE" : "DEPOSIT",
      },
      onSuccess: (categorizations) => {
        const categorization = findItemByAttribute(
          categorizations,
          "id",
          categorizationId
        );
        categorizationId = categorization
          ? categorization.id
          : categorizations[0].id;
        this.setState({
          selectedSplit: {
            ...formEntries,
            budgetMonth: budgetMonth,
            budgetYear: budgetYear,
            categorization: categorizationId,
          },
          editInfo: {
            ...this.state.editInfo,
            categorizations: categorizations,
          },
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    // Info
    const editInfo = this.state.editInfo;
    if (!editInfo) return null;
    // Whether editing
    const isEditing = this.state.batchNumber != null;
    // Whether expense
    const isExpense = this.state.isExpense;
    // Form field values
    const { date, years, months, finances, categorizations, parties } =
      editInfo;
    const {
      id,
      party,
      amount,
      finance,
      reference,
      categorization,
      budgetMonth,
      budgetYear,
      description,
    } = this.state.selectedSplit;
    // Transaction total
    let transactionTotal = 0.0;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Update" : "Create"}{" "}
            {isExpense ? "Expense" : "Deposit"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Tabs
            defaultActiveKey={this.state.defaultTab}
            activeKey={this.state.selectedTab}
            onSelect={(tab) => this.setState({ selectedTab: tab })}
            id="edit-transactions-tab"
          >
            <Tab
              eventKey={TAB_TRANSACTIONS}
              title="TRANSACTIONS"
              style={{ padding: "5px" }}
            >
              <Formik
                initialValues={{
                  date,
                }}
                validate={this.validateTransaction}
                validateOnBlur={true}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {(props) => (
                  <Form>
                    <fieldset className="form-group required">
                      <label className="control-label">Transaction Date</label>
                      <DateSelector
                        className="form-control"
                        name="date"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="date"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                  </Form>
                )}
              </Formik>
              <div className="text-right" style={{ marginBottom: "5px" }}>
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => this.editSplit(null, false)}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ marginRight: "5px" }}
                  />
                  New Split
                </button>
              </div>
              <table
                className="table table-bordered table-sm table-striped"
                style={{ marginBottom: 0 }}
              >
                <thead className="thead-dark">
                  <tr>
                    <th>Transaction Splits ({this.state.splits.length})</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.splits.map((split) => {
                    const finance = this.findFinance(split.finance);
                    const categorization = this.findCategorization(
                      split.categorization
                    ) || { name: "" };
                    // To/from
                    let toFrom = isExpense ? " to " : " from ";
                    // Amount
                    let splitAmt = isExpense ? -split.amount : split.amount;
                    // Party
                    let party = split.party;
                    // Cashback
                    const isCashback = categorization.name.match(/cashback:/i);
                    if (isCashback) {
                      toFrom = " from ";
                      splitAmt = split.amount;
                      party = finance.name;
                    }
                    transactionTotal += splitAmt;
                    // Render
                    return (
                      <tr key={"split-" + split.id}>
                        <td>
                          <div>
                            <Money amount={splitAmt} />
                            {toFrom}
                            <span style={{ fontWeight: "bold" }}>{party}</span>
                          </div>
                          {isCashback ? (
                            <div
                              style={{
                                marginLeft: "10px",
                                marginTop: "3px",
                                fontStyle: "italic",
                              }}
                            >
                              {categorization.name}
                            </div>
                          ) : (
                            <div
                              style={{ marginLeft: "10px", marginTop: "3px" }}
                            >
                              {finance && (
                                <span
                                  style={{
                                    paddingRight: "5px",
                                    borderRight: "1px solid gray",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {finance.name}
                                </span>
                              )}
                              <span
                                style={{ paddingLeft: "5px" }}
                                className="description"
                              >
                                {categorization.name}
                              </span>
                            </div>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => this.editSplit(split.id, false)}
                            style={{ marginRight: "5px" }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                          <button
                            className="btn btn-sm btn-info"
                            onClick={() => this.editSplit(split.id, true)}
                            style={{ marginRight: "5px" }}
                          >
                            <FontAwesomeIcon icon={faClone} />
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => this.removeSplit(split.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot className="table-footer">
                  <tr>
                    <td className="text-right">
                      TOTAL:
                      <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                        {fmtMoneyUSD(transactionTotal)}
                      </span>
                    </td>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </Tab>
            <Tab
              eventKey={TAB_EDITOR}
              title="Editor"
              style={{ padding: "5px" }}
            >
              <Formik
                initialValues={{
                  party,
                  amount,
                  finance,
                  reference,
                  categorization,
                  budgetMonth,
                  budgetYear,
                  description,
                }}
                onSubmit={this.processSplit}
                validate={this.validate}
                validateOnBlur={true}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {(props) => {
                  return (
                    <Form>
                      <fieldset className="form-group required">
                        <label className="control-label">
                          {isExpense ? "To" : "From"}
                        </label>
                        <Field
                          name="party"
                          className="form-control"
                          optionsList={parties}
                          component={FormikCreateFilterSelect}
                        ></Field>
                        <ErrorMessage
                          name="party"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Amount</label>
                        <Field name="amount" component={NumberField}></Field>
                        <ErrorMessage
                          name="amount"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Finance</label>
                        <Field
                          className="form-control"
                          as="select"
                          name="finance"
                        >
                          {finances.map((f) => (
                            <option key={f.id} value={f.id}>
                              {f.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="finance"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group">
                        <label className="control-label">Reference</label>
                        <Field
                          type="text"
                          name="reference"
                          className="form-control"
                        ></Field>
                        <ErrorMessage
                          name="reference"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Categorization</label>
                        <Field
                          className="form-control"
                          as="select"
                          name="categorization"
                        >
                          {categorizations.map((c) => (
                            <option key={c.id} value={c.id}>
                              {c.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="categorization"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Budget Month</label>
                        <div className="row">
                          <div className="col-7">
                            <Field
                              className="form-control"
                              as="select"
                              name="budgetMonth"
                              onChange={this.changedBudgetMonth}
                            >
                              {months.map((m) => (
                                <option key={m.id} value={m.id}>
                                  {m.name}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <div className="col-5">
                            <Field
                              className="form-control"
                              as="select"
                              name="budgetYear"
                              onChange={this.changedBudgetYear}
                            >
                              {years.map((yr) => (
                                <option key={yr} value={yr}>
                                  {yr}
                                </option>
                              ))}
                            </Field>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Description</label>
                        <Field
                          className="form-control"
                          as="textarea"
                          name="description"
                        ></Field>
                        <ErrorMessage
                          name="description"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <div className="text-right">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                        >
                          {id ? "Update" : "Create"} Split
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              className="btn btn-md btn-primary"
              disabled={
                !this.state.actionsEnabled || this.state.splits.length === 0
              }
              onClick={this.handleSave}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
