import React from "react";
import {
  fmtMoneyUSD,
  fmtNumber,
  pluralize,
  fmtDate,
} from "../../library/Utilities";
import Money from "../common/Money";

const AccountStockDetailSummary = ({ summary }) => {
  return (
    <div className="row">
      <div className="col-5">
        <table className="table table-bordered table-lg">
          <tbody>
            <tr>
              <th>Stock Price:</th>
              <td>
                {fmtMoneyUSD(summary.price)} on {fmtDate(summary.priceDate)}
              </td>
            </tr>
            <tr>
              <th>Last Updated On:</th>
              <td>{summary.lastUpdatedOn}</td>
            </tr>
            <tr>
              <th>Price Update Method:</th>
              <td>{summary.priceUpdateMethod}</td>
            </tr>
            <tr>
              <th>Shares Held:</th>
              <td>{fmtNumber(summary.totalShares, 4)}</td>
            </tr>
            <tr>
              <th>Months Owned:</th>
              <td>{pluralize(summary.monthsOwned, "month")}</td>
            </tr>
            <tr>
              <th>Stock Cost:</th>
              <td>
                <Money amount={summary.totalCost} />
              </td>
            </tr>
            <tr>
              <th>Fees Paid:</th>
              <td>
                <Money amount={summary.totalFees} />
              </td>
            </tr>
            <tr>
              <th>Dividends Received:</th>
              <td>
                <Money amount={summary.totalDividends} />
              </td>
            </tr>
            <tr>
              <th>Stock Value:</th>
              <td>
                <Money amount={summary.totalValue} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="col-7">
        <table className="table table-bordered table-lg">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Transactions</th>
              <th scope="col">Shares</th>
              <th scope="col">Cost</th>
              <th scope="col">Fees</th>
              <th scope="col">Value</th>
              <th scope="col">Activity Date</th>
            </tr>
          </thead>
          <tbody>
            {summary.transactions.map((stx) => (
              <tr key={"stx-" + stx.action}>
                <td>
                  {stx.name} ({stx.count})
                </td>
                <td>{stx.shares != null && fmtNumber(stx.shares, 4)}</td>
                <td>{stx.cost != null && <Money amount={stx.cost} />}</td>
                <td>{stx.fees != null && <Money amount={stx.fees} />}</td>
                <td>{stx.value != null && fmtMoneyUSD(stx.value)}</td>
                <td>{stx.date != null && fmtDate(stx.date)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <h4 style={{ marginTop: "25px", borderBottom: "1px solid gray" }}>
          Gain Or Loss
        </h4>
        <h3>
          <Money amount={summary.gainOrLossAmt} plusMinus={true} />
        </h3>
      </div>
    </div>
  );
};

export default AccountStockDetailSummary;
