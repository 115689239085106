import React, { Component, useState } from "react";
import { doHttpPost } from "../../services/WebService";
import { userPasswordResetUrl, userResetPasswordUrl } from "../../library/Urls";
import * as Links from "../../library/Links";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { clone } from "../../library/Utilities";
import { editInlineAlignStyle } from "../../library/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUndo } from "@fortawesome/free-solid-svg-icons";

const RequestForm = (props) => {
  // State
  const [email] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  // Validate
  const validate = (values) => {
    const errors = {};
    if (!values.email || !values.email.match(/\w+(.*)@.+\.(\w+)/)) {
      errors.email = "Please provide a valid email address";
    }
    return errors;
  };

  const requestReset = (values) => {
    // Email address
    const emailAddress = values.email;
    // Send request
    doHttpPost({
      url: userPasswordResetUrl,
      body: emailAddress,
      onSuccess: () => props.handleEmailAddress(emailAddress),
      rawError: true,
      onError: (error) => {
        let message = "There was an error while requesting password reset";
        if (error.response) {
          if (404 === error.response.status) {
            message = "The email address is not known in the system";
          } else {
            const d = error.response.data;
            message = d.message;
          }
        } else if (error.message) {
          message = error.message;
          if (message === "Network Error") {
            message =
              "Experiencing network issues, or server is not available at this time";
          }
        }
        // State
        setErrorMessage(message);
      },
    });
  };

  return (
    <div className="page-content">
      <div className="row" style={{ width: 300, margin: "0 auto" }}>
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
        <Formik
          initialValues={{
            email,
          }}
          validate={validate}
          onSubmit={requestReset}
          validateOnBlur={true}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(props) => {
            // Render
            return (
              <Form style={{ width: "300px" }}>
                <fieldset className="form-group required">
                  <label className="control-label">Email Address:</label>
                  <Field
                    type="text"
                    name="email"
                    className="form-control"
                  ></Field>
                  <ErrorMessage
                    name="email"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <div
                  className="text-right"
                  style={{ marginBottom: "5px", marginTop: "5px" }}
                >
                  <button type="submit" className="btn btn-sm btn-success">
                    <FontAwesomeIcon
                      icon={faUndo}
                      style={{ marginRight: "5px" }}
                    />
                    Request Password Reset
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const ResetForm = (props) => {
  // State
  const [email] = useState(props.emailAddress);
  const [code] = useState("");
  const [password] = useState("");
  const [confirm] = useState("");

  // Validate
  const validate = (values) => {
    const errors = {};
    if (!values.code) {
      errors.code = "Reset code is required. Check email";
    }
    const password = values.password;
    if (!password) {
      errors.password = "A new password is required";
    }
    const confirm = values.confirm;
    if (!confirm) {
      errors.confirm = "Confirmation password is required";
    } else if (confirm !== password) {
      errors.confirm = "Passwords do not match";
    }
    return errors;
  };

  const resetPassword = (values) => {
    const fields = {
      ...values,
      email: email,
    };
    props.handlePasswordReset(fields);
  };

  return (
    <div className="page-content">
      <div className="row" style={{ width: 300, margin: "0 auto" }}>
        <div className="alert alert-info" style={{ marginBottom: "10px" }}>
          A reset code was sent to the email address <b>{email}</b>. Please find
          that message and enter the code here to proceed.
        </div>
        <Formik
          initialValues={{
            code,
            password,
            confirm,
          }}
          validate={validate}
          onSubmit={resetPassword}
          validateOnBlur={true}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(props) => {
            const styles = clone(editInlineAlignStyle);
            styles.label.width = "40%";
            styles.input.width = "60%";
            // Render
            return (
              <Form style={{ width: "300px" }}>
                <fieldset className="form-group required">
                  <label className="control-label">Reset Code:</label>
                  <Field type="text" name="code" className="form-control" />
                  <ErrorMessage
                    name="code"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">New Password:</label>
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="password"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset
                  className="form-group required"
                  style={styles.fieldset}
                >
                  <label className="control-label">Confirm Password:</label>
                  <Field
                    type="password"
                    name="confirm"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="confirm"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <div
                  className="text-right"
                  style={{ marginBottom: "5px", marginTop: "5px" }}
                >
                  <button type="submit" className="btn btn-sm btn-success">
                    <FontAwesomeIcon
                      icon={faKey}
                      style={{ marginRight: "5px" }}
                    />
                    Reset Password
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default class PasswordReset extends Component {
  state = {
    showRequestForm: true,
    showResetForm: false,
    errorMessage: null,
    fields: {
      email: "",
      code: "",
      password: "",
    },
  };

  handleFormErrors = (error) => {
    let errorMessage = "";
    if (error.response && error.response.data) {
      const d = error.response.data;
      errorMessage = d.message;
    } else if (error.message) {
      errorMessage = error.message;
    }
    this.setState({
      errorMessage: "Password reset error (" + errorMessage + ")",
    });
  };

  handleEmailAddress = (email) => {
    this.setState({
      showRequestForm: false,
      showResetForm: true,
      email: email,
    });
  };

  handlePasswordReset = (info) => {
    // Update info
    info.email = this.state.email;
    // Send reset
    const { email, code, password } = info;
    // Post
    doHttpPost({
      url: userResetPasswordUrl,
      body: {
        email_address: email,
        reset_code: code,
        password: password,
      },
      onSuccess: () => {
        // Login page
        this.props.history.push(Links.login);
      },
      rawError: true,
      onError: this.handleFormErrors,
    });
  };

  render() {
    return (
      <div className="page-content">
        <div className="row" style={{ width: 400, margin: "0 auto" }}>
          <div className="container form-container col">
            <h2>Password Reset</h2>

            {this.state.errorMessage && (
              <div
                className="alert alert-danger"
                style={{ marginBottom: "10px" }}
              >
                {this.state.errorMessage}
              </div>
            )}

            {this.state.showRequestForm && (
              <RequestForm handleEmailAddress={this.handleEmailAddress} />
            )}

            {this.state.showResetForm && (
              <ResetForm
                handlePasswordReset={this.handlePasswordReset}
                emailAddress={this.state.email}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
