import React, { useState } from "react";
import "../../Geldzin.css";
import AuthenticationService from "../../services/AuthenticationService";
import { doHttpPost } from "../../services/WebService";
import * as Urls from "../../library/Urls";
import * as Links from "../../library/Links";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { clone } from "../../library/Utilities";
import { editInlineAlignStyle } from "../../library/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockOpen } from "@fortawesome/free-solid-svg-icons";

// Validate
const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Username is required";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};

const Login = (props) => {
  // State
  const [username] = useState("");
  const [password] = useState("");
  const [loginMessage, setLoginMessage] = useState(props.errorMessage);

  const login = (values) => {
    // State
    setLoginMessage(null);
    // Username
    const _username = values.username;
    // Authenticate
    doHttpPost({
      url: Urls.loginUrl,
      body: {
        username: _username,
        password: values.password,
      },
      onSuccess: (response) => {
        // JWT token
        const jwtToken = response.jwt_token;
        // Session
        const session = AuthenticationService.decodeJwtToken(jwtToken);
        props._setSession(session);
        AuthenticationService.setupJwtToken(jwtToken);
        // Account deletion date
        const deletionDt = response.deletion_date;
        props._setDeletionDate(deletionDt);
        // Navigate to dashboard
        props.history.push(Links.dashboard);
      },
      rawError: true,
      onError: (error) => {
        let message = "There was an error while logging in";
        if (error.response) {
          if (401 === error.response.status) {
            message =
              "Authorization failed. Check your username, password, or the status of your account.";
          } else {
            const d = error.response.data;
            message = d.message;
          }
        } else if (error.message) {
          message = error.message;
          if (message === "Network Error") {
            message =
              "Experiencing network issues, or server is not available at this time";
          }
        }
        // State
        setLoginMessage(message);
      },
    });
  };

  return (
    <div className="page-content">
      <div className="row" style={{ width: 300, margin: "0 auto" }}>
        <div className="container form-container col">
          <h3>Log into your account</h3>
          <div className="error">{props.errorMessage}</div>
          <Formik
            initialValues={{
              username,
              password,
            }}
            validate={validate}
            onSubmit={login}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {(props) => {
              const styles = clone(editInlineAlignStyle);
              styles.label.width = "30%";
              styles.input.width = "70%";
              // Render
              return (
                <Form>
                  <fieldset
                    className="form-group required"
                    style={styles.fieldset}
                  >
                    <label className="control-label" style={styles.label}>
                      Username:
                    </label>
                    <Field
                      type="text"
                      name="username"
                      className="form-control"
                      style={styles.input}
                    ></Field>
                  </fieldset>
                  <ErrorMessage
                    name="username"
                    className="alert alert-warning"
                    component="div"
                  />
                  <fieldset
                    className="form-group required"
                    style={styles.fieldset}
                  >
                    <label className="control-label" style={styles.label}>
                      Password:
                    </label>
                    <Field
                      type="password"
                      name="password"
                      className="form-control"
                      style={styles.input}
                    ></Field>
                  </fieldset>
                  <ErrorMessage
                    name="password"
                    className="alert alert-warning"
                    component="div"
                  />
                  <div
                    className="text-right"
                    style={{ marginBottom: "5px", marginTop: "5px" }}
                  >
                    <button type="submit" className="btn btn-sm btn-success">
                      <FontAwesomeIcon
                        icon={faLockOpen}
                        style={{ marginRight: "5px" }}
                      />
                      Login
                    </button>
                  </div>
                  {loginMessage && (
                    <div className="alert alert-danger">{loginMessage}</div>
                  )}
                </Form>
              );
            }}
          </Formik>
          <div
            className="form-group"
            style={{ textAlign: "right", marginTop: "8px" }}
          >
            <Link
              className="nav-link"
              style={{ padding: 0 }}
              to={Links.passwordReset}
            >
              Forgot Password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const _state = state === undefined ? {} : state;
  return {
    ..._state.session,
    errorMessage: null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Errors
    _handleError: (message) => {
      dispatch(Actions.setErrorMessage(message));
    },
    // Session
    _setSession: (session) => {
      dispatch(Actions.setSession(session, true));
    },
    // Account deletion date
    _setDeletionDate: (date) => {
      dispatch(Actions.setAccountDeletionDate(date));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
