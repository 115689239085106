import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import AuthenticationService from "../../services/AuthenticationService";
import { Dropdown, Nav } from "react-bootstrap";
import { currentYearMonth } from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

class Header extends Component {
  state = {
    searchKeywords: "",
  };

  search = () => {
    const keywords = this.state.searchKeywords || "";
    if (keywords && keywords.length > 2) {
      this.props.history.push(Links.search + "?q=" + keywords);
    }
  };

  searchEnter = (event) => {
    if (event.charCode === 13) {
      this.search();
    }
  };

  updateSearchTerms = (event) => {
    this.setState({ searchKeywords: event.target.value });
  };

  constructor() {
    super();
    this.searchEnter = this.searchEnter.bind(this);
  }

  render() {
    // Whether logged in
    const isLoggedIn = AuthenticationService.isLoggedIn();
    // User info
    const userInfo = AuthenticationService.getAuthenticatedUserInfo();
    // Feature options
    const isHealthcareOption =
      AuthenticationService.isHealthcareFeatureEnabled();
    const isStockOption = AuthenticationService.isStockFeatureEnabled();
    const isRetirementOption =
      AuthenticationService.isRetirementFeatureEnabled();
    const isCollegeOption = AuthenticationService.isCollegeFeatureEnabled();
    const isOptionsEnabled =
      isHealthcareOption || isStockOption || isRetirementOption;

    return (
      <div className="header">
        <table>
          <tbody>
            <tr>
              <td className="header-logo">Geldzin</td>
              <td>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td></td>
                      <td style={{ width: "425px" }}>
                        {isLoggedIn && (
                          <div
                            className="input-group"
                            style={{
                              marginBottom: "0 !important",
                            }}
                          >
                            <input
                              type="search"
                              aria-describedby="button-addon6"
                              className="form-control border-info"
                              onKeyPress={this.searchEnter}
                              value={this.state.searchKeywords}
                              onChange={this.updateSearchTerms}
                            ></input>
                            <div className="input-group-append">
                              <button
                                id="button-addon6"
                                type="submit"
                                className="btn btn-info"
                                onClick={this.search}
                              >
                                <FontAwesomeIcon icon={faSearch} />
                              </button>
                            </div>
                          </div>
                        )}
                      </td>
                      <td style={{ fontSize: "1.2em", width: "200px" }}>
                        {isLoggedIn && (
                          <Fragment>
                            <ul className="nav justify-content-end">
                              {userInfo.isAdmin && (
                                <li>
                                  <Link className="nav-link" to={Links.admin}>
                                    Admin
                                  </Link>
                                </li>
                              )}
                              <li>
                                <Link
                                  className="nav-link"
                                  to={Links.logout}
                                  onClick={AuthenticationService.handleLogout}
                                >
                                  Logout
                                </Link>
                              </li>
                            </ul>
                          </Fragment>
                        )}
                        {!isLoggedIn && (
                          <Fragment>
                            <ul className="nav justify-content-end">
                              <li>
                                <Link className="nav-link" to={Links.register}>
                                  Register
                                </Link>
                              </li>
                              <li>
                                <Link className="nav-link" to={Links.login}>
                                  Login
                                </Link>
                              </li>
                            </ul>
                          </Fragment>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{ fontSize: "1.2em" }}>
                        {isLoggedIn && (
                          <Fragment>
                            <Nav className="header-menu justify-content-end">
                              <Nav.Item>
                                <Nav.Link href={Links.dashboard}>
                                  Dashboard
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="link"
                                    id="menuFinances"
                                  >
                                    Finances
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item href={Links.finances}>
                                      Financial Accounts
                                    </Dropdown.Item>
                                    <Dropdown.Item href={Links.giftcards}>
                                      Gift Cards
                                    </Dropdown.Item>
                                    <Dropdown.Item href={Links.reconciliation}>
                                      Reconciliation
                                    </Dropdown.Item>
                                    <Dropdown.Item href={Links.designations}>
                                      Designations
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Nav.Item>
                              <Nav.Item>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="link"
                                    id="menuBudget"
                                  >
                                    Budgeting
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item href={Links.emergencies}>
                                      Emergency Plan
                                    </Dropdown.Item>
                                    <Dropdown.Item href={Links.categories}>
                                      Categories
                                    </Dropdown.Item>
                                    <Dropdown.Item href={Links.budgets}>
                                      Budgets
                                    </Dropdown.Item>
                                    <Dropdown.Item href={Links.savingsGoals}>
                                      Savings Goals
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Nav.Item>
                              <Nav.Item>
                                <Dropdown
                                  style={{
                                    backgroundColor: "#ffa500",
                                  }}
                                >
                                  <Dropdown.Toggle
                                    variant="link"
                                    id="menuActivity"
                                  >
                                    Activity
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item href={Links.transactions}>
                                      Transactions
                                    </Dropdown.Item>
                                    <Dropdown.Item href={Links.monthlyBudget}>
                                      Monthly Budget
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Nav.Item>
                              {isOptionsEnabled && (
                                <Nav.Item>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="link"
                                      id="menuFeatures"
                                    >
                                      Features
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {isHealthcareOption && (
                                        <Dropdown.Item href={Links.healthcare}>
                                          Healthcare
                                        </Dropdown.Item>
                                      )}
                                      {isStockOption && (
                                        <Dropdown.Item href={Links.stocks}>
                                          Stocks
                                        </Dropdown.Item>
                                      )}
                                      {isRetirementOption && (
                                        <Dropdown.Item href={Links.retirement}>
                                          Retirement
                                        </Dropdown.Item>
                                      )}
                                      {isCollegeOption && (
                                        <Dropdown.Item href={Links.college}>
                                          College
                                        </Dropdown.Item>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Nav.Item>
                              )}
                              <Nav.Item>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="link"
                                    id="menuReports"
                                  >
                                    Reports
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href={Links.reportBudgetAdjustmentsFor(
                                        currentYearMonth()
                                      )}
                                    >
                                      Budget Adjustments
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link href={Links.settings}>
                                  Settings
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Fragment>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// export default withRouter(Header);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
