import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpPost } from "../../services/WebService";
import { userCreateUrl } from "../../library/Urls";

const errorFieldMappings = {
  username: "username",
  password: "password",
  firstName: "firstname",
  lastName: "lastname",
  emailAddress: "email",
  role: "role"
};

class CreateUser extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleClose: props.handleClose,
      handleSave: props.handleSave,
      user: {
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        email: "",
        role: "ACCT_REGULAR"
      },
      errorMessage: null
    };
  }

  save = (values, actions) => {
    // Post
    doHttpPost({
      url: userCreateUrl,
      body: {
        ...values,
        role: this.state.user.role
      },
      rawError: true,
      onSuccess: userId => {
        // Handle save
        this.state.handleSave(userId);
      },
      onError: error => {
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach(error => {
            const errorMessage = error.defaultMessage;
            actions.setFieldError(
              errorFieldMappings[error.field],
              errorMessage
            );
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage
          });
        }
      }
    });
  };

  validate = values => {
    const errors = {};
    // All inputs are required
    if (!values.firstname) {
      errors.firstname = "First name is required";
    }
    if (!values.lastname) {
      errors.lastname = "Last name is required";
    }
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (!values.email) {
      errors.email = "Email address is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const { firstname, lastname, username, email, password } = this.state.user;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px"
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              username,
              email,
              firstname,
              lastname,
              password
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {props => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Username</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="username"
                  ></Field>
                  <ErrorMessage
                    name="username"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Password</label>
                  <Field
                    className="form-control"
                    type="password"
                    name="password"
                  ></Field>
                  <ErrorMessage
                    name="password"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Email Address</label>
                  <Field
                    className="form-control"
                    type="email"
                    name="email"
                  ></Field>
                  <ErrorMessage
                    name="email"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">First Name</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="firstname"
                  ></Field>
                  <ErrorMessage
                    name="firstname"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Last Name</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="lastname"
                  ></Field>
                  <ErrorMessage
                    name="lastname"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <Modal.Footer>
                  <button className="btn btn-primary" type="submit">
                    Create User
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateUser;
