import React, { Fragment } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Money from "./Money";

export default function MoneyArrow(props) {
  const amount = props.amount;
  const cssClasses = classnames(props.className || "", {
    "positive-amount": amount >= 0.0,
    "negative-amount": amount < 0.0,
  });
  const icon = amount >= 0.0 ? faArrowUp : faArrowDown;
  return (
    <Fragment>
      <Money amount={amount} showSymbol={props.showSymbol} />{" "}
      <FontAwesomeIcon icon={icon} className={cssClasses} />
    </Fragment>
  );
}
