import React, { Component } from "react";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { giftCardEditUrl, giftCardUrl } from "../../library/Urls";
import Modal from "react-bootstrap/Modal";
import Checkbox from "../common/Checkbox";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { isValidUsMoney } from "../../library/Utilities";
import NumberField from "../common/NumberField";
import DateSelector from "../common/DateSelector";
import { clone } from "../../library/Utilities";
import { editInlineAlignStyle } from "../../library/Styles";

export default class EditGiftCard extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      giftCardId: props.giftCardId,
      errorMessage: null,
      actionsEnabled: false,
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: giftCardEditUrl,
      params: {
        id: this.state.giftCardId,
      },
      onSuccess: (data) => {
        this.setState({
          editInfo: {
            ...data,
            brand: data.brand || "",
            number: data.number || "",
            whereUsable: data.whereUsable || "",
            balance: data.balance === null ? "0.00" : data.balance.toFixed(2),
            fees: data.fees === null ? "" : data.fees.toFixed(2),
            description: data.description || "",
            expirationDate: data.expirationDate
              ? new Date(data.expirationDate)
              : null,
          },
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  validateGiftCard = (values) => {
    // Errors
    const errors = {};
    if (!values.brand) {
      errors.brand = "Card brand/name is required";
    }
    if (!values.balance) {
      errors.balance = "Balance is required";
    } else if (!isValidUsMoney(values.balance)) {
      errors.balance = "Invalid monetary amount";
    }
    // Whether any errors
    let hasErrors = false;
    for (var attr in errors) {
      if (errors.hasOwnProperty(attr)) {
        hasErrors = true;
        break;
      }
    }
    if (!hasErrors) {
      // Save form values in state
      this.setState({
        giftCardInfo: values,
        hasValidationErrors: false,
        actionsEnabled: true,
      });
    } else {
      this.setState({
        hasValidationErrors: true,
        actionsEnabled: false,
      });
    }
    // Errors
    return errors;
  };

  saveGiftCard = () => {
    // Disable actions
    this.setState({
      actionsEnabled: false,
    });
    // Build request
    const requestInfo = {
      id: this.state.giftCardId,
      ...this.state.giftCardInfo,
    };
    // Enrich
    requestInfo.balance = Number(requestInfo.balance);
    requestInfo.fees = requestInfo.fees ? Number(requestInfo.fees) : null;
    // Post
    doHttpPost({
      url: giftCardUrl,
      body: requestInfo,
      onSuccess: (response) => {
        this.state.onSave(response.id);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          let htmlError = "<ol style='margin-bottom:0'>";
          error.response.data.errors.forEach((e) => {
            htmlError += "<li>" + e.field + ": " + e.defaultMessage + "</li>";
          });
          htmlError += "</ol>";
          this.setState({
            errorMessage: htmlError,
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  render() {
    // Info
    const editInfo = this.state.editInfo;
    if (!editInfo) return null;
    // Whether editing
    const isEditing = this.state.giftCardId != null;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Update" : "Create"} Gift Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
              dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}
            />
          )}
          <Formik
            initialValues={editInfo}
            validate={this.validateGiftCard}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {(props) => {
              const styles = clone(editInlineAlignStyle);
              styles.label.width = "35%";
              styles.input.width = "65%";
              return (
                <Form>
                  <fieldset
                    className="form-group required"
                    style={styles.fieldset}
                  >
                    <label className="control-label" style={styles.label}>
                      Brand/Name
                    </label>
                    <Field
                      type="text"
                      name="brand"
                      className="form-control"
                      style={styles.input}
                    ></Field>
                  </fieldset>
                  <ErrorMessage
                    name="brand"
                    className="alert alert-warning"
                    component="div"
                  />
                  <fieldset className="form-group" style={styles.fieldset}>
                    <label className="control-label" style={styles.label}>
                      Card Number
                    </label>
                    <Field
                      type="text"
                      name="number"
                      className="form-control"
                      style={styles.input}
                    ></Field>
                  </fieldset>
                  <fieldset className="form-group" style={styles.fieldset}>
                    <label className="control-label" style={styles.label}>
                      Where Usabe
                    </label>
                    <Field
                      type="text"
                      name="whereUsable"
                      className="form-control"
                      style={styles.input}
                    ></Field>
                  </fieldset>
                  <fieldset
                    className="form-group required"
                    style={styles.fieldset}
                  >
                    <label className="control-label" style={styles.label}>
                      Balance
                    </label>
                    <Field
                      component={NumberField}
                      name="balance"
                      style={styles.input}
                    ></Field>
                  </fieldset>
                  <ErrorMessage
                    name="balance"
                    className="alert alert-warning"
                    component="div"
                  />
                  <fieldset className="form-group" style={styles.fieldset}>
                    <label className="control-label" style={styles.label}>
                      Usage Fees
                    </label>
                    <Field
                      component={NumberField}
                      name="fees"
                      style={styles.input}
                    ></Field>
                  </fieldset>
                  <fieldset className="form-group" style={styles.fieldset}>
                    <label className="control-label" style={styles.label}>
                      Description
                    </label>
                    <Field
                      className="form-control"
                      as="textarea"
                      name="description"
                      style={styles.input}
                    ></Field>
                  </fieldset>
                  <fieldset className="form-group" style={styles.fieldset}>
                    <label className="control-label" style={styles.label}>
                      Expiration Date
                    </label>
                    <div>
                      <DateSelector
                        className="form-control"
                        name="expirationDate"
                        style={styles.input}
                      />
                    </div>
                  </fieldset>
                  <fieldset
                    className="form-group"
                    style={{ ...styles.fieldset, marginTop: "10px" }}
                  >
                    <label className="control-label" style={styles.label} />
                    <Field
                      component={Checkbox}
                      name="chargeCard"
                      id="chargeCard"
                      style={styles.input}
                      label="This card is a charge or credit card"
                    />
                  </fieldset>
                  <fieldset className="form-group" style={styles.fieldset}>
                    <label className="control-label" style={styles.label} />
                    <Field
                      component={Checkbox}
                      name="enabled"
                      id="enabled"
                      style={styles.input}
                      label="This card is enabled or active"
                    />
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              className="btn btn-md btn-primary"
              disabled={
                !this.state.actionsEnabled || this.state.hasValidationErrors
              }
              onClick={this.saveGiftCard}
            >
              Save Gift Card
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
