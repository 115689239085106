import React, { Component } from "react";
import { doHttpGet } from "../../services/WebService";
import { userLoginHistoryUrl } from "../../library/Urls";

class LoginHistory extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      userId: props.match.params.userid
    };
  }

  componentDidMount = evt => {
    // List of accounts
    doHttpGet({
      url: userLoginHistoryUrl(this.state.userId),
      onSuccess: data => {
        this.setState({
          ...data,
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  render() {
    // Logins
    const logins = this.state.user_logins || [];
    // Render
    return (
      <div className="page-content">
        <h2>Login History for {this.state.full_names}</h2>
        {this.state.errorMessage && (
          <div className="alert alert-danger">{this.state.errorMessage}</div>
        )}
        <table className="table table-striped table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Login Time</th>
              <th scope="col">Duration</th>
              <th scope="col">IP Address</th>
              <th scope="col">City</th>
              <th scope="col">Region</th>
              <th scope="col">Zip Code</th>
              <th scope="col">Country</th>
              <th scope="col">Continent</th>
            </tr>
          </thead>
          <tbody>
            {logins.map(ul => (
              <tr key={"login-" + ul.id}>
                <td>{ul.date_time}</td>
                <td>{ul.duration}</td>
                <td>{ul.ip_address}</td>
                <td>{ul.city}</td>
                <td>{ul.region}</td>
                <td>{ul.zip_code}</td>
                <td>{ul.country}</td>
                <td>{ul.continent}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default LoginHistory;
