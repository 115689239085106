import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { fmtDate, fmtMoneyUSD } from "../../library/Utilities";

export default function Disbursements(props) {
  // Disbursements
  const disbursements = props.disbursements || [];
  // Total disbursement amount
  let totalDisbursements = 0.0;
  return (
    <Fragment>
      <div className="row">
        <div
          className="col-12"
          style={{
            textAlign: "right",
            verticalAlign: "bottom",
            marginBottom: 10,
          }}
        >
          <button
            className="btn btn-success"
            onClick={() => props.fxShowEditor(null)}
          >
            <FontAwesomeIcon icon={faPlus} /> &nbsp; Add Disbursement
          </button>
        </div>
      </div>

      <table className="table table-md table-striped tbl-healthcare-cards">
        <thead className="thead-dark">
          <tr>
            <th scope="col" style={{ width: "120px" }}>
              Date
            </th>
            <th scope="col" className="text-right" style={{ width: "150px" }}>
              Amount
            </th>
            <th scope="col">Received From</th>
            <th scope="col">Description</th>
            <th scope="col" style={{ width: "150px" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {disbursements.map((d) => {
            // Add to balance
            totalDisbursements += d.amount;
            // Render
            return (
              <tr
                key={"disbursement-" + d.id}
                className={classnames("", {
                  "row-selected": d.id === props.disbursementId,
                })}
              >
                <td>{fmtDate(d.date)}</td>
                <td className="text-right">{fmtMoneyUSD(d.amount)}</td>
                <td>{d.disburser}</td>
                <td className="description">{d.description}</td>
                <td className="text-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: 10 }}
                    onClick={() => props.fxShowEditor(d.id)}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() =>
                      props.fxDeleteConfirm(d.id, d.disburser, d.amount)
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="table-footer">
          <tr>
            <th></th>
            <th className="text-right">{fmtMoneyUSD(totalDisbursements)}</th>
            <th colSpan={3} className="text-left">
              Total Disbursements
            </th>
          </tr>
        </tfoot>
      </table>
    </Fragment>
  );
}
