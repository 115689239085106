import React from "react";
import classnames from "classnames";
import Money from "../common/Money";
import { fmtMoneyUSD, fmtNumber } from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

export const PreviousStatements401kRetirement = ({
  statements,
  reconciliationId,
  editReconciliation,
  confirmReconciliationDelete,
}) => {
  return (
    <table className="table table-md table-striped table-fixed tbl-prev-reconciliations">
      <thead className="thead-dark">
        <tr>
          <th scope="col" style={{ width: 100 }}>
            Date
          </th>
          <th scope="col" className="text-right">
            Balance
          </th>
          <th scope="col" className="text-right">
            Ind.Contr
          </th>
          <th scope="col" className="text-right">
            Emp.Contr
          </th>
          <th scope="col" className="text-right">
            Fees
          </th>
          <th scope="col" className="text-right">
            Return
          </th>
          <th scope="col" className="text-right" style={{ width: 90 }}>
            Actions
          </th>
        </tr>
      </thead>
      <tbody style={{ maxHeight: 500 }}>
        {statements.map((stmt) => {
          const ret401k = stmt.retirement401kInfo;
          return (
            <tr
              key={"stmt-" + stmt.id}
              className={classnames("", {
                "row-selected": reconciliationId === stmt.id,
              })}
            >
              <td style={{ width: 100 }}>{stmt.date}</td>
              <td className="text-right">
                <Money amount={stmt.balance} />
              </td>
              <td className="text-right">
                {fmtMoneyUSD(ret401k.individualContribution)}
              </td>
              <td className="text-right">
                {fmtMoneyUSD(ret401k.employerContribution)}
              </td>
              <td className="text-right">{fmtMoneyUSD(ret401k.fees)}</td>
              <td className="text-right">
                {fmtMoneyUSD(ret401k.marketChangeAmount)} (
                {fmtNumber(ret401k.marketChangePercent, 1)}%)
              </td>
              <td className="text-right" style={{ width: 90 }}>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: 8 }}
                  onClick={() => editReconciliation(stmt.id)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    confirmReconciliationDelete(stmt.id, stmt.date)
                  }
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
