import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { savingsGoalEditUrl, savingsGoalSaveUrl } from "../../library/Urls";
import DateSelector from "../common/DateSelector";
import {
  toUsMoney,
  isValidUsMoney,
  findItemByAttribute,
} from "../../library/Utilities";
import NumberField from "../common/NumberField";

const errorFieldMappings = {
  name: "name",
  description: "description",
  goal_amount: "goal",
  monthly_limit: "monthlyLimit",
  category_id: "categoryId",
  start_date: "startDate",
  close_date: "closeDate",
  priority: "priority",
};

class EditSavingsGoal extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      editInfo: {
        id: props.savingsGoalId,
        name: "",
        description: "",
        goal_amount: 0.0,
        monthly_limit: "",
        category_id: 0,
        start_date: null,
        close_date: null,
        categories: [],
        priorities: [],
      },
      savingsGoalId: props.savingsGoalId,
      errorMessage: null,
      actionsEnabled: false,
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: savingsGoalEditUrl(this.state.savingsGoalId),
      onSuccess: (data) => {
        this.setState({
          editInfo: {
            ...data,
            goal_amount: data.goal_amount.toFixed(2),
            monthly_limit: data.monthly_limit
              ? data.monthly_limit.toFixed(2)
              : "",
            start_date: data.start_date ? new Date(data.start_date) : null,
            close_date: data.close_date ? new Date(data.close_date) : null,
            category_id: data.category_id
              ? data.category_id
              : data.categories[0].id,
          },
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false,
    });

    // Validations
    const goal_amount = isValidUsMoney(values.goal_amount)
      ? toUsMoney(values.goal_amount)
      : null;
    const monthly_limit = isValidUsMoney(values.monthly_limit)
      ? toUsMoney(values.monthly_limit)
      : null;
    const priority = findItemByAttribute(
      this.state.editInfo.priorities,
      "id",
      values.priority
    ).name;

    // Post
    doHttpPost({
      url: savingsGoalSaveUrl,
      body: {
        ...values,
        id: this.state.savingsGoalId,
        goal_amount: goal_amount,
        monthly_limit: monthly_limit,
        priority: priority,
      },
      onSuccess: (savingsGoalId) => {
        // Handle save
        this.state.onSave(savingsGoalId);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach((error) => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage,
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  render() {
    // Info
    const info = this.state.editInfo || {};

    // Form field values
    const {
      id,
      name,
      description,
      goal_amount,
      monthly_limit,
      category_id,
      start_date,
      close_date,
      priority,
    } = info;

    // Associations
    const categories = info.categories || [];
    const priorities = info.priorities || [];

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{id ? "Update" : "Create"} Savings Goal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              name,
              description,
              goal_amount,
              monthly_limit,
              category_id,
              start_date,
              close_date,
              priority,
            }}
            onSubmit={this.save}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Name</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="name"
                  ></Field>
                  <ErrorMessage
                    name="name"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Description</label>
                  <Field
                    className="form-control"
                    as="textarea"
                    name="description"
                  ></Field>
                  <ErrorMessage
                    name="description"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <div className="row">
                  <div className="col-6">
                    <fieldset className="form-group required">
                      <label className="control-label">Goal amount</label>
                      <Field name="goal_amount" component={NumberField}></Field>
                      <ErrorMessage
                        name="goal_amount"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Start Date</label>
                      <div>
                        <DateSelector
                          className="form-control"
                          name="start_date"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <ErrorMessage
                        name="start_date"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <label className="control-label">Expiration Date</label>
                      <div>
                        <DateSelector
                          className="form-control"
                          name="close_date"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <ErrorMessage
                        name="close_date"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                  </div>
                  <div className="col-6">
                    <fieldset className="form-group required">
                      <label className="control-label">Category</label>
                      <Field
                        className="form-control"
                        as="select"
                        name="category_id"
                      >
                        {categories.map((c) => (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="category_id"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Priority</label>
                      <Field
                        className="form-control"
                        as="select"
                        name="priority"
                      >
                        {priorities.map((p) => (
                          <option key={p.id} value={p.id}>
                            {p.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="priority"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <label className="control-label">Monthly limit</label>
                      <Field
                        name="monthly_limit"
                        component={NumberField}
                      ></Field>
                      <ErrorMessage
                        name="monthly_limit"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                  </div>
                </div>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.savingsGoalId ? "Update" : "Create"}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditSavingsGoal;
