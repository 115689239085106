import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { emergencyPlanEditUrl, emergencyPlanSaveUrl } from "../../library/Urls";
import { isValidUsMoney, toUsMoney } from "../../library/Utilities";

const errorFieldMappings = {
  minimumAmount: "minimum_amount"
};

class EditEmergencyPlan extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      emergency_plan: {
        minimum_amount: "3,000.00"
      },
      errorMessage: null,
      actionsEnabled: false
    };
  }

  componentDidMount = evt => {
    // Edit data
    doHttpGet({
      url: emergencyPlanEditUrl,
      onSuccess: data => {
        this.setState({
          emergency_plan: {
            ...data,
            minimum_amount: data.minimum_amount.toFixed(2)
          },
          actionsEnabled: true,
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false
    });

    // Post
    doHttpPost({
      url: emergencyPlanSaveUrl,
      body: {
        ...values,
        minimum_amount: toUsMoney(values.minimum_amount)
      },
      onSuccess: () => {
        // Handle save
        this.state.onSave();
      },
      rawError: true,
      onError: error => {
        this.setState({
          actionsEnabled: true
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach(error => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage
          });
        }
      }
    });
  };

  validate = values => {
    const errors = {};
    // All inputs are required
    if (!values.minimum_amount) {
      errors.minimum_amount = "Goal minimum is required";
    } else if (!isValidUsMoney(values.minimum_amount)) {
      errors.minimum_amount = "Invalid monetary amount";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const { minimum_amount } = this.state.emergency_plan;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Emergency Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px"
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              minimum_amount
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {props => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Minimum amount</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="minimum_amount"
                  ></Field>
                  <ErrorMessage
                    name="minimum_amount"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    Update
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditEmergencyPlan;
