import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faCheckCircle,
  faTimesCircle,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { fmtMoneyUSD, fmtDate, pluralize } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import NotificationSystem from "react-notification-system";
import ConfirmDialog from "../common/ConfirmDialog";
import EditScheduledTransfer from "./EditScheduledTransfer";
import {
  collegeScheduledTransferUrl,
  transactionDeleteUrl,
} from "../../library/Urls";
import { doHttpDelete } from "../../services/WebService";
import ExecuteScheduledTransfer from "./ExecuteScheduledTransfer";

const ScheduledTransfers = ({
  scheduledTransfers,
  inclDisabledAccts,
  collegeFinanceId,
  transferTransactions,
  fxRefreshCollegeInfo,
  fxShowErrorMessage,
}) => {
  // State
  const [scheduledTransfer, setScheduledTransfer] = useState(null);
  const [scheduledTransferId, setScheduledTransferId] = useState(null);
  const [totalTransferAmount, setTotalTransferAmount] = useState(0.0);
  const [toFinanceName, setToFinanceName] = useState(null);
  const [frFinanceName, setFrFinanceName] = useState(null);
  const [transferAmount, setTransferAmount] = useState(null);
  const [notificationSystem, setNotificationSystem] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showScheduledTransferEditor, setShowScheduledTransferEditor] =
    useState(false);
  const [showScheduledTransferExecutor, setShowScheduledTransferExecutor] =
    useState(false);

  // Computations
  var _totalTransferAmt = 0.0;
  scheduledTransfers.forEach((st) => (_totalTransferAmt += st.amount));

  // Changes
  useEffect(
    () => setTotalTransferAmount(_totalTransferAmt),
    [_totalTransferAmt]
  );

  // Notification system
  useEffect(() => setNotificationSystem(React.createRef()), []);

  // Edit scheduled transfer
  const editScheduledTransfer = (id) => {
    setScheduledTransferId(id);
    setShowScheduledTransferEditor(true);
  };

  // Delete confirmation
  const confirmDelete = (id, amount, fromAcct, destAcct) => {
    setScheduledTransferId(id);
    setTransferAmount(amount);
    setFrFinanceName(fromAcct);
    setToFinanceName(destAcct);
    setShowDeleteConfirmation(true);
  };

  // Delete scheduled transfer
  const deleteScheduledTransfer = (id) => {
    setShowDeleteConfirmation(false);
    // Delete account
    doHttpDelete({
      url: collegeScheduledTransferUrl,
      params: {
        id: scheduledTransferId,
      },
      onSuccess: () => {
        // Update view
        fxRefreshCollegeInfo({
          withDisabledAccounts: inclDisabledAccts,
        });
        // Notification
        notificationSystem.current.addNotification({
          title: "Scheduled Transfer Deleted",
          message:
            "Scheduled transfer of " +
            fmtMoneyUSD(transferAmount) +
            ' from "' +
            frFinanceName +
            '" to "' +
            toFinanceName +
            '" was successfully deleted',
          level: "success",
          position: "br",
          autoDismiss: 5,
        });
      },
      onError: (error) => {
        fxShowErrorMessage(error);
      },
    });
  };

  // After scheduled transfer create/update
  const onCreateOrUpdateScheduledTransfer = (id) => {
    setScheduledTransferId(id);
    setShowScheduledTransferEditor(false);
    setShowScheduledTransferExecutor(false);
    fxRefreshCollegeInfo({
      withDisabledAccounts: inclDisabledAccts,
    });
  };

  // Execute scheduled transfer
  const executeScheduledTransfer = (st) => {
    setScheduledTransfer(st);
    setShowScheduledTransferExecutor(true);
  };

  // Delete transaction
  const deleteTransaction = (batchNumber) => {
    // Delete account
    doHttpDelete({
      url: transactionDeleteUrl,
      params: {
        bn: batchNumber,
      },
      onSuccess: () => {
        fxRefreshCollegeInfo({
          withDisabledAccounts: inclDisabledAccts,
        });
      },
      onError: (error) => {
        fxShowErrorMessage(error);
      },
    });
  };

  // Render
  return (
    <Fragment>
      <div className="row">
        <div
          className="col-12"
          style={{
            textAlign: "right",
            verticalAlign: "bottom",
            marginBottom: 10,
          }}
        >
          <button
            className="btn btn-success"
            onClick={() => editScheduledTransfer(null)}
          >
            <FontAwesomeIcon icon={faPlus} /> &nbsp; New Scheduled Transfer
          </button>
        </div>
      </div>

      <table className="table table-bordered table-striped table-lg table-fin-accts">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Financial Accounts</th>
            <th scope="col">Scheduling</th>
            <th scope="col">Transfer Activity</th>
            <th scope="col">Amount/Status</th>
            <th scope="col" style={{ width: "110px" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {scheduledTransfers.map((stinfo) => {
            const enabledIcon = stinfo.enabled ? faCheckCircle : faTimesCircle;
            const enabledClass = classnames("", {
              "positive-amount": stinfo.enabled,
              "negative-amount": !stinfo.enabled,
            });
            return (
              <tr
                key={"stinfo-" + stinfo.id}
                className={classnames("", {
                  focus: stinfo.id === scheduledTransferId,
                })}
              >
                <td>
                  <b>FROM:</b>{" "}
                  <Link to={Links.financeAccount + `/${stinfo.fromFinanceId}`}>
                    {stinfo.fromFinanceName}
                  </Link>
                  <br />
                  <br />
                  <b>TO:</b>{" "}
                  <Link to={Links.financeAccount + `/${stinfo.toFinanceId}`}>
                    {stinfo.toFinanceName}
                  </Link>
                </td>
                <td>
                  On day <b>{stinfo.scheduleDayOfMonth}</b> of the month
                  <br />
                  <br />
                  Repeat every{" "}
                  <b>{pluralize(stinfo.scheduleFrequency, "month")}</b>
                </td>
                <td>
                  <b>Previous:</b> {fmtDate(stinfo.lastTransferDate)}{" "}
                  {stinfo.lastTransferForced && (
                    <FontAwesomeIcon icon={faPlayCircle} />
                  )}
                  <br />
                  <br />
                  <b>Upcoming:</b> {fmtDate(stinfo.nextTransferDate)}
                </td>
                <td className="text-right" style={{ fontSize: "1.5em" }}>
                  <span style={{ marginRight: 10 }}>
                    {fmtMoneyUSD(stinfo.amount)}
                  </span>
                  <FontAwesomeIcon
                    icon={enabledIcon}
                    className={enabledClass}
                  />
                </td>
                <td className="text-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => editScheduledTransfer(stinfo.id)}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </button>{" "}
                  &nbsp;{" "}
                  {stinfo.deletable && (
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() =>
                        confirmDelete(
                          stinfo.id,
                          stinfo.amount,
                          stinfo.fromFinanceName,
                          stinfo.toFinanceName
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}
                  {stinfo.enabled && (
                    <Fragment>
                      <br />
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        style={{ marginTop: 7 }}
                        onClick={() => executeScheduledTransfer(stinfo)}
                      >
                        <FontAwesomeIcon icon={faPlayCircle} /> Execute
                      </button>
                    </Fragment>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="table-footer">
          <tr>
            <th colSpan="3" className="text-right">
              Total Transfer Amount
            </th>
            <th className="text-right">{fmtMoneyUSD(totalTransferAmount)}</th>
            <th></th>
          </tr>
        </tfoot>
      </table>

      <h3>Transfer Transactions ({transferTransactions.length})</h3>

      <table className="table table-bordered table-striped table-lg table-fin-accts">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">From Account</th>
            <th scope="col">To Account</th>
            <th scope="col">Amount</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {transferTransactions.map((tx) => {
            return (
              <tr key={"tx-" + tx.id}>
                <td>{fmtDate(tx.date)}</td>
                <td>
                  <Link to={Links.financeAccount + `/${tx.fromFinanceId}`}>
                    {tx.fromFinanceName}
                  </Link>
                </td>
                <td>
                  <Link to={Links.financeAccount + `/${tx.toFinanceId}`}>
                    {tx.toFinanceName}
                  </Link>
                </td>
                <td className="text-right" style={{ fontSize: "1.5em" }}>
                  {fmtMoneyUSD(tx.amount)}
                </td>
                <td className="text-right">
                  {tx.deletable && (
                    <Fragment>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => deleteTransaction(tx.batchNumber)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </Fragment>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {showScheduledTransferEditor && (
        <EditScheduledTransfer
          show={showScheduledTransferEditor}
          handleSave={onCreateOrUpdateScheduledTransfer}
          handleClose={() => setShowScheduledTransferEditor(false)}
          scheduledTransferId={scheduledTransferId}
          contextFinanceId={collegeFinanceId}
        />
      )}

      {showScheduledTransferExecutor && (
        <ExecuteScheduledTransfer
          show={showScheduledTransferExecutor}
          handleSave={onCreateOrUpdateScheduledTransfer}
          handleClose={() => setShowScheduledTransferExecutor(false)}
          scheduledTransfer={scheduledTransfer}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmDialog
          show={showDeleteConfirmation}
          handleAction={() => deleteScheduledTransfer(scheduledTransferId)}
          handleClose={() => setShowDeleteConfirmation(false)}
          title="Delete Scheduled Transfer?"
          message={
            "The scheduled transfer will be deleted immediately, and will not be available for future automated transfers of " +
            fmtMoneyUSD(transferAmount) +
            ' from "' +
            frFinanceName +
            '" to "' +
            toFinanceName +
            '".'
          }
          question="Proceed with deleting this scheduled transfer?"
          btnAction="Yes, Delete"
        />
      )}

      <NotificationSystem ref={notificationSystem} />
    </Fragment>
  );
};

export default ScheduledTransfers;
