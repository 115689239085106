import React, { Component } from "react";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { healthcareSavingsEditUrl, healthcareSavingsSaveUrl } from "../../library/Urls";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {isBlank, isValidUsMoney, toUsMoney, trimString} from "../../library/Utilities";
import NumberField from "../common/NumberField";

export default class EditHealthcareSavings extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.handleClose,
      onSave: props.handleSave,
      savingsGoalId: props.savingsGoalId == null ? null : parseInt(props.savingsGoalId),
      errorMessage: null,
      fieldErrors: {},
      actionsEnabled: false,
      onError: props.showErrorMessage,
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: healthcareSavingsEditUrl,
      params: {
        id: this.state.savingsGoalId,
      },
      onSuccess: (data) => {
        this.setState({
          editInfo: {
            ...data,
            name: data.name || "",
            amount: data.amount || "",
          },
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
        this.state.onError(error);
      },
    });
  };

  validateSavingsInfo = (values) => {
    // Errors
    const errors = {};
    // Name
    if (isBlank(values.name)) { errors.name = "Savings goal name is required"; }
    // Amount
    if (!isValidUsMoney(values.amount)) { errors.amount = "Valid target amount is required"; }
    // Whether any errors
    let hasErrors = false;
    for (const attr in errors) {
      if (errors.hasOwnProperty(attr)) {
        hasErrors = true;
        break;
      }
    }
    if (!hasErrors) {
      // Save form values in state
      this.setState({
        savingsGoalInfo: values,
        hasValidationErrors: false,
        actionsEnabled: true,
      });
    } else {
      this.setState({
        hasValidationErrors: true,
        actionsEnabled: false,
      });
    }
    // Errors
    return errors;
  };

  saveSavings = () => {
    // Disable actions
    this.setState({
      actionsEnabled: false,
      fieldErrors: {},
    });
    // Build request
    const requestInfo = {
      // Id
      id: this.state.savingsGoalId,
      // Form values
      ...this.state.savingsGoalInfo,
      // Fix up form data
      name: trimString(this.state.savingsGoalInfo.name),
      amount: toUsMoney(this.state.savingsGoalInfo.amount),
    };
    // Post
    doHttpPost({
      url: healthcareSavingsSaveUrl,
      body: requestInfo,
      onSuccess: (response) => {
        this.state.onSave(response.id);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus >= 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        } else if (httpStatus >= 400) {
          let fieldErrors = {};
          error.response.data.errors.forEach((e) => {
            fieldErrors[e.field] = e.defaultMessage;
          });
          this.setState({
            fieldErrors: fieldErrors,
            hasValidationErrors: true,
            actionsEnabled: false,
          });
        }
      },
    });
  };

  render() {
    // Info
    const editInfo = this.state.editInfo;
    if (!editInfo) return null;
    // Whether editing
    const isEditing = this.state.savingsGoalId != null;
    // Form field values
    const { name, amount } = editInfo;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Update" : "Create"} Savings Goal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{ name, amount }}
            validate={this.validateSavingsInfo}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
            initialErrors={this.state.fieldErrors}
          >
            {(props) => {
              return (
                  <Form>
                    <fieldset className="form-group required">
                      <label className="control-label">Savings Goal Name</label>
                      <Field type="text" name="name" className="form-control"></Field>
                      <ErrorMessage name="name" className="alert alert-warning" component="div"/>
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Target Amount</label>
                      <Field component={NumberField} name="amount" allowNegative={false}></Field>
                      <ErrorMessage name="amount" className="alert alert-warning" component="div"/>
                    </fieldset>
                  </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
                type="button"
                className="btn btn-md btn-primary"
                disabled={
                    !this.state.actionsEnabled || this.state.hasValidationErrors
                }
                onClick={this.saveSavings}
            >
              Save Savings Goal
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
