import React, { useState, useEffect } from "react";
import { doHttpGet } from "../../services/WebService";
import { collegeInfoUrl } from "../../library/Urls";
import Money from "../common/Money";
import { Tabs, Tab } from "react-bootstrap";
import CollegeAccounts from "./CollegeAccounts";
import ScheduledTransfers from "./ScheduledTransfers";

const CollegeInfo = () => {
  // State
  const [collegeInfo, setCollegeInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Get college info
  const getCollegeInfo = (p) => {
    // Params
    const params = {};
    if (p) {
      params.includeDisabledAccounts = p.withDisabledAccounts;
    }
    // GET
    doHttpGet({
      url: collegeInfoUrl,
      params: params,
      onSuccess: (info) => {
        setErrorMessage(null);
        setCollegeInfo(info);
      },
      onError: (error) => setErrorMessage(error),
    });
  };

  // Changes
  useEffect(() => getCollegeInfo(), []);

  // Render
  return (
    <div className="page-content">
      {errorMessage && (
        <div
          className="alert alert-danger"
          style={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "1.2em",
            padding: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}

      {collegeInfo && (
        <div>
          {!collegeInfo.featureExists && (
            <div className="alert alert-danger" style={{ marginBottom: "5px" }}>
              The <b>College</b> feature has not been added to this account
            </div>
          )}
          {!collegeInfo.featureEnabled && (
            <div className="alert alert-danger" style={{ marginBottom: "5px" }}>
              The <b>College</b> feature is not enabled for this account
            </div>
          )}
          <div className="row">
            <div className="col-8">
              <h2>
                College Savings
                <span className="gzn-detail-flag">Feature/Option</span>
              </h2>
            </div>
            <div
              className="col-4"
              style={{ textAlign: "right", verticalAlign: "bottom" }}
            >
              <Money amount={collegeInfo.totalSavings} className="amount-lg" />
            </div>
          </div>
          <Tabs defaultActiveKey="fa" id="financial-account-tab">
            <Tab
              eventKey="fa"
              title={
                "College Accounts (" + collegeInfo.collegeAccounts.length + ")"
              }
            >
              <CollegeAccounts
                collegeAccounts={collegeInfo.collegeAccounts}
                totalCollegeSavings={collegeInfo.totalSavings}
                inclDisabledAccts={collegeInfo.includeDisabledAccounts}
                collegeFinanceId={null}
                fxRefreshCollegeInfo={(params) => getCollegeInfo(params)}
                fxShowErrorMessage={(errorMsg) => setErrorMessage(errorMsg)}
              />
            </Tab>
            <Tab
              eventKey="st"
              title={
                "Scheduled Transfers (" +
                collegeInfo.scheduledTransfers.length +
                ")"
              }
            >
              <ScheduledTransfers
                scheduledTransfers={collegeInfo.scheduledTransfers}
                inclDisabledAccts={collegeInfo.includeDisabledAccounts}
                transferTransactions={collegeInfo.scheduledTransferTransactions}
                fxRefreshCollegeInfo={(params) => getCollegeInfo(params)}
                fxShowErrorMessage={(errorMsg) => setErrorMessage(errorMsg)}
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default CollegeInfo;
