import React, { Component } from "react";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import Select from "react-select";
import { doHttpGet } from "../../services/WebService";
import {
  reconciliationFinancesUrl,
  reconciliationUrl,
} from "../../library/Urls";
import classnames from "classnames";
import { findItemByAttribute, isDefined } from "../../library/Utilities";
import AccountReconciliation from "./AccountReconciliation";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

class Reconciliation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      financialAccounts: [],
      includeDisabledAccounts: false,
      actionsEnabled: false,
      selectedFinanceId: this.props.match.params.financeId,
      selectedFinance: null,
    };
  }

  getFinancialAccounts = (includeDisabled) => {
    let selectedFinanceId = isDefined(this.state.selectedFinanceId)
      ? parseInt(this.state.selectedFinanceId, 10)
      : null;
    doHttpGet({
      url: reconciliationFinancesUrl,
      params: {
        disabled: includeDisabled,
      },
      onSuccess: (accounts) => {
        // Selected
        let selectedFinance = findItemByAttribute(
          accounts,
          "id",
          selectedFinanceId
        );
        // State
        this.setState({
          selectedFinanceId: selectedFinanceId,
          selectedFinance: selectedFinance,
          includeDisabledAccounts: includeDisabled,
          financialAccounts: accounts,
          actionsEnabled: accounts.length > 0,
          errorMessage: accounts.length
            ? null
            : "There are no financial accounts to reconcile",
        });
        // Reconciliation
        if (selectedFinanceId) {
          this.getReconciliationInfo(selectedFinanceId, null);
        }
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  componentDidMount = () => {
    this.getFinancialAccounts(false);
  };

  selectFinancialAccount = (info) => {
    this.setState({
      selectedFinance: info,
    });
    // Reconciliation info
    if (info) {
      this.getReconciliationInfo(info.id, null);
    } else {
      this.props._setReconciliationInfo(null);
    }
  };

  includeDisabledFinances = (evt) => {
    const isSelected = evt.target.checked;
    this.getFinancialAccounts(isSelected);
  };

  buildFinanceOption = (fa) => {
    if (fa == null) return null;
    return {
      value: fa.id,
      label: fa.description + " (*" + fa.name + ")",
      info: fa,
    };
  };

  getReconciliationInfo = (financeId, reconciliationId, fxUpdate) => {
    doHttpGet({
      url: reconciliationUrl,
      params: {
        fid: financeId,
        rid: reconciliationId,
      },
      onSuccess: (info) => {
        // If updating transactions
        if (fxUpdate) {
          fxUpdate(info.transactions);
        } else {
          // Reconciliation
          info.statementDate = new Date(info.statementDate);
          info.statementBalance = info.statementBalance.toFixed(2);
          this.props._setReconciliationInfo(info);
          // State
          this.setState({
            errorMessage: null,
            financeId: financeId,
            reconciliationId: reconciliationId,
          });
        }
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    const { errorMessage, selectedFinance, selectedFinanceId } = this.state;
    // Financial account options
    const financialAccountOptions = [];
    let selectedOption = null;
    this.state.financialAccounts.forEach((fa) => {
      const option = {
        value: fa.id,
        label: fa.description + " (*" + fa.name + ")",
        info: fa,
      };
      financialAccountOptions.push(option);
      if (selectedFinanceId === fa.id) {
        selectedOption = option;
      }
    });

    // Render
    return (
      <div className="page-content">
        <div className="row" style={{ marginBottom: 8 }}>
          <div className="col-8">
            <h2>Account Reconciliation</h2>
          </div>

          <div className="col-4">
            <div className="custom-control custom-checkbox">
              <input
                id="financeDisabledCbx"
                type="checkbox"
                value={this.state.includeDisabledAccounts}
                checked={this.state.includeDisabledAccounts}
                onChange={this.includeDisabledFinances}
                className="custom-control-input"
                style={{
                  width: "20px",
                  height: "20px",
                  verticalAlign: "bottom",
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="financeDisabledCbx"
                style={{
                  verticalAlign: "top",
                  lineHeight: "20px",
                  margin: "2px 0",
                  display: "block",
                  height: "20px",
                }}
              >
                Include disabled financial accounts
              </label>
            </div>
            <Select
              options={financialAccountOptions}
              components={{ Option: SelectFinance }}
              onChange={(value) => this.selectFinancialAccount(value.info)}
              defaultValue={selectedOption}
            />
          </div>
        </div>

        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}

        {selectedFinance ? (
          <div>
            <h4>{selectedFinance.name}</h4>
            <h6>
              <Link to={Links.financeFor(selectedFinance.id)}>
                {selectedFinance.description}
              </Link>
              <span className="gzn-detail-flag">{selectedFinance.type}</span>
            </h6>
            <AccountReconciliation
              reconciliation={this.props.reconciliation}
              reloadTransactions={this.getReconciliationInfo}
            />
          </div>
        ) : (
          <div className="alert alert-info" style={{ textAlign: "right" }}>
            No financial account is selected. Please select one from the list
            above to proceed.
          </div>
        )}
      </div>
    );
  }
}

const SelectFinance = (props) => {
  const info = props.data.info;
  return (
    <div
      className={classnames("select-option", {
        "select-option-selected": props.isSelected,
        "select-option-disabled": !info.enabled,
      })}
      {...props.innerProps}
    >
      <div className="select-label">{info.name}</div>
      <div className="select-descr">{info.description}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    reconciliation: state.reconciliation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Reconciliation
    _setReconciliationInfo: (reconciliation) => {
      dispatch(Actions.setReconciliationInfo(reconciliation));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reconciliation);
