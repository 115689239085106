import React, { Fragment } from "react";
import { fmtMoneyUSD, fmtNumber } from "../../library/Utilities";

export default function GoalProgress(props) {
  const info = props.info;

  // Bar widths
  const max = Math.max(
    info.goal_amount,
    info.distributed_amount,
    info.spent_amount,
    info.allocated_amount
  );
  const spent = (info.spent_amount / max) * 100.0;
  let allocated = ((info.allocated_amount - info.spent_amount) / max) * 100.0;
  let overbudget = info.spent_amount - info.allocated_amount;
  if (overbudget > 0.0) {
    allocated = 0.0;
    overbudget = (Math.abs(overbudget) / max) * 100.0;
  } else if (overbudget < 0.0) {
    overbudget = 0.0;
  }
  const widths = [spent, overbudget, allocated];

  // Wordings and colors
  let spentAdjustment = "of";
  let remainingColor = "#339933";
  let remainingText = "available";
  if (info.available_amount < 0.0) {
    spentAdjustment = "but had";
    remainingText = "over-budget";
    remainingColor = "#CC3333";
  }

  // Component
  return (
    <Fragment>
      <table
        className="table table-sm"
        style={{ borderCollapse: "collapse", marginBottom: "0" }}
      >
        <tbody>
          <tr>
            <td style={{ color: "#999999", verticalAlign: "bottom" }}>
              Distributed{" "}
              <span style={{ fontWeight: "bold" }}>
                {fmtMoneyUSD(info.distributed_amount)}
              </span>{" "}
              ({fmtNumber(info.distributed_pct, 1)}%)
            </td>
            <td
              style={{
                fontSize: "1.5em",
                fontWeight: "bold",
                textAlign: "right",
                textTransform: "uppercase",
              }}
            >
              Goal: {fmtMoneyUSD(info.goal_amount)}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ backgroundColor: "#FF9933", width: widths[0] + "%" }}
        />
        <div
          className="progress-bar"
          role="progressbar"
          style={{ backgroundColor: "#CC3333", width: widths[1] + "%" }}
        />
        <div
          className="progress-bar"
          role="progressbar"
          style={{ backgroundColor: "#339933", width: widths[2] + "%" }}
        />
      </div>

      <div
        style={{
          textAlign: "right",
          marginBottom: "25px",
        }}
      >
        <span
          style={{
            color: "#999999",
            fontSize: "0.9em",
            paddingRight: "10px",
          }}
        >
          Spent {fmtMoneyUSD(info.spent_amount)} {spentAdjustment} allocated{" "}
          {fmtMoneyUSD(info.allocated_amount)}
        </span>{" "}
        |
        <span
          style={{
            fontWeight: "bold",
            color: remainingColor,
            fontSize: "1.5em",
            paddingLeft: "10px",
          }}
        >
          {fmtMoneyUSD(info.available_amount) + " " + remainingText}
        </span>
      </div>
    </Fragment>
  );
}
