import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { fmtMoneyUSD } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import ConfirmDialog from "../common/ConfirmDialog";
import NotificationSystem from "react-notification-system";
import { healthcareBudgetDeleteUrl } from "../../library/Urls";
import { doHttpDelete } from "../../services/WebService";
import EditHealthcareBudget from "./EditHealthcareBudget";

const HealthcareBudgets = ({
  budgets,
  refreshHealthcareInfo,
  showErrorMessage,
}) => {
  // Summations
  let totalCurrentYrAmt = 0.0, totalPreviousYrAmt = 0.0;
  let totalCurrentYrTx = 0, totalPreviousYrTx = 0;
  for(const c of budgets) {
    totalCurrentYrAmt += c.expenses_current_yr;
    totalPreviousYrAmt += c.expenses_previous_yr;
    totalCurrentYrTx += c.transactions_current_yr;
    totalPreviousYrTx += c.transactions_previous_yr;
  }
  const _totalTransactionAmt = totalCurrentYrAmt + totalPreviousYrAmt;

  // Years
  const currentYear = (new Date()).getFullYear();
  const previousYear = currentYear - 1;

  // State
  const [totalTransactionAmount, setTotalTransactionAmount] = useState(0.0);
  const [isShowEditor, setShowEditor] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [budgetId, setBudgetId] = useState(null);
  const [budgetName, setFinanceName] = useState(null);
  const [notificationSystem, setNotificationSystem] = useState(null);

  // Changes
  useEffect(
    () => setTotalTransactionAmount(_totalTransactionAmt),
    [_totalTransactionAmt]
  );

  // Notification system
  useEffect(() => setNotificationSystem(React.createRef()), []);

  // Delete confirmation
  const confirmDelete = (id, name) => {
    setBudgetId(id);
    setFinanceName(name);
    setShowDeleteConfirmation(true);
  };

  // Delete
  const deleteBudget = (id) => {
    setShowDeleteConfirmation(false);
    // Delete request
    doHttpDelete({
      url: healthcareBudgetDeleteUrl(id),
      onSuccess: () => {
        // Update view
        refreshHealthcareInfo({});
        // Notification
        notificationSystem.current.addNotification({
          title: "Expense Category Deleted",
          message:
            "Healthcare category expense '" + budgetName + "' was successfully deleted",
          level: "success",
          position: "br",
          autoDismiss: 5,
        });
      },
      onError: (error) => {
        showErrorMessage(error);
      },
    });
  };

  // Edit financial account
  const editBudget = (id) => {
    setBudgetId(id);
    setShowEditor(true);
  };

  // After financial account update
  const onUpdateBudget = (id) => {
    setBudgetId(id);
    setShowEditor(false);
    refreshHealthcareInfo({});
  };

  // Render
  return (
    <Fragment>
      <div className="row">
        <div
          className="col-12"
          style={{
            textAlign: "right",
            verticalAlign: "bottom",
            marginBottom: 10,
          }}
        >
          <button
            className="btn btn-success"
            onClick={() => editBudget(null)}
          >
            <FontAwesomeIcon icon={faPlus} /> &nbsp; Add Category
          </button>
        </div>
      </div>

      <table className="table table-bordered table-lg table-fin-accts">
        <thead className="thead-dark">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Budget</th>
          <th scope="col">Spending ({currentYear})</th>
          <th scope="col">Transactions ({currentYear})</th>
          <th scope="col">Spending ({previousYear})</th>
          <th scope="col">Transactions ({previousYear})</th>
          <th scope="col" style={{width: "110px"}}>
            Actions
          </th>
        </tr>
        </thead>
        <tbody>
        {budgets.map((c) => (
            <tr
                key={"bgt-" + c.id}
            >
              <td>{c.name}</td>
              <td><Link to={Links.budgetFor(c.id)}>{c.budget_name}</Link></td>
              <td className="text-right">{fmtMoneyUSD(c.expenses_current_yr)}</td>
              <td className="text-right">{c.transactions_current_yr}</td>
              <td className="text-right">{fmtMoneyUSD(c.expenses_previous_yr)}</td>
              <td className="text-right">{c.transactions_previous_yr}</td>
              <td className="text-right">
                {c.can_edit && (
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => editBudget(c.id)}
                    >
                      <FontAwesomeIcon icon={faEdit}/> Edit
                    </button>
                )}
                {" "}&nbsp;{" "}
                {c.can_delete && (
                    <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => confirmDelete(c.id, c.name)}
                    >
                      <FontAwesomeIcon icon={faTrash}/>
                    </button>
                )}
              </td>
            </tr>
        ))}
        </tbody>
        <tfoot className="table-footer">
        <tr>
          <th colSpan="2"></th>
          <th className="text-right">{fmtMoneyUSD(totalCurrentYrAmt)}</th>
          <th className="text-right">{totalCurrentYrTx}</th>
          <th className="text-right">{fmtMoneyUSD(totalPreviousYrAmt)}</th>
          <th className="text-right">{totalPreviousYrTx}</th>
          <th></th>
        </tr>
        </tfoot>
      </table>

      {isShowEditor && (
          <EditHealthcareBudget
          show={isShowEditor}
          handleSave={onUpdateBudget}
          handleClose={() => setShowEditor(false)}
          showErrorMessage={showErrorMessage}
          budgetId={budgetId}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmDialog
          show={showDeleteConfirmation}
          handleAction={() => deleteBudget(budgetId)}
          handleClose={() => setShowDeleteConfirmation(false)}
          title="Delete Expense Category?"
          message="The healthcare category will be deleted immediately, and will not be available for future use."
          question="Are you sure about deleting this healthcare category?"
          btnAction="Yes, Delete"
        />
      )}

      <NotificationSystem ref={notificationSystem} />
    </Fragment>
  );
};

export default HealthcareBudgets;
