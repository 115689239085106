import React, { useState, useEffect } from "react";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { editInlineAlignStyle } from "../../library/Styles";
import NumberField from "../common/NumberField";
import { clone } from "../../library/Utilities";

const Investments = ({
  cashAmount,
  stockValue,
  capitalGains,
  dividendAmount,
  _updateInfo,
}) => {
  // State
  const [_cashAmount, setCashAmount] = useState(cashAmount);
  const [_stockValue, setStockValue] = useState(stockValue);
  const [_capitalGains, setCapitalGains] = useState(capitalGains);
  const [_dividendAmount, setDividendAmount] = useState(dividendAmount);

  // Changes
  useEffect(() => setCashAmount(cashAmount), [cashAmount]);
  useEffect(() => setStockValue(stockValue), [stockValue]);
  useEffect(() => setCapitalGains(capitalGains), [capitalGains]);
  useEffect(() => setDividendAmount(dividendAmount), [dividendAmount]);

  /* Validation */
  const validate = (values) => {
    // Info
    const info = {
      investmentCashAmount: values._cashAmount,
      investmentStockValue: values._stockValue,
      investmentCapitalGains: values._capitalGains,
      investmentDividends: values._dividendAmount,
    };
    // Update
    _updateInfo(info);
    // Errors
    return null;
  };

  // Render
  return (
    <Formik
      initialValues={{
        _cashAmount,
        _stockValue,
        _capitalGains,
        _dividendAmount,
      }}
      validate={validate}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(props) => {
        const styles = clone(editInlineAlignStyle);
        styles.label.width = "45%";
        styles.input.width = "55%";
        // Render
        return (
          <Form>
            <fieldset className="form-group required" style={styles.fieldset}>
              <label className="control-label" style={styles.label}>
                Cash Amount
              </label>
              <Field
                name="_cashAmount"
                style={styles.input}
                allowNegative={true}
                component={NumberField}
              ></Field>
            </fieldset>
            <fieldset className="form-group required" style={styles.fieldset}>
              <label className="control-label" style={styles.label}>
                Stock Value
              </label>
              <Field
                name="_stockValue"
                style={styles.input}
                component={NumberField}
              ></Field>
            </fieldset>
            <fieldset className="form-group required" style={styles.fieldset}>
              <label className="control-label" style={styles.label}>
                Capital Gains
              </label>
              <Field
                name="_capitalGains"
                style={styles.input}
                component={NumberField}
              ></Field>
            </fieldset>
            <fieldset className="form-group required" style={styles.fieldset}>
              <label className="control-label" style={styles.label}>
                Dividends
              </label>
              <Field
                name="_dividendAmount"
                style={styles.input}
                component={NumberField}
              ></Field>
            </fieldset>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  const reconciliation = state.reconciliation || {};
  return {
    cashAmount: reconciliation.investmentCashAmount || "0.00",
    stockValue: reconciliation.investmentStockValue || "0.00",
    capitalGains: reconciliation.investmentCapitalGains || "0.00",
    dividendAmount: reconciliation.investmentDividends || "0.00",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _updateInfo: (o) => {
      dispatch(Actions.updateReconciliationInvestments(o));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Investments);
