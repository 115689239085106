import React, { Component, Fragment } from "react";
import moment from "moment";
import { doHttpGet, doHttpDelete, doHttpPost } from "../../services/WebService";
import {
  adminFeatureActionsUrl,
  adminFeatureActionUrl,
} from "../../library/Urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../common/ConfirmDialog";

export default class FeatureActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      featureActions: [],
      confirmDelete: false,
      approveAction: false,
    };
  }

  getFeatureActions = () => {
    doHttpGet({
      url: adminFeatureActionsUrl,
      onSuccess: (data) => {
        this.setState({
          errorMessage: null,
          featureActions: data,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  componentDidMount = (evt) => {
    this.getFeatureActions();
  };

  closeConfirm = () => {
    this.setState({
      confirmDelete: false,
      approveAction: false,
    });
  };

  deleteFeatureAction = () => {
    // Delete
    doHttpDelete({
      url: adminFeatureActionUrl(this.state.featureActionId),
      onSuccess: (data) => {
        // Close editor
        this.closeConfirm();
        // Update actions
        this.getFeatureActions();
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  confirmDeletion = (id, featureName, primaryUser) => {
    this.setState({
      featureActionId: id,
      featureName: featureName,
      confirmDelete: true,
      primaryUser: primaryUser,
    });
  };

  confirmFeatureAction = (id, featureName, primaryUser) => {
    this.setState({
      featureActionId: id,
      featureName: featureName,
      approveAction: true,
      primaryUser: primaryUser,
    });
  };

  approveFeatureAction = () => {
    // Approve
    doHttpPost({
      url: adminFeatureActionUrl(this.state.featureActionId),
      onSuccess: (data) => {
        // Close editor
        this.closeConfirm();
        // Update actions
        this.getFeatureActions();
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    // Feature actions
    const features = this.state.featureActions;
    return (
      <Fragment>
        <h3>Pending Feature Actions ({features.length})</h3>
        {this.state.errorMessage && (
          <div className="alert alert-danger">{this.state.errorMessage}</div>
        )}

        <table className="table table-striped table-bordered table-hover table-md">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Account Primary</th>
              <th scope="col">Account Status</th>
              <th scope="col">Subscription</th>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
              <th scope="col">Feature</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {features.map((feature) => (
              <tr key={"feature-" + feature.id}>
                <td>{feature.primaryUserNames}</td>
                <td>{feature.accountStatus}</td>
                <td>{feature.accountSubscription}</td>
                <td>{moment(feature.date).format("MMMM DD, YYYY")}</td>
                <td>{feature.action}</td>
                <td>{feature.feature}</td>
                <td style={{ width: "175px", textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-success btn-md"
                    style={{ marginRight: "10px" }}
                    onClick={() =>
                      this.confirmFeatureAction(
                        feature.id,
                        feature.feature,
                        feature.primaryUserNames
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faCheck} /> &nbsp; Approve
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-md"
                    onClick={() =>
                      this.confirmDeletion(
                        feature.id,
                        feature.feature,
                        feature.primaryUserNames
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {this.state.confirmDelete && (
          <ConfirmDialog
            show={this.state.confirmDelete}
            handleAction={this.deleteFeatureAction}
            handleClose={() => this.closeConfirm()}
            title="Remove Feature Action?"
            message={
              "The feature '" +
              this.state.featureName +
              "' will be removed for the account belonging to " +
              this.state.primaryUser
            }
            question="Are you sure you want to remove the feature action?"
            btnAction="Yes, Remove"
          />
        )}

        {this.state.approveAction && (
          <ConfirmDialog
            show={this.state.approveAction}
            handleAction={this.approveFeatureAction}
            handleClose={() => this.closeConfirm()}
            title="Approve Feature Action?"
            message={
              "The feature '" +
              this.state.featureName +
              "' will be added for the account belonging to <b>" +
              this.state.primaryUser +
              "</b>. The appropriate options will be created in their account."
            }
            question="Are you sure you want to add the feature option?"
            btnAction="Yes, Add"
          />
        )}
      </Fragment>
    );
  }
}
