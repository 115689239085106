import {
  setAuthenticationHeader,
  attemptServerLogout,
  removeAuthenticationHeader,
} from "../services/WebService";
import * as Actions from "../redux/actions";
import * as LocalService from "./LocalService";

// Reference to store
let REDUX_STORE = null;

class AuthenticationService {
  restoreLocalData = (reduxStore) => {
    // Save reference to store
    REDUX_STORE = reduxStore;
    // Auth token
    const authToken = this.getToken();
    // If there is a token
    if (authToken) {
      // Current time
      const currTime = Date.now() / 1000;
      // Decode
      const info = this.decodeJwtToken(authToken, true);
      // Invalid (expired)
      if (info.exp < currTime) {
        // Logout
        this.handleLogout();
      }
      // Valid token
      else {
        // Login
        this.setupJwtToken(authToken);
        // Token info
        const sessionInfo = this.decodeJwtToken(authToken, false);
        // Update session
        reduxStore.dispatch(Actions.setSession(sessionInfo, false));

        // Account deletion date
        const deletionDt = this.getAccountDeletionDate();
        reduxStore.dispatch(Actions.setAccountDeletionDate(deletionDt));
      }
    }
  };

  getFeatureOptions = () => {
    const state = REDUX_STORE.getState();
    return state ? state.featureOptions : null;
  };

  setAccountDeletionDate = (date) => {
    LocalService.setAccountDeletionDate(date);
  };

  getAccountDeletionDate = () => {
    return LocalService.getAccountDeletionDate();
  };

  setupJwtToken = (jwtToken) => {
    LocalService.setAuthenticationToken(jwtToken);
    // Set authentication header
    setAuthenticationHeader(jwtToken);
  };

  handleLogout = () => {
    const token = LocalService.getAuthenticationToken();
    if (token) {
      LocalService.removeAuthenticationToken();
      LocalService.removeAccountOptions();
      const info = this.decodeJwtToken(token);
      attemptServerLogout(info.userId);
    }
    // Clear session data
    this.setAccountDeletionDate(null);
    // Clear store data
    if (REDUX_STORE) {
      REDUX_STORE.dispatch(Actions.clearStore());
    }
    // Remove authentication header
    removeAuthenticationHeader();
  };

  isLoggedIn = () => {
    return !LocalService.isAuthTokenExpired();
  };

  getToken = () => {
    return LocalService.getAuthenticationToken();
  };

  decodeJwtToken = (token, raw) => {
    if (token) {
      const info = LocalService.decodeJwtToken(token);
      if (raw) {
        return info;
      }
      return {
        userId: info.user_id,
        accountId: info.account_id,
        username: info.username,
        previousLogin: info.last_login || "(Never)",
        isAdmin: info.roles.indexOf("ADMIN") > -1,
        isGuest: info.roles.indexOf("GUEST") > -1,
        isPrimary: info.roles.indexOf("PRIMARY") > -1,
        featureHealthcare: info.features.indexOf("HEALTHCARE") > -1,
        featureStocks: info.features.indexOf("STOCKS") > -1,
        featureRetirement: info.features.indexOf("RETIREMENT") > -1,
        featureCollege: info.features.indexOf("COLLEGE") > -1,
      };
    }
    return null;
  };

  getAuthenticatedUserInfo = () => {
    const token = LocalService.getAuthenticationToken();
    return this.decodeJwtToken(token);
  };

  isHealthcareFeatureEnabled = () => {
    const options = this.getFeatureOptions();
    const enabled = options ? options.healthcare : null;
    return enabled ? enabled : false;
  };

  isStockFeatureEnabled = () => {
    const options = this.getFeatureOptions();
    const enabled = options ? options.stocks : null;
    return enabled ? enabled : false;
  };

  isRetirementFeatureEnabled = () => {
    const options = this.getFeatureOptions();
    const enabled = options ? options.retirement : null;
    return enabled ? enabled : false;
  };

  isCollegeFeatureEnabled = () => {
    const options = this.getFeatureOptions();
    const enabled = options ? options.college : null;
    return enabled ? enabled : false;
  };
}

export default new AuthenticationService();
