import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { fmtMoneyUSD, fullYearMonthName } from "../../library/Utilities";
import { budgetFor } from "../../library/Links";

export default function UpcomingListings(props) {
  // Listings
  const listings = props.listings || [];
  // If no listings
  if (listings.length === 0) {
    return (
      <div className="description">
        There are no upcoming budgets for the selected month.
      </div>
    );
  }
  return (
    <Fragment>
      {listings.map((lst) => {
        return (
          <div
            key={lst.id}
            className="row"
            style={{
              borderBottom: "1px dotted gray",
              margin: "0px 10px",
              padding: "5px 0px",
            }}
          >
            <div
              className="col-sm-6"
              style={{ textAlign: "left", paddingRight: "10px" }}
            >
              <Link to={budgetFor(lst.id)}>{lst.name}</Link>
            </div>
            <div
              className="col-sm-6"
              style={{
                paddingLeft: "15px",
                fontSize: "0.9em",
                color: "#996633",
              }}
            >
              {fmtMoneyUSD(lst.nextAmount)} in {fullYearMonthName(lst.whenNext)}
            </div>
          </div>
        );
      })}
    </Fragment>
  );
}
