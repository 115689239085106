import React, { useState } from "react";
import { doHttpPost } from "../../services/WebService";
import { stockSplitUrl } from "../../library/Urls";
import { useForm } from "react-hook-form";
import classnames from "classnames";

const StockSplitCard = ({ stocksList, setErrorMessage }) => {
  const [updateResponse, setUpdateResponse] = useState(null);
  const [isUpdateButtonDisabled, setUpdateButtonDisabled] = useState(true);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // Select stock
  const selectStock = (evt) => {
    const stockId = parseInt(evt.target.value);
    setValue("split_shares", "");
    setUpdateButtonDisabled(false);
    setUpdateResponse(null);
  };

  // Update splits
  const updateStock = (info) => {
    doHttpPost({
      url: stockSplitUrl,
      body: info,
      onSuccess: (response) => {
        setUpdateResponse(response);
        if (response.success) {
          setUpdateButtonDisabled(true);
          setValue("split_shares", "");
        }
      },
      onError: (error) => setErrorMessage(error),
    });
  };

  // Render
  return (
    <div className="card border-dark mb-3">
      <div className="card-header">Stock Share Splitting</div>
      <div className="card-body text-dark">
        <form onSubmit={handleSubmit(updateStock)}>
          <fieldset className="form-group">
            <label className="control-label">Stock To Update :</label>
            <select
              {...register("stock_id", { required: true })}
              className="form-control"
              onChange={selectStock}
            >
              {stocksList.map((stock) => (
                <option key={stock.id} value={stock.id}>
                  {stock.symbol} ({stock.name})
                </option>
              ))}
            </select>
          </fieldset>

          <fieldset className="form-group required">
            <label className="control-label">Split Each Share Into :</label>
            <input
              className="form-control"
              defaultValue=""
              {...register("split_shares", {
                required: true,
                pattern: /^\d+$/,
                min: 2,
              })}
            />
            {errors.split_shares && (
              <div className="alert alert-warning">
                {errors.split_shares?.type === "pattern" &&
                  "Shares must be numeric"}
                {errors.split_shares?.type === "required" &&
                  "Shares to split into is required"}
                {errors.split_shares?.type === "min" &&
                  "Shares to split into should be 2 or more"}
              </div>
            )}
          </fieldset>

          <div>
            <button
              className={"btn btn-primary"}
              type="submit"
              disabled={isUpdateButtonDisabled}
            >
              Split Stock Shares
            </button>
          </div>
        </form>

        {updateResponse && (
          <div
            className={classnames("alert", {
              "alert-danger": !updateResponse.success,
              "alert-success": updateResponse.success,
            })}
            style={{
              marginTop: "10px",
            }}
          >
            {updateResponse.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default StockSplitCard;
