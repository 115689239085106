import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import { categoryListingUrl, categoryDeleteUrl } from "../../library/Urls";
import EditCategory from "./EditCategory";
import ConfirmDialog from "../common/ConfirmDialog";
import NotificationSystem from "react-notification-system";

export default class Categories extends Component {
  notificationSystem = React.createRef();

  // State
  state = {
    errorMessage: null,
    selectedId: null,
    confirmDelete: false
  };

  getCategories = () => {
    doHttpGet({
      url: categoryListingUrl,
      onSuccess: data => {
        this.setState({
          categories: data,
          actionsEnabled: true,
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  componentDidMount = evt => {
    // Categories
    this.getCategories();
  };

  showEditor = (show, categoryId) => {
    this.setState({
      selectedId: categoryId,
      showEditor: show
    });
  };

  onEdit = categoryId => {
    this.showEditor(false, categoryId);
    this.getCategories();
  };

  confirmDelete = (show, categoryId, categoryName) => {
    this.setState({
      confirmDelete: show,
      selectedId: categoryId,
      categoryName: categoryName
    });
  };

  onConfirmDelete = () => {
    // Category name
    const categoryName = this.state.categoryName;
    // Close confirmation dialog
    this.confirmDelete(false, null, categoryName);
    // Delete account
    doHttpDelete({
      url: categoryDeleteUrl(this.state.selectedId),
      onSuccess: () => {
        // Update view
        this.getCategories();
        // Notification
        const notification = this.notificationSystem.current;
        notification.addNotification({
          title: "Category Deleted",
          message: "Category [" + categoryName + "] was successfully deleted",
          level: "success",
          position: "br",
          autoDismiss: 5
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  render() {
    // Categories
    const categories = this.state.categories || [];

    // Render
    return (
      <div className="page-content">
        {this.state.errorMessage && (
          <div className="alert alert-danger">{this.state.errorMessage}</div>
        )}

        <div className="row">
          <div className="col-8">
            <h2>Categories ({categories.length})</h2>
          </div>
          <div
            className="col-4"
            style={{ textAlign: "right", verticalAlign: "bottom" }}
          >
            <button
              className="btn btn-success"
              onClick={() => this.showEditor(true, null)}
            >
              <FontAwesomeIcon icon={faPlus} /> &nbsp; New Category
            </button>
          </div>
        </div>

        <table className="table table-bordered table-lg table-striped tbl-categories">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col" style={{ width: "110px" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {categories.map(c => (
              <tr
                key={"c-" + c.id}
                className={classnames("", {
                  focus: c.id === this.state.selectedId
                })}
              >
                <td>{c.type_name}</td>
                <th scope="row">
                  <Link to={Links.categoryFor(c.id)}>{c.category_name}</Link>
                </th>
                <td>{c.description}</td>
                <td className="text-right">
                  {c.is_editable && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.showEditor(true, c.id)}
                    >
                      <FontAwesomeIcon icon={faEdit} /> Edit
                    </button>
                  )}{" "}
                  &nbsp;{" "}
                  {c.is_deletable && (
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() =>
                        this.confirmDelete(true, c.id, c.category_name)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {this.state.showEditor && (
          <EditCategory
            show={this.state.showEditor}
            onSave={this.onEdit}
            onClose={() => this.showEditor(false)}
            categoryId={this.state.selectedId}
          />
        )}

        {this.state.confirmDelete && (
          <ConfirmDialog
            show={this.state.confirmDelete}
            handleAction={this.onConfirmDelete}
            handleClose={() => this.confirmDelete(false, null, null)}
            _type="category"
          />
        )}

        <NotificationSystem ref={this.notificationSystem} />
      </div>
    );
  }
}
