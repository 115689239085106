import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import {
  financeCreatingUrl,
  financeEditUrl,
  financeSaveRetirementUrl,
} from "../../library/Urls";
import {
  isValidUsMoney,
  toUsMoney,
  findItemByAttribute,
} from "../../library/Utilities";
import Checkbox from "../common/Checkbox";
import NumberField from "../common/NumberField";

const errorFieldMappings = {
  name: "account_name",
  accountNumber: "account_number",
  institution: "institution",
  reconciliationFrequency: "reconciliation_freq",
  initialAmount: "initial_amount",
  planType: "plan_type",
};

class EditFinancialAccount extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleClose: props.handleClose,
      handleSave: props.handleSave,
      finance: {
        id: props.financeId,
        account_name: "",
        account_number: "",
        institution: "",
        is_enabled: false,
        reconciliation_freq: 1,
        initial_amount: "0.0",
        plan_type: "SECTION_401K_PLAN",
        plan_types: [],
        for_transactions: false,
      },
      financeId: props.financeId,
      errorMessage: null,
      actionsEnabled: false,
      actionSubmitText: "Save",
    };
  }

  componentDidMount = (evt) => {
    // Whether creating or editing
    const fx = this.state.financeId ? financeEditUrl : financeCreatingUrl;
    const arg = this.state.financeId || "Retirement";
    const submit = this.state.financeId
      ? this.state.actionSubmitText
      : "Create Account";
    // Initial data
    doHttpGet({
      url: fx(arg),
      onSuccess: (data) => {
        this.setState({
          finance: {
            ...data,
            initial_amount: data.initial_amount.toFixed(2),
          },
          actionsEnabled: true,
          errorMessage: null,
          actionSubmitText: submit,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false,
    });

    // Post
    doHttpPost({
      url: financeSaveRetirementUrl,
      body: {
        ...values,
        id: this.state.financeId,
        initial_amount: toUsMoney(values.initial_amount),
        reconciliation_freq: parseInt(values.reconciliation_freq, 10),
        plan_type: findItemByAttribute(
          this.state.finance.plan_types,
          "id",
          values.plan_type
        ).name,
      },
      onSuccess: (financeId) => {
        // Handle save
        this.state.handleSave(financeId);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach((error) => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage,
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  validate = (values) => {
    const errors = {};
    // Required inputs
    if (!values.account_name) {
      errors.account_name = "Account name is required";
    }
    if (!values.account_number) {
      errors.account_number = "Account number is required";
    }
    if (!values.institution) {
      errors.institution = "Institution is required";
    }
    if (!values.reconciliation_freq) {
      errors.reconciliation_freq = "Reconciliation frequency is required";
    } else {
      const freq = parseInt(values.reconciliation_freq, 10);
      if (freq < 1 || freq > 12) {
        errors.reconciliation_freq =
          "Reconciliation frequency must be between 1 and 12";
      }
    }
    if (values.initial_amount == null || values.initial_amount === "") {
      errors.initial_amount = "Initial amount is required";
    } else if (!isValidUsMoney(values.initial_amount)) {
      errors.initial_amount = "Invalid monetary amount";
    }
    if (!values.plan_type) {
      errors.plan_type = "Retirement plan type is required";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const {
      id,
      account_name,
      initial_amount,
      is_enabled,
      account_number,
      institution,
      reconciliation_freq,
      plan_type,
      for_transactions,
    } = this.state.finance;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {id ? "Update" : "Create"} Retirement Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              account_name,
              initial_amount,
              is_enabled,
              account_number,
              institution,
              reconciliation_freq,
              plan_type,
              for_transactions,
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Account Name</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="account_name"
                  ></Field>
                  <ErrorMessage
                    name="account_name"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Banking Institution</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="institution"
                  ></Field>
                  <ErrorMessage
                    name="institution"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Account Number</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="account_number"
                  ></Field>
                  <ErrorMessage
                    name="account_number"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Retirement Plan Type</label>
                  <Field className="form-control" as="select" name="plan_type">
                    {this.state.finance.plan_types.map((t) => (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    ))}
                  </Field>
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">
                    Statements Frequency (Months)
                  </label>
                  <Field
                    component={NumberField}
                    integerOnly={true}
                    name="reconciliation_freq"
                  ></Field>
                  <ErrorMessage
                    name="reconciliation_freq"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Investment Cash</label>
                  <Field
                    component={NumberField}
                    allowNegative={true}
                    name="initial_amount"
                  ></Field>
                  <ErrorMessage
                    name="initial_amount"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <Field
                    component={Checkbox}
                    name="is_enabled"
                    id="is_enabled"
                    label="Account is enabled"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <Field
                    component={Checkbox}
                    name="for_transactions"
                    id="for_transactions"
                    label="Use account for transactions"
                  />
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.actionSubmitText}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditFinancialAccount;
