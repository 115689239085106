import React, { Component } from "react";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";

class Interest extends Component {
  state = {
    interestEarned: 0,
    interestPaid: 0,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.interestEarned !== this.props.interestEarned ||
      nextProps.interestPaid !== this.props.interestPaid
    ) {
      this.setState({
        interestEarned: nextProps.interestEarned,
        interestPaid: nextProps.interestPaid,
      });
    }
  }

  handleChange = (evt) => {
    let amount = evt.target.value.replace("$", "");
    amount = amount.replace(/[^0-9.]/, "");
    const name = evt.target.name;
    let interestEarned = this.state.interestEarned;
    let interestPaid = this.state.interestPaid;
    if (name === "interestEarned") {
      interestEarned = amount;
    } else if (name === "interestPaid") {
      interestPaid = amount;
    }
    this.setState({
      interestEarned: interestEarned,
      interestPaid: interestPaid,
    });
  };

  submitInterest = () => {
    const interest = {
      interestEarned: this.state.interestEarned,
      interestPaid: this.state.interestPaid,
    };
    this.props._updateInterest(interest);
  };

  render() {
    const style = {
      label: {
        verticalAlign: "middle",
        textAlign: "right",
        lineHeight: "30px",
        paddingRight: 0,
      },
    };
    return (
      <div className="row">
        <div className="col-sm-3" style={style.label}>
          Earnings:
        </div>
        <div className="col-sm-3">
          <input
            name="interestEarned"
            className="form-control input-sm"
            type="text"
            value={this.state.interestEarned}
            onChange={this.handleChange}
            onBlur={this.submitInterest}
          />
        </div>
        <div className="col-sm-3" style={style.label}>
          Payments:
        </div>
        <div className="col-sm-3">
          <input
            name="interestPaid"
            className="form-control input-sm"
            type="text"
            value={this.state.interestPaid}
            onChange={this.handleChange}
            onBlur={this.submitInterest}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const reconciliation = state.reconciliation || {};
  return {
    financeId: reconciliation.financeId || 0,
    interestEarned: reconciliation.interestEarned || "0.00",
    interestPaid: reconciliation.interestPaid || "0.00",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Interest
    _updateInterest: (o) => {
      dispatch(Actions.updateReconciliationInterest(o));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interest);
