import React, { useState, useEffect } from "react";
import { doHttpGet } from "../../services/WebService";
import { accountStockDetailsUrl } from "../../library/Urls";
import Money from "../common/Money";
import { fmtNumber } from "../../library/Utilities";
import { Tabs, Tab } from "react-bootstrap";
import AccountStockDetailSummary from "./AccountStockDetailSummary";
import AccountStockDetailFinances from "./AccountStockDetailFinances";
import AccountStockDetailTransactions from "./AccountStockDetailTransactions";
import AccountStockDetailPrices from "./AccountStockDetailPrices";

const AccountStockDetail = (props) => {
  // State
  const [stockInfo, setStockInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [stockId, setStockId] = useState(props.match.params.stockId);

  // Get stock info
  const getStockInfo = (id) => {
    // Params
    const params = {
      id: id,
    };
    // GET
    doHttpGet({
      url: accountStockDetailsUrl,
      params: params,
      onSuccess: (info) => {
        setErrorMessage(null);
        setStockInfo(info);
        setStockId(info.id);
      },
      onError: (error) => setErrorMessage(error),
    });
  };

  // Changes
  useEffect(
    () => getStockInfo(props.match.params.stockId),
    [props.match.params.stockId]
  );

  // Render
  return (
    <div className="page-content">
      {errorMessage && (
        <div
          className="alert alert-danger"
          style={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "1.2em",
            padding: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}

      {stockInfo && (
        <div>
          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-8">
              <h1>{stockInfo.symbol}</h1>
              <div style={{ fontSize: "1.4em" }}>{stockInfo.name}</div>
            </div>
            <div
              className="col-4"
              style={{ textAlign: "right", verticalAlign: "bottom" }}
            >
              <Money amount={stockInfo.totalValue} className="amount-lg" />
              <h3 style={{ borderTop: "1px solid gray" }}>
                {fmtNumber(stockInfo.totalShares, 4)} shares
              </h3>
            </div>
          </div>

          <Tabs defaultActiveKey="summary" id="account-stock-details-tab">
            <Tab eventKey="summary" title="Summary">
              <AccountStockDetailSummary
                stockId={stockId}
                summary={stockInfo.summary}
              />
            </Tab>
            <Tab
              eventKey="finances"
              title={"Financial Accounts (" + stockInfo.finances.length + ")"}
            >
              <AccountStockDetailFinances
                stockId={stockId}
                finances={stockInfo.finances}
                fxRefresh={() => getStockInfo(stockId)}
              />
            </Tab>
            <Tab
              eventKey="transactions"
              title={"Transactions (" + stockInfo.transactions.length + ")"}
            >
              <AccountStockDetailTransactions
                transactions={stockInfo.transactions}
              />
            </Tab>
            <Tab
              eventKey="history"
              title={
                "Price History (" + stockInfo.prices.recentPrices.length + ")"
              }
            >
              <AccountStockDetailPrices
                info={{
                  stockId: stockId,
                  history: stockInfo.prices.recentPrices,
                  chart: stockInfo.prices.priceHistory,
                  allowOnlinePriceUpdate:
                    stockInfo.prices.allowOnlinePriceUpdate,
                  chartRangeOption: stockInfo.prices.priceHistoryOption,
                }}
                fxRefresh={() => getStockInfo(stockId)}
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default AccountStockDetail;
