import React, { Component } from "react";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUserCircle } from "@fortawesome/free-solid-svg-icons";

class ListedUser extends Component {
  select = () => {
    const userId = this.props.user.id;
    const selectedId = this.props.selectedUserId;
    if (userId !== selectedId) {
      this.props._setSelectedUser(userId);
    }
  };

  render() {
    const { user, selectedUserId } = this.props;
    return (
      <div
        className={classnames("row account-user", {
          "account-user-selected": selectedUserId === user.id
        })}
      >
        <div className="col-sm-10">
          <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: "2px" }} />
          <button className="btn btn-sm btn-link" onClick={this.select}>
            {user.full_names}
          </button>
        </div>
        <div
          className="col-sm-2"
          style={{ textAlign: "right", paddingTop: "8px" }}
        >
          {user.is_primary && <FontAwesomeIcon icon={faCheck} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedUserId: state._selections.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // Errors
    _handleError: message => {
      dispatch(Actions.setErrorMessage(message));
    },

    // Selected user
    _setSelectedUser: userId => {
      dispatch(Actions.setSelectedUserId(userId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListedUser);
