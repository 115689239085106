import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import { fmtDate } from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import AuthenticationService from "../../services/AuthenticationService";

class AccountMessage extends Component {
  render() {
    const isLoggedIn = AuthenticationService.isLoggedIn();
    const accountDeletionDt = this.props.accountDeletionDate;
    return (
      <Fragment>
        {isLoggedIn && accountDeletionDt && (
          <div
            className="alert alert-danger"
            style={{ fontWeight: "bold", fontSize: "1.2em" }}
          >
            <FontAwesomeIcon icon={faExclamationCircle} /> &nbsp; This account
            is scheduled to be deleted on {fmtDate(accountDeletionDt)}. &nbsp;
            <Link to={Links.settings}>Review your account settings</Link>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountDeletionDate: state === undefined ? null : state.accountDeletionDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountMessage));
