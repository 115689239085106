import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

class Designations extends Component {
  render() {
    // Data
    const designations = this.props.designations || [];
    let total_designations = 0.0;

    // Render
    return (
      <div>
        <table className="table table-bordered table-lg tbl-emerg-desig">
          <thead className="thead-dark">
            <tr>
              <th scope="col" colSpan="2">
                Financial Account
              </th>
              <th scope="col" className="check">
                Enabled?
              </th>
              <th scope="col" className="check">
                Emergency?
              </th>
              <th scope="col" className="amount">
                Balance
              </th>
              <th scope="col" className="amount">
                Designated
              </th>
              <th scope="col" className="date">
                Updated
              </th>
            </tr>
          </thead>
          <tbody>
            {designations.map((d) => {
              // Total
              if (d.is_enabled) {
                total_designations += d.designated_amount;
              }
              // Row
              return (
                <tr
                  key={"designation-" + d.account_id}
                  className={classnames("", {
                    "tbl-row-disabled": !d.is_enabled,
                  })}
                >
                  <td style={{ fontWeight: "bold" }}>
                    <Link to={Links.financeFor(d.account_id)}>
                      {d.account_name}
                    </Link>
                  </td>
                  <td>{d.account_description}</td>
                  <td className="check">
                    {d.is_enabled && <FontAwesomeIcon icon={faCheck} />}
                  </td>
                  <td className="check">
                    {d.is_emergency && <FontAwesomeIcon icon={faCheck} />}
                  </td>
                  <td className="amount">{fmtMoneyUSD(d.account_balance)}</td>
                  <td className="amount">{fmtMoneyUSD(d.designated_amount)}</td>
                  <td>{fmtDate(d.date_updated)}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="table-footer">
            <tr>
              <th colSpan="5" className="text-right">
                Total designations
              </th>
              <th className="text-right">{fmtMoneyUSD(total_designations)}</th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    designations: state.emergency_plan.designations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Designations);
