import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tabs, Tab } from "react-bootstrap";
import CategoryBudgets from "./CategoryBudgets";
import CategorySavingsGoals from "./CategorySavingsGoals";
import CategoryTransactions from "./CategoryTransactions";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import { categoryInfoUrl, categoryDeleteUrl } from "../../library/Urls";
import PageNotFound from "../common/PageNotFound";
import EditCategory from "./EditCategory";
import ConfirmDialog from "../common/ConfirmDialog";
import * as Links from "../../library/Links";

export default class Category extends Component {
  constructor(props) {
    super(props);
    // State
    this.state = {
      errorMessage: null,
      confirmDelete: false,
      categoryId: props.match.params.categoryId
    };
  }

  getCategoryInfo = () => {
    doHttpGet({
      url: categoryInfoUrl(this.state.categoryId),
      onSuccess: data => {
        this.setState({
          info: data,
          actionsEnabled: true,
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  componentDidMount = evt => {
    // Category ID
    if (!this.state.categoryId) {
      this.setState({
        errorMessage:
          "To retrieve category details, a valid ID must be provided in the request URL"
      });
      return;
    }
    // Category info
    this.getCategoryInfo();
  };

  showEditor = show => {
    this.setState({
      showEditor: show
    });
  };

  onEdit = () => {
    this.showEditor(false);
    this.getCategoryInfo();
  };

  confirmDelete = show => {
    this.setState({
      confirmDelete: show
    });
  };

  onConfirmDelete = () => {
    // Close confirmation dialog
    this.confirmDelete(false);
    // Delete account
    doHttpDelete({
      url: categoryDeleteUrl(this.state.categoryId),
      onSuccess: () => {
        // Go to list of categories
        this.props.history.push(Links.categories);
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  render() {
    // Info
    const { info, errorMessage } = this.state;
    if (!info) {
      if (errorMessage) {
        return <PageNotFound title="Category" message={errorMessage} />;
      }
      return <div />;
    }
    const budgets = info.budgets || [];
    const savings_goals = info.savings_goals || [];
    const transactions = info.transactions || [];

    // Render
    return (
      <div className="page-content">
        <div className="row" style={{ marginBottom: "15px" }}>
          <div className="col-5">
            <h2>
              {info.category_name}
              <span className="gzn-detail-flag">Category</span>
            </h2>
          </div>
          <div className="col-4 cat-description text-right">
            {info.description}
            <div>
              <b>Type:</b> {info.type_name}
            </div>
          </div>
          <div className="col-3 cat-actions">
            {info.is_editable && (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.showEditor(true)}
                style={{ marginRight: "10px" }}
              >
                <FontAwesomeIcon icon={faEdit} /> Edit
              </button>
            )}
            {info.is_deletable && (
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => this.confirmDelete(true)}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete
              </button>
            )}
          </div>
        </div>

        <Tabs defaultActiveKey="budgets" id="categories-tab">
          <Tab eventKey="budgets" title={"Budgets (" + budgets.length + ")"}>
            <CategoryBudgets budgets={budgets} />
          </Tab>
          <Tab
            eventKey="savings_goals"
            title={"Savings Goals (" + savings_goals.length + ")"}
          >
            <CategorySavingsGoals savings_goals={savings_goals} />
          </Tab>
          <Tab
            eventKey="transactions"
            title={"Transactions (" + transactions.length + ")"}
          >
            <CategoryTransactions transactions={transactions} />
          </Tab>
        </Tabs>

        {this.state.showEditor && (
          <EditCategory
            show={this.state.showEditor}
            onSave={this.onEdit}
            onClose={() => this.showEditor(false)}
            categoryId={this.state.categoryId}
          />
        )}

        {this.state.confirmDelete && (
          <ConfirmDialog
            show={this.state.confirmDelete}
            handleAction={this.onConfirmDelete}
            handleClose={() => this.confirmDelete(false)}
            _type="category"
          />
        )}
      </div>
    );
  }
}
