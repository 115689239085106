import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { designationReassignUrl } from "../../library/Urls";
import { isValidUsMoney, toUsMoney } from "../../library/Utilities";
import NumberField from "../common/NumberFieldAllowNegative";

class ReassignDesignation extends Component {
  constructor(props) {
    super();
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onReassign: props.onReassign,
      errorMessage: null,
      actionsEnabled: false,
      actionSubmitText: "Reassign",
      financeId: props.financeId,
      editInfo: {
        fromDesignationId: null,
        destDesignationId: null,
        reassignAmount: 0.0,
        fromDesignations: [],
        destDesignations: [],
      },
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: designationReassignUrl,
      params: {
        f: this.state.financeId,
      },
      onSuccess: (editInfo) => {
        this.setState({
          editInfo: editInfo,
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false,
    });

    // Post
    doHttpPost({
      url: designationReassignUrl,
      body: {
        ...values,
        financialAccountId: this.state.financeId,
        destDesignationId: parseInt(values.destDesignationId),
        reassignAmount: toUsMoney(values.reassignAmount),
      },
      onSuccess: () => {
        // Successfully transfered
        this.state.onReassign();
      },
      rawError: true,
      onError: (error) => {
        // Reenable action
        this.setState({
          actionsEnabled: true,
        });
        // Error message
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach((error) => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage,
              });
            } else {
              actions.setFieldError(error.field, errorMessage);
            }
          });
        } else {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  validate = (values) => {
    const errors = {};
    // Field validations
    if (!values.fromDesignationId) {
      errors.fromDesignationId = "Origination designation is required";
    }
    if (!values.destDesignationId) {
      errors.destDesignationId = "Destination designation is required";
    }
    if (values.reassignAmount == null || values.reassignAmount === "") {
      errors.reassignAmount = "Reassign amount is required";
    } else if (!isValidUsMoney(values.reassignAmount)) {
      errors.reassignAmount = "Invalid monetary amount";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const {
      fromDesignationId,
      destDesignationId,
      reassignAmount,
      fromDesignations,
      destDesignations,
    } = this.state.editInfo;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Reassign Designation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              fromDesignationId,
              destDesignationId,
              reassignAmount,
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Transfer From</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="fromDesignationId"
                  >
                    {fromDesignations.map((designation) => (
                      <option key={designation.id} value={designation.id}>
                        {designation.description}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fromDesignationId"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Reassign To</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="destDesignationId"
                  >
                    {destDesignations.map((designation) => (
                      <option key={designation.id} value={designation.id}>
                        {designation.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="destDesignationId"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Amount To Reassign</label>
                  <Field component={NumberField} name="reassignAmount"></Field>
                  <ErrorMessage
                    name="reassignAmount"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.actionSubmitText}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ReassignDesignation;
