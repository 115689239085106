import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpPost } from "../../services/WebService";
import { adminEmailSendUrl } from "../../library/Urls";
import classnames from "classnames";

export default class EmailSettings extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      emailAddress: "",
      emailMessage: "",
      displayStatus: false,
      sendSuccess: false,
      statusMessage: null,
    };
  }

  sendEmail = (values) => {
    // Email
    const emailInfo = {
      emailAddress: values.emailAddress,
      emailMessage: values.emailMessage,
    };

    // Post
    doHttpPost({
      url: adminEmailSendUrl,
      body: emailInfo,
      onSuccess: () => {
        this.setState({
          ...emailInfo,
          sendSuccess: true,
          displayStatus: true,
          statusMessage: "Successfully sent email message",
        });
      },
      onError: (error) => {
        this.setState({
          sendSuccess: false,
          displayStatus: true,
          statusMessage: error,
        });
      },
    });
  };

  render() {
    // Settings
    const {
      emailAddress,
      emailMessage,
      sendSuccess,
      statusMessage,
      displayStatus,
    } = this.state;
    // Render
    return (
      <div>
        {displayStatus && (
          <div
            className={classnames("alert", {
              "alert-warning": !sendSuccess,
              "alert-success": sendSuccess,
            })}
            style={{
              marginBottom: "10px",
            }}
          >
            {statusMessage}
          </div>
        )}
        <div className="row">
          <div className="col-5">
            <Formik
              initialValues={{
                emailAddress,
                emailMessage,
              }}
              onSubmit={this.sendEmail}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
            >
              {(props) => (
                <Form>
                  <div className="card">
                    <div className="card-header">Send Email</div>
                    <div className="card-body">
                      <fieldset className="form-group required">
                        <label className="control-label">Email Address</label>
                        <Field
                          type="text"
                          name="emailAddress"
                          className="form-control"
                        ></Field>
                        <ErrorMessage
                          name="emailAddress"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Message Body</label>
                        <Field
                          className="form-control"
                          as="textarea"
                          name="emailMessage"
                        ></Field>
                        <ErrorMessage
                          name="emailMessage"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{ marginTop: "20px" }}
                  >
                    Send Email Message
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
