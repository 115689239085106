import React, { Component } from "react";
import TransactionsPanel from "./TransactionsPanel";

export default class Transactions extends Component {
  state = {
    errorMessage: null,
    selectedId: null,
    confirmDelete: false,
  };

  render() {
    // Render
    return (
      <div className="page-content">
        {this.state.errorMessage && (
          <div className="alert alert-danger error-message">
            {this.state.errorMessage}
          </div>
        )}
        <h2>Transactions</h2>
        <TransactionsPanel pageSize={75} isForTransactions={true} />
      </div>
    );
  }
}
