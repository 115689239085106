import React, { Component } from "react";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import { giftCardListUrl, giftCardUrl } from "../../library/Urls";
import { fmtDate, fmtMoneyUSD, isDefined } from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import EditGiftCard from "./EditGiftCard";
import Money from "../common/Money";
import ConfirmDialog from "../common/ConfirmDialog";
import NotificationSystem from "react-notification-system";

class GiftCards extends Component {
  notificationSystem = React.createRef();

  // Constructor
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      selectedId: null,
      confirmDelete: false,
      giftCards: null,
      includeDisabled: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.includeDisabled !== this.props.includeDisabled) {
      this.setState({
        includeDisabled: nextProps.includeDisabled,
      });
    }
  }

  getGiftCards = (includeDisabled) => {
    // Include disabled
    if (!isDefined(includeDisabled)) {
      includeDisabled = this.props.includeDisabled;
    }
    // List of gift cards
    doHttpGet({
      url: giftCardListUrl,
      params: {
        disabled: includeDisabled,
      },
      onSuccess: (_giftCards) => {
        this.setState({
          giftCards: _giftCards,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  includeDisabled = (evt) => {
    // Whether selected
    const checked = evt.target.checked;
    this.props._includeDisabled(checked);
    // Update info
    this.getGiftCards(checked);
  };

  componentDidMount = (evt) => {
    this.getGiftCards();
  };

  showEditor = (id, show) => {
    this.setState({
      showEditor: show,
      selectedId: id,
    });
  };

  closeEditor = () => {
    this.setState({
      showEditor: false,
    });
  };

  giftCardSaved = (id) => {
    this.showEditor(id, false);
    this.getGiftCards(this.props.includeDisabled);
  };

  confirmDelete = (id, brand, number) => {
    const name = brand + (number ? " (" + number + ")" : "");
    this.setState({
      showDeleteConfirm: true,
      selectedId: id,
      giftCardName: name,
    });
  };

  closeDeleteConfirm = () => {
    this.setState({
      showDeleteConfirm: false,
    });
  };

  deleteGiftCard = () => {
    doHttpDelete({
      url: giftCardUrl,
      params: {
        id: this.state.selectedId,
      },
      onSuccess: (response) => {
        // Close confirmation
        this.closeDeleteConfirm();
        // Refresh
        this.getGiftCards();
        // Notification
        this.deleteNotification(response.success);
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  deleteNotification = (success) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title: "Gift Card Deleted",
      message:
        "Gift card '" +
        this.state.giftCardName +
        "' was " +
        (success ? "" : "not ") +
        "deleted successfully",
      level: success ? "success" : "error",
      position: "br",
      autoDismiss: 5,
    });
  };

  render() {
    // State
    const { giftCards, errorMessage, includeDisabled } = this.state;
    if (!giftCards) {
      return null;
    }
    // Total balance
    let balance = 0.0;
    // Render
    return (
      <div className="page-content">
        {errorMessage && (
          <div className="alert alert-danger" style={{ marginBottom: "10px" }}>
            {errorMessage}
          </div>
        )}

        <div className="row">
          <div className="col-8">
            <h2>Gift Cards ({giftCards.length})</h2>
          </div>
          <div
            className="col-4"
            style={{ textAlign: "right", verticalAlign: "bottom" }}
          >
            <button
              className="btn btn-success"
              onClick={() => this.showEditor(null, true)}
            >
              <FontAwesomeIcon icon={faPlus} /> &nbsp; Add Gift Card
            </button>
          </div>
        </div>

        <div className="form-check" style={{ marginBottom: "10px" }}>
          <input
            className="form-check-input"
            name="includeDisabledCbx"
            type="checkbox"
            onChange={this.includeDisabled}
          />
          <label className="form-check-label" htmlFor="includeDisabledCbx">
            Include disabled gift cards
          </label>
        </div>

        <table className="table table-bordered table-lg table-fin-accts">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Brand/Name</th>
              <th scope="col">Card Number</th>
              <th scope="col">Balance</th>
              <th scope="col">Where Usable</th>
              <th scope="col">Expiration</th>
              {includeDisabled && <th scope="col">Enabled</th>}
              <th scope="col" style={{ width: "110px" }}>
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {giftCards.map((gc) => {
              // Balance
              balance += gc.balance;
              // Row
              return (
                <tr
                  key={"gc-" + gc.id}
                  className={classnames("", {
                    "tbl-row-disabled": !gc.enabled,
                    focus: gc.id === this.state.selectedId,
                  })}
                >
                  <th scope="row">
                    <Link to={Links.giftCardForId(gc.id)}>{gc.brand}</Link>
                  </th>
                  <td>{gc.cardNumber}</td>
                  <td className="text-right">
                    <Money amount={gc.balance} />
                  </td>
                  <td>{gc.whereUsable}</td>
                  <td>{fmtDate(gc.expirationDate)}</td>
                  {includeDisabled && <td>{gc.enabled ? "Yes" : "No"}</td>}
                  <td className="text-right">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.showEditor(gc.id, true)}
                    >
                      <FontAwesomeIcon icon={faEdit} /> Edit
                    </button>
                    {gc.deletable && (
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        style={{ marginLeft: "8px" }}
                        onClick={() =>
                          this.confirmDelete(gc.id, gc.brand, gc.cardNumber)
                        }
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="table-footer">
            <tr>
              <th colSpan={2} className="text-right">
                Total Balance
              </th>
              <th className="text-right">{fmtMoneyUSD(balance)}</th>
              <th colSpan={includeDisabled ? 4 : 3}></th>
            </tr>
          </tfoot>
        </table>

        {this.state.showEditor && (
          <EditGiftCard
            show={this.state.showEditor}
            onSave={this.giftCardSaved}
            onClose={this.closeEditor}
            giftCardId={this.state.selectedId}
          />
        )}

        {this.state.showDeleteConfirm && (
          <ConfirmDialog
            show={this.state.showDeleteConfirm}
            handleAction={this.deleteGiftCard}
            handleClose={this.closeDeleteConfirm}
            _type="giftCard"
          />
        )}

        <NotificationSystem ref={this.notificationSystem} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const giftcards = state.giftcards || {};
  return {
    includeDisabled: giftcards.includeDisabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Whether to include disabled gift cards
    _includeDisabled: (include) => {
      dispatch(Actions.setIncludeDisabledGiftCards(include));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftCards);
