import React, { useState, useEffect } from "react";
import { doHttpGet } from "../../services/WebService";
import { stockInfoUrl } from "../../library/Urls";
import Money from "../common/Money";
import { fmtMoneyUSD, fmtDate, fmtNumber } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

const AccountStocks = (props) => {
  // State
  const [stockInfo, setStockInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Get stock info
  const getStockInfo = (p) => {
    // Params
    const params = {};
    if (p) {
      params.includeDisabledAccounts = p.withDisabledAccounts;
    }
    // GET
    doHttpGet({
      url: stockInfoUrl,
      params: params,
      onSuccess: (info) => {
        setErrorMessage(null);
        setStockInfo(info);
      },
      onError: (error) => setErrorMessage(error),
    });
  };

  // Changes
  useEffect(
    () =>
      getStockInfo({
        withDisabledAccounts: false,
      }),
    []
  );

  // Render
  return (
    <div className="page-content">
      {errorMessage && (
        <div
          className="alert alert-danger"
          style={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "1.2em",
            padding: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}

      {stockInfo && (
        <div>
          {!stockInfo.featureExists && (
            <div className="alert alert-danger" style={{ marginBottom: "5px" }}>
              The <b>Stock</b> feature has not been added to this account
            </div>
          )}
          {!stockInfo.featureEnabled && (
            <div className="alert alert-danger" style={{ marginBottom: "5px" }}>
              The <b>Stock</b> feature is not enabled for this account
            </div>
          )}
          <div className="row">
            <div className="col-8">
              <h2>
                Stock Portfolio
                <span className="gzn-detail-flag">Feature/Option</span>
              </h2>
            </div>
            <div
              className="col-4"
              style={{ textAlign: "right", verticalAlign: "bottom" }}
            >
              <Money amount={stockInfo.totalValue} className="amount-lg" />
            </div>
          </div>

          <table className="table table-bordered table-lg table-fin-accts">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Stock</th>
                <th scope="col">Price</th>
                <th scope="col">Shares</th>
                <th scope="col">Value</th>
                <th scope="col">Financial Accounts</th>
              </tr>
            </thead>
            <tbody>
              {stockInfo.stockListings.map((xs) => (
                <tr key={"xs-" + xs.id}>
                  <td>
                    <div style={{ marginBottom: "3px", fontWeight: "bold" }}>
                      <Link to={Links.accountStockFor(xs.id)}>{xs.symbol}</Link>
                    </div>
                    <div>{xs.name}</div>
                  </td>
                  <td className="text-right">
                    <div>{fmtMoneyUSD(xs.price)}</div>
                    <div
                      style={{
                        marginTop: "3px",
                        color: "gray",
                        fontStyle: "italic",
                      }}
                    >
                      {fmtDate(xs.priceDate)}
                    </div>
                  </td>
                  <td className="text-right">{fmtNumber(xs.shares, 4)}</td>
                  <td className="text-right">
                    <Money amount={xs.stockValue} />
                  </td>
                  <td>
                    {xs.financialAccounts &&
                      xs.financialAccounts.length > 0 && (
                        <table
                          className="table table-sm table-borderless"
                          style={{ marginBottom: "0" }}
                        >
                          <tbody>
                            {xs.financialAccounts.map((fa) => (
                              <tr key={"fa-" + fa.id}>
                                <td>
                                  <Link to={Links.financeFor(fa.id)}>
                                    {fa.name} ({fa.description})
                                  </Link>
                                </td>
                                <td
                                  className="text-right"
                                  style={{ width: "75px", fontWeight: "bold" }}
                                >
                                  {fmtNumber(fa.shares, 4)}
                                </td>
                                <td
                                  className="text-right"
                                  style={{ width: "75px" }}
                                >
                                  <Money amount={fa.stockValue} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AccountStocks;
