import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useState, useEffect, Fragment} from "react";
import {fmtDate, fmtMoneyUSD} from "../../library/Utilities";
import Categorization from "../transactions/Categorization";
import Financing from "../transactions/Financing";
import {doHttpDelete, doHttpGet} from "../../services/WebService";
import {healthcareTransactionsUrl, transactionDeleteUrl} from "../../library/Urls";
import EditTransaction from "../transactions/EditTransaction";
import classnames from "classnames";
import TransactionDescription from "../transactions/TransactionDescription";
import ConfirmDialog from "../common/ConfirmDialog";

const HealthcareTransactions = ({
    monthOldestTransactions,
    canCreateTransactions,
    setTransactionCount,
    financialAccountId,
    refreshHealthcareInfo,
    showErrorMessage,
}) => {
    const [includeOlderTransactions, setIncludeOlderTransactions] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [_batchNumber, setBatchNumber] = useState(null);
    const [_showTransactionEditor, setShowTransactionEditor] = useState(false);
    const [_isDeposit, setDeposit] = useState(false);
    const [_showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    /**
     * Fetches healthcare-related transactions from a specified URL and updates internal states.
     *
     * This function makes an HTTP GET request to a predefined URL to retrieve healthcare transactions.
     * The function provides an option to include older transactions or not. On a successful response,
     * it sets the transactions data, counts the transactions, and refreshes the healthcare information.
     * In case of an error, an error message is displayed.
     *
     * @param {boolean} includeOlderTransactions - A flag to indicate whether to include older transactions
     *        in the fetch. If `true`, the request will include older transactions in the results.
     */
    const getHealthcareTransactions = (includeOlderTransactions) => {
        doHttpGet({
            url: healthcareTransactionsUrl,
            params: {
                older: includeOlderTransactions,
            },
            onSuccess: (data) => {
                setTransactions(data);
                setTransactionCount(data.length);
                refreshHealthcareInfo();
            },
            onError: (error) => {
                showErrorMessage(error)
            },
        });
    };

    /**
     * What should run once upon component load
     */
    useEffect(() => {
        getHealthcareTransactions(false);
    }, []);

    /**
     * Toggles the visibility of older healthcare transactions.
     *
     * This function manages the state of whether older transactions
     * should be included in the transaction display. It updates the
     * state appropriately and retrieves the necessary transactions
     * based on the updated state.
     *
     * @param {boolean} show - A boolean value indicating whether to
     * include older transactions. If true, older transactions will be
     * included; otherwise, they will be excluded.
     *
     * @returns {void} This function does not return a value. It performs
     * side-effects by updating the state and fetching transactions.
     */
    const setShowOlderTransactions = (show) => {
        Promise.resolve(setIncludeOlderTransactions(show))
            .then(() => getHealthcareTransactions(show));
    };

    /**
     * Toggles the visibility of the transaction editor and sets contextual data.
     *
     * This function is used to control the visibility of a transaction editor interface.
     * It sets the batch number, determines whether the editor is intended for a deposit
     * transaction, and shows or hides the editor based on the `show` parameter.
     *
     * @param {boolean} show - Determines whether to display the transaction editor.
     *                         Set to true to show the editor, or false to hide it.
     * @param {string|number} batchNumber - The identifier for the batch associated with the transaction.
     *                                      It can either be a string or a number.
     * @param {boolean} isDeposit - Indicates if the transaction is a deposit. Set to true for deposit
     *                              transactions, or false for other transaction types.
     */
    const showTransactionEditor = (show, batchNumber, isDeposit) => {
        setBatchNumber(batchNumber);
        setShowTransactionEditor(show);
        setDeposit(isDeposit);
    };

    /**
     * A callback function that is triggered to edit a transaction.
     * This function performs several actions including updating the current batch number,
     * hiding the transaction editor UI component, and fetching the latest healthcare transactions.
     *
     * @param {number} batchNumber - The new batch number to be set, replacing the current one.
     */
    const onEditTransaction = (batchNumber) => {
        setBatchNumber(batchNumber);
        setShowTransactionEditor(false);
        getHealthcareTransactions(includeOlderTransactions);
    };

    /**
     * Handles the confirmation dialog state for transaction deletion.
     *
     * This function updates the state to either show or hide
     * the confirmation dialog for deleting a transaction, based on the
     * provided parameters. It manages the specific transaction `batchNumber`
     * that is being considered for deletion.
     *
     * @param {boolean} show - A boolean value indicating whether to display
     *                         the delete confirmation dialog.
     *                         If `true`, the dialog will be shown.
     *                         If `false`, the dialog will be hidden.
     *
     * @param {number} batchNumber - The batch number of the transaction that
     *                               is subject to deletion. This value helps
     *                               identify and set the specific transaction
     *                               within the application that the delete
     *                               confirmation pertains to.
     */
    const confirmTransactionDeletion = (show, batchNumber) => {
        setBatchNumber(batchNumber);
        setShowDeleteConfirm(show);
    };

    /**
     * Deletes a transaction identified by the given batch number.
     *
     * This function executes an HTTP DELETE request to remove a specified
     * transaction from the server by its batch number. Upon successful deletion,
     * it closes the transaction confirmation dialog and refreshes the list of
     * healthcare transactions. If an error occurs during the deletion process,
     * an error message is displayed.
     *
     * @param {string} batchNumber - The unique identifier for the transaction
     *                               batch to be deleted.
     */
    const deleteTransaction = (batchNumber) => {
        setBatchNumber(batchNumber);
        // Delete account
        doHttpDelete({
            url: transactionDeleteUrl,
            params: {
                bn: _batchNumber,
            },
            onSuccess: () => {
                // Close confirmation
                confirmTransactionDeletion(false, batchNumber);
                // Refresh
                getHealthcareTransactions(includeOlderTransactions);
            },
            onError: (error) => {
                showErrorMessage(error);
            },
        });
    };

    /**
     * Render content
     */
    return (
        <Fragment>
            <div className="row">
                <div
                    className="col-12"
                    style={{
                        textAlign: "right",
                        verticalAlign: "bottom",
                        marginBottom: 10,
                    }}
                >
                    <button
                        className="btn btn-danger"
                        style={{marginRight: "5px"}}
                        onClick={() => showTransactionEditor(true, null, false)}
                        disabled={!canCreateTransactions}
                    >
                        <FontAwesomeIcon icon={faPlus}/> &nbsp; Expense
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => showTransactionEditor(true, null, true)}
                        disabled={!canCreateTransactions}
                    >
                        <FontAwesomeIcon icon={faPlus}/> &nbsp; Deposit
                    </button>
                </div>
            </div>

            <div className="form-check" style={{marginBottom: "10px"}}>
                <input
                    className="form-check-input"
                    name="showOlderTransactionsCbx"
                    type="checkbox"
                    onChange={(evt) => setShowOlderTransactions(evt.target.checked)}
                    defaultChecked={includeOlderTransactions}
                />
                <label className="form-check-label" htmlFor="showOlderTransactionsCbx">
                    Show transactions older than {monthOldestTransactions}
                </label>
            </div>

            <table className="table table-md table-striped table-bordered table-transactions">
                <thead className="thead-dark">
                <tr>
                    <th scope="col" style={{width: "150px", maxWidth: "150px"}}>Date/Amount</th>
                    <th scope="col">Description</th>
                    <th scope="col">Financing</th>
                    <th scope="col">Categorization</th>
                    <th scope="col" style={{width: "50px", maxWidth: "50px"}}>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {(transactions || []).map((t) => {
                    // To/from
                    let toFromText = "TO:";
                    if (t.type === "DEPOSIT") toFromText = "FROM:";

                    // Render
                    return (
                        <tr key={"tx-" + t.id}
                            className={classnames("", {
                                "tx-selected": t.batchNumber === _batchNumber,
                            })}
                        >
                            <td className="col-date-amount">
                                <span style={{fontSize: "0.9em"}}>{fmtDate(t.date)}</span>
                                <br/>
                                <button
                                    type="button"
                                    disabled={!t.editable}
                                    style={{padding: 0}}
                                    className={classnames("btn btn-link amount", {
                                        "deposit-amount": t.type === 'DEPOSIT',
                                        "expense-amount": t.type === 'EXPENSE',
                                    })}
                                    onClick={() => showTransactionEditor(true, t.batchNumber, t.type === 'DEPOSIT')}
                                >
                                    {t.reconciled && (
                                        <span style={{marginRight: "5px"}}>
                                            <FontAwesomeIcon icon={faCheckCircle}/>
                                        </span>
                                    )}
                                    {fmtMoneyUSD(t.amount)}
                                </button>
                            </td>
                            <td className="col-description" style={{maxWidth: "500px"}}>
                                <TransactionDescription transaction={t}/>
                            </td>
                            <td><Financing finance={t.financing}/></td>
                            <td><Categorization categorization={t.categorization}/></td>
                            <td className="text-center">
                                {t.deletable && (
                                    <button
                                        className="btn btn-md btn-danger"
                                        onClick={() => confirmTransactionDeletion(true, t.batchNumber)}
                                    >
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </button>
                                )}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>

            {_showTransactionEditor && (
                <EditTransaction
                    show={_showTransactionEditor}
                    onClose={() => showTransactionEditor(false, null)}
                    onSave={onEditTransaction}
                    batchNumber={_batchNumber}
                    isDeposit={_isDeposit}
                    financeId={financialAccountId}
                />
            )}

            {_showDeleteConfirm && (
                <ConfirmDialog
                    show={_showDeleteConfirm}
                    handleAction={deleteTransaction}
                    handleClose={() => confirmTransactionDeletion(false, _batchNumber)}
                    _type="transaction"
                />
            )}
        </Fragment>
    );
};

export default HealthcareTransactions;