import React, { Component } from "react";
import { fmtDate, fmtMoneyUSD, fmtNumber } from "../../library/Utilities";
import MoneyArrow from "../common/MoneyArrow";
import classnames from "classnames";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

export default class FinanceStocks extends Component {
  render() {
    return (
      <table className="table table-bordered table-striped table-lg stocks">
        <thead className="thead-dark">
          <tr>
            <th scope="col" className="symbol">
              Symbol
            </th>
            <th scope="col">Name</th>
            <th scope="col" className="amount">
              Price
            </th>
            <th scope="col">Date</th>
            <th scope="col" className="amount">
              Shares
            </th>
            <th scope="col" className="amount">
              Cost
            </th>
            <th scope="col" className="amount">
              Equity
            </th>
            <th scope="col" className="amount">
              Dividends
            </th>
            <th scope="col" className="amount">
              Change
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.stocks.map((s) => (
            <tr
              key={"stock-" + s.id}
              className={classnames("", {
                disabled: s.shares === 0,
              })}
            >
              <td className="symbol">
                <Link to={Links.accountStockFor(s.id)}>{s.symbol}</Link>
              </td>
              <td>{s.name}</td>
              <td className="amount">{fmtNumber(s.price, 2)}</td>
              <td>{fmtDate(s.date)}</td>
              <td className="amount">{s.shares}</td>
              <td className="amount">{fmtNumber(s.cost, 2)}</td>
              <td className="amount">{fmtNumber(s.equity, 2)}</td>
              <td className="amount">{fmtNumber(s.dividends, 2)}</td>
              <td className="amount">
                <MoneyArrow amount={s.change} showSymbol={false} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="table-footer">
          <tr>
            <th colSpan="5"></th>
            <th className="text-right">{fmtMoneyUSD(this.props.totalCost)}</th>
            <th className="text-right">
              {fmtMoneyUSD(this.props.totalEquity)}
            </th>
            <th className="text-right">
              {fmtMoneyUSD(this.props.totalDividends)}
            </th>
            <th className="text-right">
              <MoneyArrow amount={this.props.totalChange} />
            </th>
          </tr>
        </tfoot>
      </table>
    );
  }
}
