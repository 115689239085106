import React from "react";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

export default function CategoryTransactions(props) {
  // Transactions
  const transactions = props.transactions || [];

  // Render
  return (
    <div>
      <table className="table table-bordered table-striped table-lg gzn-table">
        <thead className="thead-dark">
          <tr>
            <th scope="col" className="gzn-col-date">
              Date
            </th>
            <th scope="col" className="gzn-col-amount">
              Amount
            </th>
            <th scope="col">Type</th>
            <th scope="col">Party</th>
            <th scope="col">Description</th>
            <th scope="col">Financing</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map(t => (
            <tr key={"t-" + t.id}>
              <td className="gzn-col-date">{fmtDate(t.date)}</td>
              <td className="gzn-col-amount">{fmtMoneyUSD(t.amount)}</td>
              <td>{t.type}</td>
              <td>{t.party}</td>
              <td>{t.description}</td>
              <td>
                <Link to={Links.financeFor(t.finance_id)}>
                  {t.finance_name}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
