import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { designationTransferUrl } from "../../library/Urls";
import { isValidUsMoney, toUsMoney } from "../../library/Utilities";
import NumberField from "../common/NumberFieldAllowNegative";
import Money from "../common/Money";

class TransferDesignation extends Component {
  constructor(props) {
    super();
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onTransfer: props.onTransfer,
      errorMessage: null,
      actionsEnabled: false,
      actionSubmitText: "Transfer",
      designationId: props.designationId,
      financeId: props.financeId,
      editInfo: {
        financialAccounts: [],
      },
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: designationTransferUrl,
      params: {
        f: this.state.financeId,
        d: this.state.designationId,
      },
      onSuccess: (editInfo) => {
        this.setState({
          editInfo: editInfo,
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false,
    });

    // Post
    doHttpPost({
      url: designationTransferUrl,
      body: {
        ...values,
        origFinanceId: parseInt(this.state.financeId),
        destFinanceId: parseInt(values.destFinanceId),
        designationId: parseInt(this.state.designationId),
        transferAmount: toUsMoney(values.transferAmount),
      },
      onSuccess: () => {
        // Successfully transfered
        this.state.onTransfer();
      },
      rawError: true,
      onError: (error) => {
        // Reenable action
        this.setState({
          actionsEnabled: true,
        });
        // Error message
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach((error) => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage,
              });
            } else {
              actions.setFieldError(error.field, errorMessage);
            }
          });
        } else {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  validate = (values) => {
    const errors = {};
    // Field validations
    if (!values.destFinanceId) {
      errors.destFinanceId = "Destination account is required";
    }
    if (values.transferAmount == null || values.transferAmount === "") {
      errors.transferAmount = "Transfer amount is required";
    } else if (!isValidUsMoney(values.transferAmount)) {
      errors.transferAmount = "Invalid monetary amount";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const {
      designationName,
      designationAmount,
      origFinanceName,
      financialAccounts,
      destFinanceId,
      transferAmount,
    } = this.state.editInfo;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Transfer Designation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              destFinanceId,
              transferAmount,
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <table
                  className="table table-sm"
                  style={{ marginBottom: 10, border: 0, borderSpacing: 0 }}
                >
                  <tbody>
                    <tr>
                      <td className="label">Designation:</td>
                      <td>{designationName}</td>
                    </tr>
                    <tr>
                      <td className="label">Amount:</td>
                      <td>
                        <Money amount={designationAmount} />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">From Account:</td>
                      <td>{origFinanceName}</td>
                    </tr>
                  </tbody>
                </table>
                <fieldset className="form-group required">
                  <label className="control-label">To Account</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="destFinanceId"
                  >
                    {financialAccounts.map((finance) => (
                      <option key={finance.id} value={finance.id}>
                        {finance.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="destFinanceId"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Transfer Amount</label>
                  <Field component={NumberField} name="transferAmount"></Field>
                  <ErrorMessage
                    name="transferAmount"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.actionSubmitText}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default TransferDesignation;
