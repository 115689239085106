import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import classnames from "classnames";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import EditCollegeAccount from "./EditCollegeAccount";
import ConfirmDialog from "../common/ConfirmDialog";
import NotificationSystem from "react-notification-system";
import { financeDeleteUrl } from "../../library/Urls";
import { doHttpDelete } from "../../services/WebService";

const CollegeAccounts = ({
  collegeAccounts,
  totalCollegeSavings,
  inclDisabledAccts,
  fxRefreshCollegeInfo,
  fxShowErrorMessage,
}) => {
  // State
  const [totalBalance, setTotalBalance] = useState(0.0);
  const [showAccountEditor, setShowAccountEditor] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [financeId, setFinanceId] = useState(null);
  const [financeName, setFinanceName] = useState(null);
  const [notificationSystem, setNotificationSystem] = useState(null);
  const [includeDisabled, setIncludeDisabled] = useState(inclDisabledAccts);

  // Changes
  useEffect(() => setTotalBalance(totalCollegeSavings), [totalCollegeSavings]);

  // Notification system
  useEffect(() => setNotificationSystem(React.createRef()), []);

  // Delete confirmation
  const confirmDelete = (id, name) => {
    setFinanceId(id);
    setFinanceName(name);
    setShowDeleteConfirmation(true);
  };

  // Delete financial account
  const deleteFinancialAccount = (id) => {
    setShowDeleteConfirmation(false);
    // Delete account
    doHttpDelete({
      url: financeDeleteUrl(id),
      onSuccess: () => {
        // Update view
        fxRefreshCollegeInfo({
          withDisabledAccounts: includeDisabled,
        });
        // Notification
        notificationSystem.current.addNotification({
          title: "College Account Deleted",
          message:
            "College account '" + financeName + "' was successfully deleted",
          level: "success",
          position: "br",
          autoDismiss: 5,
        });
      },
      onError: (error) => {
        fxShowErrorMessage(error);
      },
    });
  };

  // Edit financial account
  const editFinancialAccount = (id) => {
    setFinanceId(id);
    setShowAccountEditor(true);
  };

  // After financial account update
  const onUpdateFinancialAccount = (id) => {
    setFinanceId(id);
    setShowAccountEditor(false);
    fxRefreshCollegeInfo({
      withDisabledAccounts: includeDisabled,
    });
  };

  // Including disabled accounts
  const includeDisabledAccounts = (yes) => {
    setIncludeDisabled(yes);
    fxRefreshCollegeInfo({
      withDisabledAccounts: yes,
    });
  };

  // Render
  return (
    <Fragment>
      <div className="row">
        <div
          className="col-12"
          style={{
            textAlign: "right",
            verticalAlign: "bottom",
            marginBottom: 10,
          }}
        >
          <button
            className="btn btn-success"
            onClick={() => editFinancialAccount(null)}
          >
            <FontAwesomeIcon icon={faPlus} /> &nbsp; Add College Account
          </button>
        </div>
      </div>

      <div className="form-check" style={{ marginBottom: "10px" }}>
        <input
          className="form-check-input"
          name="includeDisabledCbx"
          type="checkbox"
          onChange={(evt) => includeDisabledAccounts(evt.target.checked)}
          defaultChecked={includeDisabled}
        />
        <label className="form-check-label" htmlFor="includeDisabledCbx">
          Include disabled accounts
        </label>
      </div>

      <table className="table table-bordered table-lg table-fin-accts">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">Reconciled</th>
            <th scope="col">Balance</th>
            <th scope="col" style={{ width: "110px" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {collegeAccounts.map((fa) => (
            <tr
              key={"fa-" + fa.id}
              className={classnames("", {
                "fin-emergency": fa.is_emergency,
                "fin-negative-bal": fa.balance < 0,
                "fin-disabled": !fa.is_enabled,
                focus: fa.id === financeId,
              })}
            >
              <th scope="row">
                <Link to={Links.financeAccount + `/${fa.id}`}>{fa.name}</Link>
              </th>
              <td>{fa.description}</td>
              <td>{fmtDate(fa.date_reconciled)}</td>
              <td className="text-right">{fmtMoneyUSD(fa.balance)}</td>
              <td className="text-right">
                {fa.is_editable && (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => editFinancialAccount(fa.id)}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </button>
                )}{" "}
                &nbsp;{" "}
                {fa.is_deletable && (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => confirmDelete(fa.id, fa.name)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="table-footer">
          <tr>
            <th colSpan="3" className="text-right">
              Total Balance
            </th>
            <th className="text-right">{fmtMoneyUSD(totalBalance)}</th>
            <th></th>
          </tr>
        </tfoot>
      </table>

      {showAccountEditor && (
        <EditCollegeAccount
          show={showAccountEditor}
          handleSave={onUpdateFinancialAccount}
          handleClose={() => setShowAccountEditor(false)}
          financeId={financeId}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmDialog
          show={showDeleteConfirmation}
          handleAction={() => deleteFinancialAccount(financeId)}
          handleClose={() => setShowDeleteConfirmation(false)}
          title="Delete Account?"
          message="The college account will be deleted immediately, and will not be available for future use."
          question="Are you sure about deleting this college account?"
          btnAction="Yes, Delete"
        />
      )}

      <NotificationSystem ref={notificationSystem} />
    </Fragment>
  );
};

export default CollegeAccounts;
