import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Tabs, Tab } from "react-bootstrap";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import {
  transferEditUrl,
  transferSaveUrl,
  transactionFinanceDesignationsUrl,
} from "../../library/Urls";
import DateSelector from "../common/DateSelector";
import Money from "../common/Money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  isValidUsMoney,
  toUsMoney,
  UUID,
  removeIndexWithAttr,
  fmtMoneyUSD,
} from "../../library/Utilities";
import Checkbox from "../common/Checkbox";
import NumberField from "../common/NumberField";

const TAB_DESIGNATIONS = "designations";
const TAB_EDITOR = "editor";

const DEFAULT_SPLIT = {
  id: null,
  amount: "",
  finance: 0,
  categorization: 0,
  budgetMonth: new Date().getMonth() + 1,
  budgetYear: new Date().getFullYear(),
  description: "",
  party: "",
  reference: "",
};

export default class EditTransfer extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      errorMessage: null,
      actionsEnabled: false,
      batchNumber: props.batchNumber,
      financeId: props.financeId,
      // Edit info
      editInfo: null,
      enableDesignations: true,
      // Split info for editor form
      selectedSplit: DEFAULT_SPLIT,
      // Splits
      splits: [],
      // Tabs
      selectedTab: TAB_EDITOR,
    };
  }

  componentDidMount = (evt) => {
    // Default financial account
    let _financeId = null;
    if (this.state.financeId) {
      _financeId = "FINANCE:" + this.state.financeId;
    }
    let _loadDesignations = _financeId && this.state.batchNumber;
    // Finance selection
    const selectFinance = (financeId, collection) => {
      if (financeId) return financeId;
      if (_financeId) return _financeId;
      return collection[0];
    };
    // Initial data
    doHttpGet({
      url: transferEditUrl,
      params: {
        bn: this.state.batchNumber,
        financeId: this.state.financeId,
      },
      onSuccess: (data) => {
        // Selected financial account
        if (this.state.batchNumber) {
          _financeId = data.financeFromId;
        }
        // Designations
        const isCreditPayment = data.creditPayment;
        _loadDesignations = !isCreditPayment;
        // State
        const designations = data.designations;
        delete data.designations;
        this.setState({
          editInfo: {
            ...data,
            date: data.date ? new Date(data.date) : null,
            amount: data.amount.toFixed(2),
            financeFromId: selectFinance(
              data.financeFromId,
              data.financesFromIds
            ),
            financeToId: selectFinance(data.financeToId, data.financesToIds),
            reference: data.reference || "",
            description: data.description || "",
          },
          selectedFinanceId: _financeId,
          designations: designations,
          errorMessage: null,
          enableDesignations: !isCreditPayment,
        });
        // Load designations
        if (_loadDesignations) {
          this.loadDesignations(designations);
        }
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  validateTransfer = (values) => {
    // Errors
    const errors = {};
    // Party
    if (!values.date) {
      errors.date = "Transfer date is required";
    }
    // Amount
    if (!values.amount) {
      errors.amount = "Amount is required";
    } else {
      if (!isValidUsMoney(values.amount)) {
        errors.amount = "Invalid monetary amount";
      } else {
        const _amount = toUsMoney(values.amount);
        if (_amount <= 0.0) {
          errors.amount = "Amount must be more than 0.0";
        }
      }
    }
    // Finance
    let selectedFinanceId = null;
    if (!values.financeFromId) {
      errors.financeFromId = "Select origination financial account";
    } else {
      selectedFinanceId = values.financeFromId;
    }
    if (!values.financeToId) {
      errors.financeToId = "Select destination financial account";
    }
    // Description
    if (!values.description) {
      errors.description = "Description is required";
    }
    // Whether any errors
    let hasErrors = false;
    for (var attr in errors) {
      if (errors.hasOwnProperty(attr)) {
        hasErrors = true;
        break;
      }
    }
    // Designations
    const enableDesignations = !values.creditPayment;
    if (!hasErrors) {
      // Save form values in state
      this.setState({
        transactionInfo: values,
        hasValidationErrors: false,
        actionsEnabled: true,
        selectedFinanceId: selectedFinanceId,
        enableDesignations: enableDesignations,
      });
    } else {
      this.setState({
        hasValidationErrors: true,
        actionsEnabled: false,
        selectedFinanceId: selectedFinanceId,
        enableDesignations: enableDesignations,
      });
    }
    // Errors
    return errors;
  };

  handleSave = (evt) => {
    // Disable actions
    this.setState({
      actionsEnabled: false,
    });
    // Transaction
    let _transactionInfo = this.state.transactionInfo;
    if (!_transactionInfo) {
      const editInfo = this.state.editInfo;
      _transactionInfo = { ...editInfo };
      delete _transactionInfo.finances;
      delete _transactionInfo.designations;
      delete _transactionInfo.financesFromIds;
      delete _transactionInfo.financesToIds;
    }
    if (!this.whetherFinanceIsCredit(_transactionInfo.financeToId)) {
      _transactionInfo.creditPayment = false;
    }
    // Designations
    const _designations = {};
    if (!_transactionInfo.creditPayment) {
      const designations = [...this.state.selectedDesignationsList];
      let totalTransferAmt = 0.0;
      designations.forEach((designation) => {
        const transferAmount = designation.transferAmount;
        if (transferAmount !== 0.0) {
          _designations[designation.id] = transferAmount;
          totalTransferAmt += transferAmount;
        }
      });
      const transferAmount = toUsMoney(_transactionInfo.amount);
      if (transferAmount === 0.0 && totalTransferAmt !== 0.0) {
        _transactionInfo.amount = Math.abs(totalTransferAmt);
        const editInfo = this.state.editInfo;
        editInfo.amount = _transactionInfo.amount;
        this.setState({
          editInfo: editInfo,
        });
      }
    }
    // Build request
    const requestInfo = {
      batchNumber: this.state.batchNumber,
      ..._transactionInfo,
      designations: _designations,
    };
    // Post
    doHttpPost({
      url: transferSaveUrl,
      body: requestInfo,
      onSuccess: (response) => {
        this.state.onSave(response.batchNumber);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          let htmlError = "<ol>";
          error.response.data.errors.forEach((e) => {
            htmlError += "<li>" + e.field + ": " + e.defaultMessage + "</li>";
          });
          htmlError += "</ol>";
          this.setState({
            errorMessage: htmlError,
            actionsEnabled: true,
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
            actionsEnabled: true,
          });
        }
      },
    });
  };

  findFinance = (id) => {
    const finances = this.state.editInfo.finances.filter((f) => f.id === id);
    return finances.length > 0 ? finances[0] : null;
  };

  financeItem = (finance) => {
    if (finance) {
      return finance.description + " (*" + finance.name + ")";
    }
    return "(UNKNOWN)";
  };

  reviewDesignations = () => {
    // Finance
    let financeId = null;
    if (this.state.selectedFinanceId) {
      financeId = this.state.selectedFinanceId.replace("FINANCE:", "");
    } else if (this.state.financeId) {
      financeId = this.state.financeId;
    } else {
      return;
    }
    // Designations
    doHttpGet({
      url: transactionFinanceDesignationsUrl,
      params: {
        fa: financeId,
      },
      onSuccess: (designations) => {
        // Load designations
        this.loadDesignations(designations);
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  loadDesignations = (designations) => {
    // Listings
    const selectedDesignationIds = [];
    const selectedDesignationsList = [];
    const designationsForSelection = [];
    designations.forEach((d) => {
      if (d.transferAmount === 0.0) {
        designationsForSelection.push(d);
      } else {
        selectedDesignationIds.push(d.id);
        selectedDesignationsList.push(d);
      }
    });

    // Remove listed IDs
    for (var i = designations.length - 1; i >= 0; i -= 1) {
      const designation = designations[i];
      const isListed = selectedDesignationIds.indexOf(designation.id) >= 0;
      if (isListed) {
        removeIndexWithAttr(designations, "id", designation.id);
      }
    }
    // Selectable designations and switch tab
    const selectedDesignationId =
      designationsForSelection.length > 0 ? designationsForSelection[0].id : 0;
    this.setState({
      selectedDesignationId: selectedDesignationId,
      selectedTab: TAB_DESIGNATIONS,
      selectedDesignationIds: selectedDesignationIds,
      selectedDesignationsList: selectedDesignationsList,
      designationsForSelection: designationsForSelection,
    });
  };

  addDesignation = (values) => {
    const selectedDesignationsList = this.state.selectedDesignationsList || [];
    const designationsForSelection = this.state.designationsForSelection || [];
    // Selected
    const designationId =
      parseInt(values.selectedDesignationId, 10) ||
      this.state.selectedDesignationId;
    // Designation
    const _designations = designationsForSelection.filter(
      (d) => d.id === designationId
    );
    if (_designations.length > 0) {
      // Designation
      const designation = _designations[0];
      designation.transferAmount = designation.designatedAmount;
      // Remove from available
      removeIndexWithAttr(designationsForSelection, "id", designation.id);
      // Add to selected
      selectedDesignationsList.push(designation);
      // Next selected index
      const selectedDesignationId =
        designationsForSelection.length > 0
          ? designationsForSelection[0].id
          : 0;
      // Selected
      this.setState({
        designationsForSelection: designationsForSelection,
        selectedDesignationsList: selectedDesignationsList,
        selectedDesignationId: selectedDesignationId,
        actionsEnabled: true,
      });
    }
  };

  updateDesignationAmount = (designationId, amount) => {
    if (amount === 0) {
      this.removeDesignation(designationId);
    } else {
      // Designation
      const designations = [...this.state.selectedDesignationsList];
      const _designations = designations.filter((d) => d.id === designationId);
      if (_designations.length > 0) {
        // Designation
        const designation = _designations[0];
        // Set amount
        designation.transferAmount = amount;
        // Selected
        this.setState({
          actionsEnabled: true,
        });
      }
    }
  };

  removeDesignation = (designationId) => {
    // Designation
    const designations = [...this.state.selectedDesignationsList];
    const _designations = designations.filter((d) => d.id === designationId);
    if (_designations.length > 0) {
      // Designation
      const designation = _designations[0];
      // Remove from listing
      removeIndexWithAttr(designations, "id", designation.id);
      // Add to available
      const designationsForSelection = [...this.state.designationsForSelection];
      designationsForSelection.push(designation);
      // Selected
      this.setState({
        designationsForSelection: designationsForSelection,
        selectedDesignationId: designation.id,
        selectedDesignationsList: designations,
        actionsEnabled: true,
      });
    }
  };

  whetherFinanceIsCredit = (financeId) => {
    const finances = this.state.editInfo.finances.filter(
      (f) => f.type === "Credit" && f.id === financeId
    );
    return finances && finances.length > 0;
  };

  render() {
    // Info
    const editInfo = this.state.editInfo;
    if (!editInfo) return null;
    // Whether editing
    const isEditing = this.state.batchNumber != null;
    // Form field values
    const {
      date,
      amount,
      financeFromId,
      financeToId,
      creditPayment,
      description,
      reference,
      financesFromIds,
      financesToIds,
    } = editInfo;
    this.selectedFinanceId = financeFromId;
    // Designations
    const selectedDesignationsList = this.state.selectedDesignationsList || [];
    const designationsForSelection = this.state.designationsForSelection || [];
    const selectedDesignationId = this.state.selectedDesignationId || 0;
    // Total amount
    const enableDesignations = this.state.enableDesignations;
    let totalTransferAmt = 0.0;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Update" : "Create"} Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Tabs
            defaultActiveKey={TAB_EDITOR}
            activeKey={this.state.selectedTab}
            onSelect={(tab) => this.setState({ selectedTab: tab })}
            id="edit-transactions-tab"
          >
            <Tab
              eventKey={TAB_EDITOR}
              title="Editor"
              style={{ padding: "5px" }}
            >
              <Formik
                initialValues={{
                  date,
                  amount,
                  financeFromId,
                  financeToId,
                  creditPayment,
                  reference,
                  description,
                }}
                validate={this.validateTransfer}
                validateOnBlur={true}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {(props) => {
                  // Disable credit payment option
                  const creditFinanceSelected = this.whetherFinanceIsCredit(
                    props.values.financeToId
                  );
                  // Render
                  return (
                    <Form>
                      <fieldset className="form-group required">
                        <label className="control-label">Date</label>
                        <DateSelector
                          className="form-control"
                          name="date"
                          style={{ width: "100%" }}
                        />
                        <ErrorMessage
                          name="date"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Amount</label>
                        <Field name="amount" component={NumberField}></Field>
                        <ErrorMessage
                          name="amount"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">From Account</label>
                        <Field
                          className="form-control"
                          as="select"
                          name="financeFromId"
                        >
                          {financesFromIds.map((financeId) => {
                            const finance = this.findFinance(financeId);
                            return (
                              <option key={financeId} value={financeId}>
                                {this.financeItem(finance)}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage
                          name="financeFromId"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      {enableDesignations && (
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() =>
                              this.reviewDesignations(financeFromId)
                            }
                          >
                            Review Designations
                          </button>
                        </div>
                      )}
                      <fieldset className="form-group required">
                        <label className="control-label">To Account</label>
                        <Field
                          className="form-control"
                          as="select"
                          name="financeToId"
                        >
                          {financesToIds.map((financeId) => {
                            const finance = this.findFinance(financeId);
                            return (
                              <option key={financeId} value={financeId}>
                                {this.financeItem(finance)}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage
                          name="financeToId"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      {creditFinanceSelected && (
                        <fieldset className="form-group">
                          <Field
                            component={Checkbox}
                            name="creditPayment"
                            id="creditPayment"
                            label="This is a credit payment"
                          />
                        </fieldset>
                      )}
                      <fieldset className="form-group">
                        <label className="control-label">Reference</label>
                        <Field
                          type="text"
                          name="reference"
                          className="form-control"
                        ></Field>
                        <ErrorMessage
                          name="reference"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                      <fieldset className="form-group required">
                        <label className="control-label">Description</label>
                        <Field
                          className="form-control"
                          as="textarea"
                          name="description"
                        ></Field>
                        <ErrorMessage
                          name="description"
                          className="alert alert-warning"
                          component="div"
                        />
                      </fieldset>
                    </Form>
                  );
                }}
              </Formik>
            </Tab>
            {enableDesignations && (
              <Tab
                eventKey={TAB_DESIGNATIONS}
                title="Designations"
                style={{ padding: "5px" }}
              >
                <Formik
                  initialValues={{
                    selectedDesignationId,
                  }}
                  onSubmit={this.addDesignation}
                  validateOnBlur={true}
                  validateOnChange={true}
                  enableReinitialize={true}
                >
                  {(props) => {
                    return (
                      <Form>
                        <fieldset className="form-group required">
                          <label className="control-label">
                            Select Designation
                          </label>
                          <Field
                            className="form-control"
                            as="select"
                            name="selectedDesignationId"
                            disabled={designationsForSelection.length === 0}
                          >
                            {designationsForSelection.map((designation) => {
                              const designationId = designation.id;
                              let designationName =
                                designation.type +
                                ": " +
                                (designation.description
                                  ? designation.description
                                  : "(*No Description Available)") +
                                " => " +
                                fmtMoneyUSD(designation.totalDesignationAmount);
                              return (
                                <option
                                  key={designationId}
                                  value={designationId}
                                >
                                  {designationName}
                                </option>
                              );
                            })}
                          </Field>
                        </fieldset>
                        <div
                          className="text-right"
                          style={{ marginBottom: "5px" }}
                        >
                          <button
                            type="submit"
                            className="btn btn-sm btn-success"
                            disabled={designationsForSelection.length === 0}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              style={{ marginRight: "5px" }}
                            />
                            Add Selected Designation
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                <table
                  className="table table-bordered table-sm table-striped"
                  style={{ marginBottom: 0, marginTop: 10 }}
                >
                  <thead className="thead-dark">
                    <tr>
                      <th>Description</th>
                      <th>Available</th>
                      <th style={{ width: "90px" }}>Transfer</th>
                      <th className="text-center" style={{ width: "60px" }}>
                        Remove
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedDesignationsList.map((designation) => {
                      // Total
                      const _selectedFinanceId =
                        "FINANCE:" + designation.financialAccountId;
                      if (_selectedFinanceId === this.state.selectedFinanceId) {
                        totalTransferAmt += designation.transferAmount;
                      }
                      // Render
                      return (
                        <tr key={"designation-" + designation.id}>
                          <td
                            style={{
                              verticalAlign: "middle",
                            }}
                          >
                            {designation.primary ? (
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "gray",
                                  fontStyle: "italic",
                                }}
                              >
                                {designation.type}
                              </span>
                            ) : (
                              <table
                                className="table table-sm table-borderless table-unstriped"
                                style={{
                                  marginBottom: 0,
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        width: "50px",
                                      }}
                                    >
                                      <span
                                        className="gzn-detail-flag"
                                        style={{ margin: 0 }}
                                      >
                                        {designation.type}
                                      </span>
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {designation.description ? (
                                        designation.description
                                      ) : (
                                        <span className="description">
                                          (No Description)
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              verticalAlign: "middle",
                            }}
                          >
                            <Money amount={designation.designatedAmount} />
                          </td>
                          <td style={{ textAlign: "center", width: "90px" }}>
                            <DesignationAmount
                              amount={
                                designation.transferAmount ||
                                designation.designatedAmount
                              }
                              fxUpdateDesignation={(amount) =>
                                this.updateDesignationAmount(
                                  designation.id,
                                  amount
                                )
                              }
                            />
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() =>
                                this.removeDesignation(designation.id)
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot className="table-footer">
                    <tr>
                      <td className="text-right" colSpan={3}>
                        TOTAL:
                        <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                          {fmtMoneyUSD(totalTransferAmt)}
                        </span>
                      </td>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </Tab>
            )}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              className="btn btn-md btn-primary"
              disabled={
                !this.state.actionsEnabled || this.state.hasValidationErrors
              }
              onClick={this.handleSave}
            >
              Save Transfer
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

class DesignationAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: props.amount.toFixed(2),
    };
  }

  amountChanged = (event) => {
    let amount = event.target.value;
    if (amount.match(/^-{0,1}((\d*(\.\d+){1})|(\d+(\.\d*){1})|(\d+))$/)) {
      this.setState({
        amount: amount,
      });
    }
  };

  updateAmount = () => {
    const amount = Number(this.state.amount);
    this.props.fxUpdateDesignation(amount);
  };

  render() {
    return (
      <input
        className="form-control text-right"
        type="text"
        id={UUID()}
        style={{ width: 80, padding: 3, height: 25 }}
        value={this.state.amount}
        onChange={this.amountChanged}
        onBlur={this.updateAmount}
      />
    );
  }
}
