import React, { Component } from "react";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import { budgetsUrl, budgetDeleteUrl } from "../../library/Urls";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import ConfirmDialog from "../common/ConfirmDialog";
import NotificationSystem from "react-notification-system";
import EditBudget from "./EditBudget";

class Budgets extends Component {
  notificationSystem = React.createRef();

  state = {
    errorMessage: null,
    selectedId: null,
    confirmDelete: false
  };

  getBudgets = includeDisabled => {
    // List of budgets
    doHttpGet({
      url: budgetsUrl(includeDisabled),
      onSuccess: budgets => {
        this.setState({
          budgets: budgets,
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  includeDisabled = evt => {
    // Whether selected
    const checked = evt.target.checked;
    this.props._includeDisabledAccounts(checked);
    // Update info
    this.getBudgets(checked);
  };

  componentDidMount = evt => {
    this.getBudgets(this.props.includeDisabled);
  };

  showEditor = (show, budgetId) => {
    this.setState({
      showEditor: show,
      selectedId: budgetId
    });
  };

  onEditBudget = budgetId => {
    this.showEditor(false, budgetId);
    this.getBudgets(this.props.includeDisabled);
  };

  confirmDelete = (show, budgetId, budgetName) => {
    this.setState({
      confirmDelete: show,
      selectedId: budgetId,
      budgetName: budgetName
    });
  };

  onConfirmDelete = () => {
    // Close confirmation dialog
    this.confirmDelete(false, null, this.state.budgetName);
    // Delete account
    doHttpDelete({
      url: budgetDeleteUrl(this.state.selectedId),
      onSuccess: () => {
        // Update view
        this.getBudgets(this.props.includeDisabled);
        // Notification
        const notification = this.notificationSystem.current;
        notification.addNotification({
          title: "Budget Deleted",
          message:
            "Budget '" + this.state.budgetName + "' was successfully deleted",
          level: "success",
          position: "br",
          autoDismiss: 5
        });
      },
      onError: error => {
        this.props._handleError(error);
      }
    });
  };

  render() {
    // Bugdets
    const budgets = this.state.budgets || [];

    // Render
    return (
      <div className="page-content">
        {this.state.errorMessage && (
          <div className="alert alert-danger">{this.state.errorMessage}</div>
        )}

        <div className="row">
          <div className="col-8">
            <h2>Budgets ({budgets.length})</h2>
          </div>
          <div
            className="col-4"
            style={{ textAlign: "right", verticalAlign: "bottom" }}
          >
            <button
              className="btn btn-success"
              onClick={() => this.showEditor(true, null)}
            >
              <FontAwesomeIcon icon={faPlus} /> &nbsp; New Budget
            </button>
          </div>
        </div>

        <div className="form-check" style={{ marginBottom: "10px" }}>
          <input
            className="form-check-input"
            name="includeDisabledCbx"
            type="checkbox"
            onChange={this.includeDisabled}
          />
          <label className="form-check-label" htmlFor="includeDisabledCbx">
            Include disabled budgets
          </label>
        </div>

        <table className="table table-bordered table-lg table-fin-accts">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Budgeted</th>
              <th scope="col">Start Date</th>
              <th scope="col">Expire Date</th>
              <th scope="col">Category</th>
              <th scope="col">Schedule</th>
              <th scope="col" style={{ width: "110px" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {budgets.map(bgt => (
              <tr
                key={"fa-" + bgt.budget_id}
                className={classnames("", {
                  "tbl-row-disabled": !bgt.is_enabled,
                  focus: bgt.budget_id === this.state.selectedId
                })}
              >
                <th scope="row">
                  <Link to={Links.budgetFor(bgt.budget_id)}>
                    {bgt.budget_name}
                  </Link>
                </th>
                <td className="text-right">{fmtMoneyUSD(bgt.amount)}</td>
                <td>{fmtDate(bgt.start_date)}</td>
                <td>{fmtDate(bgt.expire_date)}</td>
                <td>
                  <Link to={Links.categoryFor(bgt.category_id)}>
                    {bgt.category_name}
                  </Link>
                </td>
                <td>{bgt.schedule}</td>
                <td className="text-right">
                  {bgt.is_editable && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.showEditor(true, bgt.budget_id)}
                    >
                      <FontAwesomeIcon icon={faEdit} /> Edit
                    </button>
                  )}{" "}
                  &nbsp;{" "}
                  {bgt.is_deletable && (
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() =>
                        this.confirmDelete(true, bgt.budget_id, bgt.budget_name)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {this.state.showEditor && (
          <EditBudget
            show={this.state.showEditor}
            onSave={this.onEditBudget}
            onClose={() => this.showEditor(false)}
            budgetId={this.state.selectedId}
          />
        )}

        {this.state.confirmDelete && (
          <ConfirmDialog
            show={this.state.confirmDelete}
            handleAction={this.onConfirmDelete}
            handleClose={() => this.confirmDelete(false, null)}
            _type="budget"
          />
        )}

        <NotificationSystem ref={this.notificationSystem} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    includeDisabled: state.budgets.includeDisabled,
    budgetInfo: state.budgets.budgetInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // Errors
    _handleError: message => {
      dispatch(Actions.setErrorMessage(message));
    },
    // Budget info
    _setBudgetInfo: info => {
      dispatch(Actions.setBudgetInfo(info));
    },
    // Whether to include disabled budgets
    _includeDisabledAccounts: include => {
      dispatch(Actions.setIncludeDisabledBudgets(include));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Budgets);
