import React, { Component } from "react";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { healthcareDisbursementUrl } from "../../library/Urls";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { isValidUsMoney, toUsMoney } from "../../library/Utilities";
import DateSelector from "../common/DateSelector";
import NumberField from "../common/NumberField";

export default class EditDisbursement extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      disbursementId: props.disbursementId,
      healthcareCardId: props.healthcareCardId,
      errorMessage: null,
      actionsEnabled: false,
    };
  }

  componentDidMount = () => {
    doHttpGet({
      url: healthcareDisbursementUrl,
      params: {
        id: this.state.disbursementId,
        hc: this.state.healthcareCardId,
      },
      onSuccess: (data) => {
        this.setState({
          editInfo: {
            ...data,
            amount: data.amount === null ? "0.00" : data.amount.toFixed(2),
            from: data.from || "",
            description: data.description || "",
            dateTime: data.dateTime ? new Date(data.dateTime) : null,
          },
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  validateDisbursement = (values) => {
    // Errors
    const errors = {};
    // From
    if (!values.from) {
      errors.from = "Disburser is required";
    }
    // Date
    if (!values.dateTime) {
      errors.dateTime = "Date is required";
    }
    // Description
    if (!values.description) {
      errors.description = "Description is required";
    }
    // Amount
    if (!values.amount) {
      errors.amount = "Amount is required";
    } else {
      if (!isValidUsMoney(values.amount)) {
        errors.amount = "Invalid monetary amount";
      } else {
        const _amount = toUsMoney(values.amount);
        if (_amount <= 0.0) {
          errors.amount = "Amount must be more than 0.0";
        }
      }
    }
    // Whether any errors
    let hasErrors = false;
    for (var attr in errors) {
      if (errors.hasOwnProperty(attr)) {
        hasErrors = true;
        break;
      }
    }
    if (!hasErrors) {
      // Save form values in state
      this.setState({
        disbursementInfo: values,
        hasValidationErrors: false,
        actionsEnabled: true,
      });
    } else {
      this.setState({
        hasValidationErrors: true,
        actionsEnabled: false,
      });
    }
    // Errors
    return errors;
  };

  saveDisbursement = () => {
    // Disable actions
    this.setState({
      actionsEnabled: false,
    });
    // Build request
    const requestInfo = {
      id: this.state.disbursementId,
      healthcareCardId: this.state.healthcareCardId,
      ...this.state.disbursementInfo,
      date: this.state.disbursementInfo.dateTime,
    };
    delete requestInfo.dateTime;
    // Post
    doHttpPost({
      url: healthcareDisbursementUrl,
      body: requestInfo,
      onSuccess: (response) => {
        this.state.onSave(response.id);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          let htmlError = "<ol>";
          error.response.data.errors.forEach((e) => {
            htmlError += "<li>" + e.field + ": " + e.defaultMessage + "</li>";
          });
          htmlError += "</ol>";
          this.setState({
            errorMessage: htmlError,
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  render() {
    // Info
    const editInfo = this.state.editInfo;
    if (!editInfo) return null;
    // Whether editing
    const isEditing = this.state.disbursementId != null;
    // Form field values
    const { from, dateTime, amount, description } = editInfo;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Update" : "Create"} Disbursement
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{ from, dateTime, amount, description }}
            validate={this.validateDisbursement}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {(props) => {
              return (
                <Form>
                  <fieldset className="form-group required">
                    <label className="control-label">Date</label>
                    <DateSelector
                      className="form-control"
                      name="dateTime"
                      style={{ width: "100%" }}
                    />
                    <ErrorMessage
                      name="dateTime"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">Amount</label>
                    <Field component={NumberField} name="amount"></Field>
                    <ErrorMessage
                      name="amount"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <label className="control-label">From</label>
                    <Field
                      type="text"
                      name="from"
                      className="form-control"
                    ></Field>
                    <ErrorMessage
                      name="from"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">Description</label>
                    <Field
                      className="form-control"
                      as="textarea"
                      name="description"
                    ></Field>
                    <ErrorMessage
                      name="description"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              className="btn btn-md btn-primary"
              disabled={
                !this.state.actionsEnabled || this.state.hasValidationErrors
              }
              onClick={this.saveDisbursement}
            >
              Save Healthcare Card
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
