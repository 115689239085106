import React, { Fragment } from "react";
import { fmtMoneyUSD } from "../../library/Utilities";
import { Link } from "react-router-dom";
import { savingsGoalFor, budgetFor } from "../../library/Links";

const Progress = (props) => {
  // Width
  const width = props.width;
  const color = props.color;
  // Return
  return (
    <div
      className="progress-bar"
      role="progressbar"
      style={{
        backgroundColor: color,
        width: width + "%",
      }}
      aria-valuenow={width}
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  );
};

export default function BudgetListings(props) {
  // Listings
  const listings = props.listings || [];
  return (
    <Fragment>
      {listings.map((lst) => {
        let remainingAmt = lst.remaining;
        let remainingText = "available";
        let spentAdj = "of";
        if (remainingAmt < 0) {
          remainingText = "over-budget";
          spentAdj = "but had";
        }
        remainingText = fmtMoneyUSD(remainingAmt) + " " + remainingText;

        // Conditions
        const condition1 = lst.condition1;
        const condition2 = !condition1 && lst.condition2;
        const condition3 = !condition1 && !condition2 && lst.condition3;
        const condition4 =
          !condition1 && !condition2 && !condition3 && lst.condition4;

        // Return
        return (
          <div key={lst.id} style={{ marginBottom: "15px" }}>
            <div className="sub-section-title">{lst.name}</div>
            <div className="row">
              <div
                className="col-md-3"
                style={{ textAlign: "right", paddingRight: "20px" }}
              >
                <div style={{ fontSize: "1.3em" }}>
                  {fmtMoneyUSD(lst.budgeted)}
                </div>
                <div style={{ fontSize: "0.9em", textTransform: "lowercase" }}>
                  <Link
                    to={
                      lst.savingsGoal
                        ? savingsGoalFor(lst.id)
                        : budgetFor(lst.id)
                    }
                  >
                    Review
                  </Link>
                </div>
              </div>
              <div className="col-sm-9">
                <div className="progress" style={{ marginTop: "10px" }}>
                  {condition1 && (
                    <Fragment>
                      <Progress width={lst.percentUsage[0]} color="#FF9933" />
                      <Progress width={lst.percentUsage[1]} color="#339933" />
                      <Progress width={lst.percentUsage[2]} color="#EEEEEE" />
                    </Fragment>
                  )}
                  {condition2 && (
                    <Fragment>
                      <Progress width={lst.percentUsage[0]} color="#FF9933" />
                      <Progress width={lst.percentUsage[1]} color="#CC3333" />
                      <Progress width={lst.percentUsage[2]} color="#EEEEEE" />
                    </Fragment>
                  )}
                  {condition3 && (
                    <Fragment>
                      <Progress width={lst.percentUsage[0]} color="#FF9933" />
                      <Progress width={lst.percentUsage[1]} color="#339933" />
                    </Fragment>
                  )}
                  {condition4 && (
                    <Fragment>
                      <Progress width={lst.percentUsage[0]} color="#FF9933" />
                      <Progress width={lst.percentUsage[1]} color="#CC3333" />
                    </Fragment>
                  )}
                </div>
                <div style={{ textAlign: "right", marginTop: "4px" }}>
                  <span style={{ color: "#999999", fontSize: "0.9em" }}>
                    Spent {fmtMoneyUSD(lst.used)} {spentAdj} allocated{" "}
                    {fmtMoneyUSD(lst.allocated)}
                  </span>{" "}
                  | <b>{remainingText}</b>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
}
