import React, { useState, Fragment } from "react";
import { fmtDate, fmtMoneyUSD } from "../../library/Utilities";
import MoneyArrow from "../common/MoneyArrow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DateSelector from "../common/DateSelector";
import NumberField from "../common/NumberField";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Checkbox from "../common/Checkbox";
import classnames from "classnames";
import { doHttpDelete, doHttpGet, doHttpPost } from "../../services/WebService";
import {
  stockPriceUrl,
  stockPricesUrl,
  stockOnlineUrl,
} from "../../library/Urls";
import ConfirmDialog from "../common/ConfirmDialog";

const AccountStockDetailPrices = ({ info, fxRefresh }) => {
  // State
  const allowOnlinePriceUpdate = info.allowOnlinePriceUpdate;
  const [chartRangeOption, setChartRangeOption] = useState(
    info.chartRangeOption
  );
  const [chartData, setChartData] = useState(info.chart);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showStockPriceDeleteConfirm, setShowStockPriceDeleteConfirm] =
    useState(false);
  const [deleteStockPriceId, setDeleteStockPriceId] = useState(null);
  const [deleteStockPriceAmt, setDeleteStockPriceAmt] = useState(null);
  const [deleteStockPriceDt, setDeleteStockPriceDt] = useState(null);

  // Delete price history
  const confirmStockPriceDeletion = (show, id, dt, amt) => {
    setDeleteStockPriceDt(dt);
    setDeleteStockPriceAmt(amt);
    setDeleteStockPriceId(id);
    setShowStockPriceDeleteConfirm(show);
  };

  // Delete price history
  const deleteStockPrice = (stockPriceId) => {
    // Delete
    doHttpDelete({
      url: stockPriceUrl,
      params: {
        id: stockPriceId,
      },
      onSuccess: () => {
        confirmStockPriceDeletion(false);
        setErrorMessage(null);
        fxRefresh();
      },
      rawError: true,
      onError: (error) => {
        confirmStockPriceDeletion(false);
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          setErrorMessage("STOCK PRICE DELETION: " + errors.join("; "));
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          setErrorMessage("STOCK PRICE DELETION: " + errorMessage);
        } else {
          setErrorMessage(
            "STOCK PRICE DELETION: Could not perform request due to network or server issues"
          );
        }
      },
    });
  };

  // Validate stock price
  const validateStockPrice = (values) => {
    const errors = {};
    if (!values.date) {
      errors.date = "Date is required";
    } else if (new Date(values.date) > new Date()) {
      errors.date =
        "Date cannot be in the future or during the weekend (Saturday/Sunday)";
    }
    if (!values.price) {
      errors.price = "Price is required";
    } else if (parseFloat(values.price) <= 0.0) {
      errors.price = "Stock price cannot be less than 0.0, must be greater";
    }
    return errors;
  };

  // Update stock price
  const updateStockPrice = (values) => {
    // POST
    doHttpPost({
      url: stockPriceUrl,
      body: {
        stockId: info.stockId,
        price: values.price,
        date: values.date,
      },
      onSuccess: () => {
        setErrorMessage(null);
        fxRefresh();
      },
      rawError: true,
      onError: (error) => {
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          setErrorMessage("STOCK PRICE UPDATE: " + errors.join("; "));
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          setErrorMessage("STOCK PRICE UPDATE: " + errorMessage);
        } else {
          setErrorMessage(
            "STOCK PRICE UPDATE: Could not perform request due to network or server issues"
          );
        }
      },
    });
  };

  // Set update method
  const setOnlinePriceUpdating = (values) => {
    // POST
    doHttpPost({
      url: stockOnlineUrl,
      body: {
        stockId: info.stockId,
        updatePriceOnline: values.allowOnlinePriceUpdate,
      },
      onSuccess: () => {
        setErrorMessage(null);
        fxRefresh();
      },
      rawError: true,
      onError: (error) => {
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          setErrorMessage("PRICE UPDATE ONLINE: " + errors.join("; "));
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          setErrorMessage("PRICE UPDATE ONLINE: " + errorMessage);
        } else {
          setErrorMessage(
            "PRICE UPDATE ONLINE: Could not perform request due to network or server issues"
          );
        }
      },
    });
  };

  // Chart range link
  const rangeLink = (name, option) => {
    if (option === chartRangeOption) {
      return <b>{name}</b>;
    } else {
      return (
        <button
          type="button"
          style={{ padding: 0, fontSize: "1.1em" }}
          className="btn btn-link"
          onClick={() => getChartData(option)}
        >
          {name}
        </button>
      );
    }
  };

  // Get chart data for range
  const getChartData = (option) => {
    doHttpGet({
      url: stockPricesUrl,
      params: {
        id: info.stockId,
        range: option,
      },
      onSuccess: (data) => {
        setChartData(data);
        setChartRangeOption(option);
        setErrorMessage(null);
      },
      onError: (error) => {
        setErrorMessage(error);
      },
    });
  };

  // Return
  return (
    <div className="row">
      {errorMessage && (
        <div
          className="alert alert-danger col-12"
          style={{
            marginBottom: "20px",
            fontSize: "1.1em",
            padding: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}

      <div className="col-7">
        <div
          style={{
            borderBottom: "2px solid gray",
            marginBottom: "20px",
            paddingBottom: "20px",
          }}
        >
          <Formik
            initialValues={{
              allowOnlinePriceUpdate,
            }}
            enableReinitialize={true}
            onSubmit={setOnlinePriceUpdating}
          >
            <Form>
              <label className="control-label">Online Price Update:</label>
              <small
                id="currentOnlineUpdateStatus"
                className={classnames("form-text text-muted alert", {
                  "alert-success": allowOnlinePriceUpdate,
                  "alert-danger": !allowOnlinePriceUpdate,
                })}
              >
                The latest stock price is{" "}
                {!allowOnlinePriceUpdate && <b>NOT</b>} updated from online
                sources
                {allowOnlinePriceUpdate && (
                  <Fragment>
                    {" "}
                    <b>every weekday after markets close</b>
                  </Fragment>
                )}
                .
              </small>
              <Field
                component={Checkbox}
                name="allowOnlinePriceUpdate"
                id="allowOnlinePriceUpdate"
                label="Allow stock price to be updated online"
              />
              <button
                type="submit"
                className="btn btn-md btn-primary"
                style={{ marginLeft: "15px" }}
              >
                Set Online Price Update Option
              </button>
            </Form>
          </Formik>
        </div>

        <div style={{ borderBottom: "2px solid gray", marginBottom: "20px" }}>
          <Formik
            initialValues={{
              date: new Date(),
              price: "",
            }}
            validate={validateStockPrice}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
            onSubmit={updateStockPrice}
          >
            <Form>
              <label className="control-label">Set Stock Price On Date:</label>
              <DateSelector
                className="form-control"
                style={{
                  display: "inline-block",
                  width: "120px",
                  marginLeft: "15px",
                }}
                name="date"
                maxDate={new Date()}
                openToDate={new Date()}
              />
              <Field
                component={NumberField}
                allowNegative={false}
                integerOnly={false}
                name="price"
                className="form-control"
                style={{
                  display: "inline-block",
                  width: "100px",
                  marginLeft: "15px",
                }}
              />
              <button
                type="submit"
                className="btn btn-md btn-primary"
                style={{ marginLeft: "15px" }}
              >
                Set Stock Price
              </button>
              <div style={{ marginTop: "10px", marginLeft: "155px" }}>
                <ErrorMessage
                  name="date"
                  className="alert alert-warning"
                  component="div"
                />
                <ErrorMessage
                  name="price"
                  className="alert alert-warning"
                  component="div"
                />
              </div>
            </Form>
          </Formik>
        </div>

        <div style={{ fontSize: "1.2em" }}>
          <b>Stock Price History:</b> {rangeLink("1 mo", "1MO")} |{" "}
          {rangeLink("3 mos", "3MO")} | {rangeLink("6 mos", "6MO")} |{" "}
          {rangeLink("12 mos", "12MO")} | {rangeLink("2 yrs", "2YR")} |{" "}
          {rangeLink("5 yrs", "5YR")} | {rangeLink("ALL", "ALL")}
        </div>

        <div style={{ height: "300px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={chartData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="price"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="col-5">
        <table className="table table-bordered table-md table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Date</th>
              <th scope="col" style={{ textAlign: "right" }}>
                Price
              </th>
              <th scope="col">Source</th>
              <th scope="col" style={{ textAlign: "right" }}>
                Change
              </th>
              <th scope="col" className="text-center" style={{ width: "50px" }}>
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {info.history.map((hist) => (
              <tr key={"hist-" + hist.id}>
                <td style={{ verticalAlign: "middle" }}>
                  {fmtDate(hist.date)}
                </td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                  {fmtMoneyUSD(hist.price)}
                </td>
                <td style={{ verticalAlign: "middle" }}>{hist.howUpdated}</td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                  <MoneyArrow amount={hist.change} showSymbol={false} />
                </td>
                <td className="text-center">
                  {hist.deletable && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() =>
                        confirmStockPriceDeletion(
                          true,
                          hist.id,
                          hist.date,
                          hist.price
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showStockPriceDeleteConfirm && (
        <ConfirmDialog
          show={showStockPriceDeleteConfirm}
          handleAction={() => deleteStockPrice(deleteStockPriceId)}
          handleClose={() => confirmStockPriceDeletion(false)}
          title="Delete Stock Price?"
          message={
            "The stock price " +
            fmtMoneyUSD(deleteStockPriceAmt) +
            " of " +
            fmtDate(deleteStockPriceDt) +
            " will be deleted immediately, and cannot be recovered thereafter!"
          }
          question="Are you sure about deleting this stock price?"
          btnAction="Yes, Delete"
        />
      )}
    </div>
  );
};

export default AccountStockDetailPrices;
