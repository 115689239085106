import React, { Component } from "react";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import DateSelector from "../common/DateSelector";
import Money from "../common/Money";
import { Accordion, Card } from "react-bootstrap";
import {
  fmtMoneyUSD,
  toFloat,
  pluralize,
  removeFromArray,
  isDefined,
  findIndexWithAttr,
  fmtDateFullMonth,
  rewardStatement,
} from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faTasks } from "@fortawesome/free-solid-svg-icons";
import Fees from "./Fees";
import Interest from "./Interest";
import Rewards from "./Rewards";
import classnames from "classnames";
import { doHttpPost, doHttpDelete } from "../../services/WebService";
import { reconciliationUrl } from "../../library/Urls";
import { Tabs, Tab } from "react-bootstrap";
import ConfirmDialog from "../common/ConfirmDialog";
import NotificationSystem from "react-notification-system";
import Investments from "./Investments";
import { PreviousStatementsCredit } from "./PreviousStatementsCredit";
import { PreviousStatementsInvestment } from "./PreviousStatementsInvestment";
import { PreviousStatementsGeneral } from "./PreviousStatementsGeneral";
import Retirement401k from "./Retirement401k";
import { PreviousStatements401kRetirement } from "./PreviousStatements401kRetirement";
import { PreviousStatementsStdRetirement } from "./PreviousStatementsStdRetirement";
import CollegeInfo from "./CollegeInfo";
import { PreviousStatementsCollege } from "./PreviousStatementsCollege";
import RetirementStandard from "./RetirementStandard";

class AccountReconciliation extends Component {
  notificationSystem = React.createRef();
  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const reconciliation = nextProps.reconciliation;
    if (reconciliation) {
      // Statement balance
      const statementBalance = toFloat(reconciliation.statementBalance, 0);
      // Transactions
      let totalTransactionAmt = 0.0;
      let totalReconciledAmt = 0.0;
      const totalTransactions = reconciliation.transactions.length;
      const transactionIds = [];
      reconciliation.transactions.forEach((t) => {
        const amount = t.amount;
        if (t.reconciled) {
          totalReconciledAmt += amount;
          transactionIds.push(t.batchNumber);
        }
        totalTransactionAmt += amount;
      });
      const totalReconciled = transactionIds.length;
      // Fees
      let totalFeesAmt = 0.0;
      reconciliation.fees.forEach((fee) => {
        totalFeesAmt += toFloat(fee.amount, 0);
      });
      // Interest
      const interestEarned = toFloat(reconciliation.interestEarned, 0);
      const interestPaid = toFloat(reconciliation.interestPaid, 0);
      const totalInterestAmt = interestEarned - interestPaid;
      // Rewards
      let totalRewards = null;
      if (reconciliation.canHaveRewards) {
        const rewardAmount = toFloat(reconciliation.rewardAmount, 0);
        totalRewards =
          rewardAmount +
          (reconciliation.rewardMode === "TOTAL"
            ? 0
            : reconciliation.rewardBalance);
        totalRewards = rewardStatement(totalRewards, reconciliation.rewardType);
      }
      // Investments
      let totalInvestments = null;
      if (reconciliation.investmentFinance) {
        const cash = toFloat(reconciliation.investmentCashAmount, 0);
        const stocks = toFloat(reconciliation.investmentStockValue, 0);
        const capgains = toFloat(reconciliation.investmentCapitalGains, 0);
        const dividends = toFloat(reconciliation.investmentDividends, 0);
        totalInvestments = cash + stocks + capgains + dividends;
      }
      // Update
      return {
        ...reconciliation,
        reconciliationId: reconciliation.id,
        statementBalance: statementBalance,
        totalTransactionAmt: totalTransactionAmt,
        totalReconciledAmt: totalReconciledAmt,
        totalTransactions: totalTransactions,
        transactionIds: transactionIds,
        totalReconciled: totalReconciled,
        totalFeesAmt: totalFeesAmt,
        totalInterestAmt: totalInterestAmt,
        totalRewards: totalRewards,
        totalInvestments: totalInvestments,
      };
    }
    return null;
  }

  statementInfo = (values) => {
    // Balance
    const amount = String(values.statementBalance)
      .replace("$", "")
      .replace(/[^0-9.-]/, "");
    const statementBalance = toFloat(amount, 0);
    // Date
    const statementDate = values.statementDate;
    // Update state
    this.setState({
      statementBalance: statementBalance,
      statementDate: statementDate,
    });
    // Update computations
    this.props._updateStatementInfo(statementBalance, statementDate);
  };

  selectTransaction = (evt) => {
    // Selection
    const checked = evt.target.checked;
    const _batchNumber = evt.target.value;
    // Transactions
    const transactions = this.state.transactions;
    let amount = 0.0;
    transactions.forEach((t) => {
      if (_batchNumber === t.batchNumber) {
        // Change
        if (isDefined(t.previously)) {
          t.changed = t.previously !== checked;
        } else {
          t.previously = t.reconciled;
          t.changed = true;
        }
        t.reconciled = checked;
        amount = t.amount;
        return;
      }
    });
    // Reconciled
    let totalReconciledAmt = this.state.totalReconciledAmt;
    let totalReconciled = this.state.totalReconciled;
    let transactionIds = this.state.transactionIds;
    if (checked) {
      totalReconciledAmt += amount;
      totalReconciled++;
      transactionIds.push(_batchNumber);
    } else {
      totalReconciledAmt -= amount;
      totalReconciled--;
      removeFromArray(transactionIds, _batchNumber);
    }
    this.setState({
      transactions: transactions,
      totalReconciledAmt: totalReconciledAmt,
      totalReconciled: totalReconciled,
      transactionIds: transactionIds,
    });
  };

  reloadTransactions = () => {
    // Selections
    const transactions = [...this.state.transactions];
    // Reload
    this.props.reloadTransactions(
      this.state.financeId,
      this.state.reconciliationId,
      (updatedTransactions) => {
        // Changes
        updatedTransactions.forEach((t) => {
          const index = findIndexWithAttr(
            transactions,
            "batchNumber",
            t.batchNumber
          );
          if (index >= 0) {
            const t_old = transactions[index];
            t.changed = t_old.changed;
            t.previously = t_old.previously;
            t.reconciled = t_old.reconciled;
          }
        });
        // State
        this.props._updateStatementTransactions(updatedTransactions);
      }
    );
  };

  saveReconciliation = () => {
    // Transactions
    let reconcileIds = [];
    let unreconcileIds = [];
    this.state.transactions.forEach((t) => {
      if (t.reconciled) {
        reconcileIds = reconcileIds.concat(t.transactionIds);
      } else if (t.previously && t.changed) {
        unreconcileIds = unreconcileIds.concat(t.transactionIds);
      }
    });
    // Fees
    const fees = [];
    this.state.fees.forEach((fee) => {
      const amount = Number(toFloat(fee.amount, 0));
      if (
        amount &&
        amount !== 0.0 &&
        fee.description &&
        !fee.description.match(/description for fee/i)
      ) {
        fee.id = (fee.id + "").match(/\w+(-\w+){4}/) ? null : fee.id;
        fee.amount = amount;
        fees.push(fee);
      }
    });
    // Info
    const reconciliationInfo = {
      id: this.state.reconciliationId,
      financeId: this.state.financeId,
      statementBalance: Number(toFloat(this.state.statementBalance, 0)),
      statementDate: this.state.statementDate,
      reconcileIds: reconcileIds,
      unreconcileIds: unreconcileIds,
      fees: fees,
      interestEarned: Number(toFloat(this.state.interestEarned, 0)),
      interestPaid: Number(toFloat(this.state.interestPaid, 0)),
      retirement401k: this.state.retirement401kInfo,
      retirementStandard: this.state.retirementStandardInfo,
      collegeInfo: this.state.collegeInfo,
    };
    if (this.state.canHaveRewards) {
      reconciliationInfo.rewardAmount = Number(
        toFloat(this.state.rewardAmount, 0)
      );
      reconciliationInfo.rewardMode = this.state.rewardMode;
    }
    if (this.state.canHaveInvestments) {
      reconciliationInfo.investmentCashAmount = Number(
        toFloat(this.state.investmentCashAmount, 0)
      );
      reconciliationInfo.investmentStockValue = Number(
        toFloat(this.state.investmentStockValue, 0)
      );
      reconciliationInfo.investmentCapitalGains = Number(
        toFloat(this.state.investmentCapitalGains, 0)
      );
      reconciliationInfo.investmentDividends = Number(
        toFloat(this.state.investmentDividends, 0)
      );
    }
    // Post
    doHttpPost({
      url: reconciliationUrl,
      body: reconciliationInfo,
      onSuccess: (identity) => {
        const reconciliationId = identity.id;
        // State
        this.setState({
          errorMessage: null,
          reconciliationId: reconciliationId,
        });
        // Reload
        this.props.reloadTransactions(
          this.state.financeId,
          this.state.reconciliationId
        );
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  editReconciliation = (reconciliationId) => {
    this.props.reloadTransactions(this.state.financeId, reconciliationId);
  };

  confirmReconciliationDelete = (reconciliationId, statementDate) => {
    this.setState({
      showReconciliationConfirm: true,
      reconciliationIdToDelete: reconciliationId,
      reconciliationDeleteDate: statementDate,
    });
  };

  closeReconciliationConfirmation = () => {
    this.setState({
      showReconciliationConfirm: false,
      reconciliationIdToDelete: null,
    });
  };

  deleteReconciliation = () => {
    // Whether to reload after deletion
    const { reconciliationId, reconciliationIdToDelete } = this.state;
    const reconciliationIdToReload =
      reconciliationId === reconciliationIdToDelete ? null : reconciliationId;
    // Delete
    doHttpDelete({
      url: reconciliationUrl,
      params: {
        id: reconciliationIdToDelete,
      },
      onSuccess: (response) => {
        // Close confirmation
        this.closeReconciliationConfirmation();
        // Refresh
        this.editReconciliation(reconciliationIdToReload);
        // Notification
        this.reconciliationDeleteNotification(response.success);
        // Clear checkboxes
        document
          .querySelectorAll("input.reconciliation-tx-cbx")
          .forEach((checkbox) => (checkbox.checked = false));
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  reconciliationDeleteNotification = (success) => {
    // Notification
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title: "Reconciliation Deleted",
      message:
        "Reconciliation of {" +
        this.state.reconciliationDeleteDate +
        "} was " +
        (success ? "" : "not ") +
        "deleted successfully",
      level: success ? "success" : "error",
      position: "br",
      autoDismiss: 5,
    });
  };

  render() {
    // No ready to render
    if (!this.state.financeId) return null;
    // Statement form
    const {
      canHaveFees,
      canHaveInterest,
      canHaveRewards,
      canHaveInvestments,
      statementDate,
      statementBalance,
      previousStatementBalance,
      totalFeesAmt,
      totalInterestAmt,
      transactions,
      totalTransactionAmt,
      totalReconciledAmt,
      totalTransactions,
      totalReconciled,
      previousStatements,
      reconciliationId,
      rewardType,
      collegeInfo,
      retirement401kInfo,
      retirementStandardInfo,
      creditFinance,
    } = this.state;

    // Flags
    const hasCollegeInfo = collegeInfo !== null;
    const isRetirement401k = retirement401kInfo !== null;
    const isRetirementStandard = retirementStandardInfo !== null;

    // Unreconciled
    const unreconciledAmt =
      previousStatementBalance -
      statementBalance +
      totalFeesAmt +
      totalInterestAmt +
      totalReconciledAmt;

    // Render
    return (
      <div className="row">
        <div className="col-5">
          <div className="reconciliation-title">
            <FontAwesomeIcon icon={faTasks} style={{ marginRight: "8px" }} />
            {fmtDateFullMonth(statementDate)}
          </div>
          <Formik
            initialValues={{
              statementDate,
              statementBalance,
            }}
            validate={this.statementInfo}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <table className="table table-sm tbl-form">
                  <tbody>
                    <tr>
                      <td className="field-label">Statement Date:</td>
                      <td className="field-item">
                        <DateSelector
                          className="form-control inline-field"
                          name="statementDate"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Statement Balance:</td>
                      <td className="field-item">
                        <Field
                          type="text"
                          name="statementBalance"
                          className="form-control"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        className="field-item instruction text-right"
                      >
                        Use a negative amount for outstanding credit card
                        balances or bank accounts in the red (with negative
                        balances).
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label">Unreconciled Amount:</td>
                      <td className="field-item text-right">
                        <Money
                          amount={unreconciledAmt}
                          className="unreconciled-amt"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="field-label"></td>
                      <td className="field-item text-right">
                        <button
                          type="button"
                          className="btn btn-md btn-primary"
                          onClick={this.saveReconciliation}
                        >
                          Save Reconciliation
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            )}
          </Formik>
          <Accordion defaultActiveKey="(none)">
            {canHaveFees && (
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="fees"
                >
                  FEES ({fmtMoneyUSD(this.state.totalFeesAmt)})
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="fees">
                  <Card.Body>
                    <Fees />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {canHaveInterest && (
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="interest"
                >
                  INTEREST ({fmtMoneyUSD(this.state.totalInterestAmt)})
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="interest">
                  <Card.Body>
                    <Interest />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {canHaveRewards && (
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="rewards"
                >
                  REWARDS ({this.state.totalRewards})
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="rewards">
                  <Card.Body>
                    <Rewards />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {canHaveInvestments && (
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="investments"
                >
                  INVESTMENTS ({fmtMoneyUSD(this.state.totalInvestments)})
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="investments">
                  <Card.Body>
                    <Investments />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {hasCollegeInfo && (
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="college"
                >
                  COLLEGE INFO
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="college">
                  <Card.Body>
                    <CollegeInfo />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {isRetirement401k && (
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="retirement401k"
                >
                  401(K) RETIREMENT INFO
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="retirement401k">
                  <Card.Body>
                    <Retirement401k />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {isRetirementStandard && (
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="retirementStd"
                >
                  RETIREMENT INFO
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="retirementStd">
                  <Card.Body>
                    <RetirementStandard />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
          </Accordion>
        </div>
        <div className="col-7">
          <Tabs defaultActiveKey="transactions-tab" id="reconciliation-tabs">
            <Tab eventKey="transactions-tab" title="Transactions">
              <div className="text-right" style={{ marginBottom: "5px" }}>
                <button
                  className="btn btn-sm btn-success"
                  onClick={this.reloadTransactions}
                >
                  <FontAwesomeIcon
                    icon={faSync}
                    style={{ marginRight: "8px" }}
                  />
                  Reload Transactions
                </button>
              </div>
              <table
                className="table table-md table-fixed tbl-reconciliations"
                style={{ borderBottom: "1px solid gray" }}
              >
                <thead className="thead-dark">
                  <tr>
                    <th
                      scope="col"
                      style={{ width: 50, textAlign: "center" }}
                    ></th>
                    <th scope="col" style={{ width: 120 }}>
                      Date
                    </th>
                    <th
                      scope="col"
                      className="text-right"
                      style={{ width: 100 }}
                    >
                      Amount
                    </th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody style={{ maxHeight: 500 }}>
                  {transactions.map((t) => (
                    <tr
                      key={"tx-" + t.batchNumber}
                      className={classnames("", {
                        reconciled: t.reconciled,
                        changed: t.changed,
                      })}
                    >
                      <td style={{ width: 50, textAlign: "center" }}>
                        <input
                          name={"tx-" + t.batchNumber}
                          type="checkbox"
                          className="reconciliation-tx-cbx"
                          defaultValue={t.batchNumber}
                          defaultChecked={t.reconciled}
                          onChange={this.selectTransaction}
                        />
                      </td>
                      <td style={{ width: 120, fontSize: "1.1em" }}>
                        {t.date}
                      </td>
                      <td
                        className="text-right"
                        style={{ width: 100, fontSize: "1.1em" }}
                      >
                        <Money amount={t.amount} />
                      </td>
                      <td>
                        <div style={{ fontWeight: "bold", paddingBottom: 5 }}>
                          {t.party}
                        </div>
                        <div className="description">{t.description}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="table-footer">
                  <tr>
                    <td colSpan={4}>
                      {totalReconciled + " Reconciled"} (
                      {fmtMoneyUSD(totalReconciledAmt)})
                      <span style={{ margin: "0px 10px", fontWeight: "bold" }}>
                        of
                      </span>
                      {pluralize(totalTransactions, "Transaction")} (
                      {fmtMoneyUSD(totalTransactionAmt)})
                    </td>
                  </tr>
                </tfoot>
              </table>
            </Tab>
            <Tab
              eventKey="previous-tab"
              title={
                "Previous Reconciliations (" + previousStatements.length + ")"
              }
            >
              {isRetirement401k ? (
                <PreviousStatements401kRetirement
                  statements={previousStatements}
                  reconciliationId={reconciliationId}
                  editReconciliation={this.editReconciliation}
                  confirmReconciliationDelete={this.confirmReconciliationDelete}
                />
              ) : isRetirementStandard ? (
                <PreviousStatementsStdRetirement
                  statements={previousStatements}
                  reconciliationId={reconciliationId}
                  editReconciliation={this.editReconciliation}
                  confirmReconciliationDelete={this.confirmReconciliationDelete}
                />
              ) : hasCollegeInfo ? (
                <PreviousStatementsCollege
                  statements={previousStatements}
                  reconciliationId={reconciliationId}
                  editReconciliation={this.editReconciliation}
                  confirmReconciliationDelete={this.confirmReconciliationDelete}
                />
              ) : creditFinance ? (
                <PreviousStatementsCredit
                  statements={previousStatements}
                  reconciliationId={reconciliationId}
                  rewardType={rewardType}
                  editReconciliation={this.editReconciliation}
                  confirmReconciliationDelete={this.confirmReconciliationDelete}
                />
              ) : canHaveInvestments ? (
                <PreviousStatementsInvestment
                  statements={previousStatements}
                  reconciliationId={reconciliationId}
                  editReconciliation={this.editReconciliation}
                  confirmReconciliationDelete={this.confirmReconciliationDelete}
                />
              ) : (
                <PreviousStatementsGeneral
                  statements={previousStatements}
                  reconciliationId={reconciliationId}
                  editReconciliation={this.editReconciliation}
                  confirmReconciliationDelete={this.confirmReconciliationDelete}
                />
              )}
            </Tab>
          </Tabs>
        </div>

        {this.state.showReconciliationConfirm && (
          <ConfirmDialog
            show={this.state.showReconciliationConfirm}
            handleAction={this.deleteReconciliation}
            handleClose={this.closeReconciliationConfirmation}
            title="Delete Reconciliation?"
            message="The reconciliation will be deleted immediately, and associated fees, interest, and rewards reversed. Transactions that were reconciled for this statement will be undone (unreconciled)."
            question="Are you sure about deleting this statement reconciliation?"
            btnAction="Yes, Delete"
          />
        )}

        <NotificationSystem ref={this.notificationSystem} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const reconciliation = state.reconciliation || {};
  return {
    ...reconciliation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Statement info
    _updateStatementInfo: (balance, date) => {
      dispatch(Actions.updateReconciliationStatement(balance, date));
    },
    // Transactions
    _updateStatementTransactions: (transactions) => {
      dispatch(Actions.updateReconciliationTransactions(transactions));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountReconciliation);
