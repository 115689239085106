import React from "react";
import { fmtMoneyUSD } from "../../library/Utilities";
import classnames from "classnames";

export default function Money(props) {
  if (props.amount == null) {
    return "";
  }
  const amount = fmtMoneyUSD(props.amount, props.showSymbol, props.plusMinus);
  const cssClasses = classnames(props.className || "", {
    "positive-amount": props.amount >= 0.0,
    "negative-amount": props.amount < 0.0,
  });
  return <span className={cssClasses}>{amount}</span>;
}
