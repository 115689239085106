import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field } from "formik";
import { doHttpPost } from "../../services/WebService";
import { collegeScheduledTransferExecuteUrl } from "../../library/Urls";
import Checkbox from "../common/Checkbox";
import NumberField from "../common/NumberField";
import { fmtNumber } from "../../library/Utilities";

class ExecuteScheduledTransfer extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleClose: props.handleClose,
      handleSave: props.handleSave,
      scheduledTransferId: props.scheduledTransfer.id,
      errorMessage: null,
      actionsEnabled: true,
      actionSubmitText: "Execute Now",
      _actionSubmitText: "Execute Now",
      _submittingText: "Creating Transfer ...",
      scheduledTransfer: {
        origFinanceId: props.scheduledTransfer.fromFinanceId,
        destFinanceId: props.scheduledTransfer.toFinanceId,
        amount: fmtNumber(props.scheduledTransfer.amount, 2),
        updateSchedule: true,
        finances: [
          {
            id: props.scheduledTransfer.fromFinanceId,
            name: props.scheduledTransfer.fromFinanceName,
          },
          {
            id: props.scheduledTransfer.toFinanceId,
            name: props.scheduledTransfer.toFinanceName,
          },
        ],
      },
    };
  }

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false,
      actionSubmitText: this.state._submittingText,
    });

    // Post
    doHttpPost({
      url: collegeScheduledTransferExecuteUrl,
      body: {
        scheduledTransferId: this.state.scheduledTransferId,
        updateSchedule: values.updateSchedule,
      },
      onSuccess: (transactionId) => {
        // Handle save
        this.state.handleSave(transactionId);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
          actionSubmitText: this.state._actionSubmitText,
        });
        const httpStatus = error.response.status;
        if (httpStatus >= 400 && httpStatus <= 499) {
          const errors = error.response.data.errors;
          errors.forEach((error) => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage,
              });
            } else {
              actions.setFieldError(error.field, errorMessage);
            }
          });
        } else if (httpStatus >= 500 && httpStatus <= 599) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  render() {
    // Form field values
    const { origFinanceId, destFinanceId, amount, updateSchedule } =
      this.state.scheduledTransfer;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Execute Scheduled Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              origFinanceId,
              destFinanceId,
              amount,
              updateSchedule,
            }}
            onSubmit={this.save}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">
                    FROM Financial Account
                  </label>
                  <Field
                    className="form-control"
                    as="select"
                    name="origFinanceId"
                    disabled={true}
                  >
                    {this.state.scheduledTransfer.finances.map((finance) => (
                      <option key={finance.id} value={finance.id}>
                        {finance.name}
                      </option>
                    ))}
                  </Field>
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">TO Financial Account</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="destFinanceId"
                    disabled={true}
                  >
                    {this.state.scheduledTransfer.finances.map((finance) => (
                      <option key={finance.id} value={finance.id}>
                        {finance.name}
                      </option>
                    ))}
                  </Field>
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Transfer Amount</label>
                  <Field
                    component={NumberField}
                    allowNegative={false}
                    name="amount"
                    disabled={true}
                  ></Field>
                </fieldset>
                <fieldset className="form-group">
                  <Field
                    component={Checkbox}
                    name="updateSchedule"
                    id="updateSchedule"
                    label="Update schedule after transfer"
                  />
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.actionSubmitText}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExecuteScheduledTransfer;
