import React, { Component } from "react";
import PageNotFound from "../common/PageNotFound";
import {
  currentYearMonth,
  fullYearMonthName,
  fmtMoneyUSD,
} from "../../library/Utilities";
import { doHttpGet } from "../../services/WebService";
import {
  reportBudgetAdjustments,
  reportBudgetAdjustmentMonthsUrl,
} from "../../library/Urls";
import SelectAdjustment from "./SelectAdjustment";
import AdjustmentItems from "./AdjustmentItems";

export default class BudgetAdjustment extends Component {
  constructor(props) {
    super(props);
    // State
    this.state = {
      errorMessage: null,
      selectedMonth: props.match.params.yearMonth || currentYearMonth(),
      adjustmentMonths: [],
      adjustments: {},
      numPreviousMonths: 0,
      numCurrentMonth: 0,
      numFutureMonths: 0,
      numSavingsGoals: 0,
      numEmergencies: 0,
    };
  }

  setErrorMessage = (message) => {
    this.setState({
      errorMessage: message,
    });
  };

  getInfo = (yearMonth) => {
    doHttpGet({
      url: reportBudgetAdjustments(yearMonth),
      onSuccess: (info) => {
        if (info) {
          this.setState({
            info: info,
            selectedMonth: yearMonth,
            numPreviousMonths: info.previousMonths.length,
            numCurrentMonth: info.currentMonth.length,
            numFutureMonths: info.futureMonth.length,
            numSavingsGoals: info.savingsGoals.length,
            numEmergencies: info.emergencies.length,
            errorMessage: null,
          });
        } else {
          this.setErrorMessage(
            "There are no budget adjustments for " +
              fullYearMonthName(yearMonth)
          );
        }
      },
      onError: this.setErrorMessage,
    });
  };

  componentDidMount = (evt) => {
    // Savingsg goal ID
    if (!this.state.selectedMonth) {
      this.setState({
        errorMessage:
          "To retrieve budget adjustments, a valid month must be provided in the request URL",
      });
      return;
    }
    // Adjustment months
    doHttpGet({
      url: reportBudgetAdjustmentMonthsUrl,
      onSuccess: (months) => {
        this.setState({ adjustmentMonths: months });
        // Adjustment info
        this.getInfo(this.state.selectedMonth);
      },
      onError: this.setErrorMessage,
    });
  };

  render() {
    // Info
    const {
      info,
      errorMessage,
      numPreviousMonths,
      numCurrentMonth,
      numFutureMonths,
      numSavingsGoals,
      numEmergencies,
    } = this.state;

    if (!info) {
      if (errorMessage) {
        return (
          <PageNotFound title="Budget Adjustment" message={errorMessage} />
        );
      }
      return <div />;
    }

    // Undistribution change
    const undistributedChangeAmt =
      info.endingUndistributedAmt - info.startingUndistributedAmt;

    return (
      <div className="page-content budget-adjustment-report">
        <div className="row" style={{ marginBottom: "15px" }}>
          <div className="col report-page-title">
            Adjustments for {info.monthName}
          </div>
          <div
            className="col"
            style={{ verticalAlign: "bottom", textAlign: "right" }}
          >
            <SelectAdjustment
              month={this.state.selectedMonth}
              onSelectMonth={(evt, cmp) => {
                const selectedMonth = evt.target.value;
                if (this.state.selectedMonth !== selectedMonth) {
                  this.getInfo(selectedMonth);
                }
              }}
              adjustments={this.state.adjustmentMonths}
            />
          </div>
        </div>

        <div className="section-title">
          Previous Months ({numPreviousMonths})
        </div>
        {numPreviousMonths ? (
          <AdjustmentItems
            items={info.previousMonths}
            showMonths={true}
            showCategories={true}
          />
        ) : (
          <div className="report-no-changes">
            There are no adjustments for previous months
          </div>
        )}

        <div className="section-title">Current Month ({numCurrentMonth})</div>
        {numCurrentMonth ? (
          <AdjustmentItems
            items={info.currentMonth}
            showMonths={true}
            showCategories={true}
          />
        ) : (
          <div className="no-changes">
            There are no adjustments for the current month
          </div>
        )}

        <div className="section-title">Future Months ({numFutureMonths})</div>
        {numFutureMonths ? (
          <AdjustmentItems
            items={info.futureMonth}
            showMonths={true}
            showCategories={true}
          />
        ) : (
          <div className="no-changes">
            There are no adjustments for future months
          </div>
        )}

        <div className="section-title">Savings Goals ({numSavingsGoals})</div>
        {numSavingsGoals ? (
          <AdjustmentItems
            items={info.savingsGoals}
            showMonths={true}
            showCategories={true}
          />
        ) : (
          <div className="no-changes">
            There are no savings goals adjustments
          </div>
        )}

        <div className="section-title">Emergency Plan ({numEmergencies})</div>
        {numEmergencies ? (
          <AdjustmentItems
            items={info.emergencies}
            showMonths={true}
            showCategories={false}
          />
        ) : (
          <div className="no-changes">
            There are no emergency plan adjustments
          </div>
        )}

        <div className="section-title">Undesignated Pool</div>
        <table className="table table-striped table-md">
          <tbody>
            <tr key="before">
              <td className="col-5">
                Undesignated amount available <b>before</b> adjustments:
              </td>
              <td>{fmtMoneyUSD(info.startingUndistributedAmt)}</td>
            </tr>
            <tr key="after">
              <td className="col-5">
                Undesignated amount remaining <b>after</b> adjustments:
              </td>
              <td>{fmtMoneyUSD(info.endingUndistributedAmt)}</td>
            </tr>
            {undistributedChangeAmt < 0 && (
              <tr key="change">
                <td className="col-5">
                  Undesignated amount that was allocated to budget concerns:
                </td>
                <td>
                  <span className="negative-amount">
                    {fmtMoneyUSD(undistributedChangeAmt * -1)}
                  </span>
                </td>
              </tr>
            )}
            {undistributedChangeAmt > 0 && (
              <tr key="change">
                <td className="col-5">
                  Unallocated amount that was added to the undesignated pool:
                </td>
                <td>
                  <span className="positive-amount">
                    {fmtMoneyUSD(undistributedChangeAmt)}
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
