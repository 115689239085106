import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { budgetEditUrl, budgetSaveUrl } from "../../library/Urls";
import DateSelector from "../common/DateSelector";
import Checkbox from "../common/Checkbox";
import { isValidUsMoney } from "../../library/Utilities";
import NumberField from "../common/NumberField";

const errorFieldMappings = {
  name: "name",
  description: "description",
  amount: "amount",
  is_enabled: "enabled",
  is_bill: "bill",
  is_distribute_overbudget: "distributeOverbudget",
  category_id: "categoryId",
  start_date: "startDate",
  expire_date: "expireDate",
  schedule_type: "scheduleType",
  adjustment_type: "adjustmentType",
};

class EditBudget extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      editInfo: {
        id: props.budgetId,
        name: "",
        description: "",
        amount: 0.0,
        is_enabled: true,
        is_bill: false,
        is_distribute_overbudget: true,
        category_id: 0,
        start_date: null,
        expire_date: null,
        schedule_type: "ONLY_ONCE",
        adjustment_type: "FORFEIT",
        month_01_selected: false,
        month_02_selected: false,
        month_03_selected: false,
        month_04_selected: false,
        month_05_selected: false,
        month_06_selected: false,
        month_07_selected: false,
        month_08_selected: false,
        month_09_selected: false,
        month_10_selected: false,
        month_11_selected: false,
        month_12_selected: false,
        categories: [],
        adjustment_types: [],
        schedule_types: [],
      },
      budgetId: props.budgetId,
      errorMessage: null,
      actionsEnabled: false,
      selectedMonthLabel: "Selected",
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: budgetEditUrl(this.state.budgetId),
      onSuccess: (data) => {
        this.setState({
          editInfo: {
            ...data,
            amount: data.amount.toFixed(2),
            start_date: data.start_date ? new Date(data.start_date) : null,
            expire_date: data.expire_date ? new Date(data.expire_date) : null,
            category_id: data.category_id
              ? data.category_id
              : data.categories[0].id,
          },
          actionsEnabled: true,
          errorMessage: null,
          selectedMonthLabel: this.scheduleMonthLabel(data.schedule_type),
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false,
    });

    // Post
    doHttpPost({
      url: budgetSaveUrl,
      body: {
        ...values,
        id: this.state.budgetId,
      },
      onSuccess: (budgetId) => {
        // Handle save
        this.state.onSave(budgetId);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach((error) => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage,
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  validate = (values) => {
    const errors = {};
    // All inputs are required
    if (!values.name) {
      errors.name = "Budget name is required";
    }
    if (!values.description) {
      errors.description = "Budget description is required";
    }
    if (!values.amount) {
      errors.amount = "Amount is required";
    } else if (!isValidUsMoney(values.amount)) {
      errors.amount = "Invalid monetary amount";
    }
    // Update state
    this.setState({
      editInfo: {
        ...this.state.editInfo,
        ...values,
      },
    });
    // Errors
    return errors;
  };

  scheduleMonthLabel = (scheduleType) => {
    return scheduleType === "CUSTOM_MONTHS" ? "Selected" : "Skipped";
  };

  scheduleTypeChanged = (schedule_type) => {
    const selectedMonthLabel = this.scheduleMonthLabel(schedule_type);
    this.setState({
      editInfo: {
        ...this.state.editInfo,
        schedule_type: schedule_type,
      },
      selectedMonthLabel: selectedMonthLabel,
    });
  };

  render() {
    // Info
    const info = this.state.editInfo || {};

    // Form field values
    const {
      id,
      name,
      description,
      amount,
      is_enabled,
      is_bill,
      is_distribute_overbudget,
      category_id,
      start_date,
      expire_date,
      schedule_type,
      adjustment_type,
      month_01_selected,
      month_02_selected,
      month_03_selected,
      month_04_selected,
      month_05_selected,
      month_06_selected,
      month_07_selected,
      month_08_selected,
      month_09_selected,
      month_10_selected,
      month_11_selected,
      month_12_selected,
    } = info;

    // Associations
    const categories = info.categories || [];
    const adjustment_types = info.adjustment_types || [];
    const schedule_types = info.schedule_types || [];

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{id ? "Update" : "Create"} Budget</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              name,
              description,
              amount,
              is_enabled,
              is_bill,
              is_distribute_overbudget,
              category_id,
              start_date,
              expire_date,
              schedule_type,
              adjustment_type,
              month_01_selected,
              month_02_selected,
              month_03_selected,
              month_04_selected,
              month_05_selected,
              month_06_selected,
              month_07_selected,
              month_08_selected,
              month_09_selected,
              month_10_selected,
              month_11_selected,
              month_12_selected,
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <div className="row">
                  <div className="col-6">
                    <fieldset className="form-group required">
                      <label className="control-label">Name</label>
                      <Field
                        className="form-control"
                        type="text"
                        name="name"
                      ></Field>
                      <ErrorMessage
                        name="name"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Description</label>
                      <Field
                        className="form-control"
                        as="textarea"
                        name="description"
                      ></Field>
                      <ErrorMessage
                        name="description"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Category</label>
                      <Field
                        className="form-control"
                        as="select"
                        name="category_id"
                      >
                        {categories.map((c) => (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="category_id"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Budget amount</label>
                      <Field component={NumberField} name="amount"></Field>
                      <ErrorMessage
                        name="amount"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Start Date</label>
                      <div>
                        <DateSelector
                          className="form-control"
                          name="start_date"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <ErrorMessage
                        name="start_date"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <label className="control-label">Expiration Date</label>
                      <div>
                        <DateSelector
                          className="form-control"
                          name="expire_date"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <ErrorMessage
                        name="expire_date"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                  </div>
                  <div className="col-6">
                    <fieldset className="form-group">
                      <Field
                        component={Checkbox}
                        name="is_enabled"
                        id="is_enabled"
                        label="Budget is active"
                      />
                      <Field
                        component={Checkbox}
                        name="is_bill"
                        id="is_bill"
                        label="Budget is a bill"
                      />
                      <Field
                        component={Checkbox}
                        name="is_distribute_overbudget"
                        id="is_distribute_overbudget"
                        label="Distribute when over-budget"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">
                        Unused allocations
                      </label>
                      <Field
                        className="form-control"
                        as="select"
                        name="adjustment_type"
                      >
                        {adjustment_types.map((t) => (
                          <option key={t.id} value={t.id}>
                            {t.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="adjustment_type"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Schedule</label>
                      <Field
                        className="form-control"
                        as="select"
                        name="schedule_type"
                        onChange={(evt) =>
                          this.scheduleTypeChanged(evt.target.value)
                        }
                      >
                        {schedule_types.map((s) => (
                          <option key={s.id} value={s.id}>
                            {s.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="schedule_type"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <label className="control-label">
                        {this.state.selectedMonthLabel} Months
                      </label>
                      <div className="row">
                        <div className="col-6">
                          <Field
                            component={Checkbox}
                            name="month_01_selected"
                            id="month_01_selected"
                            label="January"
                          />
                          <Field
                            component={Checkbox}
                            name="month_02_selected"
                            id="month_02_selected"
                            label="February"
                          />
                          <Field
                            component={Checkbox}
                            name="month_03_selected"
                            id="month_03_selected"
                            label="March"
                          />
                          <Field
                            component={Checkbox}
                            name="month_04_selected"
                            id="month_04_selected"
                            label="April"
                          />
                          <Field
                            component={Checkbox}
                            name="month_05_selected"
                            id="month_05_selected"
                            label="May"
                          />
                          <Field
                            component={Checkbox}
                            name="month_06_selected"
                            id="month_06_selected"
                            label="June"
                          />
                        </div>
                        <div className="col-6">
                          <Field
                            component={Checkbox}
                            name="month_07_selected"
                            id="month_07_selected"
                            label="July"
                          />
                          <Field
                            component={Checkbox}
                            name="month_08_selected"
                            id="month_08_selected"
                            label="August"
                          />
                          <Field
                            component={Checkbox}
                            name="month_09_selected"
                            id="month_09_selected"
                            label="September"
                          />
                          <Field
                            component={Checkbox}
                            name="month_10_selected"
                            id="month_10_selected"
                            label="October"
                          />
                          <Field
                            component={Checkbox}
                            name="month_11_selected"
                            id="month_11_selected"
                            label="November"
                          />
                          <Field
                            component={Checkbox}
                            name="month_12_selected"
                            id="month_12_selected"
                            label="December"
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.budgetId ? "Update" : "Create"}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditBudget;
