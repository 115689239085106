import React, { Component, Fragment } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { financeInfoUrl, financeDeleteUrl } from "../../library/Urls";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import TransactionsPanel from "../transactions/TransactionsPanel";
import Designations from "./Designations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Money from "../common/Money";
import * as Links from "../../library/Links";
import ConfirmDialog from "../common/ConfirmDialog";
import {
  fmtDate,
  fmtMoneyUSD,
  fmtInterestRate,
  fmtNumeric,
} from "../../library/Utilities";
import { Link } from "react-router-dom";
import FinanceDescription from "./FinanceDescription";
import EditCashAccount from "../finance/EditCashAccount";
import EditCheckingAccount from "../finance/EditCheckingAccount";
import EditSavingsAccount from "../finance/EditSavingsAccount";
import EditCreditAccount from "../finance/EditCreditAccount";
import EditInvestmentAccount from "../finance/EditInvestmentAccount";
import FinanceStocks from "./FinanceStocks";
import EditFinancialAccount from "../retirement/EditFinancialAccount";
import { connect } from "react-redux";
import EditCollegeAccount from "../college/EditCollegeAccount";

const FinanceTypes = {
  Credit: "CREDIT",
  Checking: "CHECKING",
  Savings: "SAVINGS",
  Investment: "INVESTMENT",
  Cash: "CASH",
  Retirement: "RETIREMENT",
  College: "COLLEGE",
};

class FinancialAccount extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      info: null,
      financeId: props.match.params.financeId,
      accountName: null,
      confirmDelete: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const oldFinanceId = prevState.financeId;
    const newFinanceId = nextProps.match.params.financeId;
    if (oldFinanceId && newFinanceId && oldFinanceId !== newFinanceId) {
      window.location.reload();
    }
    return null;
  }

  getInfo = () => {
    doHttpGet({
      url: financeInfoUrl(this.state.financeId),
      onSuccess: (info) => {
        this.setState({
          info: info,
          errorMessage: null,
          accountName: info.name,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  componentDidMount = (evt) => {
    // Account info
    this.getInfo(null);
  };

  confirmDelete = (show) => {
    this.setState({
      confirmDelete: show,
    });
  };

  onConfirmDelete = () => {
    // Delete account
    doHttpDelete({
      url: financeDeleteUrl(this.state.financeId),
      onSuccess: () => {
        // Close confirmation dialog
        this.confirmDelete(false);
        // Navigate to finances list
        var destinationLink = Links.finances;
        if (this.state.info.type === "RETIREMENT") {
          destinationLink = Links.retirement;
        } else if (this.state.info.type === "COLLEGE") {
          destinationLink = Links.college;
        }
        this.props.history.push(destinationLink);
      },
      onError: (error) => {
        this.props._handleError(error);
      },
    });
  };

  editFinance = () => {
    const financeType = this.state.info.type;
    this.setState({
      showCashEditor: financeType === FinanceTypes.Cash,
      showCheckingEditor: financeType === FinanceTypes.Checking,
      showSavingsEditor: financeType === FinanceTypes.Savings,
      showInvestmentEditor: financeType === FinanceTypes.Investment,
      showCreditEditor: financeType === FinanceTypes.Credit,
      showRetirementEditor: financeType === FinanceTypes.Retirement,
      showCollegeEditor: financeType === FinanceTypes.College,
    });
  };

  showCashEditor = (show) => {
    this.setState({ showCashEditor: show });
  };

  onEditCashAccount = (financeId) => {
    this.showCashEditor(false);
    this.getInfo();
  };

  showCheckingEditor = (show) => {
    this.setState({ showCheckingEditor: show });
  };

  onEditCheckingAccount = (financeId) => {
    this.showCheckingEditor(false);
    this.getInfo();
  };

  showSavingsEditor = (show) => {
    this.setState({ showSavingsEditor: show });
  };

  onEditSavingsAccount = (financeId) => {
    this.showSavingsEditor(false);
    this.getInfo();
  };

  showCreditEditor = (show) => {
    this.setState({ showCreditEditor: show });
  };

  onEditCreditAccount = (financeId) => {
    this.showCreditEditor(false);
    this.getInfo();
  };

  showInvestmentEditor = (show) => {
    this.setState({ showInvestmentEditor: show });
  };

  onEditInvestmentAccount = (financeId) => {
    this.showInvestmentEditor(false);
    this.getInfo();
  };

  showRetirementEditor = (show) => {
    this.setState({ showRetirementEditor: show });
  };

  onEditRetirementAccount = (financeId) => {
    this.showRetirementEditor(false);
    this.getInfo();
  };

  showCollegeEditor = (show) => {
    this.setState({ showCollegeEditor: show });
  };

  onEditCollegeAccount = (financeId) => {
    this.showCollegeEditor(false);
    this.getInfo();
  };

  accountDescription = (info) => {
    // Reconciliation
    const showReconciliation = info.is_reconcilable && info.type !== "CASH";
    let reconciliationMsg = "Never";
    if (showReconciliation && info.reconciliation_date) {
      reconciliationMsg =
        fmtMoneyUSD(info.reconciled_amount) +
        " as of " +
        fmtDate(info.reconciliation_date);
    }

    // Interest rate
    const interestRt = fmtInterestRate(info.interest_rate);

    // Credit
    const isCredit = info.type === "CREDIT";
    let nextPayment = null,
      rewardBalance = null,
      expiration = null;
    if (isCredit) {
      if (info.payment_date) {
        nextPayment =
          fmtMoneyUSD(info.payment_amount) +
          " on " +
          fmtDate(info.payment_date);
      } else {
        nextPayment = "None Scheduled";
      }
      rewardBalance =
        (info.reward_type === "Cash"
          ? fmtMoneyUSD(info.payment_amount)
          : fmtNumeric(info.payment_amount)) +
        " " +
        info.reward_type;
      expiration =
        (info.expiration_month < 10 ? "0" : "") +
        info.expiration_month +
        "/" +
        info.expiration_year;
    }

    // Description
    return (
      <div className="row">
        <div className="col-8 fin-description">
          {info.description}
          <div>
            <b>Currency:</b> {info.currency}
          </div>
          {interestRt && (
            <div>
              <b>Interest Rate:</b> {interestRt} APR
            </div>
          )}
          {showReconciliation && (
            <div>
              <b>Reconciled:</b> {reconciliationMsg}
            </div>
          )}
          {isCredit && (
            <Fragment>
              <div>
                <b>Next Payment:</b> {nextPayment}
              </div>
              <div>
                <b>Reward Balance:</b> {rewardBalance}
              </div>
              <div>
                <b>Expiration:</b> {expiration}
              </div>
            </Fragment>
          )}
        </div>
        <div className="col-4">
          {info.is_editable && (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={this.editFinance}
              style={{ marginRight: "10px" }}
            >
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
          )}
          {info.is_deletable && (
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() => this.confirmDelete(true)}
            >
              <FontAwesomeIcon icon={faTrash} /> Delete
            </button>
          )}
          {showReconciliation && (
            <div style={{ marginTop: "5px" }}>
              <Link
                to={Links.reconcile(this.state.financeId)}
                className="btn btn-link btn-sm"
              >
                Reconciliation
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    // Info
    const { info } = this.state;
    if (!info) {
      return <div />;
    }

    // Account type
    const isCredit = info.type === "CREDIT";
    const isInvestment = info.type === "INVESTMENT";
    const isRetirement = info.type === "RETIREMENT";

    // Render
    return (
      <div className="page-content">
        {this.props.errorMessage && (
          <div
            className="alert alert-danger"
            style={{
              marginBottom: "10px",
              fontWeight: "bold",
              fontSize: "1.2em",
              padding: "15px",
            }}
          >
            {this.props.errorMessage}
          </div>
        )}
        <div className="row">
          <div className="col-3">
            <h2>{info.name}</h2>
          </div>
          <div className="col-6">
            <FinanceDescription
              info={info}
              editFinance={this.editFinance}
              confirmDelete={this.confirmDelete}
            />
          </div>
          <div className="col-3 text-right">
            <Money amount={info.balance} className="amount-lg" />
            {isCredit && (
              <div className="fin-descriptions">
                (Credit Limit: {fmtMoneyUSD(info.credit_limit)})
              </div>
            )}
          </div>
        </div>

        <Tabs defaultActiveKey="transactions" id="financial-account-tab">
          <Tab eventKey="transactions" title="Transactions">
            <TransactionsPanel
              pageSize={50}
              financeId={this.state.financeId}
              isInvestmentAccount={isInvestment}
              isRetirementAccount={isRetirement}
              isForTransactions={info.for_transactions}
              fxReloadFinance={this.getInfo}
            />
          </Tab>
          <Tab
            eventKey="designations"
            title={"Designations (" + info.designations.length + ")"}
          >
            <Designations
              designations={info.designations}
              financeId={this.state.financeId}
              refreshDesignationInfo={() => this.getInfo(null)}
            />
          </Tab>
          {(isInvestment || isRetirement) && (
            <Tab
              eventKey="stocks"
              title={"Stocks (" + info.stocks.length + ")"}
            >
              <FinanceStocks
                stocks={info.stocks}
                totalCost={info.total_stock_cost}
                totalEquity={info.total_stock_equity}
                totalDividends={info.total_stock_dividends}
                totalChange={info.total_stock_change}
              />
            </Tab>
          )}
        </Tabs>

        {this.state.confirmDelete && (
          <ConfirmDialog
            show={this.state.confirmDelete}
            handleAction={this.onConfirmDelete}
            handleClose={() => this.confirmDelete(false)}
            title="Delete Account?"
            message="The financial account will be deleted immediately, and will not be available for use in transactions and budgeting thereafter."
            question="Are you sure about deleting this financial account?"
            btnAction="Yes, Delete"
          />
        )}

        {this.state.showCashEditor && (
          <EditCashAccount
            show={this.state.showCashEditor}
            handleSave={this.onEditCashAccount}
            handleClose={() => this.showCashEditor(false)}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showCheckingEditor && (
          <EditCheckingAccount
            show={this.state.showCheckingEditor}
            handleSave={this.onEditCheckingAccount}
            handleClose={() => this.showCheckingEditor(false)}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showSavingsEditor && (
          <EditSavingsAccount
            show={this.state.showSavingsEditor}
            handleSave={this.onEditSavingsAccount}
            handleClose={() => this.showSavingsEditor(false)}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showCreditEditor && (
          <EditCreditAccount
            show={this.state.showCreditEditor}
            handleSave={this.onEditCreditAccount}
            handleClose={() => this.showCreditEditor(false)}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showInvestmentEditor && (
          <EditInvestmentAccount
            show={this.state.showInvestmentEditor}
            handleSave={this.onEditInvestmentAccount}
            handleClose={() => this.showInvestmentEditor(false)}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showRetirementEditor && (
          <EditFinancialAccount
            show={this.state.showRetirementEditor}
            handleSave={this.onEditRetirementAccount}
            handleClose={() => this.showRetirementEditor(false)}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showCollegeEditor && (
          <EditCollegeAccount
            show={this.state.showCollegeEditor}
            handleSave={this.onEditCollegeAccount}
            handleClose={() => this.showCollegeEditor(false)}
            financeId={this.state.financeId}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errorMessage: state.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialAccount);
