import React, { Component, Fragment } from "react";
import moment from "moment";
import { doHttpGet } from "../../services/WebService";
import { adminAccountsUrl } from "../../library/Urls";
import EditAccount from "./EditAccount";

class Accounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      accounts: [],
      showAccountEditor: false,
      accountId: null,
      selectedId: null,
    };
  }

  updateAccountsListing = () => {
    // List of accounts
    doHttpGet({
      url: adminAccountsUrl,
      onSuccess: (data) => {
        this.setState({
          errorMessage: null,
          accounts: data,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  componentDidMount = (evt) => {
    this.updateAccountsListing();
  };

  editAccount = (accountId) => {
    this.setState({
      accountId: accountId,
      showAccountEditor: true,
    });
  };

  closeAccountEditor = (accountId) => {
    this.setState({
      accountId: accountId,
      showAccountEditor: false,
    });
  };

  onAccountSave = () => {
    // Close editor
    this.closeAccountEditor(this.state.accountId);
    // Update account listing
    this.updateAccountsListing();
  };

  render() {
    return (
      <Fragment>
        <h3>All Accounts ({this.state.accounts.length})</h3>
        {this.state.errorMessage && (
          <div className="alert alert-danger">{this.state.errorMessage}</div>
        )}
        <table className="table table-striped table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Created</th>
              <th scope="col">Zip Code</th>
              <th scope="col">Subscription</th>
              <th scope="col">Status</th>
              <th scope="col">Deletion</th>
              <th scope="col">User Count</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.accounts.map((account) => (
              <tr
                key={"account-" + account.id}
                className={
                  this.state.accountId === account.id ? "updated-row" : ""
                }
              >
                <td>{account.id}</td>
                <td>{moment(account.date_created).format("MMM-DD, YYYY")}</td>
                <td>{account.zip_code}</td>
                <td>{account.subscription}</td>
                <td>{account.status}</td>
                <td>
                  {account.date_deletion
                    ? moment(account.date_deletion).format("MMM-DD, YYYY")
                    : ""}
                </td>
                <td>{account.user_count}</td>
                <td style={{ width: "60px", textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editAccount(account.id)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Modal for editing accounts */}
        {this.state.showAccountEditor && (
          <EditAccount
            show={this.state.showAccountEditor}
            handleClose={this.closeAccountEditor}
            handleSave={this.onAccountSave}
            accountId={this.state.accountId}
          />
        )}
      </Fragment>
    );
  }
}

export default Accounts;
