import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import { doHttpPost } from "../../services/WebService";
import { featureActionUrl } from "../../library/Urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faThumbsUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../common/ConfirmDialog";

class AccountFeaturesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmDialog: false,
    };
  }

  featureAction = (feature, action) => {
    doHttpPost({
      url: featureActionUrl,
      body: {
        feature: feature,
        action: action,
      },
      onSuccess: (d) => this.props._updateSettings(d),
      onError: (e) => this.props._handleError(e),
    });
  };

  openConfirmDialog = (id, action) => {
    this.setState({
      showConfirmDialog: true,
      selectedFeatureId: id,
      selectedFeatureAction: action,
    });
  };

  removeFeature = () => {
    const id = this.state.selectedFeatureId;
    const action = this.state.selectedFeatureAction;
    this.featureAction(id, action);
    this.closeConfirmDialog();
    this.props._removeOption(id);
  };

  closeConfirmDialog = () => {
    this.setState({
      showConfirmDialog: false,
    });
  };

  render() {
    // Features
    const features = this.props.features;
    if (!features) return null;
    // Render
    return (
      <Fragment>
        <h5>
          Features ({features.numberActive} Active, {features.numberAvailable}{" "}
          Available, {features.numberPending} Pending)
        </h5>
        <table className="table table-md table-striped">
          <thead>
            <tr>
              <th scope="col" style={{ minWidth: "150px" }}>
                Name
              </th>
              <th scope="col">Description</th>
              <th scope="col" style={{ minWidth: "200px" }}>
                Status
              </th>
              <th scope="col" style={{ minWidth: "120px" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {features.options.map((feature) => {
              // Render
              return (
                <tr key={"f-" + feature.id}>
                  <td>{feature.name}</td>
                  <td style={{ paddingRight: "25px" }}>
                    {feature.description}
                  </td>
                  <td>{feature.status.description}</td>
                  <td>
                    {feature.status.action === "REQUEST" && (
                      <button
                        type="button"
                        className="btn btn-md btn-success"
                        onClick={() =>
                          this.featureAction(feature.id, feature.status.action)
                        }
                      >
                        <FontAwesomeIcon icon={faThumbsUp} /> &nbsp; Request
                      </button>
                    )}
                    {feature.status.action === "CANCEL" && (
                      <button
                        type="button"
                        className="btn btn-md btn-primary"
                        onClick={() =>
                          this.featureAction(feature.id, feature.status.action)
                        }
                      >
                        <FontAwesomeIcon icon={faBan} /> &nbsp; Cancel
                      </button>
                    )}
                    {feature.status.action === "REMOVE" && (
                      <button
                        type="button"
                        className="btn btn-md btn-danger"
                        onClick={() =>
                          this.openConfirmDialog(
                            feature.id,
                            feature.status.action
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faTrash} /> &nbsp; Remove
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {this.state.showConfirmDialog && (
          <ConfirmDialog
            show={this.state.showConfirmDialog}
            handleAction={this.removeFeature}
            handleClose={this.closeConfirmDialog}
            title="Remove Account Feature?"
            message={
              "Feature <b>" +
              this.state.selectedFeatureId +
              "</b> will be disabled in this account immediately." +
              "<br/>You will no longer be able to do much with the feature, " +
              "and some of your data might no longer be accessible fully, until the feature is restored."
            }
            question="Are you sure you want to remove the feature from your account?"
            btnAction="Yes, Remove"
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    features: state.accountSettings.features,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Errors
    _handleError: (message) => {
      dispatch(Actions.setErrorMessage(message));
    },
    // Settings
    _updateSettings: (settings) => {
      dispatch(Actions.setSettings(settings));
    },
    // Options
    _removeOption: (action) => {
      dispatch(Actions.removeOption(action));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountFeaturesTab);
