import React, { Component } from "react";
import Accounts from "../admin/Accounts";
import Registrations from "../admin/Registrations";
import { Tabs, Tab } from "react-bootstrap";
import SystemSettings from "../admin/SystemSettings";
import FeatureActions from "../admin/FeatureActions";
import EmailSettings from "../admin/EmailSettings";
import Schedules from "../admin/Schedules";
import StockManagement from "../admin/StockManagement";

class AdminDashboard extends Component {
  render() {
    return (
      <div className="page-content">
        <h2>Admin Dashboard</h2>

        <Tabs defaultActiveKey="accounts" id="admin-dashboard-tab">
          <Tab eventKey="accounts" title="Accounts">
            <Accounts />
          </Tab>
          <Tab eventKey="registrations" title="Registrations">
            <Registrations />
          </Tab>
          <Tab eventKey="features" title="Feature Actions">
            <FeatureActions />
          </Tab>
          <Tab eventKey="settings" title="System Settings">
            <SystemSettings />
          </Tab>
          <Tab eventKey="email" title="Email Settings & Test">
            <EmailSettings />
          </Tab>
          <Tab eventKey="schedules" title="Schedules">
            <Schedules />
          </Tab>
          <Tab eventKey="stocks" title="Stock Management">
            <StockManagement />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
export default AdminDashboard;
