import React from "react";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

export default function CategorySavingsGoals(props) {
  // Savings goals
  const savings_goals = props.savings_goals || [];

  // Render
  return (
    <div>
      <table className="table table-bordered table-striped table-lg gzn-table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col" className="gzn-col-amount">
              Goal
            </th>
            <th scope="col" className="gzn-col-check">
              Status
            </th>
            <th scope="col" className="gzn-col-date">
              Start Date
            </th>
            <th scope="col" className="gzn-col-date">
              Close Date
            </th>
          </tr>
        </thead>
        <tbody>
          {savings_goals.map(sg => (
            <tr key={"b-" + sg.id}>
              <th scope="row">
                <Link to={Links.savingsGoalFor(sg.id)}>{sg.name}</Link>
              </th>
              <td>{sg.description}</td>
              <td className="gzn-col-amount">{fmtMoneyUSD(sg.goal)}</td>
              <td className="gzn-col-check">{sg.status}</td>
              <td className="gzn-col-date">{fmtDate(sg.start_date)}</td>
              <td className="gzn-col-date">{fmtDate(sg.close_date)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
