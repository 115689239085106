import React, { Component, Fragment } from "react";
import moment from "moment";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import {
  adminRegistrationsUrl,
  adminRegistrationDeleteUrl,
} from "../../library/Urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../common/ConfirmDialog";

class Registrations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      registrations: [],
      confirmDelete: false,
    };
  }

  getRegistrations = () => {
    // List of registrations
    doHttpGet({
      url: adminRegistrationsUrl,
      onSuccess: (data) => {
        this.setState({
          errorMessage: null,
          registrations: data,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  componentDidMount = (evt) => {
    this.getRegistrations();
  };

  confirmDelete = (show) => {
    this.setState({
      confirmDelete: show,
    });
  };

  deleteRegistration = () => {
    // Delete
    doHttpDelete({
      url: adminRegistrationDeleteUrl(this.state.registrationId),
      onSuccess: (data) => {
        // Close editor
        this.confirmDelete(false);
        // Update registrations
        this.getRegistrations();
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  confirmDeletion = (registrationId) => {
    this.setState({
      registrationId: registrationId,
    });
    this.confirmDelete(true);
  };

  render() {
    return (
      <Fragment>
        <h3>All registrations ({this.state.registrations.length})</h3>
        {this.state.errorMessage && (
          <div className="alert alert-danger">{this.state.errorMessage}</div>
        )}

        <table className="table table-striped table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Created</th>
              <th scope="col">Username</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email Address</th>
              <th scope="col">Zip Code</th>
              <th scope="col">Deletion Date</th>
              <th scope="col">Activation Code</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.registrations.map((reg) => (
              <tr key={"account-" + reg.id}>
                <td>{moment(reg.date_created).format("MMM-DD, YYYY")}</td>
                <td>{reg.username}</td>
                <td>{reg.first_name}</td>
                <td>{reg.last_name}</td>
                <td>{reg.email_address}</td>
                <td>{reg.zip_code}</td>
                <td>{moment(reg.date_deletion).format("MMM-DD, YYYY")}</td>
                <td>{reg.activation_code}</td>
                <td style={{ width: "60px", textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => this.confirmDeletion(reg.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {this.state.confirmDelete && (
          <ConfirmDialog
            show={this.state.confirmDelete}
            handleAction={this.deleteRegistration}
            handleClose={() => this.confirmDelete(false)}
            title="Delete Registration?"
            message={
              "The registration will be deleted immediately, and whoever created it will not be able to create an account."
            }
            question="Are you sure you want to delete the registration?"
            btnAction="Yes, Delete"
          />
        )}
      </Fragment>
    );
  }
}

export default Registrations;
