import React, { useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { doHttpGet } from "../../services/WebService";
import { transactionSearchPartiesUrl } from "../../library/Urls";
import { pause } from "../../library/Utilities";

const selectOption = (o) => {
  if (o) return { value: o, label: o };
  return null;
};

const mergePartiesIntoOptions = (incomingResults, currentOptions) => {
  // Sort the options
  const options = [];
  // Mappings for uniqueness
  const map = new Map();
  // Function to add options
  const addOptions = (items) => {
    for (const item of items) {
      if (!map.has(item.value)) {
        map.set(item.value, true);
        options.push(item);
      }
    }
  };
  // Add options
  addOptions(currentOptions);
  addOptions(incomingResults);
  // Sort the options
  options.sort((a, b) => {
    if (a.value < b.value) return -1;
    else if (a.value === b.value) return 0;
    else return 1;
  });
  // Return
  return options;
};

export default function FormikCreateFilterSelect({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  label,
  className,
  style,
  optionsList,
  ...props
}) {
  // Options
  const selectOptions = [];
  let valueInOptions = false;
  optionsList.forEach((option) => {
    selectOptions.push(selectOption(option));
    valueInOptions |= option === value;
  });

  // Selected
  const selectedValue = selectOption(value);
  if (selectedValue && !valueInOptions) {
    selectOptions.push(selectedValue);
  }

  // State
  const [options, setOptions] = useState(selectOptions);
  const [timestamp, setTimestamp] = useState(new Date().getTime());

  const handleChange = (info) => {
    const selection = info ? info.value : null;
    onChange({
      target: {
        name: name,
        value: selection,
      },
    });
    if (info && info.__isNew__) {
      const _options = [...options];
      delete info.__isNew__;
      _options.push(info);
      setOptions(_options);
    }
  };

  const filterOptions = async (value) => {
    const matchingOptions = options.filter((o) =>
      o.value.toLowerCase().includes(value.toLowerCase())
    );
    if (matchingOptions.length) {
      return matchingOptions;
    } else {
      // Time lapse
      const currentTime = new Date().getTime();
      const timeElapsed = currentTime - timestamp;

      // Must have at least 2 characters
      if (value.length <= 2 || timeElapsed <= 2000) {
        return [];
      }

      // Search
      doHttpGet({
        url: transactionSearchPartiesUrl,
        params: {
          kw: value,
        },
        onSuccess: (results) => {
          // Results
          results.forEach((party) => {
            matchingOptions.push(selectOption(party));
          });
          // Merge
          const _options = mergePartiesIntoOptions(matchingOptions, options);
          setOptions(_options);
          // Conclude
          setTimestamp(currentTime);
        },
      });

      // Wait for results
      pause(2);

      // Response
      return matchingOptions;
    }
  };

  const findMatchingOptions = (value) =>
    new Promise((resolve) => resolve(filterOptions(value)));

  return (
    <AsyncCreatableSelect
      name={name}
      value={selectedValue}
      isClearable
      cacheOptions
      defaultOptions
      onChange={handleChange}
      loadOptions={findMatchingOptions}
      options={options}
    />
  );
}
