import React, { useState, useEffect } from "react";
import { doHttpGet } from "../../services/WebService";
import { adminStockSettingsUrl } from "../../library/Urls";
import StockTickerUpdate from "./StockTickerUpdate";
import StockSplitCard from "./StockSplitCard";
import StockManagementPriceSettings from "./StockManagementPriceSettings";

const StockManagement = () => {
  // State
  const [stockSettings, setStockSettings] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Get stock settings
  const getStockSettings = () => {
    doHttpGet({
      url: adminStockSettingsUrl,
      onSuccess: (settings) => {
        setErrorMessage(null);
        setStockSettings(settings);
      },
      onError: (error) => setErrorMessage(error),
    });
  };

  // Changes
  useEffect(() => getStockSettings(), []);

  // Render
  return (
    <div className="page-content">
      {errorMessage && (
        <div
          className="alert alert-danger"
          style={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "1.2em",
            padding: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}

      {stockSettings && (
        <div className="container">
          <div className="row">
            <div className="col">
              <StockManagementPriceSettings
                priceSettings={stockSettings.stockPriceSettings}
                setErrorMessage={setErrorMessage}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <StockTickerUpdate
                stocksList={stockSettings.stockListing}
                setErrorMessage={setErrorMessage}
                refreshStockSettings={() => getStockSettings()}
              />
            </div>
            <div className="col">
              <StockSplitCard
                stocksList={stockSettings.stockListing}
                setErrorMessage={setErrorMessage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StockManagement;
