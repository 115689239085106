import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import {
  fmtMoneyUSD,
  fmtPercent,
  fmtLong,
  pluralize,
  forPlural,
} from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import classnames from "classnames";

const UserDashboardBudget = (props) => {
  // Budgeting
  const {
    budgetedAmount,
    maximumAmt,
    allocatedAmount,
    spentAmount,
    unplannedCount,
    unplannedAmount,
    overbudgetCount,
    overbudgetAmount,
    personalBudgetAmt,
    personalBudgetLimit,
    personalBudgetId,
  } = props.budgeting || {};
  const personalBudgetOverAmt = personalBudgetAmt - personalBudgetLimit;

  // Render
  return (
    <div
      className="card"
      style={{
        width: "20rem",
        marginBottom: "3px",
        marginLeft: "3px",
      }}
    >
      <div className="card-body">
        <h5 className="card-title">
          <span style={{ marginRight: "8px" }}>
            <FontAwesomeIcon icon={faClipboardList} />
          </span>
          Budget Progress
        </h5>
      </div>
      <table className="table table-sm dashboard-budget">
        <tbody>
          <tr>
            <td
              style={{ textAlign: "right", fontWeight: "none", width: "100px" }}
            >
              Planned
            </td>
            <td style={{ verticalAlign: "middle" }}>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: fmtPercent(budgetedAmount, maximumAmt, 2),
                  }}
                  aria-valuenow={fmtLong(budgetedAmount)}
                  aria-valuemin="0"
                  aria-valuemax={fmtLong(maximumAmt)}
                ></div>
              </div>
            </td>
            <td style={{ width: "80px", color: "#007bff" }}>
              {fmtMoneyUSD(budgetedAmount)}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "right", fontWeight: "none" }}>
              Allocated
            </td>
            <td style={{ verticalAlign: "middle" }}>
              <div className="progress">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{
                    width: fmtPercent(allocatedAmount, maximumAmt, 2),
                  }}
                  aria-valuenow={fmtLong(allocatedAmount)}
                  aria-valuemin="0"
                  aria-valuemax={fmtLong(maximumAmt)}
                >
                  {fmtPercent(allocatedAmount, budgetedAmount, 1)}
                </div>
              </div>
            </td>
            <td style={{ color: "#28a745" }}>{fmtMoneyUSD(allocatedAmount)}</td>
          </tr>
          <tr>
            <td style={{ textAlign: "right", fontWeight: "none" }}>Spent</td>
            <td style={{ verticalAlign: "middle" }}>
              <div className="progress">
                <div
                  className="progress-bar bg-warning"
                  role="progressbar"
                  style={{
                    width: fmtPercent(spentAmount, maximumAmt, 2),
                  }}
                  aria-valuenow={fmtLong(spentAmount)}
                  aria-valuemin="0"
                  aria-valuemax={fmtLong(maximumAmt)}
                >
                  {fmtPercent(spentAmount, allocatedAmount, 1)}
                </div>
              </div>
            </td>
            <td style={{ color: "#ffc107" }}>{fmtMoneyUSD(spentAmount)}</td>
          </tr>
          <tr>
            <td style={{ textAlign: "right", fontWeight: "none" }}>
              Overbudget
            </td>
            <td style={{ verticalAlign: "middle" }}>
              <div className="progress">
                <div
                  className="progress-bar bg-danger"
                  role="progressbar"
                  style={{
                    width: fmtPercent(overbudgetAmount, spentAmount, 2),
                  }}
                  aria-valuenow={fmtLong(overbudgetAmount)}
                  aria-valuemin="0"
                  aria-valuemax={fmtLong(spentAmount)}
                ></div>
              </div>
            </td>
            <td style={{ color: "#dc3545" }}>
              {fmtMoneyUSD(overbudgetAmount)}
            </td>
          </tr>
        </tbody>
      </table>
      <ul className="list-group list-group-flush">
        {personalBudgetAmt >= 0.0 && (
          <li className="list-group-item">
            Your{" "}
            <Link to={Links.budgetFor(personalBudgetId)}>personal budget</Link>{" "}
            has{" "}
            <span className="positive-amount">
              {fmtMoneyUSD(personalBudgetAmt)}
            </span>{" "}
            allocated.
          </li>
        )}
        {personalBudgetOverAmt > 0.0 && (
          <li className="list-group-item">
            You have overspent your{" "}
            <Link to={Links.budgetFor(personalBudgetId)}>personal budget</Link>{" "}
            by{" "}
            <span className="negative-amount">
              {fmtMoneyUSD(personalBudgetOverAmt)}
            </span>
            .
          </li>
        )}
        <li className="list-group-item">
          {unplannedAmount < 0.0 ? (
            <Fragment>
              You have{" "}
              <span className="negative-amount">
                {fmtMoneyUSD(unplannedAmount)}
              </span>{" "}
              in unplanned spending so far this month, among{" "}
              <b> {pluralize(unplannedCount, "transaction")}</b>.
            </Fragment>
          ) : unplannedCount > 0 ? (
            <Fragment>
              There {forPlural(unplannedCount, "is", "are")}{" "}
              {pluralize(unplannedCount, "transaction")} that uses an unplanned
              categorization.
            </Fragment>
          ) : (
            <Fragment>
              There are no unplanned transactions at this point.
            </Fragment>
          )}
        </li>
        <li className="list-group-item" style={{ borderBottom: "none" }}>
          There {forPlural(overbudgetCount, "is", "are")}{" "}
          <b>{pluralize(overbudgetCount, "overbudget item")}</b> so far, adding
          up to{" "}
          <span
            className={classnames("", {
              "negative-amount": overbudgetAmount > 0.0,
            })}
          >
            {fmtMoneyUSD(overbudgetAmount)}
          </span>{" "}
          in spending.
        </li>
      </ul>
      <div className="card-footer">
        <Link to={Links.monthlyBudget}>Budgets</Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to={Links.savingsGoals}>Savings Goals</Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to={Links.monthlyBudget}>Monthly Budget</Link>
      </div>
    </div>
  );
};

export default UserDashboardBudget;
