// General
export const ErrorMessage = "error_message";
export const AccountDeletionDate = "account_deletion_message";
// Session
export const SetSession = "set_session";
export const SetSelectedUserId = "set_selected_user_id";
export const ClearStore = "clear_store";
// Settings
export const SetSettings = "set_settings";
export const FeatureOptions = "feature_options";
export const FeatureOptionsOff = "feature_options_off";
// Finance
export const FinanceSetInfo = "fin_set_info";
export const FinanceSetIncludeDisabled = "fin_set_incl_disabled";
// Emergency
export const SetEmergencyPlan = "set_emergency_plan";
// Budget
export const BudgetSetInfo = "bgt_set_info";
export const BudgetSetIncludeDisabled = "bgt_set_incl_disabled";
// Savings goal
export const SavingsGoalSetInfo = "sg_set_info";
export const SavingsGoalSetIncludeDisabled = "sg_set_incl_disabled";
// Transactions
export const TransactionInfo = "transaction_info";
export const SelectedTransaction = "selected_transaction";
// Reconciliation
export const ReconciliationInfo = "reconciliation_info";
export const ReconciliationInterest = "reconciliation_interest";
export const ReconciliationFees = "reconciliation_fees";
export const ReconciliationStatement = "reconciliation_statement";
export const ReconciliationRewards = "reconciliation_rewards";
export const ReconciliationInvestments = "reconciliation_investments";
export const ReconciliationTransactions = "reconciliation_transactions";
export const ReconciliationRetirement401k = "reconciliation_retirement_401k";
export const ReconciliationRetirementStd = "reconciliation_retirement_std";
export const ReconciliationCollegeInfo = "reconciliation_college_info";
// Gift cards
export const IncludeDisabledGiftCards = "include_disabled_gift_cards";
