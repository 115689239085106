import React, { useState, useEffect } from "react";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { rewardStatement } from "../../library/Utilities";

const Rewards = (props) => {
  const { rewardMode, rewardAmount } = props;

  // Balance
  const rewardBalance = rewardStatement(props.rewardBalance, props.rewardType);

  // State
  const [mode, setMode] = useState(rewardMode);
  const [amount, setAmount] = useState(rewardAmount);

  // Changes
  useEffect(() => {
    setAmount(rewardAmount);
    setMode(rewardMode);
  }, [rewardAmount, rewardMode]);

  const amountChanged = (evt) => {
    let _amount = evt.target.value.replace("$", "");
    _amount = _amount.replace(/[^0-9.-]/, "");
    setAmount(_amount);
  };

  const modeChanged = (evt) => {
    const _mode = evt.target.value;
    setMode(_mode);
  };

  const updateRewards = () => {
    const rewards = {
      amount: amount,
      mode: mode,
    };
    props._updateRewards(rewards);
  };

  return (
    <table
      className="table table-bordered table-sm tbl-reconciliation-rewards"
      style={{ marginBottom: 0 }}
    >
      <tbody>
        <tr>
          <td className="label">Reward Balance</td>
          <td colSpan={2}>{rewardBalance}</td>
        </tr>
        <tr>
          <td className="label">Statement Rewards</td>
          <td>
            <input
              className="form-control input-sm"
              type="text"
              value={amount}
              onChange={amountChanged}
              onBlur={updateRewards}
            ></input>
          </td>
          <td>
            <select
              className="form-control input-sm"
              value={mode}
              onChange={modeChanged}
              onBlur={updateRewards}
            >
              <option value="CHANGE">Change</option>
              <option value="TOTAL">Total</option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  const reconciliation = state.reconciliation || {};
  return {
    rewardBalance: reconciliation.rewardBalance,
    rewardAmount: reconciliation.rewardAmount || "0.00",
    rewardMode: reconciliation.rewardMode,
    rewardType: reconciliation.rewardType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Rewards
    _updateRewards: (o) => {
      dispatch(Actions.updateReconciliationRewards(o));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
