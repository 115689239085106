import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import {
  financeCreatingUrl,
  financeEditUrl,
  financeSaveCreditUrl,
} from "../../library/Urls";
import { isValidUsMoney, toUsMoney } from "../../library/Utilities";
import Checkbox from "../common/Checkbox";
import NumberField from "../common/NumberField";

const errorFieldMappings = {
  name: "account_name",
  initialAmount: "initial_amount",
  accountNumber: "account_number",
  institution: "institution",
  reconciliationFrequency: "reconciliation_freq",
  interestRate: "interest_rate",
};

class EditCreditAccount extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleClose: props.handleClose,
      handleSave: props.handleSave,
      finance: {
        id: props.financeId,
        account_name: "",
        account_number: "",
        institution: "",
        initial_amount: "",
        is_enabled: false,
        reconciliation_freq: 1,
        interest_rate: 0,
        reward_type: "POINTS",
        initial_rewards: 0,
        expiration_month: 1,
        expiration_year: 2020,
        pay_balance: false,
        minimum_payment: 0,
        payment_day: 1,
        credit_limit: 0,
        credit_type: "",
        expiration_months: [],
        expiration_years: [],
        reward_types: [],
        credit_types: [],
      },
      financeId: props.financeId,
      errorMessage: null,
      actionsEnabled: false,
      actionSubmitText: "Save",
    };
  }

  componentDidMount = (evt) => {
    // Whether creating or editing
    const fx = this.state.financeId ? financeEditUrl : financeCreatingUrl;
    const arg = this.state.financeId || "Credit";
    const submit = this.state.financeId
      ? this.state.actionSubmitText
      : "Create Account";
    // Initial data
    doHttpGet({
      url: fx(arg),
      onSuccess: (data) => {
        this.setState({
          finance: {
            ...data,
            initial_amount: data.initial_amount.toFixed(2),
            credit_limit: data.credit_limit.toFixed(2),
            minimum_payment: data.minimum_payment.toFixed(2),
          },
          actionsEnabled: true,
          errorMessage: null,
          actionSubmitText: submit,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false,
    });

    // Post
    doHttpPost({
      url: financeSaveCreditUrl,
      body: {
        ...values,
        id: this.state.financeId,
        initial_amount: toUsMoney(values.initial_amount),
        reconciliation_freq: parseInt(values.reconciliation_freq, 10),
      },
      onSuccess: (financeId) => {
        // Handle save
        this.state.handleSave(financeId);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach((error) => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage,
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  validate = (values) => {
    const errors = {};
    // Required inputs
    if (!values.account_name) {
      errors.account_name = "Account name is required";
    }
    if (!values.account_number) {
      errors.account_number = "Account number is required";
    }
    if (!values.institution) {
      errors.institution = "Institution is required";
    }
    if (!values.reconciliation_freq) {
      errors.reconciliation_freq = "Reconciliation frequency is required";
    } else {
      const freq = parseInt(values.reconciliation_freq, 10);
      if (freq < 1 || freq > 12) {
        errors.reconciliation_freq =
          "Reconciliation frequency must be between 1 and 12";
      }
    }
    const rate = parseFloat(values.interest_rate);
    if (rate === undefined) {
      errors.interest_rate = "Interest rate is required";
    } else {
      if (rate < 0) {
        errors.interest_rate = "Interest rate must be 0 or greater";
      }
    }
    if (!values.initial_amount) {
      errors.initial_amount = "Initial amount is required";
    } else if (!isValidUsMoney(values.initial_amount)) {
      errors.initial_amount = "Invalid monetary amount";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const {
      id,
      account_name,
      initial_amount,
      is_enabled,
      account_number,
      institution,
      reconciliation_freq,
      interest_rate,
      reward_type,
      initial_rewards,
      expiration_month,
      expiration_year,
      pay_balance,
      minimum_payment,
      payment_day,
      credit_limit,
      credit_type,
      for_transactions,
    } = this.state.finance;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{id ? "Update" : "Create"} Credit Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              account_name,
              initial_amount,
              is_enabled,
              account_number,
              institution,
              reconciliation_freq,
              interest_rate,
              reward_type,
              initial_rewards,
              expiration_month,
              expiration_year,
              pay_balance,
              minimum_payment,
              payment_day,
              credit_limit,
              credit_type,
              for_transactions,
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <div className="row">
                  <div className="col-6">
                    <fieldset className="form-group required">
                      <label className="control-label">Account Name</label>
                      <Field
                        className="form-control"
                        type="text"
                        name="account_name"
                      ></Field>
                      <ErrorMessage
                        name="account_name"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Institution/Bank</label>
                      <Field
                        className="form-control"
                        type="text"
                        name="institution"
                      ></Field>
                      <ErrorMessage
                        name="institution"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Account Number</label>
                      <Field
                        className="form-control"
                        type="text"
                        name="account_number"
                      ></Field>
                      <ErrorMessage
                        name="account_number"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Credit Type</label>
                      <Field
                        className="form-control"
                        as="select"
                        name="credit_type"
                      >
                        {this.state.finance.credit_types.map((rt) => (
                          <option key={rt.id} value={rt.name}>
                            {rt.name}
                          </option>
                        ))}
                      </Field>
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Credit Limit</label>
                      <Field
                        component={NumberField}
                        name="credit_limit"
                      ></Field>
                      <ErrorMessage
                        name="credit_limit"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Initial Amount</label>
                      <Field
                        component={NumberField}
                        allowNegative={true}
                        name="initial_amount"
                      ></Field>
                      <ErrorMessage
                        name="initial_amount"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Interest Rate</label>
                      <Field
                        component={NumberField}
                        name="interest_rate"
                      ></Field>
                      <ErrorMessage
                        name="interest_rate"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <Field
                        component={Checkbox}
                        name="is_enabled"
                        id="is_enabled"
                        label="Account is enabled"
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <Field
                        component={Checkbox}
                        name="for_transactions"
                        id="for_transactions"
                        label="Use account for transactions"
                      />
                    </fieldset>
                  </div>
                  <div className="col-6">
                    <fieldset className="form-group required">
                      <label className="control-label">Expiration Month</label>
                      <Field
                        className="form-control"
                        as="select"
                        name="expiration_month"
                      >
                        {this.state.finance.expiration_months.map((m) => (
                          <option key={m.id} value={m.id}>
                            {m.name}
                          </option>
                        ))}
                      </Field>
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Expiration Year</label>
                      <Field
                        className="form-control"
                        as="select"
                        name="expiration_year"
                      >
                        {this.state.finance.expiration_years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Field>
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Reward Type</label>
                      <Field
                        className="form-control"
                        as="select"
                        name="reward_type"
                      >
                        {this.state.finance.reward_types.map((rt) => (
                          <option key={rt.id} value={rt.id}>
                            {rt.name}
                          </option>
                        ))}
                      </Field>
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Initial Rewards</label>
                      <Field
                        component={NumberField}
                        name="initial_rewards"
                      ></Field>
                      <ErrorMessage
                        name="initial_rewards"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">
                        Statements Frequency (Months)
                      </label>
                      <Field
                        component={NumberField}
                        integerOnly={true}
                        allowDecimals={false}
                        name="reconciliation_freq"
                      ></Field>
                      <ErrorMessage
                        name="reconciliation_freq"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Minimum Payment</label>
                      <Field
                        component={NumberField}
                        name="minimum_payment"
                      ></Field>
                      <ErrorMessage
                        name="minimum_payment"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Month Payment Day</label>
                      <Field
                        component={NumberField}
                        integerOnly={true}
                        allowDecimals={false}
                        name="payment_day"
                      ></Field>
                      <ErrorMessage
                        name="payment_day"
                        className="alert alert-warning"
                        component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <Field
                        component={Checkbox}
                        name="pay_balance"
                        id="pay_balance"
                        label="Always pay outstanding balance"
                      />
                    </fieldset>
                  </div>
                </div>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.actionSubmitText}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditCreditAccount;
