import React from "react";

export default function PageNotFound(props) {
  return (
    <div className="page-content">
      <h2>{props.title}</h2>
      <div className="alert alert-danger">{props.message}</div>
    </div>
  );
}
