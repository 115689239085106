import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelopeOpenText,
  faExclamationCircle,
  faInfoCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import { doHttpPost } from "../../services/WebService";
import { notificationDismissUrl } from "../../library/Urls";
import { UUID } from "../../library/Utilities";
import AuthenticationService from "../../services/AuthenticationService";

const UserDashboardMessages = (props) => {
  // Notifications
  const [notifications, setNotifications] = useState(props.notifications || []);

  const clearNotification = (id) => {
    doHttpPost({
      url: notificationDismissUrl,
      body: [id],
      onSuccess: (notificationIds) => {
        const _notifications = notifications.filter(
          (n) => !notificationIds.includes(n.id)
        );
        setNotifications(_notifications);
      },
    });
  };

  // Whether primary user
  const isPrimaryUser = () => {
    const userInfo = AuthenticationService.getAuthenticatedUserInfo();
    return userInfo.isPrimary;
  };

  // Render
  return (
    <div
      className="card"
      style={{
        width: "20rem",
        marginBottom: "3px",
        marginRight: "3px",
      }}
    >
      <div className="card-body">
        <h5 className="card-title">
          <span style={{ marginRight: "8px" }}>
            <FontAwesomeIcon icon={faEnvelopeOpenText} />
          </span>
          Notifications ({notifications.length})
        </h5>
        <table
          className="table table-md table-borderless dashboard-messages"
          style={{ marginTop: "10px", marginBottom: 0 }}
        >
          <tbody style={{ height: "250px" }}>
            {notifications.map((msg) => {
              // Style
              let alertCls = "alert-info";
              let icon = faInfoCircle;
              if (msg.type === "ALERT") {
                alertCls = "alert-warning";
                icon = faBell;
              } else if (msg.type === "ISSUE") {
                alertCls = "alert-danger";
                icon = faExclamationCircle;
              }
              // Actions
              let action = null;
              if (msg.action) {
                if (msg.action.match(/RECONCILE\(\d+\)/)) {
                  const financeId = msg.action.replace(/\D/g, "");
                  action = (
                    <Link to={Links.reconcile(financeId)}>
                      Reconcile the account
                    </Link>
                  );
                } else if (msg.action.match(/FINANCIAL_ACCOUNT\(\d+\)/)) {
                  const financeId = msg.action.replace(/\D/g, "");
                  action = (
                    <Link to={Links.financeFor(financeId)}>
                      Review financial account
                    </Link>
                  );
                } else if (msg.action.match(/BUDGET_ADJUSTMENTS\(\d+\)/)) {
                  const baYearMonth = msg.action.replace(/\D/g, "");
                  action = (
                    <Link to={Links.reportBudgetAdjustmentsFor(baYearMonth)}>
                      Review budget adjustments
                    </Link>
                  );
                } else if (msg.action.match(/GIFT_CARD\(\d+\)/)) {
                  const giftcardId = msg.action.replace(/\D/g, "");
                  action = (
                    <Link to={Links.giftCardForId(giftcardId)}>
                      Review gift card
                    </Link>
                  );
                } else if (
                  msg.action.match(/USER_SETTINGS_FEATURES_IF_PRIMARY/) &&
                  isPrimaryUser()
                ) {
                  action = (
                    <Link to={Links.settingsFeatures}>
                      Request the optional account feature
                    </Link>
                  );
                }
              }
              // Row
              return (
                <tr key={UUID()} className={"alert " + alertCls}>
                  <td className="icon">
                    <FontAwesomeIcon icon={icon} />
                  </td>
                  <td className="message">
                    {msg.message}
                    {action && (
                      <div style={{ marginTop: "3px" }}>
                        {action}
                        {msg.id && (
                          <span
                            style={{
                              marginLeft: "10px",
                              borderLeft: "1px solid gray",
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                marginLeft: "10px",
                                color: "red",
                              }}
                              icon={faTrashAlt}
                              onClick={() => clearNotification(msg.id)}
                            />
                          </span>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserDashboardMessages;
