import React, { Component } from "react";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import { budgetInfoUrl, budgetDeleteUrl } from "../../library/Urls";
import PageNotFound from "../common/PageNotFound";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import { fmtDate } from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditBudget from "./EditBudget";
import ConfirmDialog from "../common/ConfirmDialog";
import ProvisionsList from "../common/ProvisionsList";
import TransactionListings from "../transactions/TransactionListings";
import { Tabs, Tab } from "react-bootstrap";
import DesignationFinancesList from "../common/DesignationFinancesList";

export default class Budget extends Component {
  constructor(props) {
    super(props);
    // State
    this.state = {
      errorMessage: null,
      confirmDelete: false,
      budgetId: props.match.params.budgetId,
    };
  }

  getBudgetInfo = () => {
    doHttpGet({
      url: budgetInfoUrl(this.state.budgetId),
      onSuccess: (data) => {
        this.setState({
          info: data,
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  componentDidMount = (evt) => {
    // Budget ID
    if (!this.state.budgetId) {
      this.setState({
        errorMessage:
          "To retrieve budget details, a valid ID must be provided in the request URL",
      });
      return;
    }
    // Budget info
    this.getBudgetInfo();
  };

  showEditor = (show) => {
    this.setState({
      showEditor: show,
    });
  };

  onEditBudget = () => {
    this.showEditor(false);
    this.getBudgetInfo();
  };

  confirmDelete = (show) => {
    this.setState({
      confirmDelete: show,
    });
  };

  onConfirmDelete = () => {
    // Close confirmation dialog
    this.confirmDelete(false);
    // Delete account
    doHttpDelete({
      url: budgetDeleteUrl(this.state.budgetId),
      onSuccess: () => {
        // Go to list of categories
        this.props.history.push(Links.budgets);
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    // Info
    const { info, errorMessage } = this.state;
    if (!info) {
      if (errorMessage) {
        return <PageNotFound title="Budget" message={errorMessage} />;
      }
      return <div />;
    }
    // Provisions
    const provisions = info.provisions || [];
    // Transactions
    const transactions = info.transactions || [];
    // Designations
    const designations = info.designations || [];
    // Render
    return (
      <div className="page-content">
        <div className="row" style={{ marginBottom: "15px" }}>
          <div className="col">
            <h2>
              {info.budget_name}
              <span className="gzn-detail-flag">Budget</span>
            </h2>
          </div>
        </div>
        <div className="row" style={{ maxHeight: "400px" }}>
          <div className="col-4">
            <div
              style={{
                marginBottom: "10px",
                borderLeft: "10px solid gray",
                padding: "5px",
              }}
            >
              {info.description}
            </div>
            <table className="table table-md table-striped tbl-details">
              <tbody>
                <tr>
                  <td className="label">Category:</td>
                  <td>
                    <Link to={Links.categoryFor(info.category_id)}>
                      {info.category_name}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="label">Start Date:</td>
                  <td>{fmtDate(info.start_date)}</td>
                </tr>
                <tr>
                  <td className="label">Expiration Date:</td>
                  <td>{fmtDate(info.expire_date)}</td>
                </tr>
                <tr>
                  <td className="label">Status:</td>
                  <td>{info.status}</td>
                </tr>
                <tr>
                  <td className="label">Is a bill:</td>
                  <td>{info.is_bill ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td className="label">Adjustment:</td>
                  <td>{info.adjustment_type}</td>
                </tr>
                <tr>
                  <td className="label">Schedule:</td>
                  <td>{info.schedule}</td>
                </tr>
                <tr>
                  <td className="text-right" colSpan="2">
                    {info.is_editable && (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => this.showEditor(true)}
                      >
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </button>
                    )}
                    {info.is_deletable && (
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        style={{ marginLeft: "10px" }}
                        onClick={() => this.confirmDelete(true)}
                      >
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-8">
            <Tabs defaultActiveKey="provisions" id="budget-info-tab">
              <Tab
                eventKey="provisions"
                title={"Provisions (" + provisions.length + ")"}
              >
                <ProvisionsList
                  provisions={provisions}
                  tableHeight={225}
                  isBudget={true}
                />
              </Tab>
              <Tab
                eventKey="designations"
                title={"Designations (" + designations.length + ")"}
              >
                <DesignationFinancesList
                  designations={designations}
                  tableHeight={225}
                  refreshInfoCallback={() => this.getBudgetInfo()}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
        <TransactionListings
          transactions={transactions}
          displayCategorization={false}
        />

        {this.state.showEditor && (
          <EditBudget
            show={this.state.showEditor}
            onSave={this.onEditBudget}
            onClose={() => this.showEditor(false)}
            budgetId={this.state.budgetId}
          />
        )}

        {this.state.confirmDelete && (
          <ConfirmDialog
            show={this.state.confirmDelete}
            handleAction={this.onConfirmDelete}
            onClose={() => this.confirmDelete(false, null)}
            _type="budget"
          />
        )}
      </div>
    );
  }
}
