import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { fmtMoneyUSD, fmtDateSimple } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

const UserDashboardCollege = ({ collegeInfo, _style }) => {
  // Render
  return (
    <div className="card" style={_style}>
      <div className="card-body">
        <h5 className="card-title">
          <span style={{ marginRight: "8px" }}>
            <FontAwesomeIcon icon={faUniversity} />
          </span>
          College Savings
        </h5>
        <div
          className={classnames("card-block dashboard-big-amt", {
            "negative-amount": collegeInfo.totalAmount < 0.0,
            "positive-amount": collegeInfo.totalAmount >= 0.0,
          })}
        >
          {fmtMoneyUSD(collegeInfo.totalAmount)}
        </div>
      </div>

      <ul className="list-group list-group-flush">
        <li
          className={classnames("list-group-item", {
            "negative-amount": collegeInfo.activeAccountsCount <= 0,
          })}
        >
          <b>{collegeInfo.activeAccountsCount}</b>/
          {collegeInfo.totalAccountsCount} active college savings accounts
        </li>
        <li
          className={classnames("list-group-item", {
            "negative-amount": collegeInfo.activeScheduledTransfersCount <= 0,
          })}
        >
          <b>{collegeInfo.activeScheduledTransfersCount}</b>/
          {collegeInfo.totalScheduledTransfersCount} active scheduled transfers
        </li>
        {collegeInfo.upcomingTransferAmount ? (
          <li className="list-group-item">
            Next scheduled transfer of{" "}
            <b>{fmtMoneyUSD(collegeInfo.upcomingTransferAmount)}</b> from '
            {collegeInfo.upcomingTransferFrAccount}' to '
            {collegeInfo.upcomingTransferToAccount}' will be performed on{" "}
            <b>{fmtDateSimple(collegeInfo.upcomingTransferDate)}</b>
          </li>
        ) : (
          <li className="list-group-item negative-amount">
            There is no future scheduled transfer to college savings funds
          </li>
        )}
        {collegeInfo.recentTransferAmount ? (
          <li className="list-group-item" style={{ borderBottom: "none" }}>
            Most recent scheduled transfer of{" "}
            <b>{fmtMoneyUSD(collegeInfo.recentTransferAmount)}</b> from '
            {collegeInfo.recentTransferFrAccount}' to '
            {collegeInfo.recentTransferToAccount}' was performed on{" "}
            <b>{fmtDateSimple(collegeInfo.recentTransferDate)}</b>
          </li>
        ) : (
          <li className="list-group-item" style={{ borderBottom: "none" }}>
            There are no recent scheduled transfer transactions
          </li>
        )}
      </ul>
      <div className="card-footer">
        <Link to={Links.college}>Review College Info</Link>
      </div>
    </div>
  );
};

export default UserDashboardCollege;
