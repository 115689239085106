import React, { Component, Fragment } from "react";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import { giftCardUrl } from "../../library/Urls";
import PageNotFound from "../common/PageNotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { fmtMoneyUSD } from "../../library/Utilities";
import Money from "../common/Money";
import classnames from "classnames";
import EditGiftCard from "./EditGiftCard";
import ConfirmDialog from "../common/ConfirmDialog";
import * as Links from "../../library/Links";
import TransactionListings from "../transactions/TransactionListings";

export default class GiftCard extends Component {
  constructor(props) {
    super(props);

    // State
    this.state = {
      errorMessage: null,
      showEditor: false,
      confirmDelete: false,
      giftcardId: props.match.params.id,
    };
  }

  getInfo = () => {
    doHttpGet({
      url: giftCardUrl,
      params: {
        id: this.state.giftcardId,
      },
      onSuccess: (info) => {
        this.setState({
          info: info,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  componentDidMount = () => {
    this.getInfo();
  };

  showEditor = (id, show) => {
    this.setState({
      showEditor: show,
      selectedId: id,
    });
  };

  closeEditor = () => {
    this.setState({
      showEditor: false,
    });
  };

  giftCardSaved = (id) => {
    this.showEditor(id, false);
    this.getInfo();
  };

  confirmDelete = (id) => {
    this.setState({
      showDeleteConfirm: true,
      selectedId: id,
    });
  };

  closeDeleteConfirm = () => {
    this.setState({
      showDeleteConfirm: false,
    });
  };

  deleteGiftCard = () => {
    doHttpDelete({
      url: giftCardUrl,
      params: {
        id: this.state.selectedId,
      },
      onSuccess: (response) => {
        // Close confirmation
        this.closeDeleteConfirm();
        // Navigate to giftcards
        this.props.history.push(Links.giftcards);
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    // Info
    const { info, errorMessage } = this.state;
    if (!info) {
      if (errorMessage) {
        return <PageNotFound title="Healthcare Card" message={errorMessage} />;
      }
      return null;
    }
    // Render
    return (
      <div className="page-content">
        <div className="row" style={{ marginBottom: "15px" }}>
          <div className="col-4">
            <h2>{info.brand}</h2>
            <span
              className="gzn-detail-flag"
              style={{ marginLeft: 0, fontSize: "0.9em", fontWeight: "bold" }}
            >
              Gift Card{" "}
              {info.cardNumber && <Fragment>({info.cardNumber})</Fragment>}
            </span>
            <div
              className="text-right"
              style={{ marginTop: "8px", textAlign: "top" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.showEditor(info.id, true)}
              >
                <FontAwesomeIcon icon={faEdit} style={{ marginRight: 5 }} />
                Edit
              </button>
              {info.deletable && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.confirmDelete(info.id)}
                >
                  <FontAwesomeIcon icon={faTrash} style={{ marginRight: 5 }} />
                  Delete
                </button>
              )}
            </div>
          </div>
          <div className="col-5">
            <div style={{ marginBottom: "15px" }} className="description">
              {info.description}
            </div>
            <table
              className="table table-sm table-striped tbl-details"
              style={{ marginBottom: 0 }}
            >
              <tbody>
                <tr>
                  <td className="label">Charge Card:</td>
                  <td>{info.chargeCard ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td className="label">Usage Fees:</td>
                  <td>{fmtMoneyUSD(info.fees)}</td>
                </tr>
                <tr>
                  <td className="label">Where Usable:</td>
                  <td>{info.whereUsable}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-3">
            <div
              className="text-right"
              style={{ fontSize: "40px", fontWeight: "bold" }}
            >
              <Money amount={info.balance} />
            </div>
            <div className="text-right" style={{ marginTop: 10 }}>
              <span
                className={classnames("alert", {
                  "alert-success": info.enabled,
                  "alert-danger": !info.enabled,
                })}
              >
                {info.enabled ? "Active" : "Disabled"}
              </span>
            </div>
          </div>
        </div>

        <TransactionListings
          transactions={info.transactions}
          displayFinancing={false}
        />

        {this.state.showEditor && (
          <EditGiftCard
            show={this.state.showEditor}
            onSave={this.giftCardSaved}
            onClose={this.closeEditor}
            giftCardId={this.state.selectedId}
          />
        )}

        {this.state.showDeleteConfirm && (
          <ConfirmDialog
            show={this.state.showDeleteConfirm}
            handleAction={this.deleteGiftCard}
            handleClose={this.closeDeleteConfirm}
            _type="giftCard"
          />
        )}
      </div>
    );
  }
}
