import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCheckCircle,
  faSitemap,
  faTrash,
  faChartLine,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import { transactionsUrl, transactionDeleteUrl } from "../../library/Urls";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import classnames from "classnames";
import Categorization from "./Categorization";
import Financing from "./Financing";
import PageToolbar from "./PageToolbar";
import IncomeDistributions from "./IncomeDistributions";
import TransactionSplits from "./TransactionSplits";
import EditTransaction from "./EditTransaction";
import ConfirmDialog from "../common/ConfirmDialog";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import TransferDesignations from "./TransferDesignations";
import EditTransfer from "./EditTransfer";
import AuthenticationService from "../../services/AuthenticationService";
import EditStockTransaction from "../stocks/EditStockTransaction";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import EditStockReinvestment from "../stocks/EditStockReinvestment";
import EditStockDividend from "../stocks/EditStockDividend";
import EditStockAward from "./EditStockAward";

class TransactionsPanel extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      // Financing
      financeId: props.financeId,
      isInvestmentAccount: props.isInvestmentAccount,
      isRetirementAccount: props.isRetirementAccount,
      showFinancing: props.financeId == null,
      fxReloadFinance: props.fxReloadFinance,
      isForTransactions: props.isForTransactions,
      // Page size
      pageSize: props.pageSize || 100,
    };
  }

  getTransactions = (pageNumber, batchNumber) => {
    // Selected transaction
    if (batchNumber) {
      this.setState({
        batchNumber: batchNumber,
      });
    }
    // GET request
    doHttpGet({
      url: transactionsUrl,
      params: {
        pg: pageNumber,
        ps: this.state.pageSize,
        fa: this.state.financeId,
        bn: batchNumber,
      },
      onSuccess: (data) => {
        data.financeId = this.state.financeId;
        this.props._setTransactionInfo(data);
        this.props._setErrorMessage(null);
      },
      onError: (error) => {
        this.props._setErrorMessage(error);
      },
    });
  };

  componentDidMount = (evt) => {
    this.getTransactions(1, null);
  };

  showDistributions = (show, batchNumber) => {
    this.setState({
      showDistributions: show,
      batchNumber: batchNumber,
    });
  };

  showTransfers = (show, batchNumber) => {
    this.setState({
      showTransfers: show,
      batchNumber: batchNumber,
    });
  };

  showSplits = (show, batchNumber) => {
    this.setState({
      showSplits: show,
      batchNumber: batchNumber,
    });
  };

  showTransactionEditor = (show, batchNumber, isDeposit) => {
    this.props._setSelectedTransaction(batchNumber);
    this.setState({
      showTransactionEditor: show,
      isDeposit: isDeposit,
      batchNumber: batchNumber,
    });
  };

  showTransferEditor = (show, batchNumber) => {
    this.props._setSelectedTransaction(batchNumber);
    this.setState({
      showTransferEditor: show,
      batchNumber: batchNumber,
    });
  };

  showStockTransactionEditor = (show, batchNumber) => {
    this.props._setSelectedTransaction(batchNumber);
    this.setState({
      showStockTransactionEditor: show,
      batchNumber: batchNumber,
    });
  };

  showStockReinvestmentEditor = (show, batchNumber) => {
    this.props._setSelectedTransaction(batchNumber);
    this.setState({
      showStockReinvestmentEditor: show,
      batchNumber: batchNumber,
    });
  };

  showStockDividendEditor = (show, batchNumber) => {
    this.props._setSelectedTransaction(batchNumber);
    this.setState({
      showStockDividendEditor: show,
      batchNumber: batchNumber,
    });
  };

  showStockAwardEditor = (show, batchNumber) => {
    this.props._setSelectedTransaction(batchNumber);
    this.setState({
      showStockAwardEditor: show,
      batchNumber: batchNumber,
    });
  };

  confirmDelete = (show, batchNumber) => {
    this.props._setSelectedTransaction(batchNumber);
    this.setState({
      showDeleteConfirm: show,
      batchNumber: batchNumber,
    });
  };

  refresh = (batchNumber) => {
    // Load page with transaction
    if (this.state.financeId) {
      this.state.fxReloadFinance();
    }
    // Load page with transaction
    this.getTransactions(this.props.pageNumber, batchNumber);
  };

  deleteTransaction = () => {
    const batchNumber = this.props.selectedBatchNumber;
    // Delete account
    doHttpDelete({
      url: transactionDeleteUrl,
      params: {
        bn: batchNumber,
      },
      onSuccess: () => {
        // Close confirmation
        this.confirmDelete(false, null);
        // Refresh
        this.refresh(null);
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  onEditTransaction = (batchNumber) => {
    // Close editor
    this.props._setSelectedTransaction(batchNumber);
    this.setState({
      showTransferEditor: false,
      showTransactionEditor: false,
      showStockTransactionEditor: false,
      showStockReinvestmentEditor: false,
      showStockDividendEditor: false,
      showStockAwardEditor: false,
      batchNumber: batchNumber,
    });
    // Refresh
    this.refresh(batchNumber);
  };

  render() {
    // Transactions
    const transactions = this.props.transactions;
    if (!transactions) {
      return null;
    }
    // Whether all reconciled
    let allReconciled = true;
    transactions.forEach((t) => (allReconciled &= t.reconciled));
    // Selected batch number
    const selectedBatchNumber = this.props.selectedBatchNumber;
    // Finance
    const viewFinanceId = this.state.financeId;
    const isFinance =
      viewFinanceId !== null && this.state.financeId !== undefined;
    const isInvestmentAccount = this.state.isInvestmentAccount || false;
    const isRetirementAccount = this.state.isRetirementAccount || false;
    // Transactions
    const isForTransactions = this.state.isForTransactions || false;
    // Options
    const isStockOption = AuthenticationService.isStockFeatureEnabled();
    // Whether to display the stock buttons
    const showStockActions =
      isStockOption &&
      viewFinanceId != null &&
      (isInvestmentAccount || isRetirementAccount);
    // Render
    return (
      <div className="container">
        <div className="row">
          <div
            className="col-12"
            style={{
              textAlign: "right",
              verticalAlign: "bottom",
              paddingRight: 0,
            }}
          >
            {showStockActions && (
              <DropdownButton
                id="dropdown-basic-button"
                title="Stocks"
                variant="success"
                style={{ marginRight: "5px", display: "inline-block" }}
              >
                <Dropdown.Item
                  onSelect={() => this.showStockTransactionEditor(true, null)}
                >
                  <FontAwesomeIcon icon={faChartLine} /> &nbsp; Buy/Sell Stock
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() => this.showStockReinvestmentEditor(true, null)}
                >
                  <FontAwesomeIcon icon={faPlus} /> &nbsp; Reinvestment
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() => this.showStockDividendEditor(true, null)}
                >
                  <FontAwesomeIcon icon={faCoins} /> &nbsp; Dividend
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() => this.showStockAwardEditor(true, null)}
                >
                  <FontAwesomeIcon icon={faCoins} /> &nbsp; Stock Award
                </Dropdown.Item>
              </DropdownButton>
            )}
            {isForTransactions && (
              <React.Fragment>
                <button
                  className="btn btn-success"
                  style={{ marginRight: "5px" }}
                  onClick={() => this.showTransferEditor(true, null)}
                >
                  <FontAwesomeIcon icon={faPlus} /> &nbsp; Transfer
                </button>
                <button
                  className="btn btn-danger"
                  style={{ marginRight: "5px" }}
                  onClick={() => this.showTransactionEditor(true, null, false)}
                >
                  <FontAwesomeIcon icon={faPlus} /> &nbsp; Expense
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => this.showTransactionEditor(true, null, true)}
                >
                  <FontAwesomeIcon icon={faPlus} /> &nbsp; Deposit
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <table className="table table-bordered table-md table-striped table-transactions">
            <thead className="thead-dark">
              <tr>
                <th scope="col" style={{ width: "165px" }}>
                  Date/Amount
                </th>
                <th scope="col">Description</th>
                <th scope="col" style={{ maxWidth: "500px" }}>
                  Categorization
                </th>
                {this.state.showFinancing && <th scope="col">Financing</th>}
                {!allReconciled && (
                  <th scope="col" className="text-center">
                    Delete
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {transactions.map((t) => {
                // Cashback
                const isSingleCashback =
                  isFinance && t.transactionCount === 1 && t.cashback;
                t.isSingleCashback = isSingleCashback;
                t.isFinanceView = isFinance;
                t.viewFinanceId = viewFinanceId;
                // Render
                return (
                  <tr
                    key={"tx-" + t.batchNumber}
                    className={classnames("", {
                      "tx-selected": t.batchNumber === selectedBatchNumber,
                    })}
                  >
                    <td className="col-date-amount">
                      <span style={{ fontSize: "0.9em" }}>
                        {fmtDate(t.date)}
                      </span>
                      <br />
                      <button
                        type="button"
                        disabled={!t.editable}
                        style={{ padding: 0 }}
                        className={classnames("btn btn-link amount", {
                          "deposit-amount": t.deposit || isSingleCashback,
                          "expense-amount": t.expense && !isSingleCashback,
                          "transfer-amount": t.transfer && !isSingleCashback,
                        })}
                        onClick={() => {
                          if (t.stockTransaction) {
                            this.showStockTransactionEditor(
                              true,
                              t.batchNumber
                            );
                          } else if (t.stockReinvestment) {
                            this.showStockReinvestmentEditor(
                              true,
                              t.batchNumber
                            );
                          } else if (t.stockDividend) {
                            this.showStockDividendEditor(true, t.batchNumber);
                          } else if (t.stockAward) {
                            this.showStockAwardEditor(true, t.batchNumber);
                          } else if (t.transfer) {
                            this.showTransferEditor(true, t.batchNumber);
                          } else {
                            this.showTransactionEditor(
                              true,
                              t.batchNumber,
                              t.deposit
                            );
                          }
                        }}
                      >
                        {t.reconciled && (
                          <span style={{ marginRight: "5px" }}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                        )}
                        {fmtMoneyUSD(
                          isSingleCashback ? Math.abs(t.amount) : t.amount
                        )}
                      </button>
                    </td>
                    <td
                      className="col-description"
                      style={{ maxWidth: "500px" }}
                    >
                      {t.transactionCount > 1 ? (
                        <div className="row">
                          <div className="col-9">
                            <TransactionDescription transaction={t} />
                          </div>
                          <div className="col-3 text-right">
                            <button
                              type="button"
                              className="btn btn-md btn-primary"
                              onClick={() =>
                                this.showSplits(true, t.batchNumber)
                              }
                            >
                              <FontAwesomeIcon icon={faSitemap} />
                              <span
                                style={{
                                  marginLeft: "7px",
                                  fontWeight: "bold",
                                }}
                              >
                                {t.transactionCount}
                              </span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <TransactionDescription transaction={t} />
                      )}
                    </td>
                    <td>
                      <Categorization
                        transaction={t}
                        viewFinanceId={viewFinanceId}
                        fxShowDistributions={() =>
                          this.showDistributions(true, t.batchNumber)
                        }
                        fxShowTransfers={() =>
                          this.showTransfers(true, t.batchNumber)
                        }
                      />
                    </td>
                    {this.state.showFinancing && (
                      <td>
                        <Financing
                          finances={t.finances}
                          isTransfer={t.transfer}
                        />
                      </td>
                    )}
                    {!allReconciled && (
                      <td className="text-center">
                        {t.editable && (
                          <button
                            className="btn btn-md btn-danger"
                            onClick={() =>
                              this.confirmDelete(true, t.batchNumber)
                            }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
            <tfoot className="table-footer">
              <tr>
                <td colSpan={this.state.showFinancing ? 5 : 4}>
                  <PageToolbar fxLoadPage={this.getTransactions} />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        {this.state.showDistributions && (
          <IncomeDistributions
            show={this.state.showDistributions}
            onClose={() => this.showDistributions(false, null)}
            batchNumber={this.state.batchNumber}
          />
        )}

        {this.state.showTransfers && (
          <TransferDesignations
            show={this.state.showTransfers}
            onClose={() => this.showTransfers(false, null)}
            batchNumber={this.state.batchNumber}
          />
        )}

        {this.state.showSplits && (
          <TransactionSplits
            show={this.state.showSplits}
            onClose={() => this.showSplits(false, null)}
            batchNumber={this.state.batchNumber}
          />
        )}

        {this.state.showTransactionEditor && (
          <EditTransaction
            show={this.state.showTransactionEditor}
            onClose={() => this.showTransactionEditor(false, null)}
            onSave={this.onEditTransaction}
            batchNumber={this.state.batchNumber}
            isDeposit={this.state.isDeposit}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showTransferEditor && (
          <EditTransfer
            show={this.state.showTransferEditor}
            onClose={() => this.showTransferEditor(false, null)}
            onSave={this.onEditTransaction}
            batchNumber={this.state.batchNumber}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showStockTransactionEditor && (
          <EditStockTransaction
            show={this.state.showStockTransactionEditor}
            onClose={() => this.showStockTransactionEditor(false, null)}
            onSave={this.onEditTransaction}
            batchNumber={this.state.batchNumber}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showStockReinvestmentEditor && (
          <EditStockReinvestment
            show={this.state.showStockReinvestmentEditor}
            onClose={() => this.showStockReinvestmentEditor(false, null)}
            onSave={this.onEditTransaction}
            batchNumber={this.state.batchNumber}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showStockDividendEditor && (
          <EditStockDividend
            show={this.state.showStockDividendEditor}
            onClose={() => this.showStockDividendEditor(false, null)}
            onSave={this.onEditTransaction}
            batchNumber={this.state.batchNumber}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showStockAwardEditor && (
          <EditStockAward
            show={this.state.showStockAwardEditor}
            onClose={() => this.showStockAwardEditor(false, null)}
            onSave={this.onEditTransaction}
            batchNumber={this.state.batchNumber}
            financeId={this.state.financeId}
          />
        )}

        {this.state.showDeleteConfirm && (
          <ConfirmDialog
            show={this.state.showDeleteConfirm}
            handleAction={this.deleteTransaction}
            handleClose={() => this.confirmDelete(false, null)}
            _type="transaction"
          />
        )}
      </div>
    );
  }
}

const TransactionDescription = (props) => {
  const t = props.transaction;
  // To/from
  let toFromText = "TO:";
  if (t.deposit) {
    toFromText = "FROM:";
  }
  let party = t.party;
  // Cashback
  const isCashback = t.isSingleCashback;
  if (isCashback) {
    toFromText = "FROM:";
    const finances = t.finances.filter((f) => f.primary);
    if (finances.length > 0) {
      const finance = finances[0];
      party = <Link to={Links.financeFor(finance.id)}>{finance.name}</Link>;
    }
  }
  // Transfer
  const isTransfer = t.transfer;
  if (isTransfer) {
    toFromText = "FROM:";
    let _finances = t.finances.filter((f) => f.primary);
    if (t.isFinanceView) {
      const viewFinanceId = parseInt(t.viewFinanceId, 10);
      _finances = t.finances.filter((f) => f.id !== viewFinanceId);
      if (!_finances[0].primary) {
        toFromText = "TO:";
      }
    } else {
      toFromText = "TO:";
      _finances = t.finances.filter((f) => !f.primary);
    }
    if (_finances.length > 0) {
      const finance = _finances[0];
      party = (
        <Link to={Links.financeFor(finance.id)}>
          {finance.description} (*{finance.name})
        </Link>
      );
    }
  }
  // Render
  return (
    <Fragment>
      <div className="party">
        <span className="to-from">{toFromText}</span> {party}
      </div>
      {t.reference && (
        <div>
          <span style={{ fontWeight: "bold", marginRight: "10px" }}>
            REFERENCE:
          </span>
          <span className="reference">{t.reference}</span>
        </div>
      )}
      <div className="description">{t.description}</div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    transactions: state.transactionInfo.results,
    pageNumber: state.transactionInfo.pageNumber,
    selectedBatchNumber: state.transactionInfo.selectedBatchNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Errors
    _setErrorMessage: (message) => {
      dispatch(Actions.setErrorMessage(message));
    },
    // Transactions
    _setTransactionInfo: (data) => {
      dispatch(Actions.setTransactionInfo(data));
    },
    _setSelectedTransaction: (batchNumber) => {
      dispatch(Actions.setSelectedTransaction(batchNumber));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPanel);
