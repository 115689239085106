import React, { Fragment } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFunnelDollar } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { fmtMoneyUSD } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

const UserDashboardDesignations = (props) => {
  const isRetirementOption = AuthenticationService.isRetirementFeatureEnabled();
  const isCollegeOption = AuthenticationService.isCollegeFeatureEnabled();

  // Designations
  const {
    totalDesignations,
    budgetAmt,
    savingsGoalAmt,
    savingsAmt,
    emergencyAmt,
    investmentAmt,
    debtAmt,
    undesignatedAmt,
    retirementAmt,
    collegeAmount,
  } = props.designations || {};

  // Render
  return (
    <div
      className="card"
      style={{ width: "20rem", marginBottom: "3px", marginTop: "3px" }}
    >
      <div className="card-body">
        <h5 className="card-title">
          <span style={{ marginRight: "8px" }}>
            <FontAwesomeIcon icon={faFunnelDollar} />
          </span>
          Designations
        </h5>
        <div
          className={classnames("card-block dashboard-big-amt", {
            "negative-amount": totalDesignations < 0.0,
            "positive-amount": totalDesignations >= 0.0,
          })}
        >
          {fmtMoneyUSD(totalDesignations)}
        </div>
        <div
          className={classnames("card-text", {
            "negative-amount": budgetAmt < 0.0,
          })}
        >
          {fmtMoneyUSD(budgetAmt)} is designated for <b>budgets</b>
        </div>
        <div
          className={classnames("card-text", {
            "negative-amount": savingsGoalAmt < 0.0,
          })}
        >
          {fmtMoneyUSD(savingsGoalAmt)} is set for <b>savings goals</b>
        </div>
        <div
          className={classnames("card-text", {
            "negative-amount": emergencyAmt < 0.0,
          })}
        >
          {fmtMoneyUSD(emergencyAmt)} is saved for <b>emergencies</b>
        </div>
        <div
          className={classnames("card-text", {
            "negative-amount": investmentAmt < 0.0,
          })}
        >
          {fmtMoneyUSD(investmentAmt)} is vested in <b>investments</b>
        </div>
        <div
          className={classnames("card-text", {
            "negative-amount": savingsAmt < 0.0,
          })}
        >
          {fmtMoneyUSD(savingsAmt)} is set aside in <b>savings</b>
        </div>
        {isRetirementOption && (
          <div
            className={classnames("card-text", {
              "negative-amount": retirementAmt < 0.0,
            })}
          >
            {fmtMoneyUSD(retirementAmt)} nest egg for <b>retirement</b>
          </div>
        )}
        {isCollegeOption && (
          <div
            className={classnames("card-text", {
              "negative-amount": collegeAmount < 0.0,
            })}
          >
            {fmtMoneyUSD(collegeAmount)} savings for <b>college</b>
          </div>
        )}
        <div
          className={classnames("card-text", {
            "negative-amount": debtAmt < 0.0,
          })}
        >
          {fmtMoneyUSD(debtAmt)} is real <b>debt</b>
        </div>
        {false && (
          <Fragment>
            <div
              className={classnames("card-text", {
                "negative-amount": debtAmt < 0.0,
              })}
            >
              {fmtMoneyUSD(debtAmt)} is real <b>debt</b>
            </div>
            <div
              className={classnames("card-text", {
                "negative-amount": undesignatedAmt < 0.0,
              })}
            >
              {fmtMoneyUSD(undesignatedAmt)} is not designated for anything
            </div>
          </Fragment>
        )}
      </div>
      <div className="card-footer">
        <Link to={Links.designations}>Manage designations</Link>
      </div>
    </div>
  );
};

export default UserDashboardDesignations;
