// Info
const _info = "/info";
export const infoCopyrightUrl = `${_info}/copyright`;

// Session
const _account = "/account";
export const loginUrl = `${_account}/login`;
export const logoutUrl = `${_account}/logout`;

// Account
export const accountSettingsUrl = `${_account}/settings`;
export const accountEditUrl = `${_account}/edit`;
export const accountSaveUrl = `${_account}/save`;
export const accountDeleteUrl = `${_account}/delete`;
export const accountRestoreUrl = `${_account}/restore`;
export const accountUpdateStockSettingsUrl = `${_account}/settings/stock`;

// Registration
export const registrationUrl = `${_account}/register`;
export const activationUrl = `${_account}/activate`;

// Admin
const _admin = "/admin";
export const adminAccountsUrl = `${_admin}/accounts`;
export const adminAccountEditUrl = (id) => `${_admin}/accounts/edit/${id}`;
export const adminAccountSaveUrl = `${_admin}/accounts/save`;
export const adminRegistrationDeleteUrl = (id) =>
  `${_admin}/registration/${id}`;
export const adminRegistrationsUrl = `${_admin}/registrations`;
export const adminSettingsUrl = `${_admin}/settings`;
export const adminFeatureActionUrl = (id) => `${_admin}/features/${id}`;
export const adminFeatureActionsUrl = `${_admin}/features`;
const _adminEmail = `${_admin}/email`;
export const adminEmailSendUrl = `${_adminEmail}/send`;
const _adminSchedule = `${_admin}/schedule`;
export const adminScheduleMidnightUrl = `${_adminSchedule}/midnight`;
export const adminUndoBudgetAdjustmentsUrl = `${_admin}/budget-adjustments/undo`;
export const adminStockPriceUpdateUrl = `${_admin}/stocks/start-price-update`;
export const stockUpdateUrl = `${_admin}/stocks/update`;
export const stockSplitUrl = `${_admin}/stocks/split`;
export const adminStockSettingsUrl = `${_admin}/stocks/settings`;
export const adminUpdateStockPriceSettingsUrl = `${_admin}/stocks/price/settings`;

// User
const _users = "/users";
export const userMakePrimaryUrl = `${_users}/primary`;
export const userUpdatePasswordUrl = `${_users}/password`;
export const userUpdateStatusUrl = `${_users}/status`;
export const userLoginHistoryUrl = (id) => `${_users}/logins/${id}`;
export const userEditUrl = (id) => `${_users}/edit/${id}`;
export const userUpdateUrl = `${_users}/update`;
export const userDeleteUrl = (id) => `${_users}/delete/${id}`;
export const userCreateUrl = `${_users}/create`;
export const userPasswordResetUrl = `${_users}/password/q`;
export const userResetPasswordUrl = `${_users}/password/r`;
export const userDashboardUrl = `${_users}/dashboard`;

// Finances
const _finance = "/finance";
export const financesUrl = (inclDisabled) =>
  `${_finance}/finances` + (inclDisabled ? "?inclDisabled=true" : "");
export const financeCreatingUrl = (type) => `${_finance}/creating/${type}`;
export const financeEditUrl = (id) => `${_finance}/edit/${id}`;
export const financeDeleteUrl = (id) => `${_finance}/delete/${id}`;
export const financeInfoUrl = (id) => `${_finance}/info/${id}`;
export const financeSaveCashUrl = `${_finance}/save/cash`;
export const financeSaveCheckingUrl = `${_finance}/save/checking`;
export const financeSaveSavingsUrl = `${_finance}/save/savings`;
export const financeSaveCreditUrl = `${_finance}/save/credit`;
export const financeSaveInvestmentUrl = `${_finance}/save/investment`;
export const financeSaveRetirementUrl = `${_finance}/save/retirement`;
export const financeSaveCollegeUrl = `${_finance}/save/college`;

// Emergencies
const _emergency = "/emergency";
export const emergencyPlanUrl = `${_emergency}/plan`;
export const emergencyCreatingUrl = `${_emergency}/creating`;
export const emergencyPlanEditUrl = `${emergencyPlanUrl}/edit`;
export const emergencyPlanSaveUrl = `${emergencyPlanUrl}/save`;
export const emergencyEditUrl = (id) => `${_emergency}/edit/${id}`;
export const emergencySaveUrl = `${_emergency}/save`;
export const emergencyDeleteUrl = (id) => `${_emergency}/delete/${id}`;

// Categories
const _category = "/category";
export const categoryListingUrl = `${_category}/listing`;
export const categoryInfoUrl = (id) => `${_category}/${id}`;
export const categoryEditUrl = (id) =>
  `${_category}/edit` + (id ? `/${id}` : "");
export const categorySaveUrl = `${_category}/save`;
export const categoryDeleteUrl = (id) => `${_category}/delete/${id}`;

// Budgets
const _budget = "/budget";
export const budgetsUrl = (incl) =>
  `${_budget}/budgets` + (incl ? "?inclDisabled=true" : "");
export const budgetInfoUrl = (id) => `${_budget}/${id}`;
export const budgetEditUrl = (id) => `${_budget}/edit` + (id ? `/${id}` : "");
export const budgetSaveUrl = `${_budget}/save`;
export const budgetDeleteUrl = (id) => `${_budget}/delete/${id}`;
export const budgetMonthlyUrl = (ym) => `${_budget}/monthly/${ym}`;

// Savings goals
const _savingsGoal = "/savingsgoal";
export const savingsGoalsUrl = (incl) =>
  `${_savingsGoal}/list` + (incl ? "?inclDisabled=true" : "");
export const savingsGoalInfoUrl = (id) => `${_savingsGoal}/${id}`;
export const savingsGoalEditUrl = (id) =>
  `${_savingsGoal}/edit` + (id ? `/${id}` : "");
export const savingsGoalSaveUrl = `${_savingsGoal}/save`;
export const savingsGoalDeleteUrl = (id) => `${_savingsGoal}/delete/${id}`;
export const savingsGoalStatusUrl = `${_savingsGoal}/status`;

// Reports
const _reports = "/report";
const _reportBudgetAdjustments = `${_reports}/budget-adjustments`;
export const reportBudgetAdjustments = (ym) =>
  `${_reportBudgetAdjustments}/${ym}`;
export const reportBudgetAdjustmentMonthsUrl = `${_reportBudgetAdjustments}/months`;

// Transactions
const _transaction = "/transaction";
export const transactionsUrl = `${_transaction}/list`;
export const incomeDistributionsUrl = `${_transaction}/income-distributions`;
export const transactionSplitUrl = `${_transaction}/splits`;
export const transactionEditUrl = `${_transaction}/edit`;
export const transactionSaveUrl = `${_transaction}/save`;
export const transactionDeleteUrl = `${_transaction}/delete`;
export const transferInfoUrl = `${_transaction}/transfer/info`;
export const transferEditUrl = `${_transaction}/transfer/edit`;
export const transferSaveUrl = `${_transaction}/transfer/save`;
export const transactionFinanceDesignationsUrl = `${_transaction}/finance/designations`;
export const transactionCategorizationsUrl = `${_transaction}/categorizations`;
export const transactionSearchPartiesUrl = `${_transaction}/search/parties`;

// Features
export const featureActionUrl = `${_account}/features/action`;

// Healthcare
const _healthcare = "/healthcare";
export const healthcareInfoUrl = `${_healthcare}/info`;
export const healthcareFinanceEditUrl = `${_healthcare}/finance/edit`;
export const healthcareFinanceSaveUrl = `${_healthcare}/finance/save`;
export const healthcareDisbursementUrl = `${_healthcare}/disbursement`;
export const healthcareBudgetEditUrl = `${_healthcare}/budget/edit`;
export const healthcareBudgetSaveUrl = `${_healthcare}/budget/save`;
export const healthcareBudgetDeleteUrl = (id) => `${_healthcare}/budget/delete/${id}`;
export const healthcareSavingsEditUrl = `${_healthcare}/savings/edit`;
export const healthcareSavingsSaveUrl = `${_healthcare}/savings/save`;
export const healthcareSavingsDeleteUrl = (id) => `${_healthcare}/savings/delete/${id}`;
export const healthcareTransactionsUrl = `${_healthcare}/transactions`;

// Reconciliation
const _reconciliation = "/reconciliation";
export const reconciliationUrl = _reconciliation;
export const reconciliationFinancesUrl = `${_reconciliation}/finances`;

// Stocks
const _stock = "/stock";
export const stockTransactionUrl = `${_stock}/transaction`;
export const stockReinvestmentUrl = `${_stock}/reinvestment`;
export const stockDividendUrl = `${_stock}/dividend`;
export const stockAwardUrl = `${_stock}/award`;
export const financeStocksUrl = `${_stock}/finance-stocks`;
export const stockInfoUrl = `${_stock}/info`;
export const accountStockDetailsUrl = `${_stock}/details`;
export const accountStockSharesUrl = `${_stock}/shares`;
export const stockPriceUrl = `${_stock}/price`;
export const stockPricesUrl = `${_stock}/prices`;
export const stockOnlineUrl = `${_stock}/online`;

// Search
const _search = "/search";
export const searchUrl = `${_search}`;

// Gift cards
const _giftcard = "/giftcard";
export const giftCardListUrl = `${_giftcard}/list`;
export const giftCardEditUrl = `${_giftcard}/edit`;
export const giftCardUrl = `${_giftcard}`;

// Notifications
const _notification = `${_users}/notification`;
export const notificationDismissUrl = `${_notification}/dismiss`;

// Retirement
const _retirement = "/retirement";
export const retirementInfoUrl = `${_retirement}/info`;

// Designations
const _designation = "/designation";
export const designationInfoUrl = `${_designation}/info`;
export const designationTransferUrl = `${_designation}/transfer`;
export const designationReassignUrl = `${_designation}/reassign`;

// College
const _college = "/college";
export const collegeInfoUrl = `${_college}/info`;
export const collegeScheduledTransferUrl = `${_college}/scheduled-transfer`;
export const collegeScheduledTransferEditUrl = `${_college}/scheduled-transfer/edit`;
export const collegeScheduledTransferExecuteUrl = `${_college}/scheduled-transfer/execute`;

/**
 * Protected URLs
 * @param {*} url
 */
export const isProtectedUrl = (url) => {
  return !(
    url === "" ||
    url.includes(registrationUrl) ||
    url.includes(activationUrl) ||
    url.includes(loginUrl) ||
    url.includes(logoutUrl) ||
    url.includes(infoCopyrightUrl) ||
    url.includes(userPasswordResetUrl) ||
    url.includes(userResetPasswordUrl)
  );
};
