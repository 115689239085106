import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import reduxStore from "./redux/store/index";
import "./Geldzin.css";
import { Header, Footer } from "./components/layout";
import Login from "./components/session/Login";
import UserDashboard from "./components/dashboard/UserDashboard";
import * as Links from "./library/Links";
import AuthenticatedRoute from "./components/session/AuthenticatedRoute";
import { setApiBaseUrl, setupSessionInterceptors } from "./services/WebService";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import AuthenticationService from "./services/AuthenticationService";
import Settings from "./components/account/Settings";
import AccountMessage from "./components/layout/AccountMessage";
import LoginHistory from "./components/account/LoginHistory";
import Finances from "./components/finance/Finances";
import FinancialAccount from "./components/finance/FinancialAccount";
import PasswordReset from "./components/session/PasswordReset";
import Register from "./components/registration/Register";
import Activate from "./components/registration/Activate";
import Reconciliation from "./components/reconciliation/Reconciliation";
import RewardHistory from "./components/finance/RewardHistory";
import PaymentHistory from "./components/finance/PaymentHistory";
import StockAnalysis from "./components/stocks/StockAnalysis";
import StockTransactions from "./components/stocks/StockTransactions";
import EmergencyPlan from "./components/emergencies/EmergencyPlan";
import Categories from "./components/categories/Categories";
import Category from "./components/categories/Category";
import Budget from "./components/budgets/Budget";
import Budgets from "./components/budgets/Budgets";
import SavingsGoal from "./components/savingsgoals/SavingsGoal";
import SavingsGoals from "./components/savingsgoals/SavingsGoals";
import BudgetAdjustment from "./components/reports/BudgetAdjustment";
import MonthlyBudget from "./components/budgets/MonthlyBudget";
import Transactions from "./components/transactions/Transactions";
import Healthcare from "./components/healthcare/Healthcare";
import HealthcareCard from "./components/healthcare/HealthcareCard";
import AccountStocks from "./components/stocks/AccountStocks";
import DesignationsInfo from "./components/designations/DesignationsInfo";
import IncomeAnalysis from "./components/reports/income/IncomeAnalysis";
import CashflowForecast from "./components/reports/income/CashflowForecast";
import SearchResults from "./components/search/SearchResults";
import GiftCards from "./components/giftcards/GiftCards";
import GiftCard from "./components/giftcards/GiftCard";
import Retirement from "./components/retirement/Retirement";
import CollegeInfo from "./components/college/CollegeInfo";
import AccountStockDetail from "./components/stocks/AccountStockDetail";

/*************************************************************************************
 * BASE API URL
 *************************************************************************************/
setApiBaseUrl();

/*************************************************************************************
 * SESSION INTERCEPTORS
 *************************************************************************************/
setupSessionInterceptors(reduxStore);

/*************************************************************************************
 * STATE MANAGEMENT ON PAGE RELOAD
 *************************************************************************************/
AuthenticationService.restoreLocalData(reduxStore);

/*************************************************************************************
 * ERROR PAGE
 *************************************************************************************/
function ErrorPage() {
  return (
    <div className="page-content">
      <h1>HTTP 400</h1>
      <p>Unknown URL request</p>
    </div>
  );
}

/*************************************************************************************
 * MAIN APPLICATION
 *************************************************************************************/
function Geldzin() {
  return (
    <div className="container page">
      <Provider store={reduxStore}>
        <Router>
          <Header />
          <AccountMessage />
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path={Links.login} exact component={Login} />
            <Route path={Links.logout} exact component={Login} />
            <Route path={Links.passwordReset} exact component={PasswordReset} />
            <Route path={Links.register} exact component={Register} />
            <Route path={Links.activate} exact component={Activate} />
            <AuthenticatedRoute
              path={Links.dashboard}
              component={UserDashboard}
            />
            <AuthenticatedRoute
              path={Links.admin}
              exact
              component={AdminDashboard}
            />
            <AuthenticatedRoute
              path={Links.settings}
              exact
              component={Settings}
            />
            <AuthenticatedRoute
              path={Links.loginHistory + "/:userid"}
              component={LoginHistory}
            />
            <AuthenticatedRoute
              path={Links.finances}
              exact
              component={Finances}
            />
            <AuthenticatedRoute
              path={Links.financeAccount + "/:financeId"}
              component={FinancialAccount}
            />
            <AuthenticatedRoute
              path={Links.reconciliation}
              exact
              component={Reconciliation}
            />
            <AuthenticatedRoute
              path={Links.reconciliation + "/:financeId"}
              component={Reconciliation}
            />
            <AuthenticatedRoute
              path={Links.paymentHistory + "/:financeId"}
              component={PaymentHistory}
            />
            <AuthenticatedRoute
              path={Links.rewardHistory + "/:financeId"}
              component={RewardHistory}
            />
            <AuthenticatedRoute
              path={Links.stockAnalysis + "/:financeId"}
              component={StockAnalysis}
            />
            <AuthenticatedRoute
              path={Links.stockTransactions + "/:financeId"}
              component={StockTransactions}
            />
            <AuthenticatedRoute
              path={Links.emergencies}
              exact
              component={EmergencyPlan}
            />
            <AuthenticatedRoute
              path={Links.categories}
              exact
              component={Categories}
            />
            <AuthenticatedRoute
              path={Links.categories + "/:categoryId"}
              component={Category}
            />
            <AuthenticatedRoute
              path={Links.budgets + "/:budgetId"}
              component={Budget}
            />
            <AuthenticatedRoute
              path={Links.budgets}
              exact
              component={Budgets}
            />
            <AuthenticatedRoute
              path={Links.savingsGoals + "/:savingsGoalId"}
              component={SavingsGoal}
            />
            <AuthenticatedRoute
              path={Links.reportBudgetAdjustments + "/:yearMonth"}
              component={BudgetAdjustment}
            />
            <AuthenticatedRoute
              path={Links.monthlyBudget}
              component={MonthlyBudget}
            />
            <AuthenticatedRoute
              path={Links.savingsGoals}
              exact
              component={SavingsGoals}
            />
            <AuthenticatedRoute
              path={Links.transactions}
              component={Transactions}
            />
            <AuthenticatedRoute
              path={Links.healthcareCard + "/:healthcareCardId"}
              component={HealthcareCard}
            />
            <AuthenticatedRoute
              path={Links.healthcare}
              component={Healthcare}
            />
            <AuthenticatedRoute
              path={Links.designations}
              component={DesignationsInfo}
            />
            <AuthenticatedRoute
              path={Links.incomeAnalysis}
              component={IncomeAnalysis}
            />
            <AuthenticatedRoute
              path={Links.cashflowForecast}
              component={CashflowForecast}
            />
            <AuthenticatedRoute path={Links.stocks} component={AccountStocks} />
            <AuthenticatedRoute path={Links.search} component={SearchResults} />
            <AuthenticatedRoute path={Links.giftcards} component={GiftCards} />
            <AuthenticatedRoute
              path={Links.giftcard + "/:id"}
              component={GiftCard}
            />
            <AuthenticatedRoute
              path={Links.retirement}
              component={Retirement}
            />
            <AuthenticatedRoute
              path={Links.accountStock + "/:stockId"}
              component={AccountStockDetail}
            />
            <AuthenticatedRoute path={Links.college} component={CollegeInfo} />
            <Route component={ErrorPage} />
          </Switch>
          <Footer />
        </Router>
      </Provider>
    </div>
  );
}

export default Geldzin;
