import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { fmtMoneyUSD, fmtNumber, pluralize } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

const UserDashboardRewards = (props) => {
  // Rewards
  const { rewardBalance, totalPoints, pointsValue, totalCash } =
    props.rewards || {};
  const giftcards = props.giftcards || {};

  // Render
  return (
    <div
      className="card"
      style={{ width: "20rem", marginBottom: "3px", marginTop: "3px" }}
    >
      <div className="card-body">
        <h5 className="card-title">
          <span style={{ marginRight: "8px" }}>
            <FontAwesomeIcon icon={faHandHoldingUsd} />
          </span>
          Rewards & Gifts
        </h5>
        <div
          className={classnames("card-block dashboard-big-amt", {
            "negative-amount": rewardBalance < 0.0,
            "positive-amount": rewardBalance >= 0.0,
          })}
        >
          {fmtMoneyUSD(rewardBalance)}
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="card-text">
              {fmtNumber(totalPoints, 0)} points/miles could be redeemed for{" "}
              <b>{fmtMoneyUSD(pointsValue)}</b>.
            </div>
            <div className="card-text">
              Cash rewards amount to <b>{fmtMoneyUSD(totalCash)}</b>
            </div>
          </li>
          <li className="list-group-item" style={{ borderBottom: "none" }}>
            {pluralize(giftcards.count, "gift card")} with{" "}
            <b>{fmtMoneyUSD(giftcards.balance)}</b> balance.
          </li>
        </ul>
      </div>
      <div className="card-footer">
        <Link to={Links.rewardHistory}>Reward History</Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to={Links.giftcards}>Gift Cards</Link>
      </div>
    </div>
  );
};

export default UserDashboardRewards;
