import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import {
  fmtMoneyUSD,
  pluralize,
  fullYearMonthName,
} from "../../library/Utilities";
import { doHttpGet } from "../../services/WebService";
import { transactionSplitUrl } from "../../library/Urls";
import Money from "../common/Money";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

export default class TransactionSplits extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleClose: props.onClose,
      errorMessage: null,
      batchNumber: props.batchNumber,
      transactions: [],
    };
  }

  componentDidMount = (evt) => {
    doHttpGet({
      url: transactionSplitUrl,
      params: {
        bn: this.state.batchNumber,
      },
      onSuccess: (listings) => {
        this.setState({
          transactions: listings,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    // Transactions
    const transactions = this.state.transactions || [];
    // Total
    let totalAmount = 0.0;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Reviewing {pluralize(transactions.length, "Transaction Split")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <table
            className="table table-md transaction-split"
            style={{ marginBottom: "0" }}
          >
            <thead className="thead-dark">
              <tr>
                <th scope="col">Description</th>
                <th scope="col">Categorization</th>
                <th scope="col">Financing</th>
                <th scope="col" className="text-right">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((t) => {
                // Add amount
                totalAmount += t.amount;
                // Render
                return (
                  <tr key={"tx-" + t.id}>
                    <td>
                      <div style={{ fontWeight: "bold" }}>{t.party}</div>
                      <div className="description">{t.description}</div>
                    </td>
                    <td>
                      <Categorization
                        categorization={t.categorization}
                        budgetMonth={t.budgetMonth}
                      />
                    </td>
                    <td>
                      <Financing financing={t.financing} />
                    </td>
                    <td className="text-right">
                      <Money amount={t.amount} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot className="table-footer">
              <tr>
                <td className="text-right" colSpan={3}>
                  Total Transaction Amount
                </td>
                <td className="text-right">{fmtMoneyUSD(totalAmount)}</td>
              </tr>
            </tfoot>
          </table>
        </Modal.Body>
      </Modal>
    );
  }
}

const Financing = (props) => {
  const financing = props.financing;
  let url = null;
  if (financing.type === "FINANCE") {
    url = Links.financeFor(financing.id);
  } else if (financing.type === "HEALTHCARE") {
    url = Links.healthcareCardById(financing.id);
  } else if (financing.type === "GIFTCARD") {
    url = Links.giftCardForId(financing.id);
  }
  let financeName = <span>{financing.name}</span>;
  if (url) {
    financeName = <Link to={url}>{financing.name}</Link>;
  }
  return (
    <Fragment>
      {financeName}
      <div>{financing.description}</div>
    </Fragment>
  );
};

const Categorization = (props) => {
  const categorization = props.categorization;
  const budgetMonth = fullYearMonthName(props.budgetMonth);
  let url = null;
  let isCashback = false;
  switch (categorization.type) {
    case "DEPOSIT":
    case "BANKING":
    case "INCOME":
      url = Links.categoryFor(categorization.id);
      break;
    case "CASHBACK":
      url = Links.financeFor(categorization.id);
      isCashback = true;
      break;
    case "BUDGET":
      url = Links.budgetFor(categorization.id);
      break;
    case "SAVINGS":
      url = Links.savingsGoalFor(categorization.id);
      break;
    default:
  }
  return (
    <Fragment>
      <Link to={url}>{categorization.name}</Link>
      <div style={{ fontStyle: "italic" }}>
        ({isCashback ? "CASHBACK" : budgetMonth})
      </div>
    </Fragment>
  );
};
