import React, { Component } from "react";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import AccountUsersTab from "./AccountUsersTab";
import { doHttpGet } from "../../services/WebService";
import { accountSettingsUrl } from "../../library/Urls";
import AccountFeaturesTab from "./AccountFeaturesTab";
import AccountSettings from "./AccountSettings";
import { Tabs, Tab } from "react-bootstrap";

class Settings extends Component {
  getSettings = () => {
    // List of accounts
    doHttpGet({
      url: accountSettingsUrl,
      onSuccess: (data) => {
        // Select the primary user
        if (!this.props.selectedUserId) {
          this.props._setSelectedUserId(this.props.currentUserId);
        }
        // Update settings
        this.props._setSettings(data);
      },
      onError: (error) => {
        this.props._handleError(error);
      },
    });
  };

  componentDidMount = (evt) => {
    this.getSettings();
  };

  render() {
    // Which tab
    var selectedTab = this.props.location.search || "account";
    if (selectedTab.match(/features/)) {
      selectedTab = "features";
    }
    // Return
    return (
      <div className="page-content">
        <h2>Account Settings</h2>

        <Tabs defaultActiveKey={selectedTab} id="account-settings-tabs">
          <Tab eventKey="account" title="Account & Users">
            <AccountUsersTab />
          </Tab>
          <Tab eventKey="features" title="Account Features">
            <AccountFeaturesTab />
          </Tab>
          <Tab eventKey="settings" title="Account Settings">
            <AccountSettings accountSettings={this.props.accountSettings} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedUserId: state._selections.userId,
    currentUserId: state.session.userId,
    errorMessage: null,
    accountSettings: state.accountSettings.settings || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Errors
    _handleError: (message) => {
      dispatch(Actions.setErrorMessage(message));
    },
    // Settings
    _setSettings: (settings) => {
      dispatch(Actions.setSettings(settings));
    },
    // User ID
    _setSelectedUserId: (id) => {
      dispatch(Actions.setSelectedUserId(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
