import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export default class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleAction: props.handleAction,
      handleClose: props.handleClose,
      title: props.title || messages[props._type].title,
      message: props.message || messages[props._type].message,
      question: props.question || messages[props._type].question,
      actionBtnText:
        props.btnAction || messages[props._type].btnAction || "Yes",
      cancelBtnText: props.btnCancel || "No",
    };
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>
            <FontAwesomeIcon icon={faExclamationCircle} /> &nbsp;{" "}
            {this.state.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="confirm-message"
            dangerouslySetInnerHTML={{ __html: this.state.message }}
          />
          {this.state.question && (
            <div className="confirm-question">{this.state.question}</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-md btn-danger"
            onClick={this.state.handleAction}
          >
            {this.state.actionBtnText}
          </button>
          <button
            type="button"
            className="btn btn-md btn-secondary"
            onClick={this.state.handleClose}
          >
            {this.state.cancelBtnText}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const messages = {
  category: {
    title: "Delete Category?",
    message:
      "The category will be deleted immediately, and will not be available for use in budgets, savings goals, or transactions.",
    question: "Are you sure about deleting this category?",
    btnAction: "Yes, Delete",
  },
  budget: {
    title: "Delete Budget?",
    message:
      "The budget will be deleted immediately, and will not be available for use in transactions.",
    question: "Are you sure about deleting this budget?",
    btnAction: "Yes, Delete",
  },
  savingsGoal: {
    title: "Delete Savings Goal?",
    message:
      "The savings goal will be deleted immediately, and will not be available for use in transactions.",
    question: "Are you sure about deleting this savings goal?",
    btnAction: "Yes, Delete",
  },
  transaction: {
    title: "Delete Transaction?",
    message:
      "The transaction will be deleted immediately, making adjustments to the various associated records.",
    question: "Are you sure about deleting this transaction?",
    btnAction: "Yes, Delete",
  },
  healthcareCard: {
    title: "Delete Healthcare Card?",
    message:
      "The healthcare card will be deleted immediately, and will not be available to record disbursements and other healthcare related transactions.",
    question: "Are you sure about deleting this healthcare card?",
    btnAction: "Yes, Delete",
  },
  giftCard: {
    title: "Delete Gift Card?",
    message:
      "The gift card will be deleted immediately, and will not be available for expense transactions.",
    question: "Are you sure about deleting this gift card?",
    btnAction: "Yes, Delete",
  },
};
