import React, { Component } from "react";
import { connect } from "react-redux";
import { isDefined, fmtDate } from "../../library/Utilities";
import ListedUser from "./ListedUser";
import UserDetails from "./UserDetails";
import * as Actions from "../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faTrash,
  faEdit,
  faTrashRestore
} from "@fortawesome/free-solid-svg-icons";
import EditAccount from "./EditAccount";
import { doHttpGet, doHttpDelete, doHttpPost } from "../../services/WebService";
import {
  accountSettingsUrl,
  accountDeleteUrl,
  accountRestoreUrl
} from "../../library/Urls";
import ConfirmDialog from "../common/ConfirmDialog";
import CreateUser from "./CreateUser";

class AccountUsersTab extends Component {
  state = {
    showAccountEditor: false,
    confirmAccountDelete: false,
    showUserCreate: false
  };

  edit = () => {
    this.setState({
      showAccountEditor: true
    });
  };

  closeAccountEditor = () => {
    this.setState({
      showAccountEditor: false
    });
  };

  updateSettings = () => {
    doHttpGet({
      url: accountSettingsUrl,
      onSuccess: data => {
        this.props._updateSettings(data);
      },
      onError: error => {
        this.props._handleError(error);
      }
    });
  };

  onAccountEdit = () => {
    // Close editor
    this.closeAccountEditor();
    // Update settings
    this.updateSettings();
  };

  accountDeletionConfirm = show => {
    this.setState({
      confirmAccountDelete: show
    });
  };

  deletion = () => {
    // Delete
    doHttpDelete({
      url: accountDeleteUrl,
      onSuccess: data => {
        // Close editor
        this.accountDeletionConfirm(false);
        // Update settings
        this.updateSettings();
        // Account deletion date
        this.props._updateAccountDeletionDate(data);
      },
      onError: error => {
        this.props._handleError(error);
      }
    });
  };

  restoration = () => {
    // Delete
    doHttpPost({
      url: accountRestoreUrl,
      onSuccess: () => {
        // Update settings
        this.updateSettings();
        // Account deletion date
        this.props._updateAccountDeletionDate(null);
      },
      onError: error => {
        this.props._handleError(error);
      }
    });
  };

  showUserCreate = show => {
    this.setState({ showUserCreate: show });
  };

  onUserCreate = userId => {
    // Close editor
    this.showUserCreate(false);
    // Set selected user
    this.props._setSelectedUserId(userId);
    // Update settings
    this.updateSettings();
  };

  render() {
    // State
    const _state = this.props;
    const _account = _state.account || {};
    const _users = _state.users || [];
    // Whether users can be added
    const canAddUsers = !_state.at_max_users && _state.is_primary;
    // Render
    return (
      <div className="row">
        <div className="col-lg-4" style={{ borderRight: "2px dotted gray" }}>
          <h6>ACCOUNT</h6>
          <table className="table table-lg">
            <tbody>
              <tr>
                <td className="label">Subscription</td>
                <td>{_account.subscription}</td>
              </tr>
              <tr>
                <td className="label">Zip code</td>
                <td>
                  <div style={{ marginBottom: "5px" }}>{_account.zip_code}</div>
                  {_state.is_primary && (
                    <button
                      type="button"
                      className="btn btn-md btn-success"
                      onClick={this.edit}
                    >
                      <FontAwesomeIcon icon={faEdit} /> &nbsp; Edit Account
                    </button>
                  )}
                </td>
              </tr>
              <tr>
                <td className="label">Date created</td>
                <td>{fmtDate(_account.date_created)}</td>
              </tr>
              <tr>
                <td className="label">Account status</td>
                <td>
                  <div style={{ marginBottom: "5px" }}>
                    {_account.account_status}
                  </div>
                  {_state.is_primary &&
                    (isDefined(_account.deletion_message) ? (
                      <button
                        type="button"
                        className="btn btn-md btn-primary"
                        onClick={this.restoration}
                      >
                        <FontAwesomeIcon icon={faTrashRestore} /> &nbsp; Restore
                        Account
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-md btn-danger"
                        onClick={() => this.accountDeletionConfirm(true)}
                      >
                        <FontAwesomeIcon icon={faTrash} /> &nbsp; Delete Account
                      </button>
                    ))}
                </td>
              </tr>
              {_account.deletion_message && (
                <tr className="alert alert-danger" role="alert">
                  <td colSpan="2">{_account.deletion_message}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="col-sm-3 account-users">
          <h6>USERS</h6>
          {_users.map(user => {
            return <ListedUser key={user.id} user={user} />;
          })}
          {canAddUsers && (
            <div style={{ marginTop: "20px" }}>
              <button
                type="button"
                className="btn btn-md btn-success"
                onClick={() => this.showUserCreate(true)}
              >
                <FontAwesomeIcon icon={faUserPlus} /> &nbsp; Additional User
              </button>
            </div>
          )}
        </div>

        <div className="col-sm-5">
          <UserDetails />
        </div>

        {this.state.showAccountEditor && (
          <EditAccount
            show={this.state.showAccountEditor}
            handleClose={this.closeAccountEditor}
            handleSave={this.onAccountEdit}
          />
        )}

        {this.state.showUserCreate && (
          <CreateUser
            show={this.state.showUserCreate}
            handleClose={() => this.showUserCreate(false)}
            handleSave={this.onUserCreate}
          />
        )}

        {this.state.confirmAccountDelete && (
          <ConfirmDialog
            show={this.state.confirmAccountDelete}
            handleAction={this.deletion}
            handleClose={() => this.accountDeletionConfirm(false)}
            title="Account Deletion"
            message={
              "The account will be scheduled for deletion a few days from now. " +
              "At that time, all account information will be deleted permanently and irreversibly."
            }
            question="Are you sure you want to delete the account?"
            btnAction="Yes, Delete"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.accountSettings,
    accountId: state.session.accountId,
    errorMessage: null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // Errors
    _handleError: message => {
      dispatch(Actions.setErrorMessage(message));
    },
    // Settings
    _updateSettings: settings => {
      dispatch(Actions.setSettings(settings));
    },
    // Account deletion date
    _updateAccountDeletionDate: date => {
      dispatch(Actions.setAccountDeletionDate(date));
    },
    // User ID
    _setSelectedUserId: id => {
      dispatch(Actions.setSelectedUserId(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountUsersTab);
