import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import {
  fmtMoneyUSD,
  fmtNumber,
  fullYearMonthName,
  pluralize,
} from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import Chart from "chart.js/auto";

class CashflowChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    // Income and expense
    const { history } = this.props;

    // Data
    const labels = [],
      inflows_data = [],
      inflows_ma = [],
      inflows_tx = [],
      outflows_data = [],
      outflows_ma = [],
      outflows_tx = [];
    history.forEach((item) => {
      labels.push(item.name);
      inflows_data.push(item.inflowAmt);
      inflows_ma.push(item.maInflows);
      inflows_tx.push(item.inflowCount);
      outflows_data.push(item.outflowAmt);
      outflows_ma.push(item.maOutflows);
      outflows_tx.push(item.outflowCount);
    });

    // Chart
    const cashflowChartRef = this.chartRef.current.getContext("2d");
    new Chart(cashflowChartRef, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Inflows MA",
            data: inflows_ma,
            transactions: inflows_tx,
            borderColor: "blue",
            type: "line",
            fill: false,
            borderWidth: 2,
            pointBackgroundColor: "blue",
          },
          {
            label: "Outflows MA",
            data: outflows_ma,
            transactions: outflows_tx,
            borderColor: "red",
            type: "line",
            fill: false,
            borderWidth: 2,
            pointBackgroundColor: "red",
          },
          {
            label: "Inflows",
            data: inflows_data,
            transactions: inflows_tx,
            backgroundColor: "rgb(74, 181, 235)",
            type: "bar",
          },
          {
            label: "Outflows",
            data: outflows_data,
            transactions: outflows_tx,
            backgroundColor: "rgb(252, 104, 104)",
            type: "bar",
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value, index, values) {
                  return (value + "").replace(/000$/, "k");
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: (tooltipItem, data) => {
              const index = tooltipItem[0].index;
              let title = data.labels[index];
              const _month = parseInt(title.replace(/'.+/, ""), 10);
              const _year = title.replace(/.+'/, "");
              const _ym = "20" + _year + (_month <= 9 ? "0" : "") + _month;
              return fullYearMonthName(_ym);
            },
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";
              if (label) {
                const _transactions =
                  data.datasets[tooltipItem.datasetIndex].transactions[
                    tooltipItem.index
                  ];
                label +=
                  ": " +
                  fmtMoneyUSD(tooltipItem.yLabel) +
                  " (" +
                  fmtNumber(_transactions) +
                  ")";
              }
              return label;
            },
          },
        },
      },
    });
  }

  render() {
    const { height } = this.props;
    return (
      <div>
        <canvas id="cashflow-chart" ref={this.chartRef} height={height} />
      </div>
    );
  }
}

const UserDashboardCashflow = (props) => {
  // Income and expense
  const { history } = props.cashflow || {};
  const currentMonth = history[history.length - 1];

  // Render
  return (
    <div
      className="card"
      style={{
        width: "20rem",
        marginBottom: "3px",
      }}
    >
      <div className="card-body" style={{ paddingBottom: 0 }}>
        <h5 className="card-title">
          <span style={{ marginRight: "8px" }}>
            <FontAwesomeIcon icon={faCoins} />
          </span>
          Cashflow
        </h5>
        <table
          className="table table-sm table-bordered dashboard-income-expense"
          style={{ marginTop: "10px" }}
        >
          <thead>
            <tr>
              <th colSpan={3}>{fullYearMonthName(currentMonth.month)}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="label">Inflows</td>
              <td className="amount income">
                {fmtMoneyUSD(currentMonth.inflowAmt)}
              </td>
              <td className="transactions">
                {pluralize(currentMonth.inflowCount, "transaction")}
              </td>
            </tr>
            <tr>
              <td className="label">Outflows</td>
              <td className="amount expense">
                {fmtMoneyUSD(currentMonth.outflowAmt)}
              </td>
              <td className="transactions">
                {pluralize(currentMonth.outflowCount, "transaction")}
              </td>
            </tr>
          </tbody>
        </table>
        <CashflowChart height="135px" history={history} />
      </div>
      <div className="card-footer">
        <Link to={Links.incomeAnalysis}>Income analysis</Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to={Links.cashflowForecast}>Forecast</Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to={Links.transactions}>Transactions</Link>
      </div>
    </div>
  );
};

export default UserDashboardCashflow;
