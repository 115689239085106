import React, { Component, Fragment } from "react";
import classnames from "classnames";
import { isDefined } from "../../library/Utilities";
import { doHttpPost } from "../../services/WebService";
import {
  adminScheduleMidnightUrl,
  adminUndoBudgetAdjustmentsUrl,
  adminStockPriceUpdateUrl,
} from "../../library/Urls";

export default class Schedules extends Component {
  state = {
    midnight: { success: false, response: null },
    undoBudgetAdjustments: { success: false, response: null },
    stockUpdateStarted: { success: false, response: null },
  };

  executeMidnightSchedule = () => {
    doHttpPost({
      url: adminScheduleMidnightUrl,
      onSuccess: (response) => {
        this.setState({
          midnight: {
            success: response.success,
            response:
              "Successful at " +
              response.timestamp +
              " (duration = " +
              response.duration +
              ")",
          },
        });
      },
      onError: (errorMessage) => {
        this.setState({
          midnight: {
            success: false,
            response: errorMessage,
          },
        });
      },
    });
  };

  executeUndoBudgetAdjustments = () => {
    doHttpPost({
      url: adminUndoBudgetAdjustmentsUrl,
      onSuccess: (response) => {
        this.setState({
          undoBudgetAdjustments: {
            success: true,
            response: response,
          },
        });
      },
      onError: (errorMessage) => {
        this.setState({
          undoBudgetAdjustments: {
            success: false,
            response: errorMessage,
          },
        });
      },
    });
  };

  executeStockPriceUpdate = () => {
    doHttpPost({
      url: adminStockPriceUpdateUrl,
      onSuccess: (response) => {
        this.setState({
          stockUpdateStarted: {
            success: true,
            response: response,
          },
        });
      },
      onError: (errorMessage) => {
        this.setState({
          stockUpdateStarted: {
            success: false,
            response: errorMessage,
          },
        });
      },
    });
  };

  render() {
    const isMidnight = isDefined(this.state.midnight.response);
    const isUndoBudgetAdjustments = isDefined(
      this.state.undoBudgetAdjustments.response
    );
    const isStockUpdateStarted = isDefined(
      this.state.stockUpdateStarted.response
    );
    // Render
    return (
      <Fragment>
        <h3>Active Schedules</h3>
        <table className="table table-md table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col" style={{ width: "30%" }}>
                Description
              </th>
              <th scope="col" style={{ width: 120 }}>
                Actions
              </th>
              <th scope="col">Response</th>
            </tr>
          </thead>
          <tbody>
            <tr key="midnight">
              <td>Daily at midnight</td>
              <td>
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  onClick={this.executeMidnightSchedule}
                >
                  Run Now
                </button>
              </td>
              <td
                className={classnames("", {
                  alert: isMidnight,
                  "alert-danger": isMidnight && !this.state.midnight.success,
                  "alert-success": isMidnight && this.state.midnight.success,
                })}
              >
                {this.state.midnight.response}
              </td>
            </tr>
            <tr key="undo_budget_adjustments">
              <td>Undo current month budget adjustments</td>
              <td>
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  onClick={this.executeUndoBudgetAdjustments}
                >
                  Run Now
                </button>
              </td>
              <td
                className={classnames("", {
                  alert: isUndoBudgetAdjustments,
                  "alert-danger":
                    isUndoBudgetAdjustments &&
                    !this.state.undoBudgetAdjustments.success,
                  "alert-success":
                    isUndoBudgetAdjustments &&
                    this.state.undoBudgetAdjustments.success,
                })}
              >
                {this.state.undoBudgetAdjustments.response}
              </td>
            </tr>
            <tr key="start_stock_price_update">
              <td>Start latest stock price update</td>
              <td>
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  onClick={this.executeStockPriceUpdate}
                >
                  Start Update
                </button>
              </td>
              <td
                className={classnames("", {
                  alert: isStockUpdateStarted,
                  "alert-danger":
                    isStockUpdateStarted &&
                    !this.state.stockUpdateStarted.success,
                  "alert-success":
                    isStockUpdateStarted &&
                    this.state.stockUpdateStarted.success,
                })}
              >
                {this.state.stockUpdateStarted.response}
              </td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  }
}
