import React, { Component } from "react";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import {
  savingsGoalInfoUrl,
  savingsGoalDeleteUrl,
  savingsGoalStatusUrl,
} from "../../library/Urls";
import PageNotFound from "../common/PageNotFound";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import { doHttpPost } from "../../services/WebService";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditSavingsGoal from "./EditSavingsGoal";
import ConfirmDialog from "../common/ConfirmDialog";
import GoalProgress from "./GoalProgress";
import ProvisionsList from "../common/ProvisionsList";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import TransactionListings from "../transactions/TransactionListings";
import { Tabs, Tab } from "react-bootstrap";
import DesignationFinancesList from "../common/DesignationFinancesList";

export default class SavingsGoal extends Component {
  constructor(props) {
    super(props);
    // State
    this.state = {
      errorMessage: null,
      confirmDelete: false,
      savingsGoalId: props.match.params.savingsGoalId,
    };
  }

  getSavingsGoalInfo = () => {
    doHttpGet({
      url: savingsGoalInfoUrl(this.state.savingsGoalId),
      onSuccess: (data) => {
        this.setState({
          info: data,
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  componentDidMount = (evt) => {
    // Savingsg goal ID
    if (!this.state.savingsGoalId) {
      this.setState({
        errorMessage:
          "To retrieve savings goal details, a valid ID must be provided in the request URL",
      });
      return;
    }
    // Savings goal info
    this.getSavingsGoalInfo();
  };

  showEditor = (show) => {
    this.setState({
      showEditor: show,
    });
  };

  onEditSavingsGoal = () => {
    this.showEditor(false);
    this.getSavingsGoalInfo();
  };

  confirmDelete = (show) => {
    this.setState({
      confirmDelete: show,
    });
  };

  onConfirmDelete = () => {
    // Close confirmation dialog
    this.confirmDelete(false);
    // Delete account
    doHttpDelete({
      url: savingsGoalDeleteUrl(this.state.savingsGoalId),
      onSuccess: () => {
        // Go to list of categories
        this.props.history.push(Links.savingsGoals);
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  updateStatus = (status) => {
    doHttpPost({
      url: savingsGoalStatusUrl,
      body: {
        id: this.state.savingsGoalId,
        status: status,
      },
      onSuccess: () => {
        this.getSavingsGoalInfo();
      },
      onError: (errorMessage) => {
        this.setState({
          errorMessage: errorMessage,
        });
      },
    });
  };

  render() {
    // Info
    const { info, errorMessage } = this.state;
    if (!info) {
      if (errorMessage) {
        return <PageNotFound title="Savings Goal" message={errorMessage} />;
      }
      return <div />;
    }
    const provisions = info.provisions || [];
    const statuses = info.statuses || [];
    // Transactions
    const transactions = info.transactions || [];
    // Designations
    const designations = info.designations || [];

    // Render
    return (
      <div className="page-content">
        <div className="row" style={{ marginBottom: "15px" }}>
          <div className="col">
            <h2>
              {info.name}
              <span className="gzn-detail-flag">Savings Goal</span>
            </h2>
          </div>
        </div>

        <div className="row" style={{ maxHeight: "400px" }}>
          <div className="col-5">
            <div
              style={{
                marginBottom: "10px",
                borderLeft: "10px solid gray",
                padding: "5px",
              }}
            >
              {info.description}
            </div>
            <table className="table table-md table-striped tbl-details">
              <tbody>
                <tr>
                  <td className="label">Category:</td>
                  <td>
                    <Link to={Links.categoryFor(info.category_id)}>
                      {info.category_name}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="label">Start Date:</td>
                  <td>{fmtDate(info.start_date)}</td>
                </tr>
                <tr>
                  <td className="label">Close Date:</td>
                  <td>{fmtDate(info.close_date)}</td>
                </tr>
                <tr>
                  <td className="label">Status:</td>
                  <td>{info.status}</td>
                </tr>
                <tr>
                  <td className="label">Priority:</td>
                  <td>{info.priority}</td>
                </tr>
                <tr>
                  <td className="label">Monthly Limit:</td>
                  <td>
                    {info.monthly_limit ? fmtMoneyUSD(info.monthly_limit) : ""}
                  </td>
                </tr>
                <tr>
                  <td className="text-right" colSpan="2">
                    <ChangeStatusSelect
                      statuses={statuses}
                      updateStatus={this.updateStatus}
                    />
                    {info.is_editable && (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => this.showEditor(true)}
                      >
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </button>
                    )}
                    {info.is_deletable && (
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        style={{ marginLeft: "10px" }}
                        onClick={() => this.confirmDelete(true)}
                      >
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-7">
            <GoalProgress info={info} />
            <Tabs defaultActiveKey="provisions" id="budget-info-tab">
              <Tab
                eventKey="provisions"
                title={"Provisions (" + provisions.length + ")"}
              >
                <ProvisionsList
                  provisions={provisions}
                  tableHeight={175}
                  isBudget={false}
                />
              </Tab>
              <Tab
                eventKey="designations"
                title={"Designations (" + designations.length + ")"}
              >
                <DesignationFinancesList
                  designations={designations}
                  tableHeight={175}
                  refreshInfoCallback={this.getSavingsGoalInfo}
                />
              </Tab>
            </Tabs>
          </div>
        </div>

        <TransactionListings
          transactions={transactions}
          displayCategorization={false}
        />

        {this.state.showEditor && (
          <EditSavingsGoal
            show={this.state.showEditor}
            onSave={this.onEditSavingsGoal}
            onClose={() => this.showEditor(false)}
            savingsGoalId={this.state.savingsGoalId}
          />
        )}

        {this.state.confirmDelete && (
          <ConfirmDialog
            show={this.state.confirmDelete}
            handleAction={this.onConfirmDelete}
            handleClose={() => this.confirmDelete(false, null)}
            _type="savingsGoal"
          />
        )}
      </div>
    );
  }
}

const ChangeStatusSelect = (props) => {
  // Statuses
  const statuses = props.statuses;
  return (
    <DropdownButton
      id="dropdown-basic-button"
      title="Change Status"
      variant="primary"
      style={{ display: "inline", marginRight: "15px" }}
      size="sm"
    >
      {statuses.map((status) => {
        return (
          <Dropdown.Item
            key={status.id}
            onSelect={() => props.updateStatus(status.id)}
          >
            {status.name}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};
