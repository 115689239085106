import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import {
  emergencyCreatingUrl,
  emergencyEditUrl,
  emergencySaveUrl
} from "../../library/Urls";
import { isValidUsMoney, toUsMoney } from "../../library/Utilities";
import DateSelector from "../common/DateSelector";

const errorFieldMappings = {
  description: "description",
  amount: "amount",
  status: "status",
  expiration: "expiration"
};

class EditEmergency extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      emergency: {
        id: props.emergencyId,
        description: "",
        amount: "",
        status: "ACTIVE",
        expiration: null,
        statuses: []
      },
      emergencyId: props.emergencyId,
      errorMessage: null,
      actionsEnabled: false
    };
  }

  componentDidMount = evt => {
    // Whether creating or editing
    const url = this.state.emergencyId
      ? emergencyEditUrl(this.state.emergencyId)
      : emergencyCreatingUrl;

    // Initial data
    doHttpGet({
      url: url,
      onSuccess: data => {
        this.setState({
          emergency: {
            ...data,
            amount: data.amount.toFixed(2),
            expiration: data.expiration ? new Date(data.expiration) : null
          },
          actionsEnabled: true,
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false
    });

    // Post
    doHttpPost({
      url: emergencySaveUrl,
      body: {
        ...values,
        id: this.state.emergencyId,
        amount: toUsMoney(values.amount)
      },
      onSuccess: emergencyId => {
        // Handle save
        this.state.onSave(emergencyId);
      },
      rawError: true,
      onError: error => {
        this.setState({
          actionsEnabled: true
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach(error => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage
          });
        }
      }
    });
  };

  validate = values => {
    const errors = {};
    // All inputs are required
    if (!values.description) {
      errors.description = "Description is required";
    }
    if (!values.status) {
      errors.status = "Status selection is required";
    }
    if (!values.amount) {
      errors.amount = "Amount is required";
    } else if (!isValidUsMoney(values.amount)) {
      errors.amount = "Invalid monetary amount";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const {
      id,
      description,
      amount,
      status,
      expiration
    } = this.state.emergency;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{id ? "Update" : "Create"} Emergency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px"
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              description,
              amount,
              status,
              expiration
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {props => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Description</label>
                  <Field
                    className="form-control"
                    as="textarea"
                    name="description"
                  ></Field>
                  <ErrorMessage
                    name="description"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Estimated amount</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="amount"
                  ></Field>
                  <ErrorMessage
                    name="amount"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <label className="control-label required">Status</label>
                  <Field className="form-control" as="select" name="status">
                    {this.state.emergency.statuses.map(s => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="status"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <label className="control-label required">Expiration</label>
                  <div>
                    <DateSelector
                      className="form-control"
                      name="expiration"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <ErrorMessage
                    name="expiration"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.emergencyId ? "Update" : "Create"}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditEmergency;
