import React from "react";
import classnames from "classnames";
import Money from "../common/Money";
import { fmtMoneyUSD, fmtNumber } from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

export const PreviousStatementsCollege = ({
  statements,
  reconciliationId,
  editReconciliation,
  confirmReconciliationDelete,
}) => {
  return (
    <table className="table table-md table-striped table-fixed tbl-prev-reconciliations">
      <thead className="thead-dark">
        <tr>
          <th scope="col" style={{ width: 100 }}>
            Date
          </th>
          <th scope="col" className="text-right">
            Balance
          </th>
          <th scope="col" className="text-right">
            Earnings
          </th>
          <th scope="col" className="text-right">
            Fees
          </th>
          <th scope="col" className="text-right">
            Transactions
          </th>
          <th scope="col" className="text-right">
            Change
          </th>
          <th scope="col" className="text-right" style={{ width: 90 }}>
            Actions
          </th>
        </tr>
      </thead>
      <tbody style={{ maxHeight: 500 }}>
        {statements.map((statement) => {
          const collegeInfo = statement.collegeInfo;
          return (
            <tr
              key={"stmt-" + statement.id}
              className={classnames("", {
                "row-selected": reconciliationId === statement.id,
              })}
            >
              <td style={{ width: 100 }}>{statement.date}</td>
              <td className="text-right">
                <Money amount={statement.balance} />
              </td>
              <td className="text-right">
                <Money amount={collegeInfo.earningsAmount} />
              </td>
              <td className="text-right">{fmtMoneyUSD(statement.feesTotal)}</td>
              <td className="text-right">
                {fmtMoneyUSD(statement.transactionTotal)} (
                {statement.transactionCount})
              </td>
              <td className="text-right">
                {fmtMoneyUSD(collegeInfo.marketChangeAmount)} (
                {fmtNumber(collegeInfo.marketChangePercent, 1)}%)
              </td>
              <td className="text-right" style={{ width: 90 }}>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: 8 }}
                  onClick={() => editReconciliation(statement.id)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    confirmReconciliationDelete(statement.id, statement.date)
                  }
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
