import React from "react";
import { fmtMoneyUSD, pluralize } from "../../library/Utilities";
import classnames from "classnames";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";

const UserDashboardFinancials = (props) => {
  // Financials
  const {
    totalAccounts,
    totalBalance,
    cashCount,
    cashBalance,
    checkingCount,
    checkingBalance,
    savingsCount,
    savingsBalance,
    investmentCount,
    investmentBalance,
    creditCount,
    creditBalance,
    retirementCount,
    retirementBalance,
    collegeCount,
    collegeBalance,
  } = props.financials || {};

  return (
    <div
      className="card"
      style={{
        width: "20rem",
        marginLeft: "3px",
        marginBottom: "3px",
        marginTop: "3px",
      }}
    >
      <div className="card-body">
        <h5 className="card-title">
          <span style={{ marginRight: "8px" }}>
            <FontAwesomeIcon icon={faPiggyBank} />
          </span>
          Finances ({totalAccounts})
        </h5>
        <div
          className={classnames("card-block dashboard-big-amt", {
            "negative-amount": totalBalance < 0.0,
            "positive-amount": totalBalance >= 0.0,
          })}
        >
          {fmtMoneyUSD(totalBalance)}
        </div>
        {cashCount > 0 && (
          <div className="card-text">
            {pluralize(cashCount, "cash account")} with{" "}
            <b>{fmtMoneyUSD(cashBalance)}</b>
          </div>
        )}
        {checkingCount > 0 && (
          <div className="card-text">
            {pluralize(checkingCount, "checking account")} with{" "}
            <b>{fmtMoneyUSD(checkingBalance)}</b>
          </div>
        )}
        {savingsCount > 0 && (
          <div className="card-text">
            {pluralize(savingsCount, "savings account")} with{" "}
            <b>{fmtMoneyUSD(savingsBalance)}</b>
          </div>
        )}
        {investmentCount > 0 && (
          <div className="card-text">
            {pluralize(investmentCount, "investment account")} with{" "}
            <b>{fmtMoneyUSD(investmentBalance)}</b>
          </div>
        )}
        {creditCount > 0 && (
          <div
            className={classnames("card-text", {
              "negative-amount": creditBalance < 0.0,
            })}
          >
            {pluralize(creditCount, "credit account")} totaling{" "}
            <b>{fmtMoneyUSD(creditBalance)}</b>
          </div>
        )}
        {retirementCount > 0 && (
          <div className="card-text">
            {pluralize(retirementCount, "retirement account")} with{" "}
            <b>{fmtMoneyUSD(retirementBalance)}</b>
          </div>
        )}
        {collegeCount > 0 && (
          <div className="card-text">
            {pluralize(collegeCount, "college account")} with{" "}
            <b>{fmtMoneyUSD(collegeBalance)}</b>
          </div>
        )}
      </div>
      <div className="card-footer">
        <Link to={Links.finances}>Manage financial accounts</Link>
      </div>
    </div>
  );
};

export default UserDashboardFinancials;
