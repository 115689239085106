import React from "react";

export default function Checkbox({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  ...props
}) {
  return (
    <div className="custom-control custom-checkbox" style={props.style}>
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        className="custom-control-input"
        style={{ width: "20px", height: "20px", verticalAlign: "bottom" }}
      />
      <label
        className="custom-control-label"
        htmlFor={id}
        style={{
          verticalAlign: "top",
          lineHeight: "20px",
          margin: "2px 0",
          display: "block",
          height: "20px",
        }}
      >
        {label}
      </label>
    </div>
  );
}
