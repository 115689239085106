import React, { Component } from "react";
import { infoCopyrightUrl } from "../../library/Urls";
import { doHttpGet } from "../../services/WebService";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    // State
    this.state = {
      copyright: ""
    };
  }

  componentDidMount = evt => {
    doHttpGet({
      url: infoCopyrightUrl,
      onSuccess: data => {
        this.setState({
          copyright: data
        });
      }
    });
  };

  render() {
    return (
      <div
        className="footer"
        dangerouslySetInnerHTML={{ __html: this.state.copyright }}
      />
    );
  }
}
