import React, { Component } from "react";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import { financesUrl, financeDeleteUrl } from "../../library/Urls";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faMoneyBill,
  faLandmark,
  faPiggyBank,
  faChartLine,
  faCoins,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ConfirmDialog from "../common/ConfirmDialog";
import NotificationSystem from "react-notification-system";
import EditCashAccount from "./EditCashAccount";
import EditCheckingAccount from "./EditCheckingAccount";
import EditSavingsAccount from "./EditSavingsAccount";
import EditCreditAccount from "./EditCreditAccount";
import EditInvestmentAccount from "./EditInvestmentAccount";

const FinanceTypes = {
  Credit: "Credit",
  Checking: "Checking",
  Savings: "Savings",
  Investment: "Investment",
  Cash: "Cash"
};

export const Icon = props => {
  let faIcon = faMoneyBill;
  switch (props.type) {
    case FinanceTypes.Credit:
      faIcon = faCreditCard;
      break;
    case FinanceTypes.Checking:
      faIcon = faLandmark;
      break;
    case FinanceTypes.Savings:
      faIcon = faPiggyBank;
      break;
    case FinanceTypes.Investment:
      faIcon = faChartLine;
      break;
    case FinanceTypes.Cash:
      faIcon = faCoins;
      break;
    default:
  }
  return <FontAwesomeIcon icon={faIcon} />;
};

class Finances extends Component {
  notificationSystem = React.createRef();

  state = {
    errorMessage: null,
    selectedId: null,
    confirmDelete: false
  };

  getFinances = includeDisabled => {
    // List of accounts
    doHttpGet({
      url: financesUrl(includeDisabled),
      onSuccess: info => {
        // Update info
        this.props._setFinancialInfo(info);
        // Clear error
        this.setState({
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  includeDisabled = evt => {
    // Whether selected
    const checked = evt.target.checked;
    this.props._includeDisabledAccounts(checked);
    // Update info
    this.getFinances(checked);
  };

  componentDidMount = evt => {
    this.getFinances(this.props.includeDisabled);
  };

  editFinance = (financeId, financeType) => {
    this.setState({
      showCashEditor: financeType === FinanceTypes.Cash,
      showCheckingEditor: financeType === FinanceTypes.Checking,
      showSavingsEditor: financeType === FinanceTypes.Savings,
      showInvestmentEditor: financeType === FinanceTypes.Investment,
      showCreditEditor: financeType === FinanceTypes.Credit,
      selectedId: financeId
    });
  };

  showCashEditor = show => {
    this.setState({ showCashEditor: show });
  };

  onEditCashAccount = financeId => {
    this.showCashEditor(false);
    this.setState({
      selectedId: financeId
    });
    this.getFinances(this.props.includeDisabled);
  };

  showCheckingEditor = show => {
    this.setState({ showCheckingEditor: show });
  };

  onEditCheckingAccount = financeId => {
    this.showCheckingEditor(false);
    this.setState({
      selectedId: financeId
    });
    this.getFinances(this.props.includeDisabled);
  };

  showSavingsEditor = show => {
    this.setState({ showSavingsEditor: show });
  };

  onEditSavingsAccount = financeId => {
    this.showSavingsEditor(false);
    this.setState({
      selectedId: financeId
    });
    this.getFinances(this.props.includeDisabled);
  };

  showCreditEditor = show => {
    this.setState({ showCreditEditor: show });
  };

  onEditCreditAccount = financeId => {
    this.showCreditEditor(false);
    this.setState({
      selectedId: financeId
    });
    this.getFinances(this.props.includeDisabled);
  };

  showInvestmentEditor = show => {
    this.setState({ showInvestmentEditor: show });
  };

  onEditInvestmentAccount = financeId => {
    this.showInvestmentEditor(false);
    this.setState({
      selectedId: financeId
    });
    this.getFinances(this.props.includeDisabled);
  };

  confirmDelete = (show, financeId, accountName) => {
    this.setState({
      confirmDelete: show,
      selectedId: financeId,
      accountName: accountName
    });
  };

  onConfirmDelete = () => {
    // Close confirmation dialog
    this.confirmDelete(false, null, this.state.accountName);
    // Delete account
    doHttpDelete({
      url: financeDeleteUrl(this.state.selectedId),
      onSuccess: () => {
        // Update view
        this.getFinances(this.props.includeDisabled);
        // Notification
        const notification = this.notificationSystem.current;
        notification.addNotification({
          title: "Financial Account Deleted",
          message:
            "Financial account '" +
            this.state.accountName +
            "' was successfully deleted",
          level: "success",
          position: "br",
          autoDismiss: 5
        });
      },
      onError: error => {
        this.props._handleError(error);
      }
    });
  };

  render() {
    // Logins
    const info = this.props.financialInfo || {};
    const accounts = info.financial_accounts || [];
    // Render
    return (
      <div className="page-content">
        {this.state.errorMessage && (
          <div className="alert alert-danger">{this.state.errorMessage}</div>
        )}

        <div className="row">
          <div className="col-8">
            <h2>Financial Accounts ({accounts.length})</h2>
          </div>
          <div
            className="col-4"
            style={{ textAlign: "right", verticalAlign: "bottom" }}
          >
            <DropdownButton
              id="dropdown-basic-button"
              title="Create an account"
              variant="success"
            >
              <Dropdown.Item
                onSelect={() => this.editFinance(null, FinanceTypes.Cash)}
              >
                Cash Account
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => this.editFinance(null, FinanceTypes.Checking)}
              >
                Checking Account
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => this.editFinance(null, FinanceTypes.Savings)}
              >
                Savings Account
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => this.editFinance(null, FinanceTypes.Credit)}
              >
                Credit Account
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => this.editFinance(null, FinanceTypes.Investment)}
              >
                Investment Account
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>

        <div className="form-check" style={{ marginBottom: "10px" }}>
          <input
            className="form-check-input"
            name="includeDisabledCbx"
            type="checkbox"
            onChange={this.includeDisabled}
          />
          <label className="form-check-label" htmlFor="includeDisabledCbx">
            Include disabled accounts
          </label>
        </div>

        <table className="table table-bordered table-lg table-fin-accts">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Reconciled</th>
              <th scope="col">Balance</th>
              <th scope="col" style={{ width: "110px" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {accounts.map(fa => (
              <tr
                key={"fa-" + fa.id}
                className={classnames("", {
                  "fin-emergency": fa.is_emergency,
                  "fin-negative-bal": fa.balance < 0,
                  "fin-disabled": !fa.is_enabled,
                  focus: fa.id === this.state.selectedId
                })}
              >
                <th scope="row">
                  <Icon type={fa.type} /> &nbsp;{" "}
                  <Link to={Links.financeAccount + `/${fa.id}`}>{fa.name}</Link>
                </th>
                <td>{fa.description}</td>
                <td>{fmtDate(fa.date_reconciled)}</td>
                <td className="text-right">{fmtMoneyUSD(fa.balance)}</td>
                <td className="text-right">
                  {fa.is_editable && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.editFinance(fa.id, fa.type)}
                    >
                      <FontAwesomeIcon icon={faEdit} /> Edit
                    </button>
                  )}{" "}
                  &nbsp;{" "}
                  {fa.is_deletable && (
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => this.confirmDelete(true, fa.id, fa.name)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="table-footer">
            <tr>
              <th colSpan="3" className="text-right">
                Total Balance
              </th>
              <th className="text-right">{fmtMoneyUSD(info.total_balance)}</th>
              <th></th>
            </tr>
          </tfoot>
        </table>

        {this.state.showCashEditor && (
          <EditCashAccount
            show={this.state.showCashEditor}
            handleSave={this.onEditCashAccount}
            handleClose={() => this.showCashEditor(false)}
            financeId={this.state.selectedId}
          />
        )}

        {this.state.showCheckingEditor && (
          <EditCheckingAccount
            show={this.state.showCheckingEditor}
            handleSave={this.onEditCheckingAccount}
            handleClose={() => this.showCheckingEditor(false)}
            financeId={this.state.selectedId}
          />
        )}

        {this.state.showSavingsEditor && (
          <EditSavingsAccount
            show={this.state.showSavingsEditor}
            handleSave={this.onEditSavingsAccount}
            handleClose={() => this.showSavingsEditor(false)}
            financeId={this.state.selectedId}
          />
        )}

        {this.state.showCreditEditor && (
          <EditCreditAccount
            show={this.state.showCreditEditor}
            handleSave={this.onEditCreditAccount}
            handleClose={() => this.showCreditEditor(false)}
            financeId={this.state.selectedId}
          />
        )}

        {this.state.showInvestmentEditor && (
          <EditInvestmentAccount
            show={this.state.showInvestmentEditor}
            handleSave={this.onEditInvestmentAccount}
            handleClose={() => this.showInvestmentEditor(false)}
            financeId={this.state.selectedId}
          />
        )}

        {this.state.confirmDelete && (
          <ConfirmDialog
            show={this.state.confirmDelete}
            handleAction={this.onConfirmDelete}
            handleClose={() => this.confirmDelete(false, null)}
            title="Delete Account?"
            message="The financial account will be deleted immediately, and will not be available for use in transactions and budgeting thereafter."
            question="Are you sure about deleting this financial account?"
            btnAction="Yes, Delete"
          />
        )}

        <NotificationSystem ref={this.notificationSystem} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    includeDisabled: state.finances.includeDisabled,
    financialInfo: state.finances.financialInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // Errors
    _handleError: message => {
      dispatch(Actions.setErrorMessage(message));
    },
    // Financial info
    _setFinancialInfo: info => {
      dispatch(Actions.setFinanceInfo(info));
    },
    // Whether to include disabled accounts
    _includeDisabledAccounts: include => {
      dispatch(Actions.setIncludeDisabledFinances(include));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Finances);
