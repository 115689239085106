import React, { Component } from "react";
import { connect } from "react-redux";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import EditEmergency from "./EditEmergency";
import NotificationSystem from "react-notification-system";
import { emergencyDeleteUrl } from "../../library/Urls";
import { doHttpDelete } from "../../services/WebService";
import ConfirmDialog from "../common/ConfirmDialog";

class Emergencies extends Component {
  notificationSystem = React.createRef();

  constructor(props) {
    super(props);
    // Initial
    this.state = {
      selectedId: null,
      fxUpdateEmergencyPlan: props.getEmergencyPlan,
    };
  }

  showEmergencyEditor = (show, emergencyId) => {
    this.setState({
      selectedId: emergencyId,
      showEmergencyEditor: show,
    });
  };

  onEditEmergency = (emergencyId) => {
    this.showEmergencyEditor(false, emergencyId);
    this.state.fxUpdateEmergencyPlan();
  };

  confirmDelete = (show, emergencyId, emergencyDescr) => {
    this.setState({
      confirmDelete: show,
      selectedId: emergencyId,
      emergencyDescr: emergencyDescr,
    });
  };

  onConfirmDelete = () => {
    // Close confirmation dialog
    this.confirmDelete(false, null, this.state.emergencyDescr);
    // Delete account
    doHttpDelete({
      url: emergencyDeleteUrl(this.state.selectedId),
      onSuccess: () => {
        // Update view
        this.props.getEmergencyPlan();
        // Notification
        const notification = this.notificationSystem.current;
        notification.addNotification({
          title: "Emergency Deleted",
          message:
            "Emergency [" +
            this.state.emergencyDescr +
            "] was successfully deleted",
          level: "success",
          position: "br",
          autoDismiss: 5,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    // Data
    const emergencies = this.props.emergencies || [];
    let total_emergencies = 0.0;

    // Render
    return (
      <div>
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="col text-right">
            <button
              className="btn btn-success btn-sm"
              onClick={() => this.showEmergencyEditor(true, null)}
            >
              <FontAwesomeIcon icon={faPlus} /> New Emergency
            </button>
          </div>
        </div>
        {this.state.errorMessage && (
          <div
            className="alert alert-warning"
            style={{
              marginBottom: "10px",
            }}
          >
            {this.state.errorMessage}
          </div>
        )}
        <table className="table table-bordered table-md tbl-emergencies">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Estimated</th>
              <th scope="col">Status</th>
              <th scope="col" className="date">
                Date Created
              </th>
              <th scope="col" className="date">
                Expiration
              </th>
              <th scope="col" className="action">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {emergencies.map((e) => {
              // Total
              if (e.is_active) {
                total_emergencies += e.estimated_amount;
              }
              // Row
              return (
                <tr
                  key={"emergency-" + e.id}
                  className={classnames("", {
                    "tbl-row-disabled": !e.is_active,
                    focus: e.id === this.state.selectedId,
                  })}
                >
                  <td>{e.description}</td>
                  <td className="text-right">
                    {fmtMoneyUSD(e.estimated_amount)}
                  </td>
                  <td>{e.status}</td>
                  <td className="text-right">{fmtDate(e.date_created)}</td>
                  <td className="text-right">{fmtDate(e.date_expire)}</td>
                  <td className="action">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.showEmergencyEditor(true, e.id)}
                    >
                      <FontAwesomeIcon icon={faEdit} /> Edit
                    </button>{" "}
                    &nbsp;{" "}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() =>
                        this.confirmDelete(true, e.id, e.description)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="table-footer">
            <tr>
              <th className="text-right">Total anticipated emergencies</th>
              <th className="text-right">{fmtMoneyUSD(total_emergencies)}</th>
              <th colSpan="4"></th>
            </tr>
          </tfoot>
        </table>

        {this.state.showEmergencyEditor && (
          <EditEmergency
            show={this.state.showEmergencyEditor}
            onSave={this.onEditEmergency}
            onClose={() => this.showEmergencyEditor(false)}
            emergencyId={this.state.selectedId}
          />
        )}

        {this.state.confirmDelete && (
          <ConfirmDialog
            show={this.state.confirmDelete}
            handleAction={this.onConfirmDelete}
            handleClose={() => this.confirmDelete(false, null, null)}
            title="Delete Emergency?"
            message="The emergency will be deleted immediately, which will affect the emergency fund goal and funding."
            question="Are you sure about deleting this emergency?"
            btnAction="Yes, Delete"
          />
        )}

        <NotificationSystem ref={this.notificationSystem} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emergencies: state.emergency_plan.emergencies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Emergencies);
