import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpPost } from "../../services/WebService";
import { userUpdatePasswordUrl } from "../../library/Urls";

export default class PasswordUpdate extends Component {
  state = {
    password: "",
    confirm: ""
  };

  save = (values, actions) => {
    // Post
    doHttpPost({
      url: userUpdatePasswordUrl,
      body: {
        user_id: this.props.userId,
        password: values.password
      },
      rawError: true,
      onSuccess: data => {
        // Handle save
        this.props.handleSave();
      },
      onError: error => {
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach(error => {
            const errorMessage = error.defaultMessage;
            if (error.field === "userId") {
              this.setState({
                errorMessage: errorMessage
              });
            } else if (error.field === "password") {
              actions.setFieldError("password", errorMessage);
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage
          });
        }
      }
    });
  };

  validate = values => {
    const errors = {};
    // Password
    if (!values.password) {
      errors.password = "Password is required";
    }
    // Confirmation
    if (!values.confirm) {
      errors.confirm = "Type password again here";
    }
    // Match
    if (values.password !== values.confirm) {
      errors.confirm = "Passwords do not match";
    }
    // Errors
    return errors;
  };

  render() {
    // Fields
    const { password, confirm } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!this.props.updatingSelf && (
            <div
              className="alert alert-info"
              style={{
                marginBottom: "10px"
              }}
            >
              Updating password for <b>{this.props.fullNames}</b>
            </div>
          )}
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px"
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{ password, confirm }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {props => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Password</label>
                  <Field
                    className="form-control"
                    type="password"
                    name="password"
                  ></Field>
                  <ErrorMessage
                    name="password"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Confirm</label>
                  <Field
                    className="form-control"
                    type="password"
                    name="confirm"
                  ></Field>
                  <ErrorMessage
                    name="confirm"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <Modal.Footer>
                  <button className="btn btn-primary" type="submit">
                    Update
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}
