import React, { Component } from "react";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { healthcareBudgetEditUrl, healthcareBudgetSaveUrl } from "../../library/Urls";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { isBlank, trimString } from "../../library/Utilities";

export default class EditHealthcareBudget extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.handleClose,
      onSave: props.handleSave,
      budgetId: props.budgetId == null ? null : parseInt(props.budgetId),
      errorMessage: null,
      actionsEnabled: false,
      onError: props.showErrorMessage,
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: healthcareBudgetEditUrl,
      params: {
        id: this.state.budgetId,
      },
      onSuccess: (data) => {
        this.setState({
          editInfo: {
            ...data,
            name: data.name || "",
          },
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
        this.state.onError(error);
      },
    });
  };

  validateBudgetInfo = (values) => {
    // Errors
    const errors = {};
    // Name
    if (isBlank(values.name)) { errors.name = "Category name is required"; }
    // Whether any errors
    let hasErrors = false;
    for (const attr in errors) {
      if (errors.hasOwnProperty(attr)) {
        hasErrors = true;
        break;
      }
    }
    if (!hasErrors) {
      // Save form values in state
      this.setState({
        budgetInfo: values,
        hasValidationErrors: false,
        actionsEnabled: true,
      });
    } else {
      this.setState({
        hasValidationErrors: true,
        actionsEnabled: false,
      });
    }
    // Errors
    return errors;
  };

  saveBudget = () => {
    // Disable actions
    this.setState({
      actionsEnabled: false,
    });
    // Build request
    const requestInfo = {
      // Id
      id: this.state.budgetId,
      // Form values
      ...this.state.budgetInfo,
      // Fix up form data
      name: trimString(this.state.budgetInfo.name),
    };
    // Post
    doHttpPost({
      url: healthcareBudgetSaveUrl,
      body: requestInfo,
      onSuccess: (response) => {
        this.state.onSave(response.id);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus >= 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        } else if (httpStatus >= 400) {
          let htmlError = "<ol>";
          error.response.data.errors.forEach((e) => {
            htmlError += "<li>" + e.field + ": " + e.defaultMessage + "</li>";
          });
          htmlError += "</ol>";
          this.setState({
            errorMessage: htmlError,
          });
        }
      },
    });
  };

  render() {
    // Info
    const editInfo = this.state.editInfo;
    if (!editInfo) return null;
    // Whether editing
    const isEditing = this.state.budgetId != null;
    // Form field values
    const { name } = editInfo;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Update" : "Create"} Expense Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{ name }}
            validate={this.validateBudgetInfo}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {(props) => {
              return (
                  <Form>
                    <fieldset className="form-group required">
                      <label className="control-label">Category Name</label>
                      <Field
                          type="text"
                          name="name"
                          className="form-control"
                      ></Field>
                      <ErrorMessage
                          name="name"
                          className="alert alert-warning"
                          component="div"
                      />
                    </fieldset>
                  </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
                type="button"
                className="btn btn-md btn-primary"
                disabled={
                    !this.state.actionsEnabled || this.state.hasValidationErrors
                }
                onClick={this.saveBudget}
            >
              Save Category
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
