import React, { useState, useEffect, Fragment } from "react";
import { doHttpGet } from "../../services/WebService";
import { searchUrl } from "../../library/Urls";
import { fmtDate, fmtMoneyUSD, pluralize } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import Money from "../common/Money";
import Highlighter from "react-highlight-words";

const SearchResults = (props) => {
  // Keywords
  let _keywords = props.location.search || "";
  if (_keywords) {
    _keywords = _keywords.replace(/\?q=/, "");
  }

  // State
  const [keywords, setKeywords] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Search
  useEffect(() => {
    // Query
    if (keywords !== _keywords) {
      doHttpGet({
        url: searchUrl,
        params: {
          kw: decodeURIComponent(_keywords),
        },
        onSuccess: (results) => {
          setKeywords(_keywords);
          setSearchResults(results);
          setErrorMessage(null);
        },
        onError: (error) => {
          setErrorMessage(error);
        },
      });
    }
  }, [keywords, _keywords]);

  // Count
  let resultNum = 0;
  // Terms
  let searchTerms = [],
    searchTermStr = "";
  if (searchResults && searchResults.terms) {
    searchTerms = searchResults.terms;
  }
  searchTermStr = searchTerms.join(" ");

  // Render
  return (
    <div className="page-content">
      <div className="row" style={{ marginBottom: 8 }}>
        <div className="col-2">
          <h2>Search</h2>
        </div>
        <div
          className="col-10"
          style={{
            paddingTop: "15px",
            color: "gray",
            fontSize: "1.2em",
            fontStyle: "italic",
            fontWeight: "bold",
          }}
        >
          <Highlighter
            highlightClassName="search-term"
            searchWords={searchTerms}
            autoEscape={true}
            textToHighlight={searchTermStr}
          />
        </div>
      </div>

      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

      {searchResults && (
        <table className="table table-lg table-striped">
          <thead>
            <tr>
              <th colSpan={3} style={{ fontSize: "1.2em" }}>
                Found {pluralize(searchResults.total, "Result")} (
                {searchResults.message})
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResults.users.map((user) => (
              <tr key={"user-" + user.id}>
                <td className="search-count">{++resultNum}.</td>
                <td>User</td>
                <td>
                  <Highlighter
                    highlightClassName="search-term"
                    searchWords={searchTerms}
                    autoEscape={true}
                    textToHighlight={
                      user.firstName +
                      " " +
                      user.lastName +
                      " (" +
                      user.username +
                      ") - " +
                      user.emailAddress
                    }
                  />
                </td>
              </tr>
            ))}
            {searchResults.finances.map((finance) => (
              <tr key={"finance-" + finance.id}>
                <td className="search-count">{++resultNum}.</td>
                <td>Financial Account</td>
                <td>
                  <Link to={Links.financeFor(finance.id)}>
                    <Highlighter
                      highlightClassName="search-term"
                      searchWords={searchTerms}
                      autoEscape={true}
                      textToHighlight={finance.name}
                    />
                  </Link>
                  <Highlighter
                    className="search-datum description"
                    highlightClassName="search-term"
                    searchWords={searchTerms}
                    autoEscape={true}
                    textToHighlight={finance.description}
                  />
                  <span className="search-datum">
                    <b>Balance:</b> <Money amount={finance.balance} />
                  </span>
                  <span className="search-datum">
                    <b>Type:</b> {finance.accountType}
                  </span>
                  <span className="search-datum">
                    <b>Enabled:</b> {finance.enabled ? "Yes" : "No"}
                  </span>
                </td>
              </tr>
            ))}
            {searchResults.giftCards.map((gc) => (
              <tr key={"giftcard-" + gc.id}>
                <td className="search-count">{++resultNum}.</td>
                <td>Gift Card</td>
                <td>
                  <Link to={Links.giftCardForId(gc.id)}>
                    <Highlighter
                      highlightClassName="search-term"
                      searchWords={searchTerms}
                      autoEscape={true}
                      textToHighlight={gc.brand}
                    />
                  </Link>
                  <Highlighter
                    className="search-datum description"
                    highlightClassName="search-term"
                    searchWords={searchTerms}
                    autoEscape={true}
                    textToHighlight={gc.description}
                  />
                  <span className="search-datum">
                    <b>Balance:</b> <Money amount={gc.balance} />
                  </span>
                  <span className="search-datum">
                    <b>Number:</b> {gc.number}
                  </span>
                  <span className="search-datum">
                    <b>Enabled:</b> {gc.active ? "Yes" : "No"}
                  </span>
                </td>
              </tr>
            ))}
            {searchResults.categories.map((category) => (
              <tr key={"category-" + category.id}>
                <td className="search-count">{++resultNum}.</td>
                <td>Category</td>
                <td>
                  <Link to={Links.categoryFor(category.id)}>
                    <Highlighter
                      highlightClassName="search-term"
                      searchWords={searchTerms}
                      autoEscape={true}
                      textToHighlight={category.name}
                    />
                  </Link>
                  <span className="search-datum">({category.type})</span>
                  <Highlighter
                    className="search-datum description"
                    highlightClassName="search-term"
                    searchWords={searchTerms}
                    autoEscape={true}
                    textToHighlight={category.description}
                  />
                </td>
              </tr>
            ))}
            {searchResults.budgets.map((budget) => (
              <tr key={"budget-" + budget.id}>
                <td className="search-count">{++resultNum}.</td>
                <td>Budget</td>
                <td>
                  <div>
                    <Link to={Links.budgetFor(budget.id)}>
                      <Highlighter
                        highlightClassName="search-term"
                        searchWords={searchTerms}
                        autoEscape={true}
                        textToHighlight={budget.name}
                      />
                    </Link>
                    <span className="search-datum">
                      (Category:
                      <Link
                        to={Links.categoryFor(budget.categoryId)}
                        style={{ marginLeft: 5 }}
                      >
                        {budget.categoryName}
                      </Link>
                      )
                    </span>
                    <Highlighter
                      className="search-datum description"
                      highlightClassName="search-term"
                      searchWords={searchTerms}
                      autoEscape={true}
                      textToHighlight={budget.description}
                    />
                  </div>
                  <div>
                    <span className="search-datum">
                      <b>Budgeted:</b> {fmtMoneyUSD(budget.amount)}
                    </span>
                    <span className="search-datum">
                      <b>Enabled:</b> {budget.enabled ? "Yes" : "No"}
                    </span>
                    <span className="search-datum">
                      <b>Start Date:</b> {fmtDate(budget.startDate)}
                    </span>
                    <span className="search-datum">
                      <b>End Date:</b>{" "}
                      {fmtDate(budget.endDate) || "(Continuous)"}
                    </span>
                    <span className="search-datum">
                      <b>Schedule:</b> {budget.schedule}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
            {searchResults.savings.map((svg) => (
              <tr key={"savings-" + svg.id}>
                <td className="search-count">{++resultNum}.</td>
                <td>Savings Goal</td>
                <td>
                  <div>
                    <Link to={Links.savingsGoalFor(svg.id)}>
                      <Highlighter
                        highlightClassName="search-term"
                        searchWords={searchTerms}
                        autoEscape={true}
                        textToHighlight={svg.name}
                      />
                    </Link>
                    <span className="search-datum">
                      (Category:
                      <Link
                        to={Links.categoryFor(svg.categoryId)}
                        style={{ marginLeft: 5 }}
                      >
                        {svg.categoryName}
                      </Link>
                      )
                    </span>
                    <Highlighter
                      className="search-datum description"
                      highlightClassName="search-term"
                      searchWords={searchTerms}
                      autoEscape={true}
                      textToHighlight={svg.description}
                    />
                  </div>
                  <div>
                    <span className="search-datum">
                      <b>Goal:</b> {fmtMoneyUSD(svg.goal)}
                    </span>
                    <span className="search-datum">
                      <b>Allocated:</b> {fmtMoneyUSD(svg.allocated)}
                    </span>
                    <span className="search-datum">
                      <b>Spent:</b> {fmtMoneyUSD(svg.expended)}
                    </span>
                    <span className="search-datum">
                      <b>Available:</b> <Money amount={svg.available} />
                    </span>
                    <span className="search-datum">
                      <b>Status:</b> {svg.status}
                    </span>
                    <span className="search-datum">
                      <b>Start Date:</b> {fmtDate(svg.startDate)}
                    </span>
                    <span className="search-datum">
                      <b>End Date:</b> {fmtDate(svg.endDate) || "(Continuous)"}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
            {searchResults.emergencies.map((emergency) => (
              <tr key={"emergency-" + emergency.id}>
                <td className="search-count">{++resultNum}.</td>
                <td>Emergency</td>
                <td>
                  <Highlighter
                    highlightClassName="search-term"
                    searchWords={searchTerms}
                    autoEscape={true}
                    textToHighlight={emergency.description}
                  />
                  <span className="search-datum">
                    <b>Estimated:</b> {fmtMoneyUSD(emergency.estimated)}
                  </span>
                  <span className="search-datum">
                    <b>Status:</b> {emergency.status}
                  </span>
                  <span className="search-datum">
                    <b>Created:</b> {fmtDate(emergency.createdDate)}
                  </span>
                  <span className="search-datum">
                    <b>Expiration:</b>{" "}
                    {fmtDate(emergency.expirationDate) || "(Continuous)"}
                  </span>
                </td>
              </tr>
            ))}
            {searchResults.transactions.map((tx) => (
              <tr key={"transaction-" + tx.id}>
                <td className="search-count">{++resultNum}.</td>
                <td>Transaction</td>
                <td>
                  {fmtDate(tx.date)}
                  <span className="search-datum">
                    <b>{tx.label}:</b>{" "}
                    <Highlighter
                      highlightClassName="search-term"
                      searchWords={searchTerms}
                      autoEscape={true}
                      textToHighlight={tx.party}
                    />
                  </span>
                  <Money amount={tx.amount} className="search-datum" />
                  {tx.financeId && (
                    <span className="search-datum">
                      <b>in</b>{" "}
                      <Link to={Links.financeFor(tx.financeId)}>
                        {tx.financeName}
                      </Link>
                    </span>
                  )}
                  {tx.categorizationId && (
                    <span className="search-datum">
                      <b>under</b>{" "}
                      {tx.categorizationId && tx.categorizationType ? (
                        <Link
                          to={
                            tx.categorizationType === "CATEGORY"
                              ? Links.categoryFor(tx.categorizationId)
                              : tx.categorizationType === "BUDGET"
                              ? Links.budgetFor(tx.categorizationId)
                              : tx.categorizationType === "SAVINGS" &&
                                Links.savingsGoalFor(tx.categorizationId)
                          }
                        >
                          {tx.categorizationName}
                        </Link>
                      ) : (
                        <Fragment>{tx.categorizationName}</Fragment>
                      )}
                    </span>
                  )}
                  <div style={{ paddingLeft: 10, marginTop: 5 }}>
                    {tx.reference && (
                      <span style={{ paddingRight: 10 }}>
                        <b>Reference:</b>{" "}
                        <Highlighter
                          highlightClassName="search-term"
                          searchWords={searchTerms}
                          autoEscape={true}
                          textToHighlight={tx.reference}
                        />
                      </span>
                    )}
                    <Highlighter
                      className="description"
                      highlightClassName="search-term"
                      searchWords={searchTerms}
                      autoEscape={true}
                      textToHighlight={tx.description}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SearchResults;
