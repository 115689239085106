import React, { Component } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import { doHttpGet } from "../../services/WebService";
import { userDashboardUrl } from "../../library/Urls";
import UserDashboardFinancials from "./UserDashboardFinancials";
import UserDashboardDesignations from "./UserDashboardDesignations";
import UserDashboardRewards from "./UserDashboardRewards";
import UserDashboardEmergency from "./UserDashboardEmergency";
import UserDashboardBudget from "./UserDashboardBudget";
import UserDashboardCashflow from "./UserDashboardCashflow";
import UserDashboardMessages from "./UserDashboardMessages";
import UserDashboardCollege from "./UserDashboardCollege";

class UserDashboard extends Component {
  state = {
    dashboardInfo: null,
  };

  componentDidMount = () => {
    doHttpGet({
      url: userDashboardUrl,
      onSuccess: (dashboardInfo) => {
        this.setState({
          dashboardInfo: dashboardInfo,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    // Info
    const dashboardInfo = this.state.dashboardInfo;
    if (!dashboardInfo) return null;

    // Options
    const isCollegeFeatureEnabled =
      AuthenticationService.isCollegeFeatureEnabled();
    const isShowOptionsRow = isCollegeFeatureEnabled;

    // Render
    return (
      <div className="page-content" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div className="user-dashboard">
          <div className="row">
            <div className="col-sm-12">
              <div className="card-group">
                <UserDashboardFinancials
                  financials={dashboardInfo.financials}
                />
                <UserDashboardDesignations
                  designations={dashboardInfo.designations}
                />
                <UserDashboardRewards
                  rewards={dashboardInfo.rewards}
                  giftcards={dashboardInfo.giftCardInfo}
                />
                <UserDashboardEmergency emergency={dashboardInfo.emergency} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card-group">
                <UserDashboardBudget budgeting={dashboardInfo.budgeting} />
                <UserDashboardCashflow cashflow={dashboardInfo.cashflow} />
                <UserDashboardMessages
                  notifications={dashboardInfo.notifications}
                />
              </div>
            </div>
          </div>
          {isShowOptionsRow && (
            <div className="row">
              <div className="col-sm-12">
                {isCollegeFeatureEnabled && (
                  <UserDashboardCollege
                    _style={{
                      width: "28rem",
                      marginBottom: "3px",
                      marginTop: "0px",
                      marginLeft: "3px",
                    }}
                    collegeInfo={dashboardInfo.collegeInfo}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UserDashboard;
