import React, { Component, useState } from "react";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { removeIndexWithAttr, UUID } from "../../library/Utilities";

class Fees extends Component {
  state = {
    fees: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      fees: nextProps.fees,
    };
  }

  removeFee = (id) => {
    const _fees = this.state.fees;
    // Remove from listing
    removeIndexWithAttr(_fees, "id", id);
    // Update state
    this.setState({ fees: _fees });
    this.props._updateFees(_fees);
  };

  updateFee = (changes) => {
    const _fees = [];
    // Update the fee
    this.state.fees.forEach((fee) => {
      if (fee.id === changes.id) {
        _fees.push(changes);
      } else {
        _fees.push(fee);
      }
    });
    // Update state
    this.setState({ fees: _fees });
    this.props._updateFees(_fees);
  };

  addFee = () => {
    const _fees = this.state.fees;
    // Create stub fee
    _fees.push({
      id: UUID(),
      amount: 0,
      description: "(Description for Fee)",
    });
    // Update state
    this.setState({ fees: _fees });
  };

  render() {
    return (
      <div>
        <div className="text-right" style={{ marginBottom: "5px" }}>
          <button className="btn btn-sm btn-success" onClick={this.addFee}>
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />
            Add Fee
          </button>
        </div>
        <table className="table table-bordered table-sm">
          <thead className="thead-dark">
            <tr>
              <th>Description</th>
              <th style={{ width: 75 }}>Amount</th>
              <th className="text-center" style={{ width: 50 }}>
                Remove
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.fees.map((fee) => (
              <FeeItem
                key={fee.id}
                fee={fee}
                removeFee={this.removeFee}
                updateFee={this.updateFee}
              />
            ))}
          </tbody>
        </table>
        <div className="instruction">
          * [positive] = credited to account; [negative] = charged from account
        </div>
      </div>
    );
  }
}

const FeeItem = (props) => {
  // State
  const [id] = useState(props.fee.id);
  const [description, setDescription] = useState(props.fee.description);
  const [amount, setAmount] = useState(props.fee.amount);

  const descriptionChanged = (evt) => {
    const description = evt.target.value;
    setDescription(description);
  };

  const amountChanged = (evt) => {
    const amount = evt.target.value.replace("$", "").replace(/[^0-9.-]/, "");
    setAmount(amount);
  };

  const submitChanges = () => {
    const changes = {
      id: id,
      description: description,
      amount: amount,
    };
    props.updateFee(changes);
  };

  const removeFee = () => {
    props.removeFee(id);
  };

  return (
    <tr key={"fee-" + id}>
      <td>
        <input
          className="form-control input-sm"
          type="text"
          value={description}
          onChange={descriptionChanged}
          onBlur={submitChanges}
        />
      </td>
      <td>
        <input
          className="form-control input-sm"
          type="text"
          value={amount}
          onChange={amountChanged}
          onBlur={submitChanges}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <button className="btn btn-sm btn-danger" onClick={removeFee}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  const reconciliation = state.reconciliation || {};
  return {
    financeId: reconciliation.financeId || 0,
    fees: reconciliation.fees || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Update fees
    _updateFees: (fees) => {
      dispatch(Actions.updateReconciliationFees(fees));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fees);
