import React from "react";
import { fmtMoneyUSD } from "../../library/Utilities";

export default function AdjustmentItems(props) {
  return (
    <table className="table table-striped table-md">
      <tbody>
        {props.items.map((item) => {
          // Text and class
          let actionText = "Added";
          let actionCls = "positive-amount";
          if (item.amount < 0) {
            actionText = "Removed";
            actionCls = "negative-amount";
          }

          // Amount is always positive
          const amount = Math.abs(item.amount);

          // Row
          return (
            <tr key={item.id}>
              {props.showMonths && <td>{item.month}</td>}
              {props.showCategories && (
                <td className="category">{item.category}</td>
              )}
              <td className="description">{item.description}</td>
              <td className={"changes " + actionCls}>
                {actionText} {fmtMoneyUSD(amount)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
