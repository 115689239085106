import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

export default function CategoryBudgets(props) {
  // Budgets
  const budgets = props.budgets || [];

  // Render
  return (
    <div>
      <table className="table table-bordered table-striped table-lg gzn-table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col" className="gzn-col-amount">
              Amount
            </th>
            <th scope="col" className="gzn-col-check">
              Active?
            </th>
            <th scope="col" className="gzn-col-date">
              Start Date
            </th>
            <th scope="col" className="gzn-col-date">
              Expiration
            </th>
          </tr>
        </thead>
        <tbody>
          {budgets.map(b => (
            <tr key={"b-" + b.id}>
              <th scope="row">
                <Link to={Links.budgetFor(b.id)}>{b.name}</Link>
              </th>
              <td>{b.description}</td>
              <td className="gzn-col-amount">{fmtMoneyUSD(b.amount)}</td>
              <td className="gzn-col-check">
                {b.is_active && <FontAwesomeIcon icon={faCheck} />}
              </td>
              <td className="gzn-col-date">{fmtDate(b.start_date)}</td>
              <td className="gzn-col-date">{fmtDate(b.expire_date)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
