// Edit dialog inline align
export const editInlineAlignStyle = {
  label: {
    display: "inline-block",
    float: "left",
    width: "25%",
    textAlign: "right",
    verticalAlign: "middle",
    lineHeight: "32px",
    paddingRight: "20px",
    marginBottom: 0,
  },
  input: {
    display: "inline-block",
    float: "right",
    width: "75%",
  },
  fieldset: {
    marginBottom: 0,
    marginTop: "3px",
  },
  error: {
    textAlign: "right",
  },
};
