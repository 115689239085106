import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { initialState, rootReducer } from "../reducers";

// Browser extensions
const middleware = [thunk];

// Store
let reduxStore;

// Configure Chrome Redux browser dev tool
const ReactReduxDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

if (window.navigator.userAgent.includes("Chrome") && ReactReduxDevTools) {
  reduxStore = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ReactReduxDevTools)
  );
} else {
  reduxStore = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware))
  );
}

// Export the store
export default reduxStore;
