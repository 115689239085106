import React, { Component } from "react";
import { doHttpGet } from "../../services/WebService";
import { transferInfoUrl } from "../../library/Urls";
import Modal from "react-bootstrap/Modal";
import * as Links from "../../library/Links";
import { Link } from "react-router-dom";
import Money from "../common/Money";
import { fmtMoneyUSD, pluralize } from "../../library/Utilities";

export default class TransferDesignations extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleClose: props.onClose,
      errorMessage: null,
      batchNumber: props.batchNumber,
      transferInfo: null,
    };
  }

  componentDidMount = () => {
    doHttpGet({
      url: transferInfoUrl,
      params: {
        bn: this.state.batchNumber,
      },
      onSuccess: (info) => {
        this.setState({
          transferInfo: info,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    // Unless loaded
    const info = this.state.transferInfo;
    if (!info) return <div></div>;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {info.creditPayment ? "Credit Payment" : "Transfer"} Summary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <TransferInfo isFrom={true} info={info.from} />
          <TransferInfo isFrom={false} info={info.to} />
        </Modal.Body>
      </Modal>
    );
  }
}

const TransferInfo = (props) => {
  const isFrom = props.isFrom;
  const finance = props.info.finance;
  const designations = props.info.designations;
  const financeId = finance.id.replace(/(FINANCE|COLLEGE):/, "");
  const url = Links.financeFor(financeId);
  // Total
  let totalAmount = 0.0;
  return (
    <div className="transfer-info">
      <div className="title">
        {isFrom ? "FROM:" : "TO:"} <Link to={url}>{finance.description}</Link>
        &nbsp; (*{finance.name})
      </div>
      <table
        className="table table-sm table-striped"
        style={{ marginBottom: "0" }}
      >
        <thead className="thead-dark">
          <tr>
            <th scope="col">Designation</th>
            <th scope="col">Description</th>
            <th scope="col" className="text-right">
              Change
            </th>
          </tr>
        </thead>
        <tbody>
          {designations.map((designation) => {
            // Add amount
            totalAmount += designation.amount;
            return (
              <tr key={"designation-" + designation.id}>
                <td>{designation.type}</td>
                <td>{designation.description}</td>
                <td className="text-right">
                  <Money amount={designation.amount} plusMinus={true} />
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="table-footer">
          <tr>
            <td className="text-right" colSpan={2}>
              Total transfered {isFrom ? "from " : "to "}
              {pluralize(designations.length, "designation")}
            </td>
            <td className="text-right">{fmtMoneyUSD(totalAmount)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
