import React, { Component } from "react";
import { fmtMoneyUSD, findItemByAttribute } from "../../library/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import ConfirmDialog from "../common/ConfirmDialog";
import { doHttpGet, doHttpPost, doHttpDelete } from "../../services/WebService";
import {
  accountSettingsUrl,
  userMakePrimaryUrl,
  userUpdateStatusUrl,
  userDeleteUrl
} from "../../library/Urls";
import PasswordUpdate from "./PasswordUpdate";
import * as Links from "../../library/Links";
import { Link } from "react-router-dom";
import EditUser from "./EditUser";

class UserDetails extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      confirmMakePrimary: false,
      showPasswordUpdater: false,
      confirmStatusUpdate: false,
      showUserEditor: false,
      confirmUserDelete: false
    };
  }

  updateSettings = () => {
    doHttpGet({
      url: accountSettingsUrl,
      onSuccess: data => {
        this.props._updateSettings(data);
      },
      onError: error => {
        this.props._handleError(error);
      }
    });
  };

  confirmMakePrimary = show => {
    this.setState({ confirmMakePrimary: show });
  };

  onConfirmMakePrimary = () => {
    // Close confirmation dialog
    this.confirmMakePrimary(false);
    // Update settings
    doHttpPost({
      url: userMakePrimaryUrl,
      body: this.props.user.id,
      onSuccess: () => {
        this.updateSettings();
      },
      onError: error => {
        this.props._handleError(error);
      }
    });
  };

  showPasswordUpdate = show => {
    this.setState({ showPasswordUpdater: show });
  };

  onPasswordUpdate = () => {
    this.showPasswordUpdate(false);
  };

  showUserEditor = show => {
    this.setState({ showUserEditor: show });
  };

  onEditUser = () => {
    // Close editor
    this.showUserEditor(false);
    // Update view
    this.updateSettings();
  };

  confirmUserDelete = show => {
    this.setState({ confirmUserDelete: show });
  };

  onConfirmUserDelete = () => {
    // Close confirmation dialog
    this.confirmUserDelete(false);
    // Delete user
    doHttpDelete({
      url: userDeleteUrl(this.props.user.id),
      onSuccess: () => {
        // Set primary as selected user
        this.props._setSelectedUserId(this.props.selectedUserId);
        // Update settings
        this.updateSettings();
      },
      onError: error => {
        this.props._handleError(error);
      }
    });
  };

  confirmStatusUpdate = (show, enabled) => {
    this.setState({
      confirmStatusUpdate: show,
      enableUser: enabled
    });
  };

  onConfirmStatusUpdate = () => {
    // Close confirmation dialog
    this.confirmStatusUpdate(false, null);
    // Update status
    doHttpPost({
      url: userUpdateStatusUrl,
      body: {
        user_id: this.props.user.id,
        status: this.state.enableUser ? "ACTIVE" : "DISABLED"
      },
      onSuccess: () => {
        this.updateSettings();
      },
      onError: error => {
        this.props._handleError(error);
      }
    });
  };

  render() {
    // Data
    const { user, currUserId, isCurrUserPrimary } = this.props;
    if (!user) {
      return <div />;
    }

    // Self
    const isSelf = user.id === currUserId;
    // Enabled
    const isEnableDelete = !user.is_primary && !isSelf;
    const showStatusAction = !user.is_primary && !isSelf;
    // Render
    return (
      <div className="card" style={{ width: "100%" }}>
        <h4 className="card-header">{user.full_names}</h4>
        <div className="card-body">
          <table className="table table-lg user-details">
            <tbody>
              <tr>
                <td className="label">Primary</td>
                <td>
                  <div style={{ marginBottom: "5px" }}>
                    {user.is_primary ? "Yes" : "No"}
                  </div>
                  {isCurrUserPrimary && !user.is_primary && (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => this.confirmMakePrimary(true)}
                    >
                      Make Primary
                    </button>
                  )}
                </td>
              </tr>
              <tr>
                <td className="label">Username</td>
                <td>
                  <div style={{ marginBottom: "5px" }}>{user.username}</div>
                  {(isCurrUserPrimary || isSelf) && (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => this.showPasswordUpdate(true)}
                    >
                      Change Password
                    </button>
                  )}
                </td>
              </tr>
              <tr>
                <td className="label">Email address</td>
                <td>{user.email_address}</td>
              </tr>
              <tr>
                <td className="label">Status</td>
                <td>
                  <div style={{ marginBottom: "5px" }}>{user.status}</div>
                  {showStatusAction &&
                    (user.enabled ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => this.confirmStatusUpdate(true, false)}
                      >
                        Disable
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => this.confirmStatusUpdate(true, true)}
                      >
                        Enable
                      </button>
                    ))}
                </td>
              </tr>
              <tr>
                <td className="label">Last login</td>
                <td>
                  <div style={{ marginBottom: "5px" }}>{user.last_login}</div>
                  <Link
                    to={Links.loginHistory + "/" + user.id}
                    className="btn btn-sm btn-primary"
                    role="button"
                  >
                    Login History
                  </Link>
                </td>
              </tr>
              <tr>
                <td className="label">Allowance</td>
                <td>
                  {fmtMoneyUSD(user.monthly_allowance)}/month
                  <span style={{ marginLeft: "10px", color: "gray" }}>
                    (bal. {fmtMoneyUSD(user.allowance_balance)})
                  </span>
                </td>
              </tr>
              <tr>
                <td className="label">Personal account</td>
                <td>
                  <Link to={Links.financeAccount + `/${user.cash_finance.id}`}>
                    {user.cash_finance.name}
                  </Link>
                  <span style={{ marginLeft: "10px", color: "gray" }}>
                    (bal. {fmtMoneyUSD(user.cash_finance.balance)})
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <div className="col-sm-12" style={{ textAlign: "right" }}>
            <button
              type="button"
              className="btn btn-md btn-success"
              style={{ marginLeft: "15px" }}
              onClick={() => this.showUserEditor(true)}
            >
              <FontAwesomeIcon icon={faEdit} /> &nbsp; Edit
            </button>
            {isEnableDelete && (
              <button
                type="button"
                className="btn btn-md btn-danger"
                style={{ marginLeft: "15px" }}
                onClick={() => this.confirmUserDelete(true)}
              >
                <FontAwesomeIcon icon={faTrash} /> &nbsp; Delete
              </button>
            )}
          </div>
        </div>

        {this.state.confirmMakePrimary && (
          <ConfirmDialog
            show={this.state.confirmMakePrimary}
            handleAction={this.onConfirmMakePrimary}
            handleClose={() => this.confirmMakePrimary(false)}
            title="Make User Primary"
            message={
              "You will no longer be the account primary, and will not be able to do anything that the special role allows. You will need to ask " +
              this.props.user.full_names +
              " to get any of that done."
            }
            question="Are you sure you want to change the account primary and relinquish the role?"
            btnAction="Yes"
          />
        )}

        {this.state.showPasswordUpdater && (
          <PasswordUpdate
            show={this.state.showPasswordUpdater}
            handleSave={this.onPasswordUpdate}
            handleClose={() => this.showPasswordUpdate(false)}
            fullNames={this.props.user.full_names}
            updatingSelf={isSelf}
            userId={this.props.user.id}
          />
        )}

        {this.state.confirmStatusUpdate && (
          <ConfirmDialog
            show={this.state.confirmStatusUpdate}
            handleAction={this.onConfirmStatusUpdate}
            handleClose={() => this.confirmStatusUpdate(false, null)}
            title={(this.state.enableUser ? "Enable" : "Disable") + " User"}
            message={
              this.props.user.full_names +
              " will be " +
              (this.state.enableUser ? "enabled" : "disabled") +
              ". The user will " +
              (this.state.enableUser
                ? "be able to log in and access the account again."
                : "no longer be able to login and access the account.")
            }
            question={
              "Are you sure you want to " +
              (this.state.enableUser ? "enable" : "disable") +
              " " +
              this.props.user.full_names +
              "?"
            }
            btnAction={"Yes, " + (this.state.enableUser ? "Enable" : "Disable")}
          />
        )}

        {this.state.showUserEditor && (
          <EditUser
            show={this.state.showUserEditor}
            handleSave={this.onEditUser}
            handleClose={() => this.showUserEditor(false)}
            fullNames={this.props.user.full_names}
            userId={this.props.user.id}
          />
        )}

        {this.state.confirmUserDelete && (
          <ConfirmDialog
            show={this.state.confirmUserDelete}
            handleAction={this.onConfirmUserDelete}
            handleClose={() => this.confirmUserDelete(false)}
            title="Delete User"
            message={
              "All user records will permanently and irreversibly be deleted, " +
              "and the user will no longer be able to login and use the account."
            }
            question={
              "Are you sure you want to delete " +
              this.props.user.full_names +
              "?"
            }
            btnAction="Yes, Delete"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const users = state.accountSettings.users;
  const selectedId = state._selections.userId;
  const user = findItemByAttribute(users, "id", selectedId);
  return {
    user: user,
    currUserId: state.session.userId,
    isCurrUserPrimary: state.session.isPrimary,
    selectedUserId: state.session.userId,
    errorMessage: null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // Errors
    _handleError: message => {
      dispatch(Actions.setErrorMessage(message));
    },
    // Settings
    _updateSettings: settings => {
      dispatch(Actions.setSettings(settings));
    },
    // User ID
    _setSelectedUserId: id => {
      dispatch(Actions.setSelectedUserId(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
