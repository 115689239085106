import {Link} from "react-router-dom";
import * as Links from "../../library/Links";
import React, {Fragment} from "react";

const TransactionDescription = (props) => {
    const t = props.transaction;
    // To/from
    const isDeposit = t.deposit || t.type === 'DEPOSIT';
    let toFromText = "TO:";
    if (isDeposit) {
        toFromText = "FROM:";
    }
    let party = t.party;
    // Cashback
    const isCashback = t.isSingleCashback;
    if (isCashback) {
        toFromText = "FROM:";
        const finances = t.finances.filter((f) => f.primary);
        if (finances.length > 0) {
            const finance = finances[0];
            party = <Link to={Links.financeFor(finance.id)}>{finance.name}</Link>;
        }
    }
    // Transfer
    const isTransfer = t.transfer;
    if (isTransfer) {
        toFromText = "FROM:";
        let _finances = t.finances.filter((f) => f.primary);
        if (t.isFinanceView) {
            const viewFinanceId = parseInt(t.viewFinanceId, 10);
            _finances = t.finances.filter((f) => f.id !== viewFinanceId);
            if (!_finances[0].primary) {
                toFromText = "TO:";
            }
        } else {
            toFromText = "TO:";
            _finances = t.finances.filter((f) => !f.primary);
        }
        if (_finances.length > 0) {
            const finance = _finances[0];
            party = (
                <Link to={Links.financeFor(finance.id)}>
                    {finance.description} (*{finance.name})
                </Link>
            );
        }
    }
    // Render
    return (
        <Fragment>
            <div className="party">
                <span className="to-from">{toFromText}</span> {party}
            </div>
            {t.reference && (
                <div>
          <span style={{ fontWeight: "bold", marginRight: "10px" }}>
            REFERENCE:
          </span>
                    <span className="reference">{t.reference}</span>
                </div>
            )}
            <div className="description">{t.description}</div>
        </Fragment>
    );
};

export default TransactionDescription;