import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpPost } from "../../services/WebService";
import { activationUrl } from "../../library/Urls";
import * as Links from "../../library/Links";

const errorFieldMappings = {
  activationCode: "activation_code",
  emailAddress: "email_address",
  password: "password"
};

export default class Activate extends Component {
  state = {
    fields: {
      activation_code: "",
      email_address: "",
      password: ""
    },
    errorMessage: null
  };

  activate = (values, actions) => {
    // Post
    doHttpPost({
      url: activationUrl,
      body: values,
      onSuccess: () => {
        // Login page
        this.props.history.push(Links.login);
      },
      rawError: true,
      onError: error => {
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach(error => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage
          });
        }
      }
    });
  };

  validate = values => {
    const errors = {};
    // All inputs are required
    if (!values.activation_code) {
      errors.activation_code = "Activation code is required";
    }
    if (!values.email_address) {
      errors.email_address = "Email address is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const { activation_code, email_address, password } = this.state.fields;

    return (
      <div className="page-content">
        <div className="row" style={{ width: 400, margin: "0 auto" }}>
          <div className="container form-container col">
            <h2>Activate Registration</h2>

            {this.state.errorMessage && (
              <div
                className="alert alert-danger"
                style={{ marginBottom: "10px" }}
              >
                {this.state.errorMessage}
              </div>
            )}

            <div className="alert alert-info" style={{ marginBottom: "10px" }}>
              An activation code was sent to the email address you provided
              during registration. Please retrieve it to complete activating
              your registration.
            </div>

            <Formik
              initialValues={{
                activation_code,
                email_address,
                password
              }}
              onSubmit={this.activate}
              validate={this.validate}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
            >
              {props => (
                <Form>
                  <fieldset className="form-group required">
                    <label className="control-label">Email Address</label>
                    <Field
                      className="form-control"
                      type="email"
                      name="email_address"
                    ></Field>
                    <ErrorMessage
                      name="email_address"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">Activation Code</label>
                    <Field
                      className="form-control"
                      type="text"
                      name="activation_code"
                    ></Field>
                    <ErrorMessage
                      name="activation_code"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">Password</label>
                    <Field
                      className="form-control"
                      type="password"
                      name="password"
                    ></Field>
                    <ErrorMessage
                      name="password"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <div className="float-right">
                    <button className="btn btn-primary" type="submit">
                      Activate
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
