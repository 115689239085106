import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { stockAwardUrl, financeStocksUrl } from "../../library/Urls";
import DateSelector from "../common/DateSelector";
import { asDateTime, findItemByAttribute } from "../../library/Utilities";
import NumberField from "../common/NumberField";
import { editInlineAlignStyle } from "../../library/Styles";

export default class EditStockAward extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      batchNumber: props.batchNumber,
      financeId: props.financeId,
      errorMessage: null,
      actionsEnabled: false,
      // Edit info
      editInfo: null,
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: stockAwardUrl,
      params: {
        bn: this.state.batchNumber,
        fa: this.state.financeId,
      },
      onSuccess: (data) => {
        // State
        this.setState({
          editInfo: {
            ...data,
            date: asDateTime(data.date),
            quantity: data.quantity ? data.quantity.toFixed(4) : "",
            financeId:
              data.financeId ||
              this.state.financeId ||
              (data.finances.length ? data.finances[0].id : null),
            stock:
              data.stock || (data.stocks.length ? data.stocks[0].id : null),
          },
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  validate = (values) => {
    const errors = {};
    if (!values.date) {
      errors.date = "Transaction date is required";
    }
    if (!values.quantity || parseFloat(values.quantity) === 0) {
      errors.quantity = "Quantity must be more than 0.00";
    }
    if (!values.financeId) {
      errors.financeId = "Select financial account";
    }
    if (!values.stock) {
      errors.stock = "Select stock";
    }
    if (!values.description) {
      errors.description = "Description is required";
    }
    const hasErrors = Object.keys(errors).length > 0;
    if (!hasErrors) {
      this.setState({
        transactionInfo: values,
        hasValidationErrors: false,
        actionsEnabled: true,
      });
    } else {
      this.setState({
        hasValidationErrors: true,
        actionsEnabled: false,
      });
    }
    return errors;
  };

  handleSave = (evt) => {
    // Disable actions
    this.setState({
      actionsEnabled: false,
    });
    // Transaction
    let transaction = this.state.transactionInfo;
    if (!transaction) {
      const editInfo = this.state.editInfo;
      transaction = { ...editInfo };
      delete transaction.finances;
      delete transaction.stocks;
    }
    // Request
    const requestInput = {
      batchNumber: this.state.batchNumber,
      ...transaction,
      quantity: Number(transaction.quantity),
      financeId: Number(transaction.financeId),
    };
    // Post
    doHttpPost({
      url: stockAwardUrl,
      body: requestInput,
      onSuccess: (response) => {
        this.state.onSave(response.batchNumber);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          let htmlError = "";
          error.response.data.errors.forEach((e) => {
            htmlError +=
              (htmlError ? "; " : "") +
              "[" +
              e.field +
              "]: " +
              e.defaultMessage;
          });
          this.setState({
            errorMessage: htmlError,
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  financeItem = (finance) => {
    if (finance) {
      return finance.name;
    }
    return "(UNKNOWN FINANCE)";
  };

  financialAccountChanged = (evt) => {
    const financeId = evt.target.value;
    const selectedStock =
      (this.state.transactionInfo || {}).stock || this.state.editInfo.stock;
    this.updateFinanceStocksList(financeId, selectedStock);
  };

  updateFinanceStocksList = (financeId, selectedStock) => {
    // Account stocks
    doHttpGet({
      url: financeStocksUrl,
      params: {
        fa: financeId,
      },
      onSuccess: (stocksList) => {
        const stockToSelect = findItemByAttribute(
          stocksList,
          "id",
          selectedStock
        );
        let stockId = selectedStock;
        if (stockToSelect) {
          stockId = stockToSelect.id;
        } else if (stocksList.length > 0) {
          stockId = stocksList[0].id;
        }
        this.setState({
          transactionInfo: {
            ...this.state.transactionInfo,
            stock: stockId,
            financeId: financeId,
          },
          editInfo: {
            ...this.state.editInfo,
            stock: stockId,
            financeId: financeId,
            stocks: stocksList,
          },
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    // Info
    const editInfo = this.state.editInfo;
    if (!editInfo) return null;
    // Whether editing
    const isEditing = this.state.batchNumber != null;
    // Form field values
    const { date, financeId, stock, quantity, stocks, finances, description } =
      editInfo;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Update" : "Create"} Stock Award
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-danger"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              date,
              financeId,
              stock,
              quantity,
              description,
            }}
            validate={this.validate}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {(props) => {
              const styles = editInlineAlignStyle;
              // Render
              return (
                <Form>
                  <fieldset className="form-group required">
                    <label className="control-label">Date</label>
                    <DateSelector
                      className="form-control"
                      name="date"
                      style={{ width: "100%" }}
                    />
                    <ErrorMessage
                      name="date"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">Financing</label>
                    <Field
                      className="form-control"
                      as="select"
                      name="financeId"
                      onChange={this.financialAccountChanged}
                    >
                      {finances.map((finance) => {
                        const id = finance.id;
                        return (
                          <option key={id} value={id}>
                            {this.financeItem(finance)}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="financeId"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">Stock</label>
                    <Field className="form-control" as="select" name="stock">
                      {stocks.map((s) => {
                        return (
                          <option key={s.id} value={s.id}>
                            {s.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="stock"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset
                    className="form-group required"
                    style={styles.fieldset}
                  >
                    <label className="control-label" style={styles.label}>
                      Quantity
                    </label>
                    <Field
                      name="quantity"
                      style={styles.input}
                      component={NumberField}
                    ></Field>
                    <ErrorMessage
                      name="quantity"
                      className="alert alert-warning"
                      component="div"
                      style={styles.error}
                    />
                  </fieldset>
                  <fieldset
                    className="form-group required"
                    style={{ marginTop: "5px" }}
                  >
                    <label className="control-label">Description</label>
                    <Field
                      className="form-control"
                      as="textarea"
                      name="description"
                    ></Field>
                    <ErrorMessage
                      name="description"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              className="btn btn-md btn-primary"
              disabled={
                !this.state.actionsEnabled || this.state.hasValidationErrors
              }
              onClick={this.handleSave}
            >
              Save Award
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
