import React from "react";

const transform = (value, integerOnly) => {
  // Defaults
  const _allowNegative = true;
  const _integerOnly = integerOnly || false;
  // Regex
  let _regex = "0-9";
  if (!_integerOnly) {
    _regex = `${_regex}\\.`;
  }
  if (_allowNegative) {
    _regex = `${_regex}\\-`;
  }
  const regex = new RegExp("[^" + _regex + "]", "g");
  // Transform
  value = String(value).replace(regex, "");
  if (value && _integerOnly) {
    value = parseInt(value, 10);
  }
  // Return
  return value;
};

export default function NumberField({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  label,
  className,
  integerOnly,
  style,
  ...props
}) {
  return (
    <input
      name={name}
      type="text"
      value={value}
      onChange={(evt) => {
        // Value
        const _value = transform(evt.target.value, integerOnly);
        // Update target
        evt.target.value = _value;
        // Regular change event
        onChange(evt);
      }}
      onBlur={onBlur}
      style={style}
      className="form-control"
    />
  );
}
