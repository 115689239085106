import React, { Component } from "react";

export default class StockTransactions extends Component {
  render() {
    return (
      <div>
        STOCK TRANSACTIONS (FINANCE={this.props.match.params.financeId})
      </div>
    );
  }
}
