import React, { useState, useEffect } from "react";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { fmtNumber } from "../../library/Utilities";

const Retirement401k = (props) => {
  const { employerContribution, individualContribution, fees } = props;

  // State
  const [_employerContribution, setEmployerContribution] =
    useState(employerContribution);
  const [_individualContribution, setIndividualContribution] = useState(
    individualContribution
  );
  const [_fees, setFees] = useState(fees);

  // Changes
  useEffect(() => {
    setEmployerContribution(employerContribution);
    setIndividualContribution(individualContribution);
    setFees(fees);
  }, [employerContribution, individualContribution, fees]);

  const amountChanged = (evt) => {
    let _amount = evt.target.value.replace("$", "");
    _amount = _amount.replace(/[^0-9.-]/, "");
    const _name = evt.target.name;
    switch (_name) {
      case "employerContribution":
        setEmployerContribution(_amount);
        break;
      case "individualContribution":
        setIndividualContribution(_amount);
        break;
      case "fees":
        setFees(_amount);
        break;
      default:
    }
  };

  const updateRetirement401k = () => {
    const info = {
      employerContribution: fmtNumber(_employerContribution, 2),
      individualContribution: fmtNumber(_individualContribution, 2),
      fees: fmtNumber(_fees, 2),
    };
    props._updateRetirement401k(info);
  };

  return (
    <table
      className="table table-sm tbl-reconciliation-opt"
      style={{ marginBottom: 0, border: 0, borderSpacing: 0 }}
    >
      <tbody>
        <tr>
          <td className="label">Individual Contribution:</td>
          <td>
            <input
              name="individualContribution"
              className="form-control input-sm"
              type="text"
              value={_individualContribution}
              onChange={amountChanged}
              onBlur={updateRetirement401k}
            ></input>
          </td>
        </tr>
        <tr>
          <td className="label">Employer Contribution:</td>
          <td>
            <input
              name="employerContribution"
              className="form-control input-sm"
              type="text"
              value={_employerContribution}
              onChange={amountChanged}
              onBlur={updateRetirement401k}
            ></input>
          </td>
        </tr>
        <tr>
          <td className="label">Account Fees:</td>
          <td>
            <input
              name="fees"
              className="form-control input-sm"
              type="text"
              value={_fees}
              onChange={amountChanged}
              onBlur={updateRetirement401k}
            ></input>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  const reconciliation = state.reconciliation || {};
  const retirement401kInfo = reconciliation.retirement401kInfo || {};
  return {
    employerContribution: retirement401kInfo.employerContribution || "0.00",
    individualContribution: retirement401kInfo.individualContribution || "0.00",
    fees: retirement401kInfo.fees || "0.00",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _updateRetirement401k: (o) => {
      dispatch(Actions.updateReconciliationRetirement401k(o));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Retirement401k);
