import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { userEditUrl, userUpdateUrl } from "../../library/Urls";
import { isValidUsMoney, toUsMoney } from "../../library/Utilities";

const errorFieldMappings = {
  firstName: "first_name",
  lastName: "last_name",
  username: "username",
  emailAddress: "email_address",
  monthlyAllowance: "monthly_allowance"
};

class EditUser extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleClose: props.handleClose,
      handleSave: props.handleSave,
      user: {
        first_name: "",
        last_name: "",
        username: "",
        email_address: "",
        monthly_allowance: 0.0
      },
      fullNames: props.fullNames,
      userId: props.userId,
      errorMessage: null
    };
  }

  componentDidMount = evt => {
    doHttpGet({
      url: userEditUrl(this.state.userId),
      onSuccess: data => {
        this.setState({
          user: {
            ...data,
            monthly_allowance: data.monthly_allowance.toFixed(2)
          },
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  save = (values, actions) => {
    // Post
    doHttpPost({
      url: userUpdateUrl,
      body: {
        ...values,
        id: this.state.userId,
        monthly_allowance: toUsMoney(values.monthly_allowance)
      },
      rawError: true,
      onSuccess: () => {
        // Handle save
        this.state.handleSave();
      },
      onError: error => {
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach(error => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage
          });
        }
      }
    });
  };

  validate = values => {
    const errors = {};
    // All inputs are required
    if (!values.first_name) {
      errors.first_name = "First name is required";
    }
    if (!values.last_name) {
      errors.last_name = "Last name is required";
    }
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (!values.email_address) {
      errors.email_address = "Email address is required";
    }
    if (!values.monthly_allowance) {
      errors.monthly_allowance = "Monthly allowance is required";
    } else if (!isValidUsMoney(values.monthly_allowance)) {
      errors.monthly_allowance = "Invalid monetary amount";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const {
      first_name,
      last_name,
      username,
      email_address,
      monthly_allowance
    } = this.state.user;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update {this.props.fullNames}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px"
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              username,
              email_address,
              first_name,
              last_name,
              monthly_allowance
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {props => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Username</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="username"
                  ></Field>
                  <ErrorMessage
                    name="username"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Email Address</label>
                  <Field
                    className="form-control"
                    type="email"
                    name="email_address"
                  ></Field>
                  <ErrorMessage
                    name="email_address"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">First Name</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="first_name"
                  ></Field>
                  <ErrorMessage
                    name="first_name"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Last Name</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="last_name"
                  ></Field>
                  <ErrorMessage
                    name="last_name"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Monthly Allowance</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="monthly_allowance"
                  ></Field>
                  <ErrorMessage
                    name="monthly_allowance"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <Modal.Footer>
                  <button className="btn btn-primary" type="submit">
                    Update
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditUser;
