import * as ActionTypes from "../actions/types";
import AuthenticationService from "../../services/AuthenticationService";
import {
  setAccountOptions,
  getAccountOptions,
} from "../../services/LocalService";
import { toUsMoney } from "../../library/Utilities";

/**
 * Initial state.
 */
export const initialState = {
  _selections: {
    userId: null,
  },
  session: {},
  accountSettings: {},
  finances: {},
  emergency_plan: {},
  budgets: {},
  savingsGoals: {},
  errorMessage: null,
  accountDeletionDate: null,
  transactionInfo: {},
  featureOptions: {
    healthcare: false,
    stocks: false,
  },
  giftcards: {},
};

/**
 * Redux reducer.
 * @param {*} state
 * @param {*} action
 */
export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    // Finance: whether to include disabled accounts
    case ActionTypes.FinanceSetIncludeDisabled:
      return {
        ...state,
        finances: {
          ...state.finances,
          includeDisabled: action.include,
        },
      };

    // Finance: info
    case ActionTypes.FinanceSetInfo:
      return {
        ...state,
        finances: {
          ...state.finances,
          financialInfo: action.info,
        },
      };

    // Selected user ID
    case ActionTypes.SetSelectedUserId:
      return {
        ...state,
        _selections: {
          ...state._selections,
          userId: action.id,
        },
      };

    // Settings
    case ActionTypes.SetSettings:
      const sf_options = action.data.features.options;
      const ss_options = mergeFeatureOptions(state, {
        healthcare:
          sf_options.filter(
            (o) => o.id === "HEALTHCARE" && o.status.action === "REMOVE"
          ).length > 0,
        stocks:
          sf_options.filter(
            (o) => o.id === "STOCKS" && o.status.action === "REMOVE"
          ).length > 0,
        retirement:
          sf_options.filter(
            (o) => o.id === "RETIREMENT" && o.status.action === "REMOVE"
          ).length > 0,
        college:
          sf_options.filter(
            (o) => o.id === "COLLEGE" && o.status.action === "REMOVE"
          ).length > 0,
      });
      return {
        ...state,
        accountSettings: action.data,
        featureOptions: ss_options,
      };

    // Emergency plan
    case ActionTypes.SetEmergencyPlan:
      return {
        ...state,
        emergency_plan: action.data,
      };

    // Session
    case ActionTypes.SetSession:
      let account_options = getAccountOptions();
      const session_info = action.data;
      const session_login = action.login;
      if (session_login) {
        account_options = {
          healthcare: session_info.featureHealthcare,
          stocks: session_info.featureStocks,
          retirement: session_info.featureRetirement,
          college: session_info.featureCollege,
        };
        setAccountOptions(account_options);
        account_options = mergeFeatureOptions(state, account_options);
      }
      delete session_info.featureHealthcare;
      delete session_info.featureStocks;
      delete session_info.featureRetirement;
      delete session_info.featureCollege;
      return {
        ...state,
        session: session_info,
        featureOptions: account_options,
      };

    // Features
    case ActionTypes.FeatureOptions:
      const fo_options = mergeFeatureOptions(state, action.options);
      return {
        ...state,
        featureOptions: fo_options,
      };

    // Features
    case ActionTypes.FeatureOptionsOff:
      let feature_options = getAccountOptions();
      const session_options = state.session;
      if (action.option === "HEALTHCARE") {
        feature_options.healthcare = false;
        session_options.featureHealthcare = false;
      } else if (action.option === "STOCKS") {
        feature_options.stocks = false;
        session_options.featureStocks = false;
      } else if (action.option === "RETIREMENT") {
        feature_options.retirement = false;
        session_options.featureRetirement = false;
      } else if (action.option === "COLLEGE") {
        feature_options.college = false;
        session_options.featureCollege = false;
      }
      setAccountOptions(feature_options);
      feature_options = mergeFeatureOptions(state, feature_options);
      return {
        ...state,
        featureOptions: feature_options,
        session: session_options,
      };

    // Error message
    case ActionTypes.ErrorMessage:
      return {
        ...state,
        errorMessage: action.message,
      };

    // Account deletion date
    case ActionTypes.AccountDeletionDate:
      const date = action.date;
      AuthenticationService.setAccountDeletionDate(date);
      return {
        ...state,
        accountDeletionDate: date,
      };

    // Clear store
    case ActionTypes.ClearStore:
      let _state = undefined;
      return _state;

    // Budgets: whether to include disabled
    case ActionTypes.BudgetSetIncludeDisabled:
      return {
        ...state,
        budgets: {
          ...state.budgets,
          includeDisabled: action.include,
        },
      };

    // Savings goals: whether to include disabled
    case ActionTypes.SavingsGoalSetIncludeDisabled:
      return {
        ...state,
        savingsGoals: {
          ...state.savingsGoals,
          includeDisabled: action.include,
        },
      };

    // Transaction info
    case ActionTypes.TransactionInfo:
      const selectedBatchNumber = state.transactionInfo.selectedBatchNumber;
      return {
        ...state,
        transactionInfo: {
          ...action.data,
          selectedBatchNumber: selectedBatchNumber,
        },
      };

    // Selected transaction batch number
    case ActionTypes.SelectedTransaction:
      return {
        ...state,
        transactionInfo: {
          ...state.transactionInfo,
          selectedBatchNumber: action.data,
        },
      };

    // Reconciliation info
    case ActionTypes.ReconciliationInfo:
      return {
        ...state,
        reconciliation: action.data,
      };

    // Reconciliation interest
    case ActionTypes.ReconciliationInterest:
      const reconciliation_i = state.reconciliation;
      if (reconciliation_i) {
        reconciliation_i._updatedTime = new Date().getTime();
        reconciliation_i.interestEarned = action.data.interestEarned;
        reconciliation_i.interestPaid = action.data.interestPaid;
      }
      return {
        ...state,
        reconciliation: reconciliation_i,
      };

    // Reconciliation fees
    case ActionTypes.ReconciliationFees:
      const reconciliation_f = state.reconciliation;
      if (reconciliation_f) {
        reconciliation_f._updatedTime = new Date().getTime();
        reconciliation_f.fees = action.data;
      }
      return {
        ...state,
        reconciliation: reconciliation_f,
      };

    // Reconciliation reload
    case ActionTypes.ReconciliationStatement:
      const reconciliation_s = state.reconciliation;
      if (reconciliation_s) {
        reconciliation_s._updatedTime = new Date().getTime();
        reconciliation_s.statementBalance = action.data.balance;
        reconciliation_s.statementDate = action.data.date;
      }
      return {
        ...state,
        reconciliation: reconciliation_s,
      };

    // Reconciliation rewards
    case ActionTypes.ReconciliationRewards:
      const reconciliation_r = state.reconciliation;
      if (reconciliation_r) {
        reconciliation_r._updatedTime = new Date().getTime();
        reconciliation_r.rewardMode = action.data.mode;
        reconciliation_r.rewardAmount = action.data.amount;
      }
      return {
        ...state,
        reconciliation: reconciliation_r,
      };

    // Reconciliation investments
    case ActionTypes.ReconciliationInvestments:
      let reconciliation_inv = state.reconciliation;
      if (reconciliation_inv) {
        reconciliation_inv = {
          ...reconciliation_inv,
          _updatedTime: new Date().getTime(),
          ...action.data,
        };
      }
      return {
        ...state,
        reconciliation: reconciliation_inv,
      };

    // Reconciliation transactions
    case ActionTypes.ReconciliationTransactions:
      let reconciliation_tx = state.reconciliation;
      if (reconciliation_tx) {
        reconciliation_tx = {
          ...reconciliation_tx,
          _updatedTime: new Date().getTime(),
          transactions: action.data,
        };
      }
      return {
        ...state,
        reconciliation: reconciliation_tx,
      };

    // Reconciliation retirement 401k
    case ActionTypes.ReconciliationRetirement401k:
      const reconciliation_r401k = state.reconciliation || {};
      const retirement401k = reconciliation_r401k.retirement401kInfo;
      if (retirement401k) {
        retirement401k._updatedTime = new Date().getTime();
        retirement401k.employerContribution = toUsMoney(
          action.data.employerContribution
        );
        retirement401k.individualContribution = toUsMoney(
          action.data.individualContribution
        );
        retirement401k.fees = toUsMoney(action.data.fees);
      }
      return {
        ...state,
        reconciliation: {
          ...reconciliation_r401k,
          retirement401kInfo: retirement401k,
        },
      };

    // Reconciliation retirement STD
    case ActionTypes.ReconciliationRetirementStd:
      const reconciliation_rStd = state.reconciliation || {};
      const retirementStd = reconciliation_rStd.retirementStandardInfo;
      if (retirementStd) {
        retirementStd._updatedTime = new Date().getTime();
        retirementStd.gainOrLossAmount = toUsMoney(
          action.data.gainOrLossAmount
        );
      }
      return {
        ...state,
        reconciliation: {
          ...reconciliation_rStd,
          retirementStandardInfo: retirementStd,
        },
      };

    // Reconciliation college info
    case ActionTypes.ReconciliationCollegeInfo:
      const reconciliation_college = state.reconciliation || {};
      const collegeInfo = reconciliation_college.collegeInfo;
      if (collegeInfo) {
        collegeInfo.earningsAmount = toUsMoney(action.data.earningsAmount);
      }
      return {
        ...state,
        reconciliation: {
          ...reconciliation_college,
          collegeInfo: collegeInfo,
        },
      };

    // Include disabled gift cards
    case ActionTypes.IncludeDisabledGiftCards:
      const giftcards = state.giftcards || {};
      giftcards.includeDisabled = action.data;
      return {
        ...state,
        giftcards: giftcards,
      };

    // Default
    default:
      return state;
  }
};

const mergeFeatureOptions = (state, options) => {
  const merged = {
    ...state.featureOptions,
    ...options,
    _timestamp: new Date().getTime(),
  };
  return merged;
};
