import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { adminSettingsUrl } from "../../library/Urls";
import Checkbox from "../common/Checkbox";

class SystemSettings extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      stocks: {
        fetch_prices: true,
        only_business_hours: true
      },
      errorMessage: null
    };
  }

  componentDidMount = evt => {
    doHttpGet({
      url: adminSettingsUrl,
      onSuccess: data => {
        this.setState({
          ...data,
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  save = values => {
    // Settings
    const settings = {
      stocks: {
        fetch_prices: values.stocks_fetch_prices,
        only_business_hours: values.stocks_only_business_hours
      }
    };

    // Post
    doHttpPost({
      url: adminSettingsUrl,
      body: settings,
      onSuccess: () => {
        this.setState({
          ...settings,
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  render() {
    // Stocks
    const stocks_fetch_prices = this.state.stocks.fetch_prices;
    const stocks_only_business_hours = this.state.stocks.only_business_hours;

    return (
      <div>
        {this.state.errorMessage && (
          <div
            className="alert alert-warning"
            style={{
              marginBottom: "10px"
            }}
          >
            {this.state.errorMessage}
          </div>
        )}
        <Formik
          initialValues={{
            stocks_fetch_prices,
            stocks_only_business_hours
          }}
          onSubmit={this.save}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
        >
          {props => (
            <Form>
              <div className="card" style={{ width: "40%" }}>
                <div className="card-header">Stocks</div>
                <div className="card-body">
                  <fieldset className="form-group">
                    <Field
                      component={Checkbox}
                      name="stocks_fetch_prices"
                      id="stocks_fetch_prices"
                      label="Enable periodic fetching of stock prices"
                    />
                    <Field
                      component={Checkbox}
                      name="stocks_only_business_hours"
                      id="stocks_only_business_hours"
                      label="Fetch stock prices only during business hours"
                    />
                  </fieldset>
                </div>
              </div>
              <button
                className="btn btn-primary"
                type="submit"
                style={{ marginTop: "20px" }}
              >
                Save Settings
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default SystemSettings;
