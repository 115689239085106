import React from "react";
import { fmtMoneyUSD, currentYearMonth } from "../../library/Utilities";
import Money from "../common/Money";

const ProvisionsList = props => {
  // Provisions
  const provisions = props.provisions;
  // Table height
  const tableHeight = props.tableHeight + "px";
  // Current year month
  const currentYM = currentYearMonth();
  // Whether budget
  const isBudget = props.isBudget || false;

  return (
    <table
      className="table table-md table-striped table-fixed"
      style={{ borderBottom: "1px solid gray" }}
    >
      <thead className="thead-dark">
        <tr>
          <th scope="col">Budget Month</th>
          <th scope="col" className="text-right">
            Budgeted
          </th>
          <th scope="col" className="text-right">
            Allocated
          </th>
          <th scope="col" className="text-right">
            Spent
          </th>
          {isBudget && (
            <th scope="col" className="text-right">
              Available
            </th>
          )}
        </tr>
      </thead>
      <tbody style={{ height: tableHeight }}>
        {provisions.map(p => (
          <tr
            key={"p-" + p.id}
            style={{
              fontWeight: currentYM === p.year_month ? "bold" : "normal"
            }}
          >
            <td>{p.month_name}</td>
            <td className="text-right">{fmtMoneyUSD(p.budgeted_amount)}</td>
            <td className="text-right">{fmtMoneyUSD(p.allocated_amount)}</td>
            <td className="text-right">{fmtMoneyUSD(p.expended_amount)}</td>
            {isBudget && (
              <td className="text-right">
                <Money amount={p.available_amount} />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProvisionsList;
