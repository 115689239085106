import React from "react";
import { fmtNumber, fmtDateSimple } from "../../library/Utilities";
import Money from "../common/Money";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";

const AccountStockDetailTransactions = ({ transactions }) => {
  // Totals
  let totalCost = 0.0;
  let totalShares = 0.0;
  let totalAmount = 0.0;
  let totalFees = 0.0;
  transactions.forEach((tx) => {
    totalCost += tx.cost || 0.0;
    totalShares += tx.shares || 0.0;
    totalAmount += tx.amount || 0.0;
    totalFees += tx.fees || 0.0;
  });

  // Return
  return (
    <div className="row">
      <div className="col-12">
        <table className="table table-bordered table-lg table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Type</th>
              <th scope="col" style={{ textAlign: "right" }}>
                Cost
              </th>
              <th scope="col" style={{ textAlign: "right" }}>
                Fees
              </th>
              <th scope="col" style={{ textAlign: "right" }}>
                Shares
              </th>
              <th scope="col" style={{ textAlign: "right" }}>
                Value
              </th>
              <th scope="col" style={{ textAlign: "right" }}>
                Price
              </th>
              <th scope="col">Financial Account, Description</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((tx) => (
              <tr key={"tx-" + tx.id}>
                <td style={{ verticalAlign: "middle" }}>
                  {fmtDateSimple(tx.date)}
                </td>
                <td style={{ verticalAlign: "middle" }}>{tx.action}</td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                  <Money amount={tx.cost} />
                </td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                  <Money amount={tx.fees} />
                </td>
                <td
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                  }}
                >
                  {fmtNumber(tx.shares, 4)}
                </td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                  <Money amount={tx.amount} />
                </td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                  <Money amount={tx.price} />
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Link to={Links.financeFor(tx.financeId)}>
                    {tx.financeName}
                  </Link>
                  <div
                    style={{
                      color: "gray",
                      fontStyle: "italic",
                      fontSize: "0.8em",
                    }}
                  >
                    {tx.description}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="table-footer">
            <tr>
              <th></th>
              <th></th>
              <th style={{ textAlign: "right" }}>
                <Money amount={totalCost} />
              </th>
              <th style={{ textAlign: "right" }}>
                <Money amount={totalFees} />
              </th>
              <th style={{ textAlign: "right" }}>
                {fmtNumber(totalShares, 4)}
              </th>
              <th style={{ textAlign: "right" }}>
                <Money amount={totalAmount} />
              </th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default AccountStockDetailTransactions;
