import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import {
  collegeScheduledTransferEditUrl,
  collegeScheduledTransferUrl,
} from "../../library/Urls";
import { toUsMoney } from "../../library/Utilities";
import Checkbox from "../common/Checkbox";
import NumberField from "../common/NumberField";

class EditScheduledTransfer extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleClose: props.handleClose,
      handleSave: props.handleSave,
      scheduledTransferId: props.scheduledTransferId,
      contextFinanceId: props.contextFinanceId,
      errorMessage: null,
      actionsEnabled: false,
      actionSubmitText: "Save",
      _actionSubmitText: props.scheduledTransferId ? "Update" : "Create",
      _submittingText: props.scheduledTransferId
        ? "Updating..."
        : "Creating...",
      scheduledTransfer: {
        id: props.scheduledTransferId,
        origFinanceId: null,
        destFinanceId: null,
        amount: "",
        dayOfMonth: "",
        frequency: "",
        enabled: true,
        description: "",
        finances: [],
      },
    };
  }

  componentDidMount = (evt) => {
    // Edit
    doHttpGet({
      url: collegeScheduledTransferEditUrl,
      params: {
        scheduledTransferId: this.state.scheduledTransferId,
        contextFinanceId: this.state.contextFinanceId,
      },
      onSuccess: (editInfo) => {
        this.setState({
          scheduledTransfer: {
            ...editInfo,
            amount: editInfo.amount ? editInfo.amount.toFixed(2) : "",
          },
          actionsEnabled: true,
          errorMessage: null,
          actionSubmitText: this.state._actionSubmitText,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
          actionSubmitText: this.state._actionSubmitText,
        });
      },
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false,
      actionSubmitText: this.state._submittingText,
    });

    // Post
    doHttpPost({
      url: collegeScheduledTransferUrl,
      body: {
        ...values,
        id: this.state.scheduledTransferId,
        amount: toUsMoney(values.amount),
        dayOfMonth: parseInt(values.dayOfMonth, 10),
        frequency: parseInt(values.frequency, 10),
      },
      onSuccess: (scheduledTransferId) => {
        // Handle save
        this.state.handleSave(scheduledTransferId);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
          actionSubmitText: this.state._actionSubmitText,
        });
        const httpStatus = error.response.status;
        if (httpStatus >= 400 && httpStatus <= 499) {
          const errors = error.response.data.errors;
          errors.forEach((error) => {
            const errorMessage = error.defaultMessage;
            if (
              error.field === "id" ||
              error.field === "origAndDestFinancesDifferent"
            ) {
              this.setState({
                errorMessage: errorMessage,
              });
            } else {
              actions.setFieldError(error.field, errorMessage);
            }
          });
        } else if (httpStatus >= 500 && httpStatus <= 599) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  render() {
    // Form field values
    const {
      id,
      origFinanceId,
      destFinanceId,
      amount,
      dayOfMonth,
      frequency,
      enabled,
      description,
    } = this.state.scheduledTransfer;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {id ? "Update" : "Create"} Schedule Transfer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              origFinanceId,
              destFinanceId,
              amount,
              dayOfMonth,
              frequency,
              enabled,
              description,
            }}
            onSubmit={this.save}
            // validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">
                    FROM Financial Account
                  </label>
                  <Field
                    className="form-control"
                    as="select"
                    name="origFinanceId"
                  >
                    {this.state.scheduledTransfer.finances.map((finance) => (
                      <option key={finance.id} value={finance.id}>
                        {finance.name}
                      </option>
                    ))}
                  </Field>
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">TO Financial Account</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="destFinanceId"
                  >
                    {this.state.scheduledTransfer.finances.map((finance) => (
                      <option key={finance.id} value={finance.id}>
                        {finance.name}
                      </option>
                    ))}
                  </Field>
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Transfer Amount</label>
                  <Field
                    component={NumberField}
                    allowNegative={false}
                    name="amount"
                  ></Field>
                  <ErrorMessage
                    name="amount"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">
                    Schedule Day of Month (1-28)
                  </label>
                  <Field
                    component={NumberField}
                    integerOnly={true}
                    allowNegative={false}
                    name="dayOfMonth"
                  ></Field>
                  <ErrorMessage
                    name="dayOfMonth"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">
                    Repeat Every (1-12) Months
                  </label>
                  <Field
                    component={NumberField}
                    integerOnly={true}
                    allowNegative={false}
                    name="frequency"
                  ></Field>
                  <ErrorMessage
                    name="frequency"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <Field
                    component={Checkbox}
                    name="enabled"
                    id="enabled"
                    label="Allow future scheduled transfers"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">
                    Transaction Description
                  </label>
                  <Field
                    className="form-control"
                    as="textarea"
                    name="description"
                  ></Field>
                  <ErrorMessage
                    name="description"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.actionSubmitText}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditScheduledTransfer;
