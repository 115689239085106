import React, { useState } from "react";
import { doHttpPost } from "../../services/WebService";
import { stockUpdateUrl } from "../../library/Urls";
import { findItemByAttribute } from "../../library/Utilities";
import { useForm } from "react-hook-form";
import classnames from "classnames";

const StockTickerUpdate = ({
  stocksList,
  setErrorMessage,
  refreshStockSettings,
}) => {
  const [updateResponse, setUpdateResponse] = useState(null);
  const [isUpdateButtonDisabled, setUpdateButtonDisabled] = useState(true);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // Select stock
  const selectStock = (evt) => {
    const stockId = parseInt(evt.target.value);
    const stock = findItemByAttribute(stocksList, "id", stockId);
    setValue("stock_symbol", stock.symbol);
    setValue("stock_name", stock.name);
    setUpdateButtonDisabled(false);
    setUpdateResponse(null);
  };

  // Update stock
  const updateStock = (info) => {
    doHttpPost({
      url: stockUpdateUrl,
      body: info,
      onSuccess: (response) => {
        setUpdateResponse(response);
        if (response.success) {
          setUpdateButtonDisabled(true);
          refreshStockSettings();
        }
      },
      onError: (error) => setErrorMessage(error),
    });
  };

  // Render
  return (
    <div className="card border-dark mb-3">
      <div className="card-header">Update Stock Ticker</div>
      <div className="card-body text-dark">
        <form onSubmit={handleSubmit(updateStock)}>
          <fieldset className="form-group">
            <label className="control-label">Stock To Update :</label>
            <select
              {...register("stock_id", { required: true })}
              className="form-control"
              onChange={selectStock}
            >
              {stocksList.map((stock) => (
                <option key={stock.id} value={stock.id}>
                  {stock.symbol} ({stock.name})
                </option>
              ))}
            </select>
          </fieldset>

          <fieldset className="form-group required">
            <label className="control-label">Stock Symbol :</label>
            <input
              className="form-control"
              defaultValue=""
              {...register("stock_symbol", { required: true })}
            />
            {errors.stock_symbol && (
              <div className="alert alert-warning">
                {errors.stock_symbol?.type === "required" &&
                  "Stock symbol is required"}
              </div>
            )}
          </fieldset>

          <fieldset className="form-group required">
            <label className="control-label">Stock Name :</label>
            <input
              className="form-control"
              defaultValue=""
              {...register("stock_name", { required: true })}
            />
            {errors.stock_name && (
              <div className="alert alert-warning">
                {errors.stock_name?.type === "required" &&
                  "Stock name is required"}
              </div>
            )}
          </fieldset>

          <div>
            <button
              className={"btn btn-primary"}
              type="submit"
              disabled={isUpdateButtonDisabled}
            >
              Update Stock
            </button>
          </div>
        </form>

        {updateResponse && (
          <div
            className={classnames("alert", {
              "alert-danger": !updateResponse.success,
              "alert-success": updateResponse.success,
            })}
            style={{
              marginTop: "10px",
            }}
          >
            {updateResponse.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default StockTickerUpdate;
