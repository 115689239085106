import axios from "axios";
import * as Urls from "../library/Urls";
import { clearLocalSession, isAuthTokenExpired } from "./LocalService";

var _AXIOS;

export const setApiBaseUrl = () => {
  // Current URL
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  let port = window.location.port;
  let pathname = window.location.pathname;
  // Base URL
  if (port === "3000") {
    // Local development
    pathname = "";
    port = "8080";
  } else {
    pathname = "";
  }
  // Finalize
  const apiBaseUrl =
    protocol + "//" + hostname + ":" + port + pathname + "/api";

  // Axios instance
  _AXIOS = axios.create({
    baseURL: apiBaseUrl,
    timeout: 60000,
    headers: { "Content-Type": "application/json" },
    params: {},
  });
};

/**
 * Handle form error messages.
 * @param {*} error Error response from Axios request.
 */
export const handleFormError = (error) => {
  // Errors
  var fieldErrors = null;
  var errorMessage = null;

  // Determine messages
  if (error.response) {
    var message = error.response.data.message;
    if (!message) {
      var numErrors = error.response.data.ERROR_COUNT;
      message =
        "Please correct the " +
        numErrors +
        " error" +
        (numErrors === 1 ? "" : "s") +
        " indicated by the fields";
      fieldErrors = error.response.data;
    }
    errorMessage = message;
  } else if (error.request) {
    errorMessage = error.request;
  } else {
    errorMessage = error.message;
  }

  // Return
  return { fieldErrors: fieldErrors || {}, errorMessage: errorMessage };
};

const getHttpErrorMessage = (error) => {
  var message = null;
  if (error.response) {
    message = error.response.data.message;
  } else if (error.request) {
    message = error.request;
  } else {
    message = error.message;
  }
  return message;
};

export const doHttpGet = (c) => {
  const url = c.url;
  const params = c.params || {};
  const onSuccess = c.onSuccess;
  const rawResponse = c.rawResponse || false;
  const onError = c.onError || (() => {});

  // Request
  _AXIOS
    .get(url, { params: params })
    .then((response) => onSuccess(rawResponse ? response : response.data))
    .catch((error) => onError(getHttpErrorMessage(error)));
};

export const doHttpPost = (c) => {
  const url = c.url;
  const body = c.body;
  const onSuccess = c.onSuccess;
  const rawResponse = c.rawResponse || false;
  const onError = c.onError || (() => {});
  const rawError = c.rawError || false;

  // Request
  _AXIOS
    .post(url, body)
    .then((response) => onSuccess(rawResponse ? response : response.data))
    .catch((error) => onError(rawError ? error : handleFormError(error)));
};

export const doHttpDelete = (c) => {
  var url = c.url;
  var onSuccess = c.onSuccess;
  var onError = c.onError || (() => {});
  const params = c.params || {};

  // Request
  _AXIOS
    .delete(url, { params: params })
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(getHttpErrorMessage(error)));
};

export const setAuthenticationHeader = (jwtToken) => {
  const authHeader = "GELDZIN::" + jwtToken;
  _AXIOS.defaults.headers.common["Authorization"] = authHeader;
};

export const removeAuthenticationHeader = () => {
  delete _AXIOS.defaults.headers.common["Authorization"];
};

export const attemptServerLogout = (userId) => {
  // Logout
  doHttpPost({
    url: Urls.logoutUrl,
    body: userId,
  });
};

export const setupSessionInterceptors = (store) => {
  // Clearing session
  const doClearSession = (navigateToLogin) => {
    // Remove authentication header
    removeAuthenticationHeader();
    // Clear local session
    clearLocalSession(navigateToLogin);
  };

  // Requests
  _AXIOS.interceptors.request.use(
    function (config) {
      const url = config.url;
      const isProtectedUrl = Urls.isProtectedUrl(url);
      if (isProtectedUrl) {
        if (isAuthTokenExpired()) {
          doClearSession(true);
          return false;
        }
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  _AXIOS.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const url = error.config.url;
      const isProtectedUrl = Urls.isProtectedUrl(url);
      if (error.response && error.response.status === 401) {
        doClearSession(isProtectedUrl);
        if (isProtectedUrl) {
          return false;
        }
      }
      return Promise.reject(error);
    }
  );
};
