import React, { useState } from "react";
import { doHttpPost } from "../../services/WebService";
import { adminUpdateStockPriceSettingsUrl } from "../../library/Urls";
import { useForm } from "react-hook-form";
import classnames from "classnames";

const StockManagementPriceSettings = ({ priceSettings, setErrorMessage }) => {
  const [updateResponse, setUpdateResponse] = useState(null);
  const [isUpdateButtonDisabled, setUpdateButtonDisabled] = useState(true);
  const [allowDailyPriceUpdates, setAllowDailyPriceUpdates] = useState(
    priceSettings.allowDailyPriceUpdates
  );

  // Form
  const { register, handleSubmit } = useForm();

  // Update settings
  const updateSettings = (settings) => {
    setUpdateButtonDisabled(true);
    doHttpPost({
      url: adminUpdateStockPriceSettingsUrl,
      body: settings,
      onSuccess: (response) => {
        setUpdateResponse(response);
        setErrorMessage(null);
        setUpdateButtonDisabled(false);
      },
      onError: (error) => {
        setErrorMessage(error);
        setUpdateButtonDisabled(false);
      },
    });
  };

  // Change handlers
  const allowDailyPriceUpdatesHandler = (evt) => {
    const _allowDailyPriceUpdates = evt.target.checked;
    setAllowDailyPriceUpdates(_allowDailyPriceUpdates);
    setUpdateButtonDisabled(false);
  };

  // Render
  return (
    <div className="card border-dark mb-3">
      <div className="card-header">Stock Price Settings</div>
      <div className="card-body text-dark">
        <form onSubmit={handleSubmit(updateSettings)}>
          <fieldset
            className="form-group required"
            style={{ paddingLeft: "20px" }}
          >
            <input
              type="checkbox"
              className="form-check-input"
              defaultChecked={allowDailyPriceUpdates}
              {...register("allowDailyPriceUpdates", {
                onChange: allowDailyPriceUpdatesHandler,
              })}
            />
            <label
              className="control-label form-check-label"
              htmlFor="allowDailyPriceUpdates"
            >
              Allow retrieval of updated stock prices from financial provider
              services
            </label>
          </fieldset>

          <div>
            <button
              className={"btn btn-primary"}
              type="submit"
              disabled={isUpdateButtonDisabled}
            >
              Update Settings
            </button>
          </div>
        </form>

        {updateResponse && (
          <div
            className={classnames("alert", {
              "alert-danger": !updateResponse.success,
              "alert-success": updateResponse.success,
            })}
            style={{
              marginTop: "10px",
            }}
          >
            {updateResponse.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default StockManagementPriceSettings;
