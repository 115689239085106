import React, { useState, useEffect } from "react";
import * as Actions from "../../redux/actions";
import { connect } from "react-redux";
import { fmtNumber } from "../../library/Utilities";

const CollegeInfo = (props) => {
  const { earningsAmount } = props;

  // State
  const [_earningsAmount, setEarningsAmount] = useState(earningsAmount);

  // Changes
  useEffect(() => {
    setEarningsAmount(earningsAmount);
  }, [earningsAmount]);

  const amountChanged = (evt) => {
    let _amount = evt.target.value.replace("$", "");
    _amount = _amount.replace(/[^0-9.-]/, "");
    const _name = evt.target.name;
    switch (_name) {
      case "earningsAmount":
        setEarningsAmount(_amount);
        break;
      default:
    }
  };

  const updateCollegeInfo = () => {
    const info = {
      earningsAmount: fmtNumber(_earningsAmount, 2),
    };
    props._updateCollegeInfo(info);
  };

  return (
    <table
      className="table table-sm tbl-reconciliation-opt"
      style={{ marginBottom: 0, border: 0, borderSpacing: 0 }}
    >
      <tbody>
        <tr>
          <td className="label">Earnings/Gain:</td>
          <td>
            <input
              name="earningsAmount"
              className="form-control input-sm"
              type="text"
              value={_earningsAmount}
              onChange={amountChanged}
              onBlur={updateCollegeInfo}
            ></input>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  const reconciliation = state.reconciliation || {};
  const collegeInfo = reconciliation.collegeInfo || {};
  return {
    earningsAmount: collegeInfo.earningsAmount || "0.00",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _updateCollegeInfo: (o) => {
      dispatch(Actions.updateReconciliationCollegeInfo(o));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollegeInfo);
