import React, { Component, Fragment } from "react";
import { fmtDate } from "../../library/Utilities";
import Money from "../common/Money";
import * as Links from "../../library/Links";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom, faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import TransferDesignation from "../designations/TransferDesignation";
import ReassignDesignation from "./ReassignDesignation";

export default class Designations extends Component {
  constructor(props) {
    super();

    // Initial state
    this.state = {
      isShowDesignationTransferDlg: false,
      selectedDesignationId: null,
      isShowDesignationReassignDlg: false,
    };
  }

  showDesignationTransferDlg = (shouldShow, designationId) => {
    this.setState({
      isShowDesignationTransferDlg: shouldShow,
      selectedDesignationId: designationId,
    });
  };

  onTransferComplete = () => {
    // Close
    this.showDesignationTransferDlg(false, null);
    // Callback
    this.props.refreshDesignationInfo();
  };

  showDesignationReassignDlg = (shouldShow) => {
    this.setState({
      isShowDesignationReassignDlg: shouldShow,
    });
  };

  onReassignComplete = () => {
    // Close
    this.showDesignationReassignDlg(false);
    // Callback
    this.props.refreshDesignationInfo();
  };

  render() {
    return (
      <Fragment>
        <div
          className="container"
          style={{
            marginBottom: "10px",
          }}
        >
          <div className="row">
            <div
              className="col-12"
              style={{
                textAlign: "right",
                verticalAlign: "bottom",
                paddingRight: 0,
              }}
            >
              <Button
                className="btn btn-md btn-primary"
                onClick={() => this.showDesignationReassignDlg(true)}
              >
                <FontAwesomeIcon
                  icon={faExchangeAlt}
                  style={{ marginRight: "10px" }}
                />
                Reassign
              </Button>
            </div>
          </div>
        </div>
        <table className="table table-striped table-bordered table-hover designations">
          <thead className="thead-dark">
            <tr>
              <th scope="col" className="type">
                Designation
              </th>
              <th scope="col">Description</th>
              <th scope="col" className="amount">
                Amount
              </th>
              <th scope="col" className="updated">
                Last Updated
              </th>
              <th scope="col" style={{ width: "35px" }}></th>
            </tr>
          </thead>
          <tbody>
            {this.props.designations.map((d) => {
              // Description
              const description = d.description;
              let isLinkDescription = d.reference_id != null;
              let descriptionUrl = null;
              if (isLinkDescription) {
                if (d.type === "Budget") {
                  descriptionUrl = Links.budgetFor(d.reference_id);
                } else if (d.type === "Savings Goal") {
                  descriptionUrl = Links.savingsGoalFor(d.reference_id);
                } else {
                  isLinkDescription = false;
                }
              }

              // Return
              return (
                <tr key={"designation-" + d.id}>
                  <td className="type">{d.type}</td>
                  <td>
                    {isLinkDescription ? (
                      <Link to={descriptionUrl}>{description}</Link>
                    ) : (
                      description
                    )}
                  </td>
                  <td className="amount">
                    <Money amount={d.amount} />
                  </td>
                  <td className="updated">{fmtDate(d.updated_date)}</td>
                  <td>
                    <Button
                      className="btn btn-sm"
                      onClick={() =>
                        this.showDesignationTransferDlg(true, d.id)
                      }
                    >
                      <FontAwesomeIcon icon={faRandom} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {this.state.isShowDesignationTransferDlg && (
          <TransferDesignation
            show={this.state.isShowDesignationTransferDlg}
            onClose={() => this.showDesignationTransferDlg(false, null)}
            onTransfer={this.onTransferComplete}
            financeId={this.props.financeId}
            designationId={this.state.selectedDesignationId}
          />
        )}

        {this.state.isShowDesignationReassignDlg && (
          <ReassignDesignation
            show={this.state.isShowDesignationReassignDlg}
            onClose={() => this.showDesignationReassignDlg(false, null)}
            onReassign={this.onReassignComplete}
            financeId={this.props.financeId}
          />
        )}
      </Fragment>
    );
  }
}
