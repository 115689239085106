import React, { Component } from "react";
import { doHttpGet } from "../../services/WebService";
import { budgetMonthlyUrl } from "../../library/Urls";
import {
  currentYearMonth,
  fullYearMonthName,
  ymMonth,
  ymYear,
  fmtMoneyUSD,
} from "../../library/Utilities";
import PageNotFound from "../common/PageNotFound";
import { Form, Col, Button, Accordion, Card } from "react-bootstrap";
import BudgetListings from "./BudgetListings";
import UpcomingListings from "./UpcomingListings";
import DisabledListings from "./DisabledListings";

export default class MonthlyBudget extends Component {
  constructor(props) {
    super(props);
    // State
    this.state = {
      errorMessage: null,
      monthlyBudget: null,
      selectedMonth: 7,
      selectedYear: 2020,
    };
  }

  setErrorMessage = (message) => {
    this.setState({
      errorMessage: message,
    });
  };

  getMonthlyBudget = (yearMonth) => {
    doHttpGet({
      url: budgetMonthlyUrl(yearMonth),
      onSuccess: (info) => {
        if (info) {
          this.setState({
            monthlyBudget: info,
            errorMessage: null,
            selectedMonth: ymMonth(info.yearMonth),
            selectedYear: ymYear(info.yearMonth),
          });
        } else {
          this.setErrorMessage(
            "Could not obtain monthly budget for " +
              fullYearMonthName(yearMonth)
          );
        }
      },
      onError: this.setErrorMessage,
    });
  };

  componentDidMount = (evt) => {
    // Monthly budget for current month
    const currentYM = currentYearMonth();
    this.getMonthlyBudget(currentYM);
  };

  changeBudgetMonth = () => {
    // Year month
    const yearMonth =
      this.state.selectedYear +
      (this.state.selectedMonth < 10 ? "0" : "") +
      this.state.selectedMonth;
    // Monthly budget
    this.getMonthlyBudget(yearMonth);
  };

  render() {
    // Information
    const { monthlyBudget, errorMessage } = this.state;

    // When there is an error
    if (!monthlyBudget) {
      if (errorMessage) {
        return (
          <PageNotFound title="Budget Adjustment" message={errorMessage} />
        );
      }
      return <div />;
    }

    // Computations
    const isOverSpending =
      monthlyBudget.spentAmount > monthlyBudget.allocatedAmount;
    const usedHeaderText = isOverSpending ? "Over-Budget" : "Spent";
    const usedHeaderColor = isOverSpending ? "#CC3333" : "#FF9933";
    const spentAmount = isOverSpending
      ? monthlyBudget.spentAmount - monthlyBudget.allocatedAmount
      : monthlyBudget.spentAmount;

    // Return
    return (
      <div className="page-content monthly-budget">
        <div className="row" style={{ marginBottom: "15px" }}>
          <div className="col-md-8 report-page-title">
            Budget for {fullYearMonthName(monthlyBudget.yearMonth)}
          </div>
          <div className="col-md-4">
            <Form>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Control
                    as="select"
                    defaultValue={this.state.selectedMonth}
                    onChange={(e) => {
                      this.setState({ selectedMonth: e.target.value });
                    }}
                  >
                    <option value={1}>January</option>
                    <option value={2}>February</option>
                    <option value={3}>March</option>
                    <option value={4}>April</option>
                    <option value={5}>May</option>
                    <option value={6}>June</option>
                    <option value={7}>July</option>
                    <option value={8}>August</option>
                    <option value={9}>September</option>
                    <option value={10}>October</option>
                    <option value={11}>November</option>
                    <option value={12}>December</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control
                    as="select"
                    defaultValue={this.state.selectedYear}
                    onChange={(e) => {
                      this.setState({ selectedYear: e.target.value });
                    }}
                  >
                    {monthlyBudget.budgetYears.map((yr) => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button size="sm" onClick={() => this.changeBudgetMonth()}>
                  Change
                </Button>
              </Form.Row>
            </Form>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7">
            <table
              style={{
                width: "99%",
                marginLeft: "auto",
                marginRight: "0",
                marginBottom: "15px",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ textAlign: "right" }}>Budgeted</td>
                  <td style={{ textAlign: "right" }}>Allocated</td>
                  <td style={{ textAlign: "right" }}>{usedHeaderText}</td>
                </tr>
                <tr>
                  <td className="account-total">
                    {fmtMoneyUSD(monthlyBudget.budgetedAmount)}
                  </td>
                  <td style={{ color: "#339933" }} className="account-total">
                    {fmtMoneyUSD(monthlyBudget.allocatedAmount)}
                  </td>
                  <td
                    style={{ color: { usedHeaderColor } }}
                    className="account-total"
                  >
                    {fmtMoneyUSD(spentAmount)}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="section-title">
              BILLS ({monthlyBudget.bills.length})
            </div>
            <BudgetListings listings={monthlyBudget.bills} />

            <div className="section-title">
              SAVINGS GOALS ({monthlyBudget.savingsGoals.length})
            </div>
            <BudgetListings listings={monthlyBudget.savingsGoals} />

            <div className="section-title">
              OTHER BUDGETS ({monthlyBudget.nonBills.length})
            </div>
            <BudgetListings listings={monthlyBudget.nonBills} />
          </div>

          <div className="col-md-5">
            <Accordion defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                  UPCOMING BUDGETS ({monthlyBudget.upcomingBudgets.length})
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <UpcomingListings
                      listings={monthlyBudget.upcomingBudgets}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                  DISABLED BUDGETS ({monthlyBudget.disabledItems.length})
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <DisabledListings listings={monthlyBudget.disabledItems} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}
