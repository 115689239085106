import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { categoryEditUrl, categorySaveUrl } from "../../library/Urls";

const errorFieldMappings = {
  name: "name",
  description: "description",
  type: "type"
};

class EditCategory extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      editInfo: {
        id: props.categoryId,
        name: "",
        description: "",
        type: "EXPENSE",
        category_types: []
      },
      categoryId: props.categoryId,
      errorMessage: null,
      actionsEnabled: false
    };
  }

  componentDidMount = evt => {
    // Initial data
    doHttpGet({
      url: categoryEditUrl(this.state.categoryId),
      onSuccess: data => {
        this.setState({
          editInfo: data,
          actionsEnabled: true,
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  save = (values, actions) => {
    this.setState({
      actionsEnabled: false
    });

    // Post
    doHttpPost({
      url: categorySaveUrl,
      body: {
        ...values,
        id: this.state.categoryId
      },
      onSuccess: categoryId => {
        // Handle save
        this.state.onSave(categoryId);
      },
      rawError: true,
      onError: error => {
        this.setState({
          actionsEnabled: true
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach(error => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage
              });
            } else {
              actions.setFieldError(
                errorFieldMappings[error.field],
                errorMessage
              );
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage
          });
        }
      }
    });
  };

  validate = values => {
    const errors = {};
    // All inputs are required
    if (!values.name) {
      errors.name = "Category name is required";
    }
    if (!values.description) {
      errors.description = "Category description is required";
    }
    if (!values.type) {
      errors.type = "Category type selection is required";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const { id, name, description, type, category_types } = this.state.editInfo;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{id ? "Update" : "Create"} Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px"
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{
              name,
              description,
              type
            }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {props => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Name</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="name"
                  ></Field>
                  <ErrorMessage
                    name="name"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Description</label>
                  <Field
                    className="form-control"
                    as="textarea"
                    name="description"
                  ></Field>
                  <ErrorMessage
                    name="description"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <label className="control-label required">Type</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="type"
                    disabled={this.state.categoryId}
                  >
                    {category_types.map(t => (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="type"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!this.state.actionsEnabled}
                  >
                    {this.state.categoryId ? "Update" : "Create"}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditCategory;
