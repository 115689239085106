import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { adminAccountEditUrl, adminAccountSaveUrl } from "../../library/Urls";
import moment from "moment";

class EditAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      handleClose: props.handleClose,
      handleSave: props.handleSave,
      account: {
        id: props.accountId,
        zip_code: "",
        account_status: "",
        deletion_date: ""
      },
      statuses: [],
      isAdmin: false,
      errorMessage: null
    };
  }

  componentDidMount = evt => {
    doHttpGet({
      url: adminAccountEditUrl(this.state.account.id),
      onSuccess: data => {
        this.setState({
          account: {
            id: data.id,
            zip_code: data.zip_code,
            account_status: data.account_status,
            deletion_date: data.deletion_date || ""
          },
          statuses: data.statuses,
          isAdmin: data.is_admin
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  save = (values, actions) => {
    // Account ID
    values.id = this.state.account.id;
    // Post
    doHttpPost({
      url: adminAccountSaveUrl,
      body: values,
      rawError: true,
      onSuccess: data => {
        // Handle save
        this.state.handleSave();
      },
      onError: error => {
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          const errors = error.response.data.errors;
          errors.forEach(error => {
            const errorMessage = error.defaultMessage;
            if (error.field === "id") {
              this.setState({
                errorMessage: errorMessage
              });
            } else if (error.field === "zipCode") {
              actions.setFieldError("zip_code", errorMessage);
            } else if (error.field === "deletionDate") {
              actions.setFieldError("deletion_date", errorMessage);
            } else if (error.field === "status") {
              actions.setFieldError("status", errorMessage);
            }
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage
          });
        }
      }
    });
  };

  validate = values => {
    const errors = {};
    // Zip code is required
    if (!values.zip_code) {
      errors.zip_code = "Zip code is required";
    }
    // Status is required
    if (!values.account_status) {
      errors.zip_code = "Account status is required";
    }
    // Date
    if (values.deletion_date) {
      const dt = moment(values.deletion_date);
      if (!dt.isValid()) {
        errors.deletion_date = "Invalid date";
      }
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const { zip_code, account_status, deletion_date } = this.state.account;

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px"
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{ zip_code, deletion_date, account_status }}
            onSubmit={this.save}
            validate={this.validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {props => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Zip Code</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="zip_code"
                  ></Field>
                  <ErrorMessage
                    name="zip_code"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group">
                  <label>Deletion Date</label>
                  <Field
                    className="form-control"
                    type="date"
                    name="deletion_date"
                    disabled={this.state.isAdmin}
                  ></Field>
                  <ErrorMessage
                    name="deletion_date"
                    className="alert alert-warning"
                    component="div"
                  />
                </fieldset>
                <fieldset className="form-group required">
                  <label className="control-label">Account Status</label>
                  <Field
                    className="form-control"
                    name="account_status"
                    as="select"
                    disabled={this.state.isAdmin}
                  >
                    {this.state.statuses.map(status => (
                      <option key={status.id} value={status.id}>
                        {status.name}
                      </option>
                    ))}
                  </Field>
                </fieldset>
                <Modal.Footer>
                  <button className="btn btn-primary" type="submit">
                    Save
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditAccount;
