import React, { useState, useEffect } from "react";
import { doHttpGet } from "../../services/WebService";
import { retirementInfoUrl } from "../../library/Urls";
import Money from "../common/Money";
import { Tabs, Tab } from "react-bootstrap";
import FinancialAccounts from "./FinancialAccounts";

const Retirement = () => {
  // State
  const [retirementInfo, setRetirementInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Get retirement info
  const getRetirementInfo = (p) => {
    // Params
    const params = {};
    if (p) {
      params.includeDisabledFinances = p.withDisabledAccounts;
    }
    // GET
    doHttpGet({
      url: retirementInfoUrl,
      params: params,
      onSuccess: (info) => {
        setErrorMessage(null);
        setRetirementInfo(info);
      },
      onError: (error) => setErrorMessage(error),
    });
  };

  // Changes
  useEffect(() => getRetirementInfo(), []);

  // Render
  return (
    <div className="page-content">
      {errorMessage && (
        <div
          className="alert alert-danger"
          style={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "1.2em",
            padding: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}

      {retirementInfo && (
        <div>
          {!retirementInfo.exists && (
            <div className="alert alert-danger" style={{ marginBottom: "5px" }}>
              The <b>Retirement</b> feature has not been added to this account
            </div>
          )}
          {!retirementInfo.enabled && (
            <div className="alert alert-danger" style={{ marginBottom: "5px" }}>
              The <b>Retirement</b> feature is not enabled for this account
            </div>
          )}
          <div className="row">
            <div className="col-8">
              <h2>
                Retirement
                <span className="gzn-detail-flag">Feature/Option</span>
              </h2>
            </div>
            <div
              className="col-4"
              style={{ textAlign: "right", verticalAlign: "bottom" }}
            >
              <Money
                amount={retirementInfo.totalRetirementValue}
                className="amount-lg"
              />
            </div>
          </div>
          <Tabs defaultActiveKey="fa" id="financial-account-tab">
            <Tab
              eventKey="fa"
              title={
                "Financial Accounts (" +
                retirementInfo.financialAccounts.length +
                ")"
              }
            >
              <FinancialAccounts
                financialAccounts={retirementInfo.financialAccounts}
                refreshRetirementInfo={(params) => getRetirementInfo(params)}
                totalRetirementValue={retirementInfo.totalRetirementValue}
                showErrorMessage={(errorMsg) => setErrorMessage(errorMsg)}
                isDisabledAccountsIncluded={
                  retirementInfo.includesDisabledFinancialAccounts
                }
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default Retirement;
