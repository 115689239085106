import React, { useState } from "react";
import AccountSettingsStocks from "./AccountSettingsStocks";

const AccountSettings = ({ accountSettings }) => {
  // State
  const stockSettings = accountSettings.stockSettings;
  const [errorMessage, setErrorMessage] = useState(null);

  // Render
  return (
    <div className="page-content">
      {errorMessage && (
        <div
          className="alert alert-danger"
          style={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "1.2em",
            padding: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}

      {stockSettings && (
        <div className="container">
          <div className="row">
            <div className="col">
              <AccountSettingsStocks
                stockSettings={stockSettings}
                setErrorMessage={setErrorMessage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountSettings;
