import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { fmtMoneyUSD, fmtDate } from "../../library/Utilities";
import { doHttpGet } from "../../services/WebService";
import { incomeDistributionsUrl } from "../../library/Urls";
import * as Links from "../../library/Links";
import { Link } from "react-router-dom";
import Money from "../common/Money";

export default class IncomeDistributions extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      handleClose: props.onClose,
      errorMessage: null,
      batchNumber: props.batchNumber,
      distributions: [],
    };
  }

  componentDidMount = (evt) => {
    doHttpGet({
      url: incomeDistributionsUrl,
      params: {
        bn: this.state.batchNumber,
      },
      onSuccess: (listings) => {
        this.setState({
          distributions: listings,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Income Distribution Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          {this.state.distributions.map((info) => {
            const emergencies = info.emergencies || [];
            const budgets = info.budgets || [];
            const savings = info.savingsGoals || [];
            const financials = info.financials || [];
            // Render
            return (
              <div
                key={"tx-" + info.transactionId}
                className="income-distribution"
              >
                <div className="header">
                  Distribution of{" "}
                  <span className="positive-amt">
                    {fmtMoneyUSD(info.transactionAmount)}
                  </span>
                  {" on "}
                  {fmtDate(info.transactionDate)}
                </div>
                <table className="table table-sm" style={{ marginBottom: "0" }}>
                  <tbody>
                    <tr>
                      <td className="lbl">Received From:</td>
                      <td className="dtl">{info.receivedFrom}</td>
                    </tr>
                    <tr>
                      <td className="lbl">Categorization:</td>
                      <td className="dtl">{info.categoryName}</td>
                    </tr>
                    <tr>
                      <td className="lbl">Financial Account:</td>
                      <td className="dtl">{info.financialAccountName}</td>
                    </tr>
                    <tr>
                      <td className="lbl">Undesignated Used:</td>
                      <td className="dtl">
                        {fmtMoneyUSD(info.initialUndesignatedAmount)}
                      </td>
                    </tr>
                    <tr>
                      <td className="lbl">Total Distribution:</td>
                      <td className="dtl">
                        {fmtMoneyUSD(info.totalDistributionAmount)}
                      </td>
                    </tr>
                    <tr>
                      <td className="lbl">Added To Undesignated:</td>
                      <td className="dtl">
                        {fmtMoneyUSD(info.totalUndistributedAmount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ padding: 0 }}>
                        {emergencies.length > 0 && (
                          <DistributionSection
                            items={emergencies}
                            title="Emergencies"
                            prefix="emg"
                            url={Links.emergencies}
                          />
                        )}
                        {budgets.length > 0 && (
                          <DistributionSection
                            items={budgets}
                            title="Budgets"
                            prefix="bgt"
                            url={Links.budgetFor}
                          />
                        )}
                        {savings.length > 0 && (
                          <DistributionSection
                            items={savings}
                            title="Savings Goals"
                            prefix="sg"
                            url={Links.savingsGoalFor}
                          />
                        )}
                        {financials.length > 0 && (
                          <DistributionSection
                            items={financials}
                            title="Other Designations"
                            prefix="other"
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>
                        <div className="row">
                          <div className="col-9 text-right">
                            Total Distributed:
                          </div>
                          <div className="col-3 text-right">
                            {fmtMoneyUSD(info.totalDistributedAmount)}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            );
          })}
        </Modal.Body>
      </Modal>
    );
  }
}

const DistributionSection = (props) => {
  return (
    <div className="section">
      <div className="title">
        {props.title} ({props.items.length})
      </div>
      {props.items.map((item) => {
        let url = props.url;
        if (url && item.referenceId) {
          if (props.prefix !== "emg") url = url(item.referenceId);
          if (props.prefix === "other") url = null;
        }
        return (
          <div key={props.prefix + "-" + item.id} className="row item">
            <div className="col-7">
              {url && item.referenceId ? (
                <Link to={url}>{item.description}</Link>
              ) : (
                item.description
              )}
            </div>
            <div className="col-3">{item.month}</div>
            <div className="col-2 text-right">
              <Money amount={item.amount} plusMinus={true} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
