import React, { Fragment } from "react";
import { fmtDate, fullYearMonthName } from "../../library/Utilities";
import Money from "../common/Money";
import * as Links from "../../library/Links";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";

export default function TransactionListings(props) {
  // Transactions
  const transactions = props.transactions || [];
  const displayFinancing = props.displayFinancing !== false;
  const displayCategorization = props.displayCategorization !== false;
  // Total amount
  let totalAmount = 0.0;
  // Render
  return (
    <Fragment>
      <div className="sub-section-title">
        Transactions ({transactions.length})
      </div>
      <table className="table table-md table-striped">
        <thead>
          <tr>
            <th scope="col" style={{ width: "100px", maxWidth: "100px" }}>
              Date
            </th>
            <th scope="col" style={{ width: "90px", textAlign: "right" }}>
              Amount
            </th>
            <th scope="col" style={{ width: "120px" }}>
              Budget Month
            </th>
            {displayFinancing && (
              <th scope="col" style={{ width: "15%" }}>
                Financing
              </th>
            )}
            <th
              scope="col"
              style={{ width: displayFinancing ? "20%" : "200px" }}
            >
              Party
            </th>
            {displayCategorization && (
              <th scope="col" style={{ width: "175px" }}>
                Categorization
              </th>
            )}
            <th scope="col" style={{ width: "275px" }}>
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((t) => {
            // To/from
            let toFromText = "TO:";
            if (t.type === "DEPOSIT") toFromText = "FROM:";
            // Total
            totalAmount += t.amount;
            // Render
            return (
              <tr key={"tx-" + t.id}>
                <td>{fmtDate(t.date)}</td>
                <td className="text-right">
                  <Money amount={t.amount} />
                </td>
                <td>{fullYearMonthName(t.budgetMonth)}</td>
                {displayFinancing && (
                  <td>
                    <Financing finance={t.financing} />
                  </td>
                )}
                <td>
                  {t.incomeDistribution ? (
                    <div style={{ fontWeight: "bold" }}>
                      <FontAwesomeIcon icon={faListAlt} size="lg" /> {t.party}
                    </div>
                  ) : (
                    <Fragment>
                      <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                        {toFromText}
                      </span>
                      {t.party}
                    </Fragment>
                  )}
                </td>
                {displayCategorization && (
                  <td>
                    {t.categorization && (
                      <Categorization categorization={t.categorization} />
                    )}
                  </td>
                )}
                <td className="description">{t.description}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="table-footer">
          <tr>
            <td colSpan={6}>
              Total Transaction Amount: <Money amount={totalAmount} />
            </td>
          </tr>
        </tfoot>
      </table>
    </Fragment>
  );
}

const Categorization = (props) => {
  const categorization = props.categorization || {};
  // URL
  let url = null;
  if (categorization.type === "BUDGET") {
    url = Links.budgetFor(categorization.id);
  } else if (categorization.type === "SAVINGS") {
    url = Links.savingsGoalFor(categorization.id);
  }
  // Content
  if (url) {
    return <Link to={url}>{categorization.name}</Link>;
  } else {
    return <span>{categorization.name}</span>;
  }
};

const Financing = (props) => {
  const finance = props.finance || {};
  // URL
  let url = null;
  if (finance.type === "FINANCE") {
    url = Links.financeFor(finance.id);
  } else if (finance.type === "HEALTHCARE") {
    url = Links.healthcareCardById(finance.id);
  } else if (finance.type === "GIFTCARD") {
    url = Links.giftCardForId(finance.id);
  }
  // Content
  if (url) {
    return <Link to={url}>{finance.name}</Link>;
  } else {
    return <span>{finance.name}</span>;
  }
};
