import React, { Component } from "react";
import Money from "../common/Money";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Tabs, Tab } from "react-bootstrap";
import Emergencies from "./Emergencies";
import Designations from "./Designations";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import { fmtMoneyUSD, countOrZero } from "../../library/Utilities";
import { doHttpGet } from "../../services/WebService";
import { emergencyPlanUrl } from "../../library/Urls";
import EditEmergencyPlan from "./EditEmergencyPlan";

class EmergencyPlan extends Component {
  state = {
    errorMessage: null
  };

  getEmergencyPlan = () => {
    doHttpGet({
      url: emergencyPlanUrl,
      onSuccess: ep => {
        // Update info
        this.props._setEmergencyPlan(ep);
        // Clear error
        this.setState({
          errorMessage: null
        });
      },
      onError: error => {
        this.setState({
          errorMessage: error
        });
      }
    });
  };

  componentDidMount = evt => {
    this.getEmergencyPlan();
  };

  showPlanEditor = show => {
    this.setState({ showPlanEditor: show });
  };

  onEditPlan = () => {
    this.showPlanEditor(false);
    this.getEmergencyPlan();
  };

  render() {
    // Data
    const emergency_plan = this.props.emergency_plan || {};
    const { goal, minimum, needed, designated } = emergency_plan;
    const still_needs = needed > 0.0;
    const total_emergencies = countOrZero(emergency_plan.emergencies);
    const total_designations = countOrZero(emergency_plan.designations);

    // Render
    return (
      <div className="page-content">
        <h2>Emergency Plan</h2>

        <table className="table table-lg emergencies">
          <tbody>
            <tr>
              <td>
                <span className="label">Goal:</span>{" "}
                <span className="amount">{fmtMoneyUSD(goal)}</span>
              </td>
              <td>
                <span className="label">Minimum:</span>{" "}
                <span className="amount">{fmtMoneyUSD(minimum)}</span>{" "}
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.showPlanEditor(true)}
                >
                  <FontAwesomeIcon icon={faEdit} /> Edit
                </button>
              </td>
              <td
                className={classnames("", {
                  "negative-amount": still_needs
                })}
              >
                <span className="label">Needed:</span>{" "}
                <span className="amount">{fmtMoneyUSD(needed)}</span>
              </td>
              <td>
                <span className="label">Designated:</span>{" "}
                <Money amount={designated} className="amount designated" />
              </td>
            </tr>
          </tbody>
        </table>

        {still_needs && (
          <div
            className="alert alert-warning"
            style={{
              marginBottom: "10px"
            }}
          >
            You still need <b>{fmtMoneyUSD(needed)}</b> to fully fund your
            Emergency Plan!
          </div>
        )}

        <Tabs defaultActiveKey="emergencies" id="femergencies-account-tab">
          <Tab
            eventKey="emergencies"
            title={"Emergencies  (" + total_emergencies + ")"}
          >
            <Emergencies getEmergencyPlan={this.getEmergencyPlan} />
          </Tab>
          <Tab
            eventKey="designations"
            title={"Designations (" + total_designations + ")"}
          >
            <Designations />
          </Tab>
        </Tabs>

        {this.state.showPlanEditor && (
          <EditEmergencyPlan
            show={this.state.showPlanEditor}
            onSave={this.onEditPlan}
            onClose={() => this.showPlanEditor(false)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    emergency_plan: state.emergency_plan,
    errorMessage: null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // Errors
    _handleError: message => {
      dispatch(Actions.setErrorMessage(message));
    },
    // Emergency plan
    _setEmergencyPlan: ep => {
      dispatch(Actions.setEmergencyPlan(ep));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyPlan);
