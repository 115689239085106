import * as ActionTypes from "./types";

///////////////////////////////////////////////////////////////////////////////
// SESSION
///////////////////////////////////////////////////////////////////////////////

export const setErrorMessage = (message) => ({
  type: ActionTypes.ErrorMessage,
  message: message,
});

export const setSession = (data, isLogin) => ({
  type: ActionTypes.SetSession,
  data: data,
  login: isLogin,
});

export const setSelectedUserId = (id) => ({
  type: ActionTypes.SetSelectedUserId,
  id: id,
});

export const setAccountDeletionDate = (date) => ({
  type: ActionTypes.AccountDeletionDate,
  date: date,
});

export const clearStore = () => ({
  type: ActionTypes.ClearStore,
});

///////////////////////////////////////////////////////////////////////////////
// SETTINGS
///////////////////////////////////////////////////////////////////////////////

export const setSettings = (data) => ({
  type: ActionTypes.SetSettings,
  data: data,
});

export const featureOptions = (options) => ({
  type: ActionTypes.FeatureOptions,
  options: options,
});

///////////////////////////////////////////////////////////////////////////////
// FINANCES
///////////////////////////////////////////////////////////////////////////////

export const setFinanceInfo = (info) => ({
  type: ActionTypes.FinanceSetInfo,
  info: info,
});

export const setIncludeDisabledFinances = (incl) => ({
  type: ActionTypes.FinanceSetIncludeDisabled,
  include: incl,
});

///////////////////////////////////////////////////////////////////////////////
// EMERGENCY PLAN
///////////////////////////////////////////////////////////////////////////////

export const setEmergencyPlan = (ep) => ({
  type: ActionTypes.SetEmergencyPlan,
  data: ep,
});

///////////////////////////////////////////////////////////////////////////////
// BUDGETS
///////////////////////////////////////////////////////////////////////////////

export const setBudgetInfo = (info) => ({
  type: ActionTypes.BudgetSetInfo,
  data: info,
});

export const setIncludeDisabledBudgets = (incl) => ({
  type: ActionTypes.BudgetSetIncludeDisabled,
  include: incl,
});

///////////////////////////////////////////////////////////////////////////////
// SAVINGS GOALS
///////////////////////////////////////////////////////////////////////////////

export const setSavingsGoalInfo = (info) => ({
  type: ActionTypes.SavingsGoalSetInfo,
  data: info,
});

export const setIncludeDisabledSavingsGoals = (incl) => ({
  type: ActionTypes.SavingsGoalSetIncludeDisabled,
  include: incl,
});

///////////////////////////////////////////////////////////////////////////////
// TRANSACTIONS
///////////////////////////////////////////////////////////////////////////////

export const setTransactionInfo = (data) => ({
  type: ActionTypes.TransactionInfo,
  data: data,
});

export const setSelectedTransaction = (batchNumber) => ({
  type: ActionTypes.SelectedTransaction,
  data: batchNumber,
});

///////////////////////////////////////////////////////////////////////////////
// RECONCILIATION
///////////////////////////////////////////////////////////////////////////////

export const setReconciliationInfo = (info) => ({
  type: ActionTypes.ReconciliationInfo,
  data: info,
});

export const updateReconciliationInterest = (interest) => ({
  type: ActionTypes.ReconciliationInterest,
  data: interest,
});

export const updateReconciliationFees = (fees) => ({
  type: ActionTypes.ReconciliationFees,
  data: fees,
});

export const updateReconciliationStatement = (balance, date) => ({
  type: ActionTypes.ReconciliationStatement,
  data: { balance: balance, date: date },
});

export const updateReconciliationRewards = (rewards) => ({
  type: ActionTypes.ReconciliationRewards,
  data: rewards,
});

export const updateReconciliationInvestments = (info) => ({
  type: ActionTypes.ReconciliationInvestments,
  data: info,
});

export const removeOption = (action) => ({
  type: ActionTypes.FeatureOptionsOff,
  option: action,
});

export const updateReconciliationTransactions = (transactions) => ({
  type: ActionTypes.ReconciliationTransactions,
  data: transactions,
});

export const updateReconciliationRetirement401k = (info) => ({
  type: ActionTypes.ReconciliationRetirement401k,
  data: info,
});

export const updateReconciliationRetirementStd = (info) => ({
  type: ActionTypes.ReconciliationRetirementStd,
  data: info,
});

export const updateReconciliationCollegeInfo = (info) => ({
  type: ActionTypes.ReconciliationCollegeInfo,
  data: info,
});

///////////////////////////////////////////////////////////////////////////////
// GIFT CARDS
///////////////////////////////////////////////////////////////////////////////

export const setIncludeDisabledGiftCards = (include) => ({
  type: ActionTypes.IncludeDisabledGiftCards,
  data: include,
});
