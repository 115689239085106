import React, { Fragment, useState } from "react";
import Money from "./Money";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import TransferDesignation from "../designations/TransferDesignation";

const DesignationFinancesList = (props) => {
  const [isShowDesignationTransferDlg, showDesignationTransferDlg] =
    useState(false);
  const [selectedFinanceId, setSelectedFinanceId] = useState(null);
  const [selectedDesignationId, setSelectedDesignationId] = useState(null);
  // Designations
  const designations = props.designations || [];
  // Table height
  const tableHeight = props.tableHeight + "px";
  // Total designations
  let totalDesignationAmt = designations.reduce((accumulator, df) => {
    return accumulator + df.designationAmount;
  }, 0.0);
  // Column styles
  const _col = {
    amount: { width: "150px" },
    updated: { width: "120px" },
    action: { width: "50px" },
  };

  // Transfer designation dialog
  const dlgDesignationTransfer = (financeId, designationId) => {
    // Required data
    setSelectedDesignationId(designationId);
    setSelectedFinanceId(financeId);
    // Open dialog
    showDesignationTransferDlg(true);
  };

  const refreshDesignationInfo = () => {
    // Close dialog
    showDesignationTransferDlg(false);
    // Refresh info
    props.refreshInfoCallback();
  };

  return (
    <Fragment>
      <table
        className="table table-sm table-striped table-fixed"
        style={{ borderBottom: "1px solid gray" }}
      >
        <thead className="thead-dark">
          <tr>
            <th scope="col">Financial Account</th>
            <th scope="col" className="text-right" style={_col.amount}>
              Designated
            </th>
            <th scope="col" className="text-right" style={_col.updated}>
              Updated
            </th>
            <th scope="col" className="text-right" style={_col.action}></th>
          </tr>
        </thead>
        <tbody style={{ height: tableHeight }}>
          {designations.map((df) => (
            <tr key={"df-" + df.id}>
              <td>
                <Link to={Links.financeFor(df.financeId)}>
                  {df.financeName}
                </Link>
              </td>
              <td className="text-right" style={_col.amount}>
                <Money amount={df.designationAmount} />
              </td>
              <td className="text-right" style={_col.updated}>
                {df.lastUpdatedDate}
              </td>
              <td className="text-right" style={_col.action}>
                <Button
                  className="btn btn-sm"
                  onClick={() =>
                    dlgDesignationTransfer(df.financeId, df.designationId)
                  }
                >
                  <FontAwesomeIcon icon={faRandom} />
                </Button>
              </td>
            </tr>
          ))}
          <tr
            style={{
              borderTop: "2px solid black",
              fontSize: "1.2em",
              textTransform: "uppercase",
            }}
          >
            <th className="text-right" style={{ backgroundColor: "white" }}>
              Designations Total
            </th>
            <th
              className="text-right"
              style={{ ..._col.amount, backgroundColor: "white" }}
            >
              <Money amount={totalDesignationAmt} />
            </th>
            <th style={{ ..._col.updated, backgroundColor: "white" }}></th>
            <th style={{ ..._col.action, backgroundColor: "white" }}></th>
          </tr>
        </tbody>
      </table>

      {isShowDesignationTransferDlg && (
        <TransferDesignation
          show={isShowDesignationTransferDlg}
          onClose={() => showDesignationTransferDlg(false)}
          onTransfer={refreshDesignationInfo}
          financeId={selectedFinanceId}
          designationId={selectedDesignationId}
        />
      )}
    </Fragment>
  );
};

export default DesignationFinancesList;
